import React, {useState} from 'react';
import Page from "../../Molecules/Page/Page";
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../Organisms/Layout/SplitLayout';
import styles from "./DepositTransfer3aPage.module.scss"
import FooterNavbar from '../../Molecules/Navbar/FooterNavbar';
import t from '../../../App/Libs/Translate/Translate';
import Lists from '../../../Assets/Data/Transfer3A.json';
import InputSearchPanel, {additionalInfoType} from "../../Molecules/InputSearchPanel/InputSearchPanel";
import {RouteComponentProps} from "react-router-dom";

const DepositTransfer3aPage = (props: RouteComponentProps) => {

  const [disableButton, setDisableButton] = useState<boolean>(true)
  const [showInput, setShowInput] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>()
  const [additionalInfo, setAdditionalInfo] = useState<additionalInfoType | null>(null)

  const renderFooter = () => {
    return <FooterNavbar
      buttonBackText={t("common.back")}
      linkToBack='/Dashboard/OverviewDepots3a/StrategyDetails'
      onNextClick={onSubmit}
      buttonNextText={t("common.continue")}
      buttonNextDisabled={disableButton}
    />
  }

  const onSearchChange = (e: HTMLInputElement) => {
    setShowInput(false)
    setDisableButton(true)
}

  const onSearchSelect = (value: string) => {
    if (value.toLowerCase() === "andere")
        setShowInput(true)
    else {
        setDisableButton(false)
        setShowInput(false)
    }
    setSearchText(value);
  }

  const additionalInfoInput = (data : additionalInfoType | null) => {
    setDisableButton(false);
    if (data) {
      setAdditionalInfo(data);
    } else {
      setDisableButton(true)
    }
  }

  const onSubmit = () => {
      props.history.push("/Dashboard/OverviewDepots3a/DepositTransferSuccessPage")
  }


  return (
    <Page>
      <OnboardingLayout
        footer={renderFooter()}
        contentMaxWidth='1600px'
        hideStepper={true}
        showBackgroundImage={true}
        backButtonLink="/Dashboard/OverviewDepots3a/StrategyDetails"
      >
        <SplitLayout className={styles.splitView}>
          <div className={styles.titleSection}>
            <h1 className={styles.title}>{t('register.contract.transfer_benefits_3a.title')}</h1>
            <p className={styles.subtitle}>{t('register.contract.transfer_benefits_3a.subtitle')}</p>
          </div>
          <InputSearchPanel onSearchSelect={onSearchSelect}
                            onSearchChange={onSearchChange}
                            lists={Lists}
                            showInput={showInput}
                            getAdditionalInfoInput={additionalInfoInput}
          />
        </SplitLayout>
      </OnboardingLayout>
    </Page>
  );
};

export default DepositTransfer3aPage;
