import React, { InputHTMLAttributes, useState } from 'react';
import styles from './SwitchBox.module.scss';

type SwitchBoxStringsProps = {
    sendData?: any,
    name?: string,
    id?: string,
    className?: string
    children?: React.ReactNode
}

type SwitchBoxProps = SwitchBoxStringsProps & React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>


const SwitchBox: React.FC<SwitchBoxProps> = (props) => {

    const [isChecked, setIsChecked] = useState<boolean>(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target

        setIsChecked(checked)

        if(props.onChange)
            props.onChange(e)

    }

    return (
        <label className={styles.wrapper}>
                <input {...props}
                    type="checkbox"
                    className={styles.checkbox}
                    defaultChecked={isChecked}
                    children={null}
                    onChange={e => handleChange(e)}
                />
                <span className={styles.switch}></span>
                <span className={styles.content}>{props.children}</span>
            </label>
    );
};

export default SwitchBox;