import { Route, Switch } from "react-router-dom";
import RegisterConfirmCodePage from "../Components/Pages/Register/RegisterConfirmCodePage";
import RegisterPersonalInfoPage from "../Components/Pages/Register/RegisterPersonalInfoPage";
import RegisterSuccessPage from "../Components/Pages/Register/RegisterSuccessPage";

// Temporary for testing
import LoginPage from "../Components/Pages/Account/Login/LoginPage";
import AdditionalAccountPage from "../Components/Pages/Account/AdditionalAccountPage";


const GuestRoutes = () => {

  return (
    <Switch>
      <Route exact path="/onboarding/Personal" component={RegisterPersonalInfoPage} />
      <Route exact path="/onboarding/Confirm" component={RegisterConfirmCodePage} />
      <Route exact path="/onboarding/Success" component={RegisterSuccessPage} />

      <Route exact path="/Login" component={LoginPage} />


      <Route path="/Home" render={() => {
        window.location.assign("/onboarding/Personal")
        return null
      }} />

      <Route exact path="/onboarding/Welcome" render={() => {
        window.location.assign("/onboarding/Personal")
        return null
      }} />

      {/* Temporary for testing */}
      <Route exact path="/onboarding/Open/Additional" component={AdditionalAccountPage} />

        <Route path="*" render={() => {
            window.location.assign("/onboarding/Personal")
            // window.location.assign("/home")
            return null
        }}/>
    </Switch>
  );
};

export default GuestRoutes;
