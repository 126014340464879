import { AxiosError, CancelToken, CancelTokenSource } from "axios"
import { Callback } from "../../Models/Callback";
import { ApiError } from "../../Models/Contracts/Common/ApiError";
import { WebClientResponse } from "./WebClientResponse"


export interface IWebClientPromise<T extends any>{
    onSuccess(callback : Callback<WebClientResponse<T>>): IWebClientPromise<T>;
    onFail(callback : Callback<AxiosError<ApiError>>): IWebClientPromise<T>;
    abortController(callback: Callback<AbortController>): IWebClientPromise<T>;
}



export default class WebClientPromise<T> implements IWebClientPromise<T>{
   

    private _onComplete: Array<Callback<WebClientResponse>> = []
    private _onFail: Array<Callback<AxiosError>> = []
    private _abortController: AbortController

    public onSuccess(callback? : Callback<WebClientResponse<T>>): IWebClientPromise<T>{
        if(callback)
            this._onComplete.push(callback)
        return this
    }

    public onFail(callback? : Callback<AxiosError<ApiError>>): IWebClientPromise<T> {
        if(callback)
            this._onFail.push(callback)
        return this
    }

    abortController(callback: Callback<AbortController>): IWebClientPromise<T> {
        callback(this._abortController)
        return this
    }

    public fireonSuccess(response: WebClientResponse): void{
        this._onComplete.forEach(onSuccess => onSuccess(response))
    }

    public fireOnFail(error: AxiosError){
        this._onFail.forEach(onFail => onFail(error))
    }

    public setAbortController(Controller: AbortController){
        this._abortController = Controller
    }

}
