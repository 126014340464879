import React, { useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ChooseProductPageLayout from "../../../Organisms/Layout/ChooseProductPageLayout/ChooseProductPageLayout";
import Page from "../../../Molecules/Page/Page";
import OnboardingLayout from "../../../Organisms/Layout/OnboardingLayout";
import { ComplianceActions } from '../../../../App/Mobx/Actions/ComplianceActions';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import { observer } from 'mobx-react';
import { ComponentDidMount } from '../../../../App/Libs/Helper/Helper';
import t from '../../../../App/Libs/Translate/Translate'
import WebSync from "../../../Molecules/DataSync/WebSync";
import { ProductDto } from '../../../../App/Models/Contracts/Compliance/ProductDto';

const DashboardAdditionalAccountPage = observer((props: RouteComponentProps) => {


  var store = useStore()
  const webRef = useRef<WebSync>()


  function load() {
    webRef.current.Activity.show();

    ComplianceActions
      .GetProducts()
      .onSuccess(() => webRef.current.Activity.hide()
      )
      .onFail(() => webRef.current.Activity.hide()
      )
  }

  ComponentDidMount(() => {
    load()
  })



  const onProductClick = (product: ProductDto) => {

    if (product.id === store.Compliance.Pens3AProduct().id)
      props.history.push("/dashboard/portfolio/add/prefer-strategy")
    else
      props.history.push("/dashboard/portfolio/add/source")

    // If the user creates a new portfolio and already has a 3a (only one), he has the option of adopting the strategy.

    // If the user create a new portfolio without having a 3a yet or has more than one, he doesn't have the Option of adopting the strategy
    // props.history.push("/dashboard/portfolio/add/experience")

  }


  return (
    <Page>
      <WebSync ref={webRef} >
        <OnboardingLayout
          progress={1}
          selectedStepIndex={0}
        >
          <ChooseProductPageLayout
            {...props}
            title={t("dashboard.additional.account.title")}
            secondaryText={t("dashboard.additional.account.subtitle")}
            products={store.Compliance?.Products}
            onProductClick={onProductClick}
          />
        </OnboardingLayout>
      </WebSync>
    </Page>
  );
});

export default DashboardAdditionalAccountPage;
