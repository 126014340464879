import styles from "./DashboardStrategyRelevateExcitedPage.module.scss";
import { Button } from "../../Atoms/Button/Button";
import dartsIcon from "../../../Assets/Images/Dashboard/darts.svg";
import detailsIcon from "../../../Assets/Images/PortfolioDetailsBtn.svg";
import React, { useEffect, useState } from "react";
import Page from "../../Molecules/Page/Page";
import DashboardLayout from "../../Organisms/Layout/DashboardLayout";
import StrategyRisksInfo, { Disclaimer } from "../../Organisms/StrategyRisksInfo/StrategyRisksInfo";
import { ModelPortfolioDto } from "../../../App/Models/Contracts/Strategy/ModelPortfolioDto";
import { StrategyDto } from "../../../App/Models/Contracts/Strategy/StrategyDto";
import { LargeButton } from "../../Atoms/Button/LargeButton";
import Dropdown from "../../Atoms/Dropdown/Dropdown";
import editIcon from "../../../Assets/Images/Edit/Pencil.svg";
import trashIcon from "../../../Assets/Images/Edit/Trash.svg";
import { RouteComponentProps, useParams } from "react-router-dom";
import WarningPopup from '../../Molecules/Popup/WarningPopup';
import Input from '../../Atoms/Input/Input';
import { Image } from 'react-bootstrap';
import ok from "../../../Assets/Images/Dashboard/ok.svg"
import { useStore } from "../../../App/Mobx/Infrastructure/mobx-hook";
import { PortfolioActions } from "../../../App/Mobx/Actions/PortfolioActions";

type Params = {
  id?: string
}
const DashboardStrategyRelevateExcitedPage = (props: RouteComponentProps) => {

  const [details, setDetails] = useState<ModelPortfolioDto>();
  const [data, setData] = useState<StrategyDto>();
  const [strategyIndex, setStrategyIndex] = useState<number>()
  const [show, setShow] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);


  const [modelPortfolio, setModelPortfolio] = useState<ModelPortfolioDto>()

  const { Dashboard } = useStore()
  const { id } = useParams<Params>()
  const Account = Dashboard.getPortfolioById(parseInt(id))
  if (!Account)
    props.history.replace('/')

  const onHandleChangeStrategy = () => {
    props.history.push("/Dashboard/OverviewDepots/ChooseStrategy");
  }

  const CustomToggle = React.forwardRef<any, customToggleProps>(({ children, onClick }, ref) => (
    <span
      ref={ref}
      className={styles.editStrategyButton}
      onClick={e => onClick(e)}
    >
      {children}
      <span className={styles.buttonIcon}><img src={detailsIcon} alt={"dots icon"} /></span>
      Bearbeiten
    </span>

  ));

  const editPortfolioName = () => {
    setShow(true)
  }

  const deletePortfolio = () => {

  }

  const onAlertClose = () => {
    setShow(false);
    setShowSuccess(false);
  }

  const onAlertHide = () => {
    setShowSuccess(true)
  }

  type customToggleProps = {
    onClick?: any
    children?: React.ReactNode
  }

  function load() {
    return PortfolioActions
      .GetProposalModelPortfolio(Account.portfolioProposalId)
      .onSuccess(x => setModelPortfolio(x.data))
  }

  return (
    <Page>
      <DashboardLayout>
        <div className={styles.header}>
          <div>
            <h3 className={styles.account}>Freizügigkeitsdepot</h3>
            <div className={styles.portfolioName}>{Account.name}</div>
            <h2 className={styles.title}>strategy <br />relevate {modelPortfolio.riskCategory.name.toLowerCase()}</h2>
          </div>
          <div className={styles.strategyButtonContainer}>
            <Button className={styles.editStrategyButton} onClick={onHandleChangeStrategy}>
              <span className={styles.buttonIcon}><img src={dartsIcon} alt={"darts icon"} /></span>
              Strategie ändern
            </Button>
            <Dropdown customToggle={CustomToggle}>
              <div className={styles.contentPortfolioDetailsMenu}>
                <div
                  className={styles.contentPortfolioDetailsMenuLink}
                  onClick={() => editPortfolioName()}>
                  Name ändern <img className={styles.whiteIcon} src={editIcon} alt="" />
                </div>
                <div
                  className={styles.contentPortfolioDetailsMenuLink}
                  onClick={() => deletePortfolio()}>
                  Depot saldieren <img className={styles.whiteIcon} src={trashIcon} alt="" />
                </div>
              </div>
            </Dropdown>
          </div>
        </div>

        {details &&
          <div className={styles.container}>
            {modelPortfolio &&
              <StrategyRisksInfo details={modelPortfolio} data={data} detailsIndex={strategyIndex} showDescription={true} />
            }
          </div>
        }
        <LargeButton className={styles.largeButton} to="/Dashboard/OverviewDepots/ChooseStrategy">Strategie anpassen</LargeButton>
        <div className={styles.container}>
          <Disclaimer />
          <Button to="/Dashboard/OverviewDepots/ChooseStrategy" className={styles.confirmButton}>Strategie anpassen</Button>
        </div>

        <WarningPopup show={show} onExit={() => onAlertClose()}>
          <div className={styles.warningMain}>

            {showSuccess ?
              <div className={styles.showSuccessContainer}>
                <Image src={ok} alt="" />
                <h3>Änderungen gespeichert</h3>
              </div> :
              <>
                <Input
                  placeholder="Freizügigkeitsdepot Risk"
                  type="text"
                />
                <p>Maximal 20 Zeichen</p>
                <Button
                  className={styles.warningButton}
                  to=""
                  onClick={() => onAlertHide()}>speichern</Button>
              </>
            }

          </div>
        </WarningPopup>

      </DashboardLayout>
    </Page>
  )
}

export default DashboardStrategyRelevateExcitedPage;