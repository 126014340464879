import React from 'react';
import styles from './SwitchRadio.module.scss';


export type SwitchRadioProps = {
    name?: string
    value?: string | number
    onInput?: React.FormEventHandler<HTMLInputElement>
    isDefaultChecked?: boolean
}

const SwitchRadio: React.FC<SwitchRadioProps> = (props) => {
    return (
        <input 
            className={styles.radio} type="radio"
            name={props.name} value={props.value} onInput={props.onInput} defaultChecked={props.isDefaultChecked}/>
    );
};

export default SwitchRadio;