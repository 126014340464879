import t from "../../../../App/Libs/Translate/Translate";
import React, {useRef, useState} from "react";
import {useStore} from "../../../../App/Mobx/Infrastructure/mobx-hook";
import WebSync from "../../../Molecules/DataSync/WebSync";
import {ComponentDidMount} from "../../../../App/Libs/Helper/Helper";
import {PortfolioActions} from "../../../../App/Mobx/Actions/PortfolioActions";
import {TransferSource} from "../../../../App/Models/Enums/TransferSource";
import {ContactActions} from "../../../../App/Mobx/Actions/ContactActions";
import {ProductEnum} from "../../../../App/Models/Enums/ProductEnum";
import Page from "../../../Molecules/Page/Page";
import OnboardingLayout from "../../../Organisms/Layout/OnboardingLayout";
import styles from "../../Account/AccountFinancialKnowledgeSurveyPage.module.scss";
import SurveyAnswerSimple from "../../../Organisms/Survey/SurveyAnswerSimple";
import {RouteComponentProps} from "react-router-dom";

const DashboardInvestmentExperiencePage = (props: RouteComponentProps) => {

  const financialKnowledgeOptions = [
    {
      id: '1',
      title: t('account.survey.financial.knowledge.little_knowledge_title'),
      description: t('account.survey.financial.knowledge.little_knowledge_desc'),
    },
    {
      id: '2',
      title: t('account.survey.financial.knowledge.advanced_knowledge_title'),
      description: t('account.survey.financial.knowledge.advanced_knowledge_desc'),
    },
    {
      id: '3',
      title: t('account.survey.financial.knowledge.expert_title'),
      description: t('account.survey.financial.knowledge.expert_desc'),
    }
  ]

  const [selectedAnswerId, setSelectedAnswerId] = useState<string>()

  const { Account } = useStore()

  const [isSynced, setIsSynced] = useState<boolean>(true)

  const webRef = useRef<WebSync>()

  // !!! left commented out code to make it easier for you to implement the middle layer

  ComponentDidMount(() => {
    // if (!Account.proposalId) {
    //   PortfolioActions
    //     .PostPortfolioProposal({
    //       productId: Account.product.id,
    //       transferSource: TransferSource.PK
    //     })
    //     .onSuccess(response => {
    //       Account.proposalId = response.data.proposalId
    //       setIsSynced(true)
    //     })
    // }
    // else {
    //   setIsSynced(true)
    // }
  })

  function onAnswerClick(id: string) {
    props.history.push('/dashboard')
  }

  // function goToNextPage() {
  //   if (Account.product.id === ProductEnum.Independent)
  //     props.history.push('/onboarding/Assets/Value')
  //   else{
  //     webRef.current.Activity.show()
  //     PortfolioActions
  //       .PostPortfolioProposal({
  //         productId: Account.product.id,
  //         transferSource: TransferSource.PK
  //       })
  //       .onSuccess(response => {
  //         Account.proposalId = response.data.proposalId
  //         Account.transferSource = TransferSource.PK
  //         Account.virtualPortfolios = [{
  //           name: "Porfolio 1",
  //           productId: Account.product.id,
  //           proposalId: Account.proposalId
  //         }]
  //         props.history.push('/onboarding/Survey/Horizon')
  //         webRef.current.Activity.hide()
  //       })
  //       .onFail(() => webRef.current.Activity.hide())
  //   }
  // }

  return (
    <Page>
      <WebSync ref={webRef}>
        <OnboardingLayout
          contentMaxWidth='1600px'
          selectedStepIndex={1} progress={0.5}>
          {isSynced &&
            <div className={styles.main}>
              <div className={styles.titleSection}>
                <h1 className={styles.title}>{t('account.survey.financial.knowledge.title')}</h1>
                <h3 className={styles.subtitle}>{t('account.survey.financial.knowledge.subtitle')}</h3>
              </div>
              <div className={styles.selectors}>
                {financialKnowledgeOptions.map(option => (
                  <SurveyAnswerSimple
                    key={option.id}
                    title={option.title}
                    description={option.description}
                    isChecked={option.id === selectedAnswerId}
                    onClick={() => onAnswerClick(option.id)}
                  ></SurveyAnswerSimple>
                ))}
              </div>
            </div>

          }
        </OnboardingLayout>
      </WebSync>
    </Page>
  );
};

export default DashboardInvestmentExperiencePage;