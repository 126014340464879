import React from 'react';
import classNames from 'classnames';

import downloadIcon from '../../../Assets/Images/Dashboard/Documents/download_icon.svg';
import documentIcon from '../../../Assets/Images/Dashboard/Documents/document_icon.svg';

import styles from './DashboardDocuments.module.scss';

type DocumentsDashboardProps = {
    title?: string,
    data?: any,
}

const DashboardDocuments = (props: DocumentsDashboardProps) =>{

    const { title, data } = props;

    const downloadDocument = (id: number) => {
        // TO DO
    }

    return (
    <div className={styles.documentsDashboard}>
        <div className={styles.documentsTitle}>{title || 'N/A'}</div>
        {data.map((option: { name: string; date: string; id: number; active: boolean; }) => (
            <div className={styles.documentDashboardContainer}>
                <div>
                    <span className={styles.documentIcon}>
                        <img src={documentIcon} alt="" />
                    </span>
                    <span className={styles.documentName}>
                        {option.name}
                    </span>
                </div>
                <div>
                    <span className={classNames(option.active && styles.active, styles.documentDate)}>
                        {option.date}
                    </span>
                    <span className={styles.documentIcon}>
                        <img src={downloadIcon} alt="" onClick={() => downloadDocument(option.id)} />
                    </span>
                </div>
            </div>
        ))}
    </div>
    )
};

export default DashboardDocuments;
