import React from 'react';
import styles from './ValidationErrorMessage.module.scss'
import errorIco from '../../../Assets/Images/Feedback_error.svg'
import classNames from "classnames";


type Props = {
  message: string,
  className?: string
}

const ValidationErrorMessage = (props: Props) => {
  return (
    <div className={classNames(styles.main, props.className)}>
      <div className={styles.icoContainer}><img src={errorIco} alt="error icon"/></div>
      <div className={styles.errorMessage}>{props.message}</div>
    </div>
  )

};

export default ValidationErrorMessage;
