import React from 'react';
import styles from './InputForm.module.scss';
import Datepicker from "../../Atoms/Datepicker/Datepicker";
import Input from '../../Atoms/Input/Input';
import Textarea from "../../Atoms/Textarea/Textarea";
import { useForm } from "react-hook-form";
import t from '../../../App/Libs/Translate/Translate'

const ERRORS = {
    required: `${t('errors.required')}`,
}

type Inputs = {
    durationOfSuspension: string,
    adress: string,
    ort: string,
    plz: string,
};

interface Props {
    duration?: string
    year?: string
    duration_of_suspension?:string
    months?: string
    what?: string
    type_illness_accident?: string
    place_of_treatment_2?: string
    doctor_hospital?: string
    address?: string
    postcode?: string
    location?: string
    add_another_record?: string
    children?: any
}


const InputForm: React.FC<Props> = (props) => {
    const {
        register,
        formState: { errors },
    } = useForm<Inputs>();

    return (
        <div className={styles.answerContainer}>
            <div className={styles.titleAnswer}>{props.duration}</div>
            <Datepicker
                onChange={() => {}}
                placeholderText={props.year}
            />
            <div className={styles.relative}>
                <Input
                    placeholder={props.duration_of_suspension}
                    {...register("durationOfSuspension", {
                    required: true,
                    })}
                    type={"number"}
                    error={errors.durationOfSuspension && ERRORS.required}
                />
                <div className={styles.sufix}>{props.months}</div>
            </div>
            <hr className={styles.ansferLine}/>
            <div className={styles.titleAnswer}>{props.what}</div>
            <div className={styles.block}>
                <p className={styles.labelOptional}>{props.type_illness_accident}</p>
                <Textarea></Textarea>
            </div>
            <hr className={styles.ansferLine} />
            
            <div className={styles.titleAnswer}>{props.place_of_treatment_2}</div>
            <div className={styles.block}>
                <p className={styles.labelOptional}>{props.doctor_hospital}</p>
                <Textarea></Textarea>
            </div>
            <div>
                    <Input
                        placeholder={props.address}
                        {...register("adress", {
                        required: true,
                        })}
                        error={errors.adress && ERRORS.required}
                    />
            </div>
            <div className={styles.flex}>
                <span className={styles.inputWidth}>
                    <Input
                        placeholder={props.postcode}
                        {...register("plz", {
                        required: true,
                        })}
                        error={errors.plz && ERRORS.required}
                    />
                </span>
                <span className={styles.inputWidth100}>
                    <Input
                        placeholder={props.location}
                        {...register("ort", {
                        required: true,
                        })}
                        error={errors.ort && ERRORS.required}
                    />
                </span>
            </div>
        </div>
    );
};

export default InputForm;

