import classNames from 'classnames';
import React, {useState} from 'react';
import {Image} from 'react-bootstrap';
import closeImg from '../../../../Assets/Images/Close_menu.svg';
import Popup from '../../../Atoms/Popup/Popup';
import styles from './DashboardSideMenu.module.scss';
import {Callback} from "../../../../App/Models/Callback";
import Link from "../../../Atoms/Link/Link";
import LangSelect from "../../../Atoms/Select/LangSelect/LangSelect";
import t from '../../../../App/Libs/Translate/Translate'
import Menu from  '../../Menu/Menu';
import Logo from '../../../Atoms/Logo/Logo';

type SideMenuProps = {
  className?: string
  frameClassName?: string
  show?: boolean
  onExit?: Callback<HTMLElement>
}

const DashboardSideMenu: React.FC<SideMenuProps> = (props) => {

  const [isOpened, setIsOpened] = useState(false);

  const handleToggleMenu = () => {
    setIsOpened(!isOpened)
  }

  return (
    <>
      <div className={styles.burgerButton} onClick={handleToggleMenu}/>
      <Popup className={styles.popup} {...props} show={isOpened}>
        <div className={classNames(styles.main, props.frameClassName)}>
          <div className={styles.headerContainer}>
            <Logo className={styles.logo}></Logo>
            <div className={styles.actionBar}>
              <Image src={closeImg} onClick={handleToggleMenu}/>
            </div>
          </div>
          <Menu></Menu>
        </div>
      </Popup>
    </>
  );
};

export default DashboardSideMenu;
