import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Button } from '../../Atoms/Button/Button';
import Datepicker from '../../Atoms/Datepicker/Datepicker';
import Input from '../../Atoms/Input/Input';
import Select from '../../Atoms/Select/Select';
import Password from '../../Atoms/Input/Password';
import Radio from '../../Atoms/Radio/Radio';
import Page from '../../Molecules/Page/Page';
import DashboardTitle from "../../Atoms/DashboardTitle/DashboardTitle";
import DashboardContainer from "../../Atoms/DashboardContainer/DashboardContainer";
import DashboardLayout from "../../Organisms/Layout/DashboardLayout";
import ValidationErrorMessage from "../../Atoms/ValidationErrorMessage/ValidationErrorMessage";
import ProfileChangePassword from '../../Molecules/ProfileChangePassword/ProfileChangePassword';
import isAnyValidationErrors from "../../../App/Libs/Validation/Validation";
import styles from './ProfilePage.module.scss';
import { ContactActions } from '../../../App/Mobx/Actions/ContactActions';
import { GetPersonalInfoRequestDto } from '../../../App/Models/Contracts/Contact/GetPersonalInformationDto';
import t from '../../../App/Libs/Translate/Translate';

const residenceList = [
  { id: "1", value: t("common.residences.switzerland") },
  { id: "2", value: t("common.residences.germany") },
  { id: "3", value: t("common.residences.france") }
]

const SWITZERLAND_ID = residenceList[0].id;

type Inputs = {
  salutation: string,
  firstName: string,
  secondName: string,
  email: string,
  phone: string,
  address: string,
  postIndex: string,
  location: string,
  residence: string,
  birthdate: Date,
  password: string,
};

const residenceSwissID = residenceList.find(item => item.value === t("common.residences.switzerland"))?.id

const ERRORS = {
  required: t("errors.required"),
  emailIsIncorrect: t("errors.email_is_incorrect"),
  mustBeOverEighteen: t("errors.must_be_over_eighteen"),
  passRequirements: t("errors.pass_requirements"),
  passMatch: t("errors.pass_match"),
  onlySwiss: t("errors.only_swiss"),
}

const DashboardProfilePage = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<Inputs>();

  const [IsVissiblePasswordChange, setIsVissiblePasswordChange] = useState(false);
  const [buttonEnabled, setButtonDisabled] = useState(false);

  const [data, setData] = useState<GetPersonalInfoRequestDto>()

  const onSubmit: SubmitHandler<Inputs> = data => {
    //@TODO do the request
  }

  const isOverEighteen = (date: Date) => {
    let TODAY = new Date(Date.now());
    let EIGHTEEN_YEARS_BACK = new Date(new Date(TODAY).getDate() + "/" + new Date(TODAY).getMonth() + "/" + (new Date(TODAY).getFullYear() - 18));
    let USER_INPUT = new Date(date);
    return EIGHTEEN_YEARS_BACK > USER_INPUT;
  }

  const getEmailValidationError = () => {
    if (errors.email?.type === 'required') {
      return ERRORS.required
    }

    if (errors.email?.type === 'pattern') {
      return ERRORS.emailIsIncorrect
    }

    return null
  }

  const load = () => {
    return ContactActions
      .GetPersonalInformation()
      .onSuccess(res => setData(res.data))
  }

  function getAddress(){
    if(data?.street && data?.propertyNumber)
      return `${data?.street} ${data?.propertyNumber}`

    return data?.street
  }

  function getName(){
    if(data)
      return `${data?.firstName} ${data?.lastName}`

    return undefined
  }

  return (
    <Page>
      <DashboardLayout sync={() => load()}>
        <DashboardContainer>
            <div className={styles.main}>
              <Form className={styles.form}>
                <DashboardTitle>{t("dashbord.profile.title")}</DashboardTitle>
                <div className={styles.salutationWrapper}>
                  <div className={styles.radioWrapper}>
                    <Radio
                      className={styles.radio}
                      value="300"
                      checked={data?.salutation === 0}
                      {...register("salutation", {
                        required: true,
                      })}
                    >
                      {t("common.genders.woman")}
                    </Radio>
                    <Radio
                      className={styles.radio}
                      value="299"
                      checked={data?.salutation === 1}
                      {...register("salutation", {
                        required: true,
                      })}
                    >
                      {t("common.genders.male")}
                    </Radio>
                  </div>
                  {errors.salutation?.type === 'required' && <ValidationErrorMessage message={ERRORS.required} />}
                </div>
                <label>
                  <Input
                    placeholder={t("common.first_name")}
                    defaultValue={data?.firstName}
                    {...register("firstName", {
                      required: true,
                    })}
                    error={errors.firstName && ERRORS.required}
                  />
                </label>
                <label>
                  <Input
                    placeholder={t("common.surname")}
                    defaultValue={data?.lastName}
                    {...register("secondName", {
                      required: true,
                    })}
                    error={errors.secondName && ERRORS.required}
                  />
                </label>

                <label>
                  <Input
                    placeholder={t("common.address")}
                    defaultValue={getAddress()}
                    {...register("address", {
                      required: true,
                    })}
                    error={errors.address && ERRORS.required}
                  />
                </label>
                <label>
                  <Input placeholder={t("common.address_supplement")}
                    defaultValue={data?.addressLine2}
                  />
                </label>
                <section className={styles.postalSection}>
                  <label>
                    <Input
                      type="number"
                      placeholder={t("common.postcode")}
                      defaultValue={data?.postalCode}
                      {...register("postIndex", {
                        required: true,
                      })}
                      error={errors.postIndex && ERRORS.required}
                    />
                  </label>
                  <label>
                    <Input
                      placeholder={t("common.location")}
                      defaultValue={data?.city}
                      {...register("location", {
                        required: true,
                      })}
                      error={errors.location && ERRORS.required}
                    />
                  </label>
                </section>

                <hr />

                <label className={styles.residence}>
                  <Controller
                    control={control}
                    name='residence'
                    render={({ field }) => (
                      <Select
                        itemsList={residenceList}
                        placeholder={t('common.residence')}
                        onChange={(v: string) => field.onChange(parseInt(v))}
                        errorMessage={errors.residence && ERRORS.onlySwiss}
                      />
                    )}
                    rules={{
                      required: true,
                      validate: id => id === SWITZERLAND_ID,
                    }}
                  />
                </label>
                <label className={styles.birthdate}>
                  <Controller
                    control={control}
                    name='birthdate'
                    render={({ field }) => (
                      <Datepicker
                        onChange={(date: Date | null) => field.onChange(date)}
                        selected={field.value || (data?.birthDate && new Date(data?.birthDate))}
                      />
                    )}
                    rules={{
                      required: true,
                      validate: isOverEighteen,
                    }}
                  />
                  {errors.birthdate?.type === 'required' && <ValidationErrorMessage message={ERRORS.required} />}
                  {errors.birthdate?.type === 'validate' && <ValidationErrorMessage message={ERRORS.mustBeOverEighteen} />}
                </label>

                <Button
                  className={styles.button}
                  disabled={buttonEnabled}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('dashboard.profile.edit_profile')}
                </Button>

                <hr />

                <label>
                  <Input
                    type="email"
                    placeholder="E-Mail"
                    defaultValue={data?.email}
                    {...register("email", {
                      required: true,
                      pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                    error={getEmailValidationError()}
                  />
                </label>

                <Button
                  className={styles.button}
                  disabled={buttonEnabled}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('dashboard.profile.edit_email')}
                </Button>

                <hr />

                <label>
                  <Input
                    type="tel"
                    defaultValue={data?.phoneNumber}
                    placeholder={t("common.phone")}
                    {...register("phone", {
                      required: true,
                    })}
                    error={errors.phone && ERRORS.required}
                  />
                </label>

                <Button
                  className={styles.button}
                  disabled={buttonEnabled}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('dashboard.profile.edit_phone_number')}
                </Button>

                <hr />

                {IsVissiblePasswordChange ? <ProfileChangePassword /> :

                  <label className={styles.passwordInput}>
                    <Password
                      placeholder={t("common.password")}
                      autoComplete='new-password'
                      {...register("password", {
                        required: true,
                        pattern: /(?=.*\d)(?=.*[!@#$%^&.*])(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z!@#$%^&.*]{8,}/g,
                      })}
                      error={errors.password && ERRORS.passRequirements}
                    />
                  </label>
                }

                <label className={styles.passwordLink} onClick={() => { setIsVissiblePasswordChange(!IsVissiblePasswordChange) }}>
                  {t("common.change_password")}
                </label>

                {isAnyValidationErrors(errors) && <ValidationErrorMessage message={t("errors.incorrect_entries")} />}

              </Form>
            </div>
        </DashboardContainer>
      </DashboardLayout>
    </Page>
  );
};

export default DashboardProfilePage;
