import classNames from 'classnames';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import addImg from '../../../Assets/Images/add.svg';
import Carousel, { CarouselEffects } from '../../Molecules/Carousel/Carousel';
import SwitchRadio from '../../Atoms/Switch/SwitchRadio';
import DetailInfoPopup from '../Popup/DetailInfoPopup'
import arrowLeftIcon from "../../../Assets/Images/arrow-left.svg"
import arrowRightIcon from "../../../Assets/Images/arrow-right.svg"
import styles from './RiskCarousel.module.scss';
import { Callback } from '../../../App/Models/Callback';
import t from '../../../App/Libs/Translate/Translate';

export type RiskCarouselProps = {
    className?: string
    onInput?: Callback<string>
}



const RiskCarousel = (props: RiskCarouselProps) => {

    const slides = [
        {
            name: t('register.survey.risk.slide_low_risk_title'),
            className: styles.riskProfileOne,
            description: t('register.survey.risk.slide_low_risk_description'),
        }
        , {
            name: t('register.survey.risk.slide_medium_risk_title'),
            className: styles.riskProfileTwo,
            description: t('register.survey.risk.slide_medium_risk_description'),
        },
        {
            name: t('register.survey.risk.slide_high_risk_title'),
            className: styles.riskProfileThree,
            description: t('register.survey.risk.slide_high_risk_description'),
        },
    ]
    const slidesNumber = slides.length


    const [selectedIndex, setSelectedIndex] = useState<number>(Math.floor(slidesNumber / 2))
    const [isDetailInfoPopupOpen, setIsDetailInfoPopupOpen] = useState<boolean>(false)

    function handleMoreInfoClick(slideIndex: number) {
        if (slideIndex === selectedIndex) {
            showDetailInfoPopup()
        }
    }

    function showDetailInfoPopup() {
        setIsDetailInfoPopupOpen(true)
    }

    function hideDetailInfoPopup() {
        setIsDetailInfoPopupOpen(false)
    }

    function renderSlides() {
        return slides.map((value, index) => {
            return (
              <div key={index} className={classNames(styles.slide, value.className)}>
                <div className={styles.slideContent}>
                    <div className={styles.slidePagination}>
                        <span className={styles.slideNumber}>{`0${index+1}`}</span>
                        <span className={styles.slidePaginationDelimeter}></span>
                        <span className={styles.slidesNumber}>{`0${slidesNumber}`}</span>
                    </div>

                    <span className={styles.slideTitle}>{value.name}</span>

                    <div className={styles.slideAction} onClick={() => handleMoreInfoClick(index)}><Image src={addImg} /> &nbsp;  {t('common.more_info')}</div>
                </div>

                <div className={styles.slideSwitch}>
                    <SwitchRadio value={index} name="strategy" onInput={e => props.onInput && props.onInput(e.currentTarget.value)}/>
                </div>
              </div>
            )
        })
    }

    function onLeftArrowClick() {
      setSelectedIndex(selectedIndex - 1)
    }

    function onRightArrowClick() {
      setSelectedIndex(selectedIndex + 1)
    }

    return (
        <div className={classNames(styles.main, props.className)}>
          <Carousel
              effect={CarouselEffects.Scale}
              className={styles.carousel}
              slidesClassName={styles.slidesContainer}
              indicatorClassName={styles.indicator}
              selectedIndex={selectedIndex}
          >
              {renderSlides()}
          </Carousel>

          <div className={styles.arrowButtonsWrapper}>
              { selectedIndex !== 0 && (
                  <button className={styles.leftArrowButton} onClick={() => onLeftArrowClick()}>
                    <img src={arrowLeftIcon} alt="previous slide button"/>
                  </button>
              )}

              { selectedIndex + 1 !== slidesNumber && (
                  <button className={styles.rightArrowButton} onClick={() => onRightArrowClick()}>
                    <img src={arrowRightIcon} alt="next slide button"/>
                  </button>
              )}
          </div>

          <DetailInfoPopup show={isDetailInfoPopupOpen} onExit={hideDetailInfoPopup}>

              <h3 className={styles.detailInfoTitle}>{slides[selectedIndex].name}</h3>
              <p className={styles.detailInfoDescription}>{slides[selectedIndex].description}</p>
          </DetailInfoPopup>
        </div>
    );
};

export default RiskCarousel;
