import 'bootstrap/dist/css/bootstrap.css';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Configuration from './App/Configs/Configuration';
import { Environment } from './App/Configs/Environment';
import { SsoAuth } from './App/Libs/Auth/SsoAuth';
import { ComponentDidMount } from './App/Libs/Helper/Helper';
import Theme, { ThemeOptions } from './App/Libs/Theme/Theme';
import { useStore } from './App/Mobx/Infrastructure/mobx-hook';
import "./Assets/Styles/App.scss";
import ProtectedRoute from './Components/Pages/PasswordProtect/ProtectedRoute';
import AuthRoutes from './Routes/OnboardingRoutes';
import GuestRoutes from './Routes/GuestRoutes';
import OnboardingRoutes from './Routes/OnboardingRoutes';
import DashboardRoutes from './Routes/DashboardRoutes';
import SsoSynchronizationPage from './Components/Pages/SSO/SsoSynchronizationPage';


Configuration.Configure()
Theme.Configure(ThemeOptions.Dark)

const App = observer(() => {

  var { Auth, App } = useStore()


  const [isAuth, setIsAuth] = useState<boolean>(false)

  ComponentDidMount(() => {
    validateAuth()
  })

  function validateAuth() {
    var item = localStorage.getItem("isAuthenticated")
    if (item !== null)
      setIsAuth(true)
  }

  function GetRoutes() {

    {
      !isAuth &&
      <ProtectedRoute />
    }

    if(Environment.IsSsoAuthentication && !Auth.IsSsoSynchronized)
      return <SsoSynchronizationPage />

    if(Auth?.IsOnboarded && Auth?.IsAuthenticated)
      return <DashboardRoutes />

    if (Environment.IsSsoAuthentication ||
      (Auth?.IsAuthenticated && !Auth?.IsOnboarded ))
      return <OnboardingRoutes />

    return <GuestRoutes />
  }

  if (!App?.isAppStarted)
    return null

  return (
    <div className="App">
      <BrowserRouter>
        {/* <Switch> */}
        {GetRoutes()}
        {/* </Switch> */}
      </BrowserRouter>
    </div>
  )
})

export default App;
