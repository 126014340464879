import { SubmitHandler, useForm } from "react-hook-form";
import { RouteComponentProps } from 'react-router-dom';
import isAnyValidationErrors from "../../../../App/Libs/Validation/Validation";
import { Button } from '../../../Atoms/Button/Button';
import { Input } from '../../../Atoms/Input/Input';
import Password from '../../../Atoms/Input/Password';
import Link from "../../../Atoms/Link/Link";
import ValidationErrorMessage from "../../../Atoms/ValidationErrorMessage/ValidationErrorMessage";
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../../Organisms/Layout/SplitLayout';

import { AuthActions } from '../../../../App/Mobx/Actions/AuthActions';
import { PortfolioActions } from '../../../../App/Mobx/Actions/PortfolioActions';
import styles from './LoginPage.module.scss';
import { GlobalStore } from "../../../../App/Mobx/Stores/MainStore";
import t from '../../../../App/Libs/Translate/Translate';


type Inputs = {
    email: string,
    password: string,
};

const ERRORS = {
    required: "Dieses Feld ist erforderlich",
    emailIsIncorrect: "E-Mail ungültig",
}

const LoginPage = (props: RouteComponentProps) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = data => {
        GlobalStore.Auth.IsOnboarded = true
        AuthActions
            .Login({
                UserName: data.email,
                Password: data.password
            })
            .onSuccess(response => {
                PortfolioActions
                    .GetInvestmentOverview()
                    .onSuccess(x =>{
                        GlobalStore.Auth.IsOnboarded = x.data?.portfolios && x.data.portfolios.length > 0
                    })
                    .onFail(x => {
                        sessionStorage.clear()
                    })

            })


        //@TODO do the request
    }

    const getEmailValidationError = () => {
        if (errors.email?.type === 'required') {
            return ERRORS.required
        }

        if (errors.email?.type === 'pattern') {
            return ERRORS.emailIsIncorrect
        }

        return null
    }

    return (
        <Page>
            <OnboardingLayout
                contentMaxWidth='1240px'
                hideStepper={true}
                showBackgroundImage={false}>

                <SplitLayout className={styles.splitView}>
                    <div className={styles.titleSection}>
                        <h1 className={styles.title}>{t("login.please_login")}</h1>
                    </div>
                    <div className={styles.contentSection}>
                        <label>
                            <Input
                                type="email"
                                error={getEmailValidationError()}
                                placeholder="E-Mail"
                                {...register("email", {
                                    required: true,
                                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                })}
                            />
                        </label>
                        <label>
                            <Password
                                placeholder={t("common.password")}
                                error={errors.password && ERRORS.required}
                                {...register("password", {
                                    required: true,
                                    pattern: /(?=.*\d)(?=.*[!@#$%^&.*])(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z!@#$%^&.*]{8,}/g,
                                })}
                            />
                        </label>
                        <Link to="/onboarding/ForgotPassword" className={styles.link}>{t("login.forgot_password")}</Link>

                        {isAnyValidationErrors(errors) && <ValidationErrorMessage message={t("errors.incorrect_entries")} />}

                        <Button
                            className={styles.button}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t("login.register")}
                        </Button>
                    </div>
                </SplitLayout>
            </OnboardingLayout>
        </Page>
    );
};

export default LoginPage;