import React, {useState} from 'react';
import SwitchBox from '../Atoms/Switch/SwitchBox';
import FooterNavBar from '../Molecules/Navbar/FooterNavbar';
import Page from '../Molecules/Page/Page';
import OnboardingLayout from '../Organisms/Layout/OnboardingLayout';
import PortfolioSplit from '../Organisms/Portfolio/PortfolioSplit';
import styles from './AccountSplitPortfolioTemplate.module.scss';
import Link from "../Atoms/Link/Link";
import addIcon from "../../Assets/Images/add.svg";
import TaxBenefitsCalculator from "../Organisms/TaxBenefitsCalculator/TaxBenefitsCalculator";
import SplitPortfolioInfoPopup from "../Organisms/Popup/SplitPortfolioInfoPopup/SplitPortfolioInfoPopup";
import { RouteComponentProps } from 'react-router-dom';
import t from '../../App/Libs/Translate/Translate';
import { VirtualPortfolioDto } from '../../App/Models/Contracts/Portfolio/VirtualPortfolioDto';

type AccountPortfolioSplitPageProps = {
  title: string;
  subtitle: string;
  selectedStepIndex: number;
  progress: number;
  portfolios: Array<VirtualPortfolioDto>
  portfoliosDummy: Array<{name: string, disabled: boolean}>
  onNextClick: () => void
  onSplitValueChange: (e: Array<VirtualPortfolioDto>) => void
  onSplitChange: (e: HTMLInputElement) => void
  isSplitEnabled: boolean
  assetAmount: number
}

const AccountPortfolioSplitPage = (props: RouteComponentProps & AccountPortfolioSplitPageProps) => {

  const [popupIsOpened, setPopupOpenedStatus] = useState(false);

  const openPopUp = () => {
    setPopupOpenedStatus(true)
  }

  const closePopUp = () => {
    setPopupOpenedStatus(false);
  }


  function renderFooter() {
    return <FooterNavBar onNextClick={() => props.onNextClick()}
                         buttonBackText={t('common.back')}
                         buttonNextText={t('account.portfolio.split.go_to_investment_horizon')}/>
  }

  return (
    <Page>
      <OnboardingLayout
        footer={renderFooter()}
        selectedStepIndex={props.selectedStepIndex}
        progress={props.progress}
        contentMaxWidth='1600px'>
        <section className={styles.mainSection}>
          <h1 className={styles.mainTitle}>{props.title}</h1>
          <div className={styles.mainDescrContainer}>
            <p className={styles.mainDescr}>{props.subtitle}</p>
            <Link className={styles.mainPopupButton} onClick={openPopUp}><img src={addIcon} alt={"add icon"}/>{t('common.learn_more')}</Link>
          </div>
        </section>

        <section className={styles.splitSection}>
          <div className={styles.splitTitleContainer}>
            <h2 className={styles.splitTitle}>{t('account.portfolio.split.portfolio_split')}</h2>
            <h4 className={styles.splitDescription}>{t('account.portfolio.split.portfolio_split_desc')}</h4>
            <div className={styles.splitCheckbox}>
              <SwitchBox onChange={e => props.onSplitChange(e.currentTarget)}>{t('account.portfolio.split.portfolio_split')}</SwitchBox></div>
          </div>
          <PortfolioSplit portfolios={props.portfoliosDummy}
                          amount={props.assetAmount}
                          isSplitEnabled={props.isSplitEnabled}
                          onChange={e => props.onSplitValueChange(e)}/>
        </section>

        {props.isSplitEnabled &&
          <TaxBenefitsCalculator
            className={styles.taxCalculator}
            amounts={props.portfolios.map(x => x.amount)}/>
        }
      </OnboardingLayout>

      <SplitPortfolioInfoPopup show={popupIsOpened} onExit={closePopUp}/>
    </Page>
  );
};

export default AccountPortfolioSplitPage;
