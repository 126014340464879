import React from 'react';
import { Callback } from '../../../App/Models/Callback';
import Link from '../../Atoms/Link/Link';
import Selector from '../../Molecules/Selector/Selector';
import styles from './Product.module.scss';


export type ProductPropsType = {
    id: number,
    title: string,
    description: string,
    className?: string
    onClick?: Callback<HTMLElement>
}


const Product = (props: ProductPropsType) => {
    return (
        <Link onClick={a => props.onClick && props.onClick(a)}>
            <Selector
                className={styles.product}>
                <h6 className={styles.title}>{props.title}</h6>
                <p className={styles.description}>{props.description}</p>
            </Selector>
        </Link>
    );
};

export default Product;
