import PreferentialTreatmentPageLayout from "../../Organisms/Layout/PreferentialTreatmentPageLayout";
import DownloadButton from "../../Atoms/Button/DownloadButton";
import styles from "./AdjustTheBenefitPage.module.scss"
import t from '../../../App/Libs/Translate/Translate';


const DashboardAdjustTheBenefitPage = () => {
  return (
    <PreferentialTreatmentPageLayout header={t("dashbord.adjust.benefit.header")}
                                     supportCardDescription={t("dashbord.risk.protection.support")}
                                     title={t("dashbord.adjust.benefit.title")}
    >
      <ol className={styles.list}>
        <li className={styles.listItem}>{t("dashbord.adjust.benefit.list.step1")}</li>
        <li className={styles.listItem}>{t("dashbord.adjust.benefit.list.step2")}</li>
        <li className={styles.listItem}>{t("dashbord.adjust.benefit.list.step3")}</li>
      </ol>step1
      <p className={styles.description}>{t("dashbord.adjust.benefit.description")}</p>
      <DownloadButton className={styles.downloadButton}>{t("dashbord.adjust.benefit.apply")}</DownloadButton>
    </PreferentialTreatmentPageLayout>

  )
}

export default DashboardAdjustTheBenefitPage;