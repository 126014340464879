import React from 'react';
import Page from "../../Molecules/Page/Page";
import DashboardContainer from "../../Atoms/DashboardContainer/DashboardContainer";
import DashboardLayout from '../../Organisms/Layout/DashboardLayout';
import styles from "./PreferentialTreatmentPageLayout.module.scss";
import ProfileSupport from "../ProfileSupport/ProfileSupport";
import WebSync from '../../Molecules/DataSync/WebSync';


type PreferentialTreatmentPageLayoutType = {
  header: string
  supportCardDescription: string
  title: string
  children: any
  syncRef?: React.MutableRefObject<WebSync>
}

const PreferentialTreatmentPageLayout = (props: PreferentialTreatmentPageLayoutType) => {
  return (
    <Page>
      <DashboardLayout syncRef={props.syncRef}>
        <DashboardContainer>
          <div className={styles.container}>
            <div className={styles.leftSideContainer}>
              <h1 className={styles.title}>{props.header}</h1>
              <ProfileSupport title={props.supportCardDescription} className={styles.supportCard}/>
            </div>
            <div className={styles.rightSideContainer}>
              <h4 className={styles.riskTitle}>
                {props.title}
              </h4>
              {props.children}
            </div>
          </div >
        </DashboardContainer>
      </DashboardLayout>
    </Page>
  );
};

export default PreferentialTreatmentPageLayout;
