import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../Organisms/Layout/SplitLayout';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import t from '../../../App/Libs/Translate/Translate'
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import styles from './DashboardPaymentOfRiskPremium.module.scss';
import Formatter from "../../../App/Libs/Formatter/Formatter";
import MoreInfoLink from "../../Organisms/Link/MoreInfoLink";
import {useState, useRef} from "react";
import InfoPopup from "../../Molecules/Popup/InfoPopup";
import Link from "../../Atoms/Link/Link";
import {RiskProtectionCalculationOutputModelDto} from '../../../App/Models/Contracts/Dashboard/RiskProtectionCalculationOutputModelDto';
import WebSync from '../../Molecules/DataSync/WebSync';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';

const DashboardPaymentOfRiskPremium = () => {

  const { RiskProtectionCalculation } = useStore();

  const [showInfoPopup, setShowInfoPopup] = useState<boolean>(false);

  const webRef = useRef<WebSync>();

  const renderFooter = () => {
    return <FooterNavBar
      linkToNext='/Dashboard/HealthCheck'
      buttonBackText={t('common.back')}
      buttonNextText={t('dashbord.survey.payment.risk.premium.next')}/>
  }

  return (
    <Page>
      <OnboardingLayout
        contentMaxWidth='1440px'
        footer={renderFooter()}>

        <SplitLayout className={styles.splitView}>
          <div className={styles.titleSection}>
            <h1 className={styles.title}>{t('dashbord.survey.payment.risk.premium.title')}</h1>
          </div>
          <div className={styles.contentSection}>
            <p>{t('dashbord.survey.payment.risk.premium.detail_desc_1')}</p>
            <p>{t('dashbord.survey.payment.risk.premium.detail_desc_2')}</p>
            <hr className={styles.line}/>
            <div>
              <div className={styles.amountTitle}>{t("dashbord.survey.risk.insurance.subtitle")}</div>
              <div className={styles.amountSubtitle}>{t("dashbord.survey.payment.risk.premium.subtitle")}</div>
              <div className={styles.amount}>CHF {Formatter.formatNumber(RiskProtectionCalculation.riskprotectionOutput?.yearPremiumPensionDisability.toFixed(2))}</div>
              <MoreInfoLink onClick={()=>setShowInfoPopup(true)}>{t("dashbord.survey.payment.risk.premium.annual_premiums_details")}</MoreInfoLink>
            </div>
            <hr className={styles.line}/>
            <div>
              <div className={styles.amountTitle}>{t("dashbord.survey.risk.insurance.second_subtitle")}</div>
              <div className={styles.amountSubtitle}>{t("dashbord.survey.payment.risk.premium.subtitle")}</div>
              <div className={styles.amount}>CHF {Formatter.formatNumber(RiskProtectionCalculation.riskprotectionOutput?.yearPremiumDeath.toFixed(2))}</div>

              <MoreInfoLink onClick={()=>setShowInfoPopup(true)}>{t("dashbord.survey.payment.risk.premium.annual_premiums_details")}</MoreInfoLink>
            </div>
            <hr className={styles.line}/>
            <InfoPopup show={showInfoPopup}
                       onExit={() => setShowInfoPopup(false)}
                       className={styles.popup}
                       frameClassName={styles.main}>
              <div className={styles.popupContainer}>
                <div className={styles.infoPopupTitle}>{t("dashbord.survey.payment.risk.premium.annual_premiums_details")}</div>
                <table className={styles.infoPopupTable}>
                  <tbody>
                  <tr className={styles.infoPopupTableRow}>
                    <td className={styles.infoPopupTableData}>{t("dashbord.survey.payment.risk.premium.annual_premium_44")}</td>
                    <td className={styles.infoPopupTableData}>CHF {RiskProtectionCalculation.riskprotectionOutput.yearPremiumUnder44Age}</td>
                  </tr>
                  <tr className={styles.infoPopupTableRow}>
                    <td className={styles.infoPopupTableData}>{t("dashbord.survey.payment.risk.premium.annual_premium_45_54")}</td>
                    <td className={styles.infoPopupTableData}>CHF {RiskProtectionCalculation.riskprotectionOutput.yearPremiumBetween45And54Age}</td>
                  </tr>
                  <tr className={styles.infoPopupTableRow}>
                    <td className={styles.infoPopupTableData}>{t("dashbord.survey.payment.risk.premium.annual_premium_55_59")}</td>
                    <td className={styles.infoPopupTableData}>CHF {RiskProtectionCalculation.riskprotectionOutput.yearPremiumBetween55And59Age}</td>
                  </tr>
                  <tr className={styles.infoPopupTableRow}>
                    <td className={styles.infoPopupTableData}>{t("dashbord.survey.payment.risk.premium.annual_premium_60")}</td>
                    <td className={styles.infoPopupTableData}>CHF {RiskProtectionCalculation.riskprotectionOutput.yearPremiumAfter60Age}</td>
                  </tr>
                  </tbody>
                </table>
                <Link className={styles.infoPopupCloseLink} onClick={() => setShowInfoPopup(false)}>{t("common.conclude")}</Link>
              </div>
            </InfoPopup>
            
          </div>
        </SplitLayout>
      </OnboardingLayout>
    </Page>
  );
};

export default DashboardPaymentOfRiskPremium;