import { RouteComponentProps } from "react-router-dom";
import FooterNavbar, { NavbarButtonType } from '../../Molecules/Navbar/FooterNavbar';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../Organisms/Layout/SplitLayout';
import t from '../../../App/Libs/Translate/Translate';
import styles from './MakeDepositFailedPage.module.scss';

const MakeDepositFailedPage = (props: RouteComponentProps) => {

  function renderFooter() {
    return <FooterNavbar
      buttonBackText={t('common.back')}
      linkToNext='/Dashboard/OverviewDepots3a'
      buttonNextText={t('common.end')}
      buttonNextType={NavbarButtonType.Close} />
  }

  return (
    <Page>
      <OnboardingLayout
        footer={renderFooter()}
        contentMaxWidth='1500px'
        hideStepper={true}
        backButtonLink='/Dashboard/OverviewDepots3a'
        showBackgroundImage={true}>

        <SplitLayout className={styles.splitView}>
          <div className={styles.titleSection}>
            <h1 className={styles.title}>{t('dashboard.overview_depots_3a.make_deposit_3a.failed.title')}</h1>
            <p className={styles.subtitle}>{t('dashboard.overview_depots_3a.make_deposit_3a.failed.subtitle')}</p>
          </div>
        </SplitLayout>
      </OnboardingLayout>
    </Page>
  );
};

export default MakeDepositFailedPage;