import React, { Component } from 'react';
import styles from './Tab.module.css'

class Tab extends Component {
    
    render() {
        return (
            <div className={styles.tabContainer} onClick={this.props.onClick} >
                {this.props.header}
            </div>
        );
    }
}
  
export default Tab;