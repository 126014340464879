import React, { useState } from 'react';
import styles from './DepositCard.module.scss';
import ThreeDotsIcon from '../../../Assets/Images/three-dots.svg'
import LineChart from '../LineChart/LineChart';
import DashboardProfitPanel from "../../Atoms/DashboardProfitPanel/DashboardProfitPanel";
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { PortfolioPerformanceDto } from '../../../App/Models/Contracts/Portfolio/PortfolioPerformanceDto';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import { ChartData } from 'chart.js';
import classNames from 'classnames';

type DepositType = {
    id: number;
    title: string;
    type: string;
    amount: number;
    currency: string;
    diff: number;
    diffPercentage: number;
    chart: ChartSchemaType;
}

type DepositCardProps = {
    info: DepositType,
    onClick?: any;
    className?: string
}


type ChartSchemaType = {
    title: string;
    userID: string;
    amountPositionsX: number;
    depositeType: string;
    depositeName: string;
    userData: Array<ChartDataType>;
    aspectRatio: number;
}

type ChartDataType = {
    id: number;
    timestamp: Date;
    yearBefore: number;
    yearCurrent: number;
    month: number;
    monthName: string;
    currency: string;
    amount: number;
}

var lastYear = new Date()
lastYear.setFullYear(lastYear.getFullYear() - 1)

const DepositCard = (props: DepositCardProps) => {
    const [performance, setPerformance] = useState<PortfolioPerformanceDto>()

    
    const renderedUserData: ChartData<"line", number[], string> = {
        // labels: props.info.chart.userData.map((labels) =>
        //     labels.monthName + " " + labels.yearCurrent
        // ),
        labels: performance?.values.map(x => new Date(x.date).toLocaleDateString('de-CH')),
        datasets: [
            {
                label: '%',
                // label: props.info.chart.userData[props.info.chart.userData.length - 1].yearBefore + " / " + props.info.chart.userData[props.info.chart.userData.length - 1].yearCurrent,
                data: performance?.values.map(x => x.value * 100),
                tension: x => 0.7,
                fill: true,
                borderColor: performance?.values.map(x => x.value < 0 ? 'red' : "#1DEBA3"),
                backgroundColor: performance?.values.map(x => x.value < 0 ? 'red' : "rgba(29, 235, 163, 0.1)"),
                pointBackgroundColor: performance?.values.map(x => x.value < 0 ? 'red' : "#1DEBA3"),
                pointBorderColor: performance?.values.map(x => x.value < 0 ? 'red' : "#1DEBA3"),
                pointHoverBackgroundColor: performance?.values.map(x => x.value < 0 ? 'red' : "#1DEBA3"),
                pointHoverBorderColor: performance?.values.map(x => x.value < 0 ? 'red' : "#1DEBA3"),
                // lineTension: 0.7,
                borderJoinStyle: 'miter',
                borderWidth: 1
            }

        ]

    }

    function getEmptyPerformance(): PortfolioPerformanceDto{
        return {
            currency:{
                id: 1,
                name:"CHF"
            },
            values:[{
                date: lastYear.toLocaleDateString('de-CH'),
                value: 0,
                amount: 0,
                additionalValue: 0
            },
            {
                date: new Date(Date.now()).toLocaleDateString('de-CH'),
                value: 0,
                amount: 0,
                additionalValue: 0
            }]
        }
    }

    const chartOptions = setChartOptions(props.info.chart.aspectRatio);


    function loadPerformance() {
        return PortfolioActions
            .GetPerformance(props.info.id)
            .onSuccess(res => {
                if(res.data.values.length === 1)
                    res.data.values = [
                        getEmptyPerformance().values[0],
                        res.data.values[0]
                    ]
                if(res.data.values.length === 0)
                    res.data = getEmptyPerformance()

                setPerformance(res.data)
            })
    }

    ComponentDidMount(() =>{
        loadPerformance()
    })

    return (
        <div className={classNames(styles.main, props.className)} onClick={e => props.onClick && props.onClick(e.currentTarget, props.info.id)}>
            <span className={styles.title}>{props.info.title}</span>
            <span className={styles.type}>{props.info.type}</span>

            <DashboardProfitPanel stockAmount={props.info.amount} profitNumber={props.info.diff} percentage={props.info.diffPercentage} chartMode={true} />
                {performance &&
                    <div className={styles.graph}>
                        <LineChart id={"canvas-" + props.info.chart.depositeName} data={renderedUserData} options={chartOptions} />
                    </div>
                }

            <img className={styles.action} src={ThreeDotsIcon} alt="three dots icon" />
        </div>
    );
};

function setChartOptions(aspectRatio: number) {
    return {
        bezierCurve: true,
        layout: {
            padding: {
                left: -15,
                right: -100,
                bottom: -100
            }
        },
        responsive: true,
        maintainSpectRatio: false,
        aspectRatio: aspectRatio,
        elements: {
            point: {
                radius: 0
            }
        },
        scales: {
            y: {
                beginAtZero: false,
                ticks: {
                    display: false,
                    padding: -20
                },
                grid: {
                    display: false
                }
            },
            x: {
                beginAtZero: false,
                ticks: {
                    display: false,
                    min: 0
                },
                grid: {
                    display: false
                }
            }
        },
        legend: {
            display: false
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.yLabel;
                    }
                }
            }
        }
    };
}

export type { ChartDataType, ChartSchemaType, DepositType }
export default DepositCard;
