import { makeObservable, observable } from "mobx"
import { makePersistable } from "mobx-persist-store"
import { ModelPortfolioDto } from "../../Models/Contracts/Strategy/ModelPortfolioDto"
import { ThemeDto } from "../../Models/Contracts/Strategy/ThemeDto"

export class RegisterStore{
    @observable Email?: string
    @observable FirstName?: string
    @observable ProductId?: number
    @observable ModelPortfolio?: ModelPortfolioDto
    @observable Themes?: Array<ThemeDto>

    constructor(){
        makeObservable(this)
        makePersistable(this, { name: 'RegisterStore', properties: ['Email', 'FirstName', 'ProductId', 'ModelPortfolio', 'Themes'], storage: window.sessionStorage });
    }

    SetEmail = (value: string) => {
        this.Email = value
    }   
}