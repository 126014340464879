import React from "react"
import { IconProps } from '../Menu'

const ChatIcon = (props: IconProps) => {
  return (
    <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path fill={props.color ?? "white"} fillRule="evenodd" clipRule="evenodd" d="M2.37135 1.66931C1.43738 1.66931 0.67753 2.4196 0.67753 3.34258V13.7045C0.67753 14.6274 1.43738 15.3777 2.37135 15.3777H4.99272L4.27996 18.0051L8.43558 15.3777H17.6286C18.5626 15.3777 19.3225 14.6274 19.3225 13.7045V3.34258C19.3225 2.4196 18.5626 1.66931 17.6286 1.66931H2.37135ZM3.17287 19.5L4.1099 16.047H2.37135C1.06406 16.047 0 14.9959 0 13.7045V3.34258C0 2.05115 1.06406 1 2.37135 1H17.6286C18.9363 1 20 2.05115 20 3.34258V13.7045C20 14.9959 18.9363 16.047 17.6286 16.047H8.63376L3.17287 19.5Z" />
    </svg>
  );
};

export default ChatIcon;
