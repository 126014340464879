import React from 'react';
import { Image } from 'react-bootstrap';
import { Callback } from '../../../App/Models/Callback';
import Link from '../../Atoms/Link/Link';

import styles from './MoreInfoLink.module.scss'
import addImg from '../../../Assets/Images/add.svg';

export type MoreInfoLinkProps  = {
    onClick?: Callback<HTMLElement>
    children?: React.ReactNode
}

const MoreInfoLink: React.FC<MoreInfoLinkProps> = (props) => {

    function onClick(e: HTMLElement){
        if(props.onClick)
            props.onClick(e)
    }

    return (
        <div className={styles.main}>
            <Link onClick={e => onClick(e)} className={styles.moreInfo}>
                <span><Image className={styles.moreInfoImg} src={addImg} alt="" /></span>
                {props.children}
            </Link>
        </div>
    );
};

export default MoreInfoLink;