import React from 'react';
import styles from './DashboardContainer.module.scss';
import DashboardTitle from "../../Atoms/DashboardTitle/DashboardTitle";

type Props = {
    children?: React.ReactNode
    title?: string
}

const DashboardContainer: React.FC<Props> = (props) => {
    return (
        <div className={styles.dashboardContainer}>
            {props.title && <DashboardTitle>{props.title}</DashboardTitle>}
            {props.children}
        </div>
    );
};

export default DashboardContainer;
