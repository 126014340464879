import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Callback } from '../../../App/Models/Callback';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Datepicker.module.scss';
import calendarIcon from "../../../Assets/Images/Calendar.svg"
import classNames from "classnames";
import t from '../../../App/Libs/Translate/Translate'

type Props = {
  selected?: Date | null,
  onChange: Callback<Date | null>,
  className? : string
  iconClassName? : string
  placeholderText? : string
}

function formatDate(date: Date | null){
  if(!date)
    return null
  return date.toISOString().substring(0, 10);
}

function parseDate(dateStr: string){
  const timestamp = Date.parse(dateStr);

  if (isNaN(timestamp)) return null;

  return new Date(dateStr);
}

const Datepicker = React.forwardRef((props: Props, ref: any) => {
  return (
    <div className={styles.datepickerContainer}>
      <ReactDatePicker
        ref={ref}
				selected={props.selected}
				onChange={(e: Date | null) => props.onChange(e)}
        placeholderText={props.placeholderText || `${t('common.date_of_birth')} (dd.mm.jjjj)`}
				className={(classNames(styles.main, props.className))}
				dateFormat='dd.MM.yyyy'
			/>
      <div className={classNames(styles.iconContainer, props.iconClassName)}><img src={calendarIcon} alt="calendar icon"/></div>
    </div>
  );
});

export default Datepicker;
export {formatDate, parseDate};
