import PreferentialTreatmentPageLayout from "../../Organisms/Layout/PreferentialTreatmentPageLayout";
import styles from "./PreferentialTreatmentRegulationsPage.module.scss"
import DownloadButton from "../../Atoms/Button/DownloadButton";
import DocumentActions from "../../../App/Mobx/Actions/DocumentActions";
import WebSync from "../../Molecules/DataSync/WebSync";
import { useRef } from "react";
import t from '../../../App/Libs/Translate/Translate'
import { ProductEnum } from "../../../App/Models/Enums/ProductEnum";
import { useParams } from "react-router-dom";
import { PortfolioParams } from "../../../App/Models/Params/PortfolioParams";
import { useStore } from "../../../App/Mobx/Infrastructure/mobx-hook";

const DashboardPreferentialTreatmentRegulationsPage = () => {

  const webSyncRef = useRef<WebSync>()

  const { Dashboard } = useStore()
  const params = useParams<PortfolioParams>()

  function onDownload() {
    
    webSyncRef.current.Activity.show()

    const portfolio = Dashboard.investmentOverview.portfolios.find(x => x.id === parseInt(params.portfolioId))

    DocumentActions
      .GenerateBeneficiaryOrder(portfolio.product.id)
      .onSuccess(() => webSyncRef.current.Activity.hide())
      .onFail(() => webSyncRef.current.Activity.hide())
  }

  return (
    <PreferentialTreatmentPageLayout
      header={t("dashboard.preferential.treatment.regulations.header")}
      supportCardDescription={t("dashboard.preferential.treatment.regulations.header")}
      title={t("dashboard.preferential.treatment.regulations.title")}
      syncRef={webSyncRef}
    >

        <p className={styles.description}>
          {t("dashboard.preferential.treatment.regulations.description1")}
        </p>
        <p className={styles.description}>
          {t("dashboard.preferential.treatment.regulations.description2")}
        </p>
        <div className={styles.listContainer}>
          <h3 className={styles.listHeader}>{t("dashboard.preferential.treatment.regulations.list.title")}</h3>
          <ol className={styles.list}>
            <li className={styles.listItem}>{t("dashboard.preferential.treatment.regulations.list.step1")}</li>
            <li className={styles.listItem}>{t("dashboard.preferential.treatment.regulations.list.step2")}</li>
            <li className={styles.listItem}>{t("dashboard.preferential.treatment.regulations.list.step3")}</li>
          </ol>
        </div>
        <DownloadButton onClick={() => onDownload()}>{t("dashbord.adjust.benefit.apply")}</DownloadButton>
      </PreferentialTreatmentPageLayout>
  )
}

export default DashboardPreferentialTreatmentRegulationsPage;