import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import styles from './LargeButton.module.scss';
import arrowRight from "../../../Assets/Images/arrow-right.svg";

type LargeButtonType = {
  className?: string,
  to?: string,
  disabled?: boolean,
  onClick?: MouseEventHandler<HTMLElement>,
  children?: React.ReactNode
}

const renderArrowIcon = () => {
  return (
    <div className={styles.arrowContainer}>
      <img src={arrowRight} alt="arrow right icon"/>
    </div>
  )
}

export const LargeButton = (props: LargeButtonType) => {
  if (props.to)
    return <Link {...props} to={props.to!} className={classNames(styles.main, props.className, props.disabled && styles.disabled)} onClick={props.onClick}>{props.children} {renderArrowIcon()}</Link>
  else
    return <button disabled={props.disabled} {...props} className={classNames(styles.main, props.className)} onClick={props.onClick}>{props.children} {renderArrowIcon()}</button>
};