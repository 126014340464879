import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import t from '../../../../App/Libs/Translate/Translate';
import AccountOptionsLayout from "../../../Organisms/Layout/AccountOptionsLayout";


const Account3aOptionsPage = (props: RouteComponentProps) => {

    const data = [
        {
            title: t('account.3a.options.salary_ahv_title'),
            description: t('account.3a.options.salary_ahv_desc')
        },
        {
            title: t('account.3a.options.pension_fund_title'),
            description: t('account.3a.options.pension_fund_desc')
        }
    ]

    const { Account } = useStore()

    function onSubmit(ahvValue, bvgValue) {

        if(ahvValue === '0' && bvgValue === '0')
            props.history.push('/onboarding/3A/Transfer/fail')
        else{
            Account.avhValue = ahvValue === "1"
            Account.bvgValue = bvgValue === "1"
            props.history.push('/onboarding/3A/Transfer/Scan')
        }
    }

    return (
      <AccountOptionsLayout {...props}
                            onConfirm={onSubmit}
                            title={t('account.3a.options.title')}
                            subtitle={t('account.3a.options.subtitle')}
                            data={data}
                            headerSelectedStepIndex={1}
                            headerProgress={0.5}
      />
    );
};

export default Account3aOptionsPage;
