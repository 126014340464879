import classnames from 'classnames';
import { useState } from 'react';
import Link from '../../Atoms/Link/Link';

import CommunicationActions from '../../../App/Mobx/Actions/CommunicationActions';
import { MessageDto } from '../../../App/Models/Contracts/Communication/MessageDto';
import WebSync from '../../Molecules/DataSync/WebSync';
import styles from './DashboardMessages.module.scss';
import DocumentActions from '../../../App/Mobx/Actions/DocumentActions';
import Formatter from '../../../App/Libs/Formatter/Formatter';
import t from '../../../App/Libs/Translate/Translate';

type Message = {
  id: string;
  title: string;
  date: string;
  downloadLink: string;
  isNew: boolean;
}



const DashboardMessages = () => {

  const [messages, setMessages] = useState<Array<MessageDto>>()


  function load() {
    return CommunicationActions
      .GetMessages()
      .onSuccess(res => setMessages(res.data))
  }

  function downloadDocument(documentId: string) {
    return DocumentActions
      .DownloadDocument(documentId)
  }


  return (
    <div className={styles.main}>
      <WebSync client={() => load()}>

        {messages?.map(message => (
          <div className={styles.message} key={message.id}>
            <span className={classnames(styles.date, !message.isRead && styles.newMessage)}>
              {Formatter.formatDate(message.createDate)}
            </span>
            <div className={styles.titleWrapper}>
              <span className={styles.title}>{message.subject}</span>
              <span className={styles.downloadLink} onClick={() => downloadDocument(message.attachments[0].documentId)}>{t("dashboard.beneficiary.regulations.intro.download.document")}</span>
            </div>
          </div>
        ))}

        <Link className={styles.link} to="/Dashboard/Messages">{t("dashbord.mainpage.messages.all")}</Link>
      </WebSync>
    </div>
  );
};

export default DashboardMessages;
