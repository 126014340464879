import WebClient from "../../Libs/WebClient/WebClient";
import { WebClientResponse } from "../../Libs/WebClient/WebClientResponse";
import { AuthorizationResponseDto } from "../../Models/Contracts/Auth/AuthorizationResponseDto";
import { LoginDto } from "../../Models/Contracts/Auth/LoginDto";
import { GlobalStore } from "../Stores/MainStore";
import { PortfolioActions } from "./PortfolioActions";


export class AuthActions {

    static Login(model: LoginDto) {

        return WebClient
            .Create()
            .Post<AuthorizationResponseDto>(`auth/login`, model)
            .onSuccess(response =>{
                GlobalStore.Auth.SetAuthorization(response!.data)
            })
    }

    static SynchronizeSso(){
        return WebClient
            .Create()
            .Post<{}>(`sso/sync`)
    }
}