import React from 'react';
import {Form} from 'react-bootstrap';
import Link from '../../Atoms/Link/Link';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import styles from './RegisterConfirmCodePage.module.scss';
import Panel from "../../Atoms/Panel/Panel";
import Input from "../../Atoms/Input/Input";
import SplitLayout from "../../Organisms/Layout/SplitLayout";
import FooterNavBar from "../../Molecules/Navbar/FooterNavbar";
import t from '../../../App/Libs/Translate/Translate'


const RegisterConfirmCodePage: React.FC = () => {
	function renderFooter() {
		return <FooterNavBar linkToNext="/onboarding/Success"
		                     buttonBackText={t('common.back')}
		                     buttonNextText={t('register.confirm.confirm')}/>
	}

	return (
		<Page>
			<OnboardingLayout
				footer={renderFooter()}
				contentMaxWidth='1600px'
				selectedStepIndex={0}>
				<SplitLayout className={styles.main}>
					<div className={styles.titleContainer}>
						<h1 className={styles.title}>{t('register.confirm.title')}</h1>
						<p className={styles.description}>{t('register.confirm.subtitle')}</p>
					</div>
					<Panel className={styles.panel}>
						<Form className={styles.form}>
							<label className={styles.label}>{t('register.confirm.enter_verification_code')}</label>
							<Input className={styles.input} maxLength={6} placeholder={"000000"} disableLabel/>
							<Link className={styles.resendCodeLink}>{t('register.confirm.resend_verification_code')}</Link>
						</Form>
					</Panel>
				</SplitLayout>
			</OnboardingLayout>
		</Page>
	);
};

export default RegisterConfirmCodePage;
