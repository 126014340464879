import React, { useState } from 'react';
import Formatter from '../../../App/Libs/Formatter/Formatter';
import { Button } from "../../Atoms/Button/Button";
import styles from './DepositAmountCard.module.scss';
import t from '../../../App/Libs/Translate/Translate';

type DepositType = {
    title?: string;
    maxAmount?: string;
    amount?: number;
    percents?: number;
    secondAmount?: number;
    onClick? : () => void;
}

const DepositAmountCard = (props: DepositType) => {

    const [percents, setPercents] = useState(props.percents);

    return (
        <div className={styles.main}>
            <div className={styles.title}>{props.title || 'N/A'}</div>
            <div className={styles.amount}>CHF {Formatter.formatNumber(props.amount || 0)}</div>
            <div className={styles.progressLine}>
                <div className={styles.progressLineGreen} style={ { width: `${ percents * 100 }%` } }></div>
            </div>
            <div className={styles.maxAmount}>{t("dashbord.deposit.overview.max_sum")} CHF {props.maxAmount}</div>
            <hr className={styles.line} />
            <div className={styles.paragraph}>{props.secondAmount}</div>
            <div className={styles.paragraph}>{t("dashbord.deposit.overview.can_paid")}</div>
            
            <Button onClick={props.onClick}>{t("dashbord.deposit.overview.deposit_now")}</Button>
            
        </div>
    );
};

export default DepositAmountCard;
