import React, { useRef } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import ChooseProductPageLayout from "../../Organisms/Layout/ChooseProductPageLayout/ChooseProductPageLayout";
import Page from "../../Molecules/Page/Page";
import OnboardingLayout from "../../Organisms/Layout/OnboardingLayout";
import { ComplianceActions } from '../../../App/Mobx/Actions/ComplianceActions';
import WebSync from '../../Molecules/DataSync/WebSync';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import { ProductDto } from '../../../App/Models/Contracts/Compliance/ProductDto';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { ProposalParams } from '../../../App/Models/Params/ProposalParams';
import { VirtualPortfolioDto } from '../../../App/Models/Contracts/Portfolio/VirtualPortfolioDto';

const products = [
  {
    id: '1',
    title: 'smoox-solution Freizügigkeit',
    description: 'Ideal bei einem Vermögenstransfer aus einer anderen Stiftung oder für den Start in eine neue Lebensphase.',
    cta: 'Jetzt anlegen',
    path: '/onboarding/Transfer/Source'
  },
  {
    id: '2',
    title: 'smoox-solution 3a',
    description: 'Mit smoox investieren Sie Ihr Säule-3a-Guthaben einfach und bequem in Wertschriften.',
    cta: 'Jetzt eröffnen',
    path: '/onboarding/Survey/Financial/Knowledge'
  },
]

const ChooseProductPage = (props: RouteComponentProps) => {

  const { Compliance, Account } = useStore()
  const deletedProposalId = parseInt(useParams<ProposalParams>().proposalId)

  const webSyncRef = useRef<WebSync>()

  function load() {
    return ComplianceActions
      .GetProducts()
  }

  ComponentDidMount(() => {
    load()
  })

  function createVirtualPortfolio(proposalId: number,product: ProductDto){
    var portfolioNumber =  Account.virtualPortfolios.length + 1
    while(true){
      var exists = Account.virtualPortfolios.some(x => x.name === `Portfolio ${portfolioNumber}`)
      if(!exists)
        break
      portfolioNumber++
    }

    Account.virtualPortfolios.push({
      product: product,
      proposalId: proposalId,
      name: `Portfolio ${portfolioNumber}`,
      replacesProposalId: deletedProposalId
    })
    
    props.history.push(`/onboarding/${proposalId}/survey/horizon`)
  }

  function onProposalAlreadyCreated(vp: VirtualPortfolioDto){
    props.history.push(`/onboarding/${vp.proposalId}/survey/horizon`)
  }

  function onProductSelected(product: ProductDto){

    var vp = Account.virtualPortfolios.find(x => x.replacesProposalId === deletedProposalId)
    if(vp){
      if(vp.product.id === product.id){
          onProposalAlreadyCreated(vp)
          return
      }
      else{
        Account.virtualPortfolios = Account.virtualPortfolios.filter(x => x.replacesProposalId !== deletedProposalId)
      }
    }

    webSyncRef.current.Activity.show()
    PortfolioActions
      .PostPortfolioProposal({
        productId: product.id,
        transferSource: Account.transferSource
      })
      .onSuccess(x => {
        createVirtualPortfolio(x.data.proposalId, product)
        webSyncRef.current.Activity.hide()
      })
      .onFail(() =>{
        webSyncRef.current.Activity.hide()
      })
      
  }

  return (
    <Page>
      <OnboardingLayout
        progress={0.5}
        selectedStepIndex={1}>
        <WebSync ref={webSyncRef} client={() => load()}>
          <ChooseProductPageLayout
            {...props}
            title={"Ihr Portfolio ist gelöscht."}
            secondaryText={"Sie haben Ihr Portfolio unwiderruflich gelöscht. Möchten Sie ein Neues erstellen?"}
            onProductClick={p => onProductSelected(p)}
            products={Compliance.Products} />
        </WebSync>
      </OnboardingLayout>

    </Page>
  );
};

export default ChooseProductPage;
