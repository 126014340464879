import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { Button } from '../../Atoms/Button/Button';
import Password from '../../Atoms/Input/Password';
import PassRequirements from '../../Molecules/PassRequirements/PassRequirements';

import styles from './ProfileChangePassword.module.scss';

type Inputs = {
    oldPassword: string,
    newPassword: string,
};

const ERRORS = {
    required: "Dieses Feld ist erforderlich",
    passRequirements: "Passwort entspricht nicht den Anforderungen",
    passMatch: "Passwort stimmt nicht überein",
}

const ProfileChangePassword = () => {

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = data => {
        //@TODO do the request
    }

    const oldPassword = watch().oldPassword;

    return (
        <Form>

            <label className={styles.passwordInput}>
                <Password
                    placeholder="Aktuelles Passwort"
                    autoComplete='oldPassword'
                    {...register("oldPassword", {
                        required: true,
                        min: 8,
                        pattern: /(?=.*\d)(?=.*[!@#$%^&.*])(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z!@#$%^&.*]{8,}/g,
                    })}
                    error={errors.oldPassword && ERRORS.passRequirements}
                />
            </label>

            <label className={styles.passwordInput}>
                <Password
                    placeholder="Neues Passwort"
                    autoComplete='newPassword'
                    {...register("newPassword", {
                        required: true,
                        min: 8,
                        pattern: /(?=.*\d)(?=.*[!@#$%^&.*])(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z!@#$%^&.*]{8,}/g,
                        validate: () => watch().oldPassword === watch().newPassword,
                    })}
                    error={errors.newPassword && ERRORS.passMatch}
                />
            </label>

            <Button
                disabled={buttonDisabled}
                className={styles.button}
                onClick={handleSubmit(onSubmit)}
            >
                Passwort speichern
            </Button>

            <PassRequirements password={oldPassword} />

        </Form>
    );
};

export default ProfileChangePassword;