import styles from "./DashboardDepositOverview3APage.module.scss"
import Page from "../../Molecules/Page/Page";
import DashboardLayout from "../../Organisms/Layout/DashboardLayout";
import dartsLargeIcon from "../../../Assets/Images/Dashboard/darts-large.svg"
import React, { useState } from "react";
import { Button } from "../../Atoms/Button/Button";
import InfoPanel from "../../Atoms/Panel/InfoPanel";
import DepositCard from "../../Molecules/DepositCard/DepositCard";
import {RouteComponentProps, useParams } from "react-router-dom";
import ProductCards from "../../Organisms/DashboardsProductCards/ProductCards";
import checkmarkIcon from "../../../Assets/Images/Checkmark-little.svg";
import DepositAmountCard from "../../Molecules/DepositAmountCard/DepositAmountCard";
import supportHeartIcon from "../../../Assets/Images/Dashboard/support-heart.svg";
import WarningPopup from '../../Molecules/Popup/WarningPopup';
import Input from '../../Atoms/Input/Input';
import { Image } from 'react-bootstrap';
import ok from "../../../Assets/Images/Dashboard/ok.svg"
import DashboardHeader from "../../Organisms/DashboardHeader/DashboardHeader";
import DashboardProfitPanel from "../../Atoms/DashboardProfitPanel/DashboardProfitPanel";
import { useStore } from "../../../App/Mobx/Infrastructure/mobx-hook";
import Formatter from "../../../App/Libs/Formatter/Formatter";
import { ModelPortfolioDto } from "../../../App/Models/Contracts/Strategy/ModelPortfolioDto";
import { ComponentDidMount } from "../../../App/Libs/Helper/Helper";
import { PortfolioActions } from "../../../App/Mobx/Actions/PortfolioActions";
import { ProductEnum } from "../../../App/Models/Enums/ProductEnum";
import StrategUtils from "../../../App/Libs/Helper/Strategy";
import { PortfolioDepositResultDto } from "../../../App/Models/Contracts/Portfolio/PortfolioDepositResultDto";
import PerformanceUtils from "../../../App/Libs/Helper/PerformanceUtils";
import t from '../../../App/Libs/Translate/Translate'

type Params = {
  id?: string
}

const DashboardDepositOverview3APage = (props: RouteComponentProps) => {
  const UserChartDataMock: Array<ChartData> = getMockChartData();
  const UserChartSchemaMock: ChartSchema = getMockSchemaData(UserChartDataMock);

  const [isStrategyChanged, setIsStrategyChanged] = useState<boolean | 0>(0);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);


  const [modelPortfolio, setModelPortfolio] = useState<ModelPortfolioDto>()
  const [portfolioDeposits, setPortfolioDeposits] = useState<PortfolioDepositResultDto>()

  const { Dashboard } = useStore()
  const { id } = useParams<Params>()
  const Account = Dashboard.getPortfolioById(parseInt(id))
  if (!Account)
    props.history.replace('/')


  function load() {
    PortfolioActions
      .GetProposalModelPortfolio(Account.portfolioProposalId)
      .onSuccess(x => setModelPortfolio(x.data))


    PortfolioActions
      .GetPortfolio3aYearlyDeposits(Account.id)
      .onSuccess(res => setPortfolioDeposits(res.data))
  }

  ComponentDidMount(() => {
    load()
  })



  const showStrategyChangeFeedback = () => {
    if (isStrategyChanged === 0) return null;

    if (isStrategyChanged) {
      return (
        <div className={styles.strategyChangeSuccess}>
          <span>{t("dashboard.strategy.details.change.success")}</span>
          <img src={checkmarkIcon} alt="checkmark icon" />
        </div>
      )
    } else return (
      <div className={styles.strategyChangeFailed}>
        <span>{t("dashboard.strategy.details.change.fail")}</span>
        <img src={checkmarkIcon} alt="checkmark icon" />
      </div>
    )
  }

  const onHandleClick = () => {
    props.history.push(`/dashboard/depot/${id}/strategy`)
  }

  const onHandleChangeStrategy = () => {
    props.history.push(`/dashboard/depot/${id}/strategy/change`);
  }

  const onHandleClickDeposit = () => {
    props.history.push(`/dashboard/depot/${id}/3a/pay/info`)
  }

  const onHandleClickTransfer = () => {
    props.history.push("/Dashboard/OverviewDepots3a/DepositTransfer")
  }

  const editPortfolioName = () => {
    setShow(true)
  }

  const deletePortfolio = () => {
    props.history.push("/Dashboard/Close3aPortfolioPage")
  }

  const onAlertClose = () => {
    setShow(false);
    setShowSuccess(false);
  }

  const onAlertHide = () => {
    setShowSuccess(true)
  }

  const onHandleDepositNow = () => {

    props.history.push(`/dashboard/depot/${id}/3a/deposits`)
  }

  function onAccountClick(id: number) {
    props.history.push(`/dashboard/depot/${id}/overview`)
  }

  function getCurrentYear() {
    return new Date(Date.now()).getFullYear()
  }

  function getDepositAmountPerYear(year: number) {
    var value = portfolioDeposits
      .transactions.filter(x => new Date(x.date).getFullYear() === year)
      .reduce((a, b) => a + b.value, 0)
    return value
  }

  function onBeneficiaryOrderClick(){
    props.history.push(`/dashboard/depot/${id}/beneficiary/order`)
  }

  return (
    <Page>
      <DashboardLayout>
        <div>
          {showStrategyChangeFeedback()}
          <DashboardHeader
            title={"3a-Depot"}
            subtitle={Account.name}
            onDepositClick={onHandleClickDeposit}
            onTransferClick={onHandleClickTransfer}
            onChangeStrategyClick={onHandleChangeStrategy}
            onEdit={editPortfolioName}
            onDelete={deletePortfolio}
          >
            <DashboardProfitPanel stockAmount={Account.investedAmount}
              profitNumber={PerformanceUtils.CalculatePerformanceValue(Account.currentValue, Account.currentPerformance)}
              percentage={Account.currentPerformance} />
          </DashboardHeader>
          <div className={styles.chartContainer}>
            <DepositCard className={styles.depotCard} key={Account.id} info={{
              id: Account.id,
              title: Account.name,
              currency: Account.currency.name,
              amount: Account.investedAmount,
              diff: PerformanceUtils.CalculatePerformanceValue(Account.currentValue, Account.currentPerformance),
              diffPercentage: Account.currentPerformance,
              type: "3a-Depot",
              chart: UserChartSchemaMock
            }} />
          </div>

          <div className={styles.infoContainer}>
            <div className={styles.infoBoard}>
              <InfoPanel icon={dartsLargeIcon} className={styles.strategyInfo} onClick={onHandleClick}>
                <p className={styles.panelHeader}>{t("dashboard.strategy.details.info.title")} {modelPortfolio?.name}</p>
                <p className={styles.panelTitle}>{t("dashboard.strategy.details.info.percentage").replace("[PERCENT]", StrategUtils.getRisk(modelPortfolio?.positions))}</p>
                <p className={styles.panelDescription}>{t("account.portfolio.depot.strategy.container.text")}</p>
              </InfoPanel>
              <div className={styles.infoBoardRightSide}>
                { portfolioDeposits &&
                  <DepositAmountCard onClick={onHandleDepositNow} title={`${t("dashbord.deposit.overview.title")} ${getCurrentYear()}`}
                      amount={getDepositAmountPerYear(getCurrentYear())}
                      percents={getDepositAmountPerYear(getCurrentYear()) / portfolioDeposits.maxDepositPerYear}
                      maxAmount={Formatter.formatNumber(portfolioDeposits.maxDepositPerYear)} />
                }

              </div>
            </div>
            <div>
              <div className={styles.infoBoardRightSide}>
                <InfoPanel icon={supportHeartIcon} small={true} className={styles.riskBenefits}
                  onClick={() => onBeneficiaryOrderClick()}>
                  {t("dashboard.strategy.details.info.favors")}
                </InfoPanel>
              </div>
            </div>
            <div className={styles.depositsContainer}>
              <h4 className={styles.depositsTitle}>{t("account.portfolio.depot.strategy.deposits.title")}</h4>
              <div className={styles.deposits}>
                {Dashboard.investmentOverview?.portfolios
                  .filter(x => x.id !== Account.id && (x.product.id === ProductEnum.Independent || x.product.id === ProductEnum.PensFree))
                  .map(deposit => (
                    <DepositCard key={deposit.id} info={{
                      id: deposit.id,
                      title: deposit.name,
                      currency: "CHF",
                      amount: deposit.investedAmount,
                      diff: PerformanceUtils.CalculatePerformanceValue(deposit.currentValue, deposit.currentPerformance),
                      diffPercentage: deposit.currentPerformance,
                      type: t("account.portfolio.depot.title"),
                      chart: UserChartSchemaMock
                    }} onClick={() => onAccountClick(deposit.id)} />
                  ))}
              </div>
            </div>
            <ProductCards portfolioLink="/onboarding/Open/Additional"
              favorLink="/Dashboard/OverviewDepots/PreferentialTreatmentRegulations" />
          </div>
        </div>

        <WarningPopup show={show} onExit={() => onAlertClose()}>
          <div className={styles.warningMain}>

            {showSuccess ?
              <div className={styles.showSuccessContainer}>
                <Image src={ok} alt="" />
                <h3>{t("dashboard.strategy.details.change.name.success")}</h3>
              </div> :
              <>
                <Input
                  placeholder={t("register.contract.signing_contract.vested_benefits_account_risk")}
                  type="text"
                />
                <p>{t("common.max_password_length")}</p>
                <Button
                  className={styles.warningButton}
                  to=""
                  onClick={() => onAlertHide()}>{t("common.button.save")}</Button>
              </>
            }

          </div>
        </WarningPopup>

      </DashboardLayout>
    </Page>
  )
}

type ChartSchema = {
  title: string;
  userID: string;
  amountPositionsX: number;
  depositeType: string;
  depositeName: string;
  userData: Array<ChartData>;
  aspectRatio: number;
}

type ChartData = {
  id: number;
  timestamp: Date;
  yearBefore: number;
  yearCurrent: number;
  month: number;
  monthName: string;
  currency: string;
  amount: number;
}


function getMockChartData(): ChartData[] {

  const min: number = 355000.00;
  const max: number = 360000.00;
  const yearAgo: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

  var chartData: Array<ChartData> = [];


  for (var i = 0; i < 365; i++) {
    let tempDate: Date = new Date(yearAgo.setDate(yearAgo.getDate() + 1));
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let chartDataDay: ChartData = {
      id: i,
      timestamp: tempDate,
      yearBefore: tempDate.getFullYear() - 1,
      yearCurrent: tempDate.getFullYear(),
      month: tempDate.getMonth(),
      monthName: monthNames[tempDate.getMonth()],
      amount: Math.round((min + Math.random() * (max - min))),
      currency: "CHF"
    };

    chartData.push(chartDataDay);
  }

  return chartData;
}

function getMockSchemaData(UserChartDataMock: ChartData[]): ChartSchema {
  return {
    title: "yearBefore / yearCurrent",
    userID: "m.fleig123@qcentris.com",
    amountPositionsX: 12,
    depositeType: "Freizügigkeitsdepots",
    depositeName: "Protfolio 1",
    userData: UserChartDataMock,
    aspectRatio: 12
  };
}


export default DashboardDepositOverview3APage;