import React  from "react";
import { useRouteMatch } from "react-router-dom";
import Link from "../../Atoms/Link/Link";

import HomeIcon from './icons/HomeIcon'
import DiagramIcon from "./icons/DiagramIcon";
import DiagramCircleIcon from "./icons/DiagramCircleIcon";
import BellIcon from "./icons/BellIcon";
import DocumentIcon from "./icons/DocumentIcon";
import ProfileIcon from "./icons/ProfileIcon";
import ChatIcon from "./icons/ChatIcon";
import ExitIcon from "./icons/ExitIcon";

import styles from "./Menu.module.scss";
import t from '../../../App/Libs/Translate/Translate';

export type IconProps = {
  className?: string;
  color?: string,
}

type MenuLinkProps = {
  to: string,
  Icon: React.FC<IconProps>,
  title: string,
  multiple?: boolean
}

const MenuLink = ({ to, Icon, title, multiple }: MenuLinkProps) => {

  const isPageActive = useRouteMatch({
    path: to,
    exact: !multiple
  });

  return (
    <Link to={to}>
        <Icon className={styles.icon} color={isPageActive ? '#00A76D' : 'white'} />
        <span className={isPageActive && styles.activeLink}>{title}</span>
    </Link>
  )
}

const Menu = () => {
  return (
    <div className={styles.main}>
      <MenuLink
        to="/Dashboard"
        Icon={HomeIcon}
        title={t("dashboard.menu.dashboard")}
      ></MenuLink>

      <hr></hr>

      <MenuLink
        to="/dashboard/fz/depots"
        Icon={DiagramIcon}
        title={t("dashboard.menu.fz.depots")}
        multiple={true}
      ></MenuLink>

      <MenuLink
        to="/dashboard/3a/depots"
        Icon={DiagramCircleIcon}
        title={t("dashboard.menu.3a.depots")}
        multiple={true}
      ></MenuLink>

      <hr></hr>

      <MenuLink
        to="/Dashboard/Messages"
        Icon={BellIcon}
        title={t("dashbord.mainpage.messages")}
      ></MenuLink>

      <MenuLink
        to="/Dashboard/Documents"
        Icon={DocumentIcon}
        title={t("dashbord.documents.title")}
      ></MenuLink>

      <hr></hr>

      <MenuLink
        to="/Dashboard/Profile"
        Icon={ProfileIcon}
        title={t("dashboard.menu.profile")}
      ></MenuLink>

      <MenuLink
        to="/Dashboard/Faq"
        Icon={ChatIcon}
        title={t("dashboard.menu.help.center")}
      ></MenuLink>

      <MenuLink
        to="/"
        Icon={ExitIcon}
        title={t("dashboard.menu.logout")}
      ></MenuLink>
    </div>
  );
};

export default Menu;
