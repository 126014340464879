import React from 'react';
import styles from "./CancelRiskProtectionPage.module.scss"
import DownloadButton from "../../Atoms/Button/DownloadButton";
import PreferentialTreatmentPageLayoutType from "../../Organisms/Layout/PreferentialTreatmentPageLayout"
import t from '../../../App/Libs/Translate/Translate'

const DashboardCancelRiskProtectionPage = () => {
  return (
          <PreferentialTreatmentPageLayoutType
            header={t('dashboard.cancel_risk_protection.title')}
            supportCardDescription={t('dashboard.cancel_risk_protection.card_support.title')}
            title={t('dashboard.cancel_risk_protection.subtitle')}
          >
            <p className={styles.riskDescription}>{t('dashboard.cancel_risk_protection.description')}</p>
            <DownloadButton>{t('Kündigungsdokument herunterladen')}</DownloadButton>
          </PreferentialTreatmentPageLayoutType>
  );
};

export default DashboardCancelRiskProtectionPage;
