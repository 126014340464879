import styles from './PassRequirements.module.scss';
import classNames from 'classnames';
import t from '../../../App/Libs/Translate/Translate'

type Props = {
  password?: string,
};

const PassRequirements = (props: Props) => {

    const passwordInput = props.password || '';

    const matchLength = passwordInput.length >= 8;
    const matchNumbers = !!passwordInput.match(/(?=.*[0-9])/g);
    const matchSymbol = !!passwordInput.match(/(?=.*[!@#$%^&.*])/g);
    const matchLowerCase = !!passwordInput.match(/(?=.*[a-z])/g);
    const matchHigherCase = !!passwordInput.match(/(?=.*[A-Z])/g);

    return (
      <ul className={styles.passReqList}>
          <li className={classNames(styles.passReqItem, !matchLength && styles.notMatched)}>{t('errors.min_eight_characters')}</li>
          <li className={classNames(styles.passReqItem, !matchNumbers && styles.notMatched)}>{t('errors.min_one_number')}</li>
          <li className={classNames(styles.passReqItem, !matchLowerCase && styles.notMatched)}>{t('errors.min_one_lowercase')}</li>
          <li className={classNames(styles.passReqItem, !matchSymbol && styles.notMatched)}>{t('errors.min_one_special')}</li>
          <li className={classNames(styles.passReqItem, !matchHigherCase && styles.notMatched)}>{t('errors.min_one_capital')}</li>
      </ul>
    )
};

export default PassRequirements
