import React, { useState } from 'react';
import classnames from 'classnames';
import Link from '../../Atoms/Link/Link';

import styles from './DashboardAllMessages.module.scss';
import { MessageDto } from '../../../App/Models/Contracts/Communication/MessageDto';
import CommunicationActions from '../../../App/Mobx/Actions/CommunicationActions';
import DocumentActions from '../../../App/Mobx/Actions/DocumentActions';
import WebSync from '../../Molecules/DataSync/WebSync';
import Formatter from '../../../App/Libs/Formatter/Formatter';


const DashboardAllMessages = () => {

  const [messages, setMessages] = useState<Array<MessageDto>>()


  function load() {
    return CommunicationActions
      .GetMessages()
      .onSuccess(res => setMessages(res.data))
  }

  function downloadDocument(documentId: string) {
    return DocumentActions
      .DownloadDocument(documentId)
  }

  return (
    <WebSync client={() => load()}>
      {messages?.map(message => (
        <div className={styles.message} key={message.id}>
          <span className={classnames(styles.messageDate, !message.isRead && styles.newMessage)}>
            {Formatter.formatDate(message.createDate)}
          </span>

          <h4 className={styles.messageTitle}>{message.subject}</h4>
          <span className={styles.downloadLink} 
            onClick={() => downloadDocument(message.attachments[0].documentId)}>Dokument herunterladen</span>
        </div>
      ))}
    </WebSync>
  );
};

export default DashboardAllMessages;
