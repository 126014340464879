import { computed, makeAutoObservable } from "mobx"
import { makePersistable } from 'mobx-persist-store'
import { AuthorizationDto } from "../../Models/Contracts/Auth/AuthorizationDto"
import { AuthorizationResponseDto } from "../../Models/Contracts/Auth/AuthorizationResponseDto"


export class AuthStore {
    token?: string
    sessionId?: string
    tokenType: string = "bearer"
    IssuedAt?: number
    expiresAt?: number
    userName?: string
    IsOnboarded?: boolean
    IsSsoSynchronized?: boolean

    @computed get AccessToken() {
        return `${this.tokenType} ${this.token}`
    }

    @computed get IsAuthenticated(){
        return this.tokenType && this.token !== undefined && this.IsOnboarded !== undefined
    }

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: 'AuthStore', properties: ['token', 'sessionId','expiresAt', 'IssuedAt', 'userName', 'IsOnboarded'], storage: window.sessionStorage });
    }

    SetAuthorization(model: AuthorizationResponseDto) {
        this.token = model.token
        this.sessionId = model.sessionId
        this.expiresAt = model.expiresIn
        this.IssuedAt =  model.issuedAt
        this.userName = model.username
    }

    ToAuthorization(): AuthorizationDto {
        return {
            token: this.AccessToken,
            sessionId: this.sessionId!,
            issuedAt: this.IssuedAt,
            expireAt: this.expiresAt,
            userName: this.userName
        }
    }
}