import styles from "./ChooseAnswerPageLayout.module.scss";
import Selector from "../../../Molecules/Selector/Selector";
import {TransferSource} from "../../../../App/Models/Enums/TransferSource";
import React from "react";


type ChooseAnswerPageLayoutProps = {
  title: string;
  subtitle: string;
  onSelectorClick: (source: TransferSource) => void;
  data: { title: string, subtitle?: string, description?:string, value: any }[];
  popup?: React.ReactNode;
}


const ChooseAnswerPageLayout = (props: ChooseAnswerPageLayoutProps) => {


  return (
    <>
      <div className={styles.main}>

        <div className={styles.titleSection}>
          <h1>{props.title}</h1>
          <h3 className={styles.secondaryText}>{props.subtitle}</h3>
        </div>

        <div className={styles.selector}>
          {props.data.map(item => {
            return (
              <Selector onClick={() => props.onSelectorClick(item.value)}>
                    <span className={styles.choiceText}>
                      {item.title} <strong>{item.subtitle}</strong>
                    </span>
                {item.description &&
                  <span className={styles.description}>{item.description}</span>
                }
              </Selector>
            )
          })}
        </div>
      </div>
      {props.popup}
    </>

  );
}

export default ChooseAnswerPageLayout;