import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import { Callback } from '../../../../App/Models/Callback';
import { ProductDto } from '../../../../App/Models/Contracts/Compliance/ProductDto';
import Product from '../../../Organisms/Product/Product';
import styles from './ChooseProductPageLayout.module.scss';

type ProductPageLayoutProps = {

  title: string,
  secondaryText: string,
  footerText?: string,
  products?: Array<ProductDto>
  onProductClick?: Callback<ProductDto>
}

const ChooseProductPageLayout = (props: RouteComponentProps & ProductPageLayoutProps) => {

  const { Account, Compliance } = useStore()

  function onProductClick(product: ProductDto) {
      props.onProductClick && props.onProductClick(product)
  }

  return (
    <div className={styles.main}>
      <div className={styles.titleSection}>
        <h1 className={styles.title}>{props.title}</h1>
        <h3 className={styles.secondaryText}>{props.secondaryText}</h3>
        {props.footerText ? <h6 className={styles.footerText}>{props.footerText}</h6> : null}
      </div>
      {props.products?.filter(x => x.id !== Compliance.PensFreeProduct().id).map(product => (
        <Product
          key={product.id}
          id={product.id}
          title={product.name}
          description={product.description}
          onClick={() => onProductClick(product)} />
      ))}
    </div>
  );
};

export default ChooseProductPageLayout;
