import React, { useEffect, useState } from 'react';
import { Callback } from '../../../App/Models/Callback';
import { Button } from '../../Atoms/Button/Button';
import Checkbox from '../../Atoms/Checkbox/Checkbox';
import { PopUpProps } from '../../Atoms/Popup/Popup';
import WarningPopup from '../../Molecules/Popup/WarningPopup';
import t from '../../../App/Libs/Translate/Translate'
import styles from './StrategyChangePopup.module.scss';


type StrategyChangePopupProps = PopUpProps & {
    onAcceptClick?: Callback<HTMLElement>
}

const StrategyChangePopup = (props: StrategyChangePopupProps) => {

    const [isChecked, setIsChecked] = useState<boolean>(false)

    function onCheckChanged(e: HTMLInputElement) {
        setIsChecked(e.checked)
    }

    useEffect(() =>{
        if(!props.show)
            setIsChecked(false)
    }, [props.show])

    return (
        <WarningPopup {...props} frameClassName={styles.popup}>
            <div className={styles.content}>
                <h5 className={styles.popupTitle}>{t('account.strategy.choose.popup.title')}</h5>
                <p className={styles.popupParagraph}>{t('account.strategy.choose.popup.subtitle')}</p>
                <Checkbox
                    className={styles.checkbox}
                    onChange={e => onCheckChanged(e.currentTarget)}
                >
                    {t('account.strategy.choose.popup.confirm')}
                </Checkbox>
                <Button disabled={!isChecked}
                    onClick={e => props.onAcceptClick && props.onAcceptClick(e.currentTarget)}>
                    {t('account.strategy.choose.popup.continue')}
                </Button>
            </div>
        </WarningPopup>
    );
};

export default StrategyChangePopup;
