import { makeAutoObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { action, storeAnnotation } from "mobx/dist/internal";
import { ProductDto } from "../../Models/Contracts/Compliance/ProductDto";
import { CreatePortfolioDto } from "../../Models/Contracts/Portfolio/CreatePortfolioDto";
import { PortfolioDto } from "../../Models/Contracts/Portfolio/PortfolioDto";
import { VirtualPortfolioDto } from "../../Models/Contracts/Portfolio/VirtualPortfolioDto";
import { QuestionnaireDto } from "../../Models/Contracts/Questionnaire/QuestionnaireDto";
import { ModelPortfolioDto } from "../../Models/Contracts/Strategy/ModelPortfolioDto";
import { TransferSource } from "../../Models/Enums/TransferSource";

export class AccountStore{
    @observable questionnaire?: QuestionnaireDto
    @observable virtualPortfolios: Array<VirtualPortfolioDto>
    @observable portfolios: Array<PortfolioDto>
    @observable modelPortfolio: ModelPortfolioDto
    @observable transferSource: TransferSource
    @observable assetAmount: number = 0
    @observable avhValue: boolean
    @observable bvgValue: boolean
    @observable currentPortfolioIndex: number

    getCurrentVirtualPortfolio(proposalId: number | string){
        var id = 0
        if(Number.isInteger(proposalId)){
            id = proposalId as number
        }
        else{
            id = parseInt(proposalId as string)
        }

        return this.virtualPortfolios.find(x => x.proposalId === id)
    }

    constructor(){
        makeAutoObservable(this)
        makePersistable(this, { name: 'AccountStore', properties: [, 'questionnaire', 'transferSource', 'assetAmount', 'virtualPortfolios', 'modelPortfolio', 'portfolios', 'currentPortfolioIndex'] , storage: window.sessionStorage });
    }

    isPortfoliosCreated(){
        return this.virtualPortfolios && this.portfolios &&
            !this.virtualPortfolios.some(x => !x.isCreated)
    }
}