import classNames from 'classnames';
import React from 'react';

import styles from './SplitLayout.module.scss'


type SplitLayoutProps = {
    className?: string
    children?: React.ReactNode
}

const SplitLayout : React.FC<SplitLayoutProps> = (props) => {
    return (
        <div {...props} className={classNames(styles.main,props.className)}>
            
        </div>
    );
};

export default SplitLayout;