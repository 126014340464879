import classNames from 'classnames';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import Formatter from '../../../App/Libs/Formatter/Formatter';
import { Callback } from '../../../App/Models/Callback';
import addImg from '../../../Assets/Images/add.svg';
import subtractImg from '../../../Assets/Images/subtract.svg';
import Panel, { PanelProps } from '../../Atoms/Panel/Panel';
import styles from './ValuePanel.module.scss';


export type ValuePanelProps = PanelProps & {
    placeholder?: number
    step?: number
    defaultValue?: number
    min?: number
    max?: number
    label: string
    onChange?: Callback<number>
}


const ValuePanel: React.FC<ValuePanelProps> = (props) => {
    const [value, setValue] = useState<number>(props.defaultValue || 0)

    function onChange(val: string) {
        let value = parseInt(val.replace(/\'/g, ""), 10)
        if (isNaN(value)) {
            setValue(0)

            if (props.onChange) {
                return props.onChange(0)
            }
        }

        var min = props.min || 0
        var max =  props.max || Number.MAX_VALUE

        if (value! >= min && value! <= max) {
            setValue(value)

            if (props.onChange) {
                return props.onChange(value)
            }
        }
    }

    function onAdd() {
        let step = props.step || 1
        let newValue = value + step
        setValue(newValue)

        if (props.onChange) {
          props.onChange(newValue)
        }
    }

    function onSubtract() {
        let step = props.step || 1
        if (value! > 0) {
            let newValue = value - step
            setValue(newValue)

            if (props.onChange) {
              props.onChange(newValue)
            }
        }
    }

    return (
        <Panel className={classNames(styles.panel, props.className)}>
				    <div className={styles.valueContainer}>
				        <Image width={60} height={60} src={subtractImg} alt="" onClick={() => onSubtract()} />
				        <div className={styles.valueDiv}>
                    <input
                        type={'text'}
                        value={value ? Formatter.formatNumber(value) : ''}
                        className={styles.input}
                        placeholder={Formatter.formatNumber(props.placeholder || 0)}
                        onChange={e => onChange(e.currentTarget.value)}
                    />
                    <span>{props.label}</span>
				        </div>
				        <Image width={60} height={60} src={addImg} alt="" onClick={() => onAdd()} />
				    </div>
				</Panel>
    );
};

export default ValuePanel;
