import React from 'react';
import classNames from 'classnames';
import Page from "../../Molecules/Page/Page";
import DashboardLayout from '../../Organisms/Layout/DashboardLayout';
import { Button } from '../../Atoms/Button/Button'
import ProfileSupport from '../../Organisms/ProfileSupport/ProfileSupport';
import t from '../../../App/Libs/Translate/Translate';
import styles from './RiskProtectionPage.module.scss'

const DashboardRiskProtectionPage = () => {
  return (
    <Page>
      <DashboardLayout>
          <div className={styles.intro}>
              <h1 className={styles.title}>{t("account.assets.value.advantage_risk_protection_title")}</h1>
              <div className={styles.descriptionWrapper}>
                  <h3 className={styles.subtitle}>{t("dashbord.risk.protection.subtitle")}</h3>
                  <p className={styles.description}>{t("dashbord.risk.protection.description")}</p>
                  <Button to="/Dashboard/AddRiskProtection" className={styles.introButton}>{t("dashbord.risk.protection.apply_risk")}</Button>
              </div>
          </div>

          <div className={styles.advantages}>
              <div className={classNames(styles.advantage, styles.advantageOne)}>
                  <span className={styles.advantageNumber}>01</span>
                  <p className={styles.advantageTitle}>{t("dashbord.risk.protection.advantage.title1")}</p>
                  <p className={styles.advantageDescription}>{t("dashbord.risk.protection.advantage.description1")}</p>
              </div>
              <div className={classNames(styles.advantage, styles.advantageTwo)}>
                  <span className={styles.advantageNumber}>02</span>
                  <p className={styles.advantageTitle}>{t("dashbord.risk.protection.advantage.title2")}</p>
                  <p className={styles.advantageDescription}>{t("dashbord.risk.protection.advantage.description2")}</p>
              </div>
              <div className={classNames(styles.advantage, styles.advantageThree)}>
                  <span className={styles.advantageNumber}>03</span>
                  <p className={styles.advantageTitle}>{t("dashbord.risk.protection.advantage.title3")}</p>
                  <p className={styles.advantageDescription}>{t("dashbord.risk.protection.advantage.description3")}</p>
              </div>
              <div className={classNames(styles.advantage, styles.advantageFour)}>
                  <span className={styles.advantageNumber}>04</span>
                  <p className={styles.advantageTitle}>{t("dashbord.risk.protection.advantage.title4")}</p>
                  <p className={styles.advantageDescription}>{t("dashbord.risk.protection.advantage.description4")}</p>
              </div>
          </div>

          <div className={styles.howItWorks}>
              <h2>{t("dashbord.risk.protection.advantage.how.title")}</h2>
              <h3>{t("dashbord.risk.protection.advantage.how.subtitle")}</h3>
              <p>{t("dashbord.risk.protection.description")}</p>
              <br></br>
              <p>{t("dashbord.risk.protection.advantage.how.steps.title")}</p>
              <ol>
                  <li>{t("dashbord.risk.protection.advantage.how.steps.step1")}</li>
                  <li>{t("dashbord.risk.protection.advantage.how.steps.step2")}</li>
                  <li>{t("dashbord.risk.protection.advantage.how.steps.step3")}</li>
              </ol>
              <Button to="/Dashboard/AddRiskProtection" className={styles.introButton}>{t("dashbord.risk.protection.apply_risk")}</Button>
              <ProfileSupport className={styles.supportBlock} title={t("dashbord.risk.protection.support")}/>
          </div>
      </DashboardLayout>
    </Page>
  );
};

export default DashboardRiskProtectionPage;
