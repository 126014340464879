import { FilteredAssetClassDto } from "../../Models/Contracts/FilteredAssetClassDto";
import { PositionDto } from "../../Models/Contracts/Strategy/PositionDto";
import { AssetClassTypes } from "../../Models/Enums/AssetClassTypes";

export default class StrategUtils{

    static getRisk(positions?: Array<PositionDto>){
        if(!positions)
            return 0
        let value = StrategUtils.getPositionsAllocation(positions.filter(x => x.security.assetClass.id === AssetClassTypes.Equity))
        return Math.round(value * 100)
    }

    static getFilteredAssetClass(positions: Array<PositionDto>){
        var array: Array<FilteredAssetClassDto> = []

        positions.forEach(x =>{
            var assetClass = x.security.assetClass

            var exists = array.find(x => x.id === assetClass.id)
            if(exists){
                exists.positions.push(x)
            }
            else{
                array.push({
                    id: assetClass.id,
                    name: assetClass.name,
                    color: assetClass.color,
                    externalId: assetClass.externalId,
                    description: assetClass.description,
                    positions: [ x ]
                })
            }
        })

        return array
    }

    static getPositionsAllocation(positions: Array<PositionDto>){
        var total = 0
        positions.forEach(x => total += x.allocation)
        return total
    }
}