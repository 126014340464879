import React, {Children, useState} from 'react';
import {Dropdown as DropdownReact} from 'react-bootstrap';
import styles from './Dropdown.module.scss';

import editIcon from '../../../Assets/Images/Edit/Pencil.svg';

type customToggleProps = {
  onClick?: any
}

type propsTypes = {
  children: Array<JSX.Element> | JSX.Element,
  customToggle? :any
}

const DefaultCustomToggle = React.forwardRef<any, customToggleProps>(({onClick}, ref) => (
  <span
    ref={ref}
    className={styles.main}
    onClick={e => onClick(e)}
  >
      <img className={styles.icon} src={editIcon} alt="edit icon"/>
  </span>
));

const Dropdown = ({children, customToggle}: propsTypes) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  function toggle() {
    setDropdownOpen(!dropdownOpen)
  }

  const arrayChildren = Children.toArray(children);

  return (
    <DropdownReact>
      <DropdownReact.Toggle as={customToggle ? customToggle :DefaultCustomToggle}></DropdownReact.Toggle>

      <DropdownReact.Menu className={styles.dropdownMenu}>
        {
          Children.map(arrayChildren, (child, index) => {
            return (
              <DropdownReact.Item
                  key={index}
                  className={styles.dropdownItem}
              >
                  {child}
              </DropdownReact.Item>
          )
        })}
      </DropdownReact.Menu>
    </DropdownReact>
  )
};

export default Dropdown;
