import classNames from 'classnames';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Callback } from '../../../App/Models/Callback';
import Tab from './Dependencies/Tab';
import styles from './Tabs.module.scss';

type Props = {
    className?:string
    selectedClassName?: string
    selectedIndex?: number
    startingstep?: number
    autoscroll?: boolean
    handler?: any
    maxSelectedIndex?: number
    onChange?: Callback<number | undefined>
    onMaxSelectedChanged?: Callback<number | undefined>
    children?: any
    disabled?: boolean
}

type State = {
    selectedIndex: number
    autoscroll: boolean
    alignCenter?: boolean
}

class Tabs<P = {},S = {}> extends Component<Props & P, State & S> {

    headers:Array<any> = [];
    activeHeaders:Array<any> = [];
    content:Array<any> = [];

    tabsDiv?: any;
    contentDiv?: any;
    tabsReference:Array<any> = [];

    handler;
    startingstep: number;

    maxSelectedIndex?: number;

    constructor(props: Props & P) {
        super(props);
        this.state = {
            ...this.state,
            selectedIndex: this.props.selectedIndex ?this.props.selectedIndex : 0,
            autoscroll: this.props.autoscroll !== undefined ? this.props.autoscroll : false
        }


        this.SetTabsFromProps(props)

        this.tabsDiv = React.createRef();
        this.contentDiv = React.createRef();

        this.handler = this.props.handler || null;
        this.startingstep = this.props.startingstep || 0;
    }

    componentDidMount() {

        this.maxSelectedIndex = this.props.maxSelectedIndex
    }

    SetTabsFromProps(props: Props) {
        this.headers = []
        this.activeHeaders = []
        this.content = []
        React.Children.forEach(props.children, child => {
            if (child !== null && child?.type === this.getTabType()) {
                this.headers.push(child!.props!.header || <></>);
                this.activeHeaders.push(child?.props!.headerActive);
                this.content.push(child!.props!.content ? React.cloneElement(child.props.content, { tabsHandler: this }) : null);
            }
        });
    }

    onTabSelected(index : number) {

    }

    setTabReference(ref: Tab, index: number) {
        this.tabsReference[index] = ref;
    }

    getTabReference(index:number) {
        return this.tabsReference[index];
    }

    getTabHeaderForIndex(index: number) {
        return ReactDOM.findDOMNode(this.getTabReference(index));
    }

    setTabsDiv(ref:HTMLDivElement) {
        this.tabsDiv = ref;
    }

    setContentDiv(ref: any) {
        this.contentDiv = ref;
    }

    getTabsDiv() {
        return this.tabsDiv;
    }

    scrollToTopOfTabSelector() {
        if (window.scrollY > this.contentDiv.offsetTop)
            this.tabsDiv!.scrollIntoView();
    }

    scrollIntoViewIfNeeded(target: HTMLDivElement) {
        try {
            if (target.getBoundingClientRect().bottom > window.innerHeight) {
                target.scrollIntoView({ behavior: 'smooth' });
            }

            if (target.getBoundingClientRect().top < 0) {
                target.scrollIntoView({ behavior: 'smooth' });
            }
        }
        catch{

        }
    }

    onMaxSelectedChanged() {
        if (this.props.onMaxSelectedChanged)
            this.props.onMaxSelectedChanged(this.maxSelectedIndex)
    }

    setTabSelected(index: number) {
        if (this.maxSelectedIndex! < this.props.maxSelectedIndex! ||
            !this.maxSelectedIndex)
            this.maxSelectedIndex = this.props.maxSelectedIndex

        if (this.maxSelectedIndex !== undefined &&
            this.maxSelectedIndex < index)
            index = this.maxSelectedIndex

        if (this.state.autoscroll)
            this.scrollIntoViewIfNeeded(this.tabsDiv)

        if (index >= this.headers.length)
            index = this.headers.length - 1;
        this.setState({
            ...this.state,
            selectedIndex: index
        }, () => {
            // if (onSetStateChange)
            //     onSetStateChange();
        });

        this.onTabSelected(index);
        if (this.props.onChange)
            this.props.onChange(index)

    }

    selectNextTab() {
        var nextIndex = this.getSelectedIndex() + 1
        if (nextIndex > this.maxSelectedIndex!) {
            this.maxSelectedIndex = nextIndex
            this.onMaxSelectedChanged()
        }
        if (nextIndex < this.content.length)
            this.setTabSelected(nextIndex);
    }

    selectPreviousTab() {
        if ((this.getSelectedIndex() - 1) >= 0)
            this.setTabSelected(this.getSelectedIndex() - 1);
    }

    tabSelected(index: number) {
        if(this.props.disabled)
            return
        this.setTabSelected(index);
    }

    getSelectedIndex() {
        return this.state.selectedIndex;
    }

    getTabsCount() {
        //console.log('Tabs Length = '+this.headers.length);
        return this.headers.length;
    }

    renderTabs() {
        var tabs: Array<JSX.Element> = [];
        var i = 0;
        var self = this;
        this.headers.forEach(element => {
            var index = i;
            if (index === self.state.selectedIndex)
                tabs.push(<Tab ref={e => self.setTabReference(e!, index)} header={React.cloneElement(self.activeHeaders[i] || self.headers[i], { selected: index === self.state.selectedIndex })} selected={index === self.state.selectedIndex} onClick={() => self.tabSelected(index)} />);
            else
                tabs.push(<Tab ref={e => self.setTabReference(e!, index)} header={React.cloneElement(self.headers[i], { selected: index === self.state.selectedIndex })} selected={index === self.state.selectedIndex} onClick={() => self.tabSelected(index)} />);
            i++;
        });
        return tabs;
    }

    getTabType() {
        return Tab;
    }

    setTabAlignmentCenter() {
        this.setState({
            ...this.state,
            alignCenter: true
        })
    }


    componentDidUpdate(oldProps: Props) {
        if (oldProps.children !== this.props.children)
            this.SetTabsFromProps(this.props)

        this.maxSelectedIndex = this.props.maxSelectedIndex

        if (oldProps.selectedIndex !== this.props.selectedIndex) {
            this.setState({
                ...this.state,
                selectedIndex: this.props.selectedIndex!,
            })
            if (this.props.onChange)
                this.props.onChange(this.props.selectedIndex)
        }
    }

    getTabSelectorClassName() {
        return undefined
    }

    render() {
        return (
            <div className={classNames(styles.tabsContainer, this.props.className)}>
                <div ref={e => this.setTabsDiv(e!)} className={classNames(styles.tabsSelectorContainer, this.getTabSelectorClassName())}>
                    {this.renderTabs()}
                </div>
                <div ref={e => this.setContentDiv(e)} className={styles.tabContent}>
                    {this.content[this.state.selectedIndex]}
                </div>
            </div>
        )
    }
}

export default Tabs;