import { default as React} from 'react';
import classNames from 'classnames';
import t from '../../../App/Libs/Translate/Translate';
import styles from './PersonalConsultationInfo.module.scss';



const PersonalConsultationInfo = () => {
  return (
    <div className={styles.main}>

      <div className={styles.advantages}>
        <div className={classNames(styles.advantage, styles.advantageOne)}>
          <span className={styles.advantageNumber}>01</span>
          <p className={styles.advantageTitle}>{t('account.assets.value.advantage_perfect_match_strategy_title')}</p>
          <p className={styles.advantageDescription}>{t('account.assets.value.advantage_perfect_match_strategy_desc')}</p>
        </div>
        <div className={classNames(styles.advantage, styles.advantageTwo)}>
          <span className={styles.advantageNumber}>02</span>
          <p className={styles.advantageTitle}>{t('account.assets.value.advantage_individual_bank_choice_title')}</p>
          <p className={styles.advantageDescription}>{t('account.assets.value.advantage_individual_bank_choice_desc')}</p>
        </div>
        <div className={classNames(styles.advantage, styles.advantageThree)}>
          <span className={styles.advantageNumber}>03</span>
          <p className={styles.advantageTitle}>{t('account.assets.value.advantage_risk_protection_title')}</p>
          <p className={styles.advantageDescription}>{t('account.assets.value.advantage_risk_protection_desc')}</p>
        </div>
      </div>
    </div>
  );
};

export default PersonalConsultationInfo;
