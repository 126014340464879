import WebClient from "../../Libs/WebClient/WebClient";

import { RiskProtectionCalculationModelDto } from "../../Models/Contracts/Dashboard/RiskProtectionCalculationModelDto";
import { RiskProtectionCalculationOutputModelDto } from "../../Models/Contracts/Dashboard/RiskProtectionCalculationOutputModelDto";
import { GlobalStore } from "../Stores/MainStore";

export class RiskProtectionCalculationActions{
    static PostRiskProtectionCalculationActions(model: RiskProtectionCalculationModelDto){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Post<RiskProtectionCalculationOutputModelDto>(`RiskProtection/calculate`, model)
    }
}
