import React from 'react';

import { IconProps } from '../Menu/Menu';
import Link from '../../Atoms/Link/Link';
import ThreeDotsIcon from '../../../Assets/Images/three-dots.svg'

import styles from './DashboardSubMenuCard.module.scss';

type PropsType = {
  title: string;
  description: string;
  Icon: React.FC<IconProps>,
  to: string;
}

const DashboardSubMenuCard = ({ title, description, Icon, to }: PropsType) => {
    return (
        <Link to={to} className={styles.main}>
            <Icon className={styles.icon} />
            <span className={styles.title}>{title}</span>
            <span className={styles.description}>{description}</span>
            <img className={styles.action} src={ThreeDotsIcon} alt="three dots icon" />
        </Link>
    );
};

export default DashboardSubMenuCard;
