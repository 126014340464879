import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SuccessPageLayout from '../../../Organisms/Layout/SuccessPageLayout';
import FooterNavBar from "../../../Molecules/Navbar/FooterNavbar";
import React from "react";
import t from '../../../../App/Libs/Translate/Translate'


const RegisterThankForRequest = () => {

  function renderFooter() {
    return <FooterNavBar linkToNext="/"
                         buttonBackText={t('common.back')}
                         buttonNextText={t('register.portfolio.register_thank_for_request.back_to_site')}/>
  }

  return (
    <Page>
      <OnboardingLayout selectedStepIndex={1}
                        progress={0.5}
                        footer={renderFooter()}
                        contentMaxWidth='1600px'
      >
        <SuccessPageLayout
          title={t('register.portfolio.register_thank_for_request.title')}
        />
      </OnboardingLayout>
    </Page>
  );
};

export default RegisterThankForRequest;
