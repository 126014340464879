import React, { useState } from 'react';
import styles from './DevelopmentOfAssets.module.scss';
import LineChart from '../LineChart/LineChart';
import Select from '../../Atoms/Select/Select';
import classNames from 'classnames';
import t from '../../../App/Libs/Translate/Translate'

type DepositType = {
  id: number;
  title: string;
  type: string;
  amount: number;
  currency: string;
  diff: number;
  diffPercentage: number;
  chart: ChartSchema;
}

type ChartSchema = {
    title: string;
    userID: string;
    amountPositionsX: number;
    depositeType: string;
    depositeName:string;
    userData: Array<ChartData>;
    aspectRatio: number;
}

type ChartData = {
    id: number;
    timestamp: Date;
    yearBefore:number;
    yearCurrent: number;
    month: number;
    monthName: string;
    currency: string;
    amount: number;
}

const DevelopmentOfAssets = (props: DepositType) => {
    
    const [renderedUserData, setRenderedUserData] = useState({
        labels: props.chart.userData.map((labels) => 
            labels.monthName + " " + labels.yearCurrent
        ), 
        datasets: [
            {
                label: props.chart.userData[props.chart.userData.length-1].yearBefore + " / " + props.chart.userData[props.chart.userData.length-1].yearCurrent,
                data: props.chart.userData.map((data) => data.amount),
                fill: true,
                borderColor: "#1DEBA3",
                backgroundColor: "rgba(29, 235, 163, 0.1)",
                pointBackgroundColor: "#1DEBA3",
                pointBorderColor: "#1DEBA3",
                pointHoverBackgroundColor: "#1DEBA3",
                pointHoverBorderColor: "#1DEBA3",
                // lineTension: 0.7,
                borderJoinStyle: 'miter',
                borderWidth: 1
            }
        ]
        
    })

    const chartOptions = setChartOptions(props.chart.aspectRatio);

    const balances = [
        {id: "profit", value: `${t("account.portfolio.depot.graph.profit")}`},
        {id: "saldo", value: `${t("account.portfolio.depot.graph.saldo")}`},
        {id: "year", value: `${t("account.portfolio.depot.graph.year")}`}
    ];

    const period = [
        {id: "first", value: `${t("account.portfolio.depot.graph.first")}`},
        {id: "sinceYear", value: `${t("account.portfolio.depot.graph.sinceYear")}`},
        {id: "halfYear", value: `${t("account.portfolio.depot.graph.halfYear")}`},
        {id: "quarter", value: `${t("account.portfolio.depot.graph.quarter")}`}
    ];

    const months = [`${t("account.portfolio.depot.graph.jun")}`, 
                    `${t("account.portfolio.depot.graph.jul")}`,
                    `${t("account.portfolio.depot.graph.aug")}`,
                    `${t("account.portfolio.depot.graph.sep")}`,
                    `${t("account.portfolio.depot.graph.okt")}`,
                    `${t("account.portfolio.depot.graph.nov")}`,
                    `${t("account.portfolio.depot.graph.dez")}`,
                   ];
    const quarter = [`${t("account.portfolio.depot.graph.okt")}`,
                     `${t("account.portfolio.depot.graph.nov")}`,
                     `${t("account.portfolio.depot.graph.dez")}`,
                   ];

    const date = ["01.01.2023", "01.07.2023", "01.01.2024", "01.07.2024", "01.01.2025", "01.07.2025"];
    
    const rate = ["80.0%", "60.0%", "40.0%", "20.0%", "0.0%", "-20.0%"]
    const year = ["", "", "", "", "", "0",""]

    const [balanceId, setBalanceId] = useState("profit");
    const [periodId, setPeriodId] = useState("first");
   
    return (
        <div className={styles.main}>
            <div className={classNames(styles.flex, styles.jc_sb)}>
                <span className={styles.title}>{t("account.portfolio.depot.graph.title")}</span>
                <div className={styles.flex}>
                    <div className={styles.balanceContainer}>
                        <span className={styles.title}>{t("account.portfolio.depot.graph.select.title")}</span>
                        <Select
                            itemsList={balances}
                            className={styles.balanceSelect}
                            placeholder={balances.find(item => item.id === balanceId).value}
                            onChange={(event)=>setBalanceId(event)}
                        >
                        </Select>
                    </div>
                    <div>
                        <span className={styles.title}>{t("account.portfolio.depot.graph.select.title2")}</span>
                        <Select
                            itemsList={period}
                            className={styles.balanceSelect}
                            placeholder={period.find(item => item.id === periodId).value}
                            onChange={(event)=>setPeriodId(event)}
                        >
                        </Select>
                    </div>
                </div>
            </div>
            <div className={styles.graph}>
                {/* <LineChart id={"canvas-"+props.chart.depositeName} data={renderedUserData} options={chartOptions}/> */}
            </div>
            <div className={styles.graph_bg}>
                {balanceId ==="profit" ?rate.map(el=>(<div className={styles.container}>
                        <span className={styles.text}>{el}</span>
                        <hr className={styles.line} />
                    </div>
                )):""}
                {balanceId ==="saldo" ?rate.map(el=>(<div className={styles.container}>
                        <span className={styles.text}>{el}</span>
                        <hr className={styles.line} />
                    </div>
                )):""}
                {balanceId ==="year" ?year.map(el=>(<div className={styles.container}>
                        <span className={styles.text}>{el?el:""}</span>
                        <hr className={styles.line}/>
                    </div>
                )):""}
                <div className={styles.months}>
                    {periodId === "first"? date.map(el => (<div>{el}</div>)):""}
                    {periodId === "sinceYear"? months.map(el => (<div>{el}</div>)):""}
                    {periodId === "halfYear"? months.map(el => (<div>{el}</div>)):""}
                    {periodId === "quarter"? quarter.map(el => (<div>{el}</div>)):""}
                </div>
            </div>
        </div>
    );
};

function setChartOptions(aspectRatio: number) {
    return {
        bezierCurve: true,
        layout: {
            padding: {
                left: -15,
                right: -100,
                bottom: -100
            }
        },
        responsive: true,
        maintainSpectRatio: false,
        aspectRatio: aspectRatio,
        elements: {
            point: {
                radius: 0
            }
        },
        scales: {
            y: {
                beginAtZero: false,
                ticks: {
                    display: false,
                    padding: -20
                },
                grid: {
                    display: false
                }
            },
            x: {
                beginAtZero: false,
                ticks: {
                    display: false,
                    min: 0
                },
                grid: {
                    display: false
                }
            }
        },
        legend: {
            display: false
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.yLabel;
                    }
                }
            }
        }
    };
}

export default DevelopmentOfAssets;
