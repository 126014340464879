import { DateOnly } from "../obj/DateOnly";

export default class Formatter {

    static phoneNumber(value: string) {
        var cleaned = ('' + value).replace(/\D/g, '');
        var match = cleaned.match(/(\d{3})(\d{1,3})?(\d{1,2})?(\d{1,2})?/);
        if (match) {
            match = match.slice(1)
            return match.map((value, index) => {
                if (index === 0)
                    return value
                if (value)
                    return ' ' + value
                else
                    return ''
            }).join('')
        }
        return cleaned
    }

    static formatNumber(value: number | string) {
        var val = value.toString()
        
        var split = val.split('.')
        var number = split[0]
        var decimal = split.length > 1? "."+split[1] : ''
        return number.replace(/\B(?=(\d{3})+(?!\d))/g, "'") + decimal
    }


    private static twoDigitUnit(value: number) {
        if (value < 10)
            return `0${value}`
        return value.toString()
    }

    static formatDate(value: string) {
        var date = new Date(value)
        return date.toLocaleDateString('FI')
        // return `${this.twoDigitUnit(date.getDay())}.${this.twoDigitUnit(date.getMonth())}.${date.getFullYear()}`
    }

    static formatIban = (value: string) => {
        value = value?.toUpperCase().split(' ').join('')
        value = value.match(/.{1,4}/g).join(' ');
        return value
    }
}