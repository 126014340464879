import React, {useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import t from '../../../../App/Libs/Translate/Translate';
import {VirtualPortfolioDto} from '../../../../App/Models/Contracts/Portfolio/VirtualPortfolioDto';
import AccountSplitPortfolioTemplate from "../../../Templates/AccountSplitPortfolioTemplate";

const portfoliosDummy = [
  {
    name: "Portfolio 1",
    disabled: false
  },
  {
    name: "Portfolio 2",
    disabled: true
  }
]

const DashboardSplitPortfolioPage = (props: RouteComponentProps) => {



  const [isSplitEnabled, setIsSplitEnabled] = useState<boolean>(false);
  const [portfolios, setPortfolios] = useState<Array<VirtualPortfolioDto>>([])


  function onSplitChange(e: HTMLInputElement) {
    setIsSplitEnabled(e.checked)
  }

  function onNextClick() {
    // If the user creates a new portfolio while already having a FZ portfolio (only one), he has the option of adopting the strategy.
    props.history.push('/dashboard/portfolio/add/prefer-strategy')

    // If the user create a new portfolio without having a FZ yet (or more than one), he doesn't have the option of adopting the strategy
    // props.history.push('/dashboard/portfolio/add/experience')
  }

  function onSplitValueChange(e: Array<VirtualPortfolioDto>) {
    setPortfolios(e)
  }

  return (
    <AccountSplitPortfolioTemplate {...props}
                                   title={t('account.portfolio.split.title')}
                                   subtitle={t('account.portfolio.split.subtitle')}
                                   selectedStepIndex={1}
                                   progress={0.5}
                                   portfolios={portfolios}
                                   portfoliosDummy={portfoliosDummy}
                                   onNextClick={onNextClick}
                                   onSplitValueChange={onSplitValueChange}
                                   onSplitChange={onSplitChange}
                                   isSplitEnabled={isSplitEnabled}
                                   assetAmount={300000}/>
  )

}

export default DashboardSplitPortfolioPage;
