import { useForm, Controller } from "react-hook-form";
import Page from "../../Molecules/Page/Page";
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import Input from '../../Atoms/Input/Input';
import Datepicker from '../../Atoms/Datepicker/Datepicker';
import { RouteComponentProps } from 'react-router-dom';
import DashboardPensionCard from '../../Organisms/DashboardPensionCard/DashboardPensionCard';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';

import styles from './AdjustRiskProtectionPage.module.scss';

type Inputs = {
  residence: string,
  profession: string,
  startInsuranceDate: Date,
};

const DashboardAdjustRiskProtectionPage = (props: RouteComponentProps) => {

  const renderFooter = () => {
    return <FooterNavBar
      linkToNext='/RiskPremiumPayment'
      buttonBackText='Zurück'
      buttonNextText='Risikoprämienzahlung ansehen'/>
  }

  const {
    register,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const ERRORS = {
    required: "Dieses Feld ist erforderlich",
  }

  return (
    <Page>
      <OnboardingLayout
        contentMaxWidth='770px'
        footer={renderFooter()}
      >

      <div className={styles.flex}>
        <Controller
          control={control}
          name='startInsuranceDate'
          render={({ field }) => (
            <Datepicker
              onChange={(date: Date | null) => field.onChange(date)}
              selected={field.value}
            />
          )}
          rules={{
            required: true,
          }}
        />

        <div className={styles.inputContainer}>
          <Input
            placeholder="Wohnsitz"
            className={styles.input}
            {...register("residence", {
              required: true,
            })}
            error={errors.residence && ERRORS.required}
          />
        </div>
      </div>
  
        <Input
          placeholder="Beruf"
          {...register("profession", {
            required: true,
          })}
          error={errors.profession && ERRORS.required}
        />

        <hr />

        <DashboardPensionCard 
          title="Jährliche Invalidenrente"
          description="Über den Tod spricht man nicht gerne. Was aber, wenn Ihnen etwas passiert? Wie sieht die finanzielle 
          Absicherung Ihrer Hinterbliebenen aus? Sorgen Sie vor."
          maxValue={1000000}
          rulesList={["maximal 300% des eingebrachten Vorsorgeguthabens", "maximal CHF 5‘000‘00"]}
        />

        <DashboardPensionCard 
          title="Todesfallkapital"
          description="Über den Tod spricht man nicht gerne. Was aber, wenn Ihnen etwas passiert? Wie sieht die finanzielle 
          Absicherung Ihrer Hinterbliebenen aus? Sorgen Sie vor."
          maxValue={1000000}
          rulesList={["maximal 300% des eingebrachten Vorsorgeguthabens", "maximal CHF 5‘000‘00"]}
        />

      </OnboardingLayout>
    </Page>
  );
};

export default DashboardAdjustRiskProtectionPage;