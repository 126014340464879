import WebClient from "../../Libs/WebClient/WebClient";
import { MessageDto } from "../../Models/Contracts/Communication/MessageDto";
import { GlobalStore } from "../Stores/MainStore";

export default class CommunicationActions{
    static GetMessages(){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<Array<MessageDto>>(`contact/messages`)
    }
}