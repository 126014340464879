import styles from './RiskProtectionDetailsPage.module.scss'
import DashboardLayout from "../../Organisms/Layout/DashboardLayout";
import Page from "../../Molecules/Page/Page";
import DashboardHeader from "../../Organisms/DashboardHeader/DashboardHeader";
import InfoPanel from "../../Atoms/Panel/InfoPanel";
import shieldIcon from "../../../Assets/Images/Dashboard/shield.svg";
import ProfileSupport from "../../Organisms/ProfileSupport/ProfileSupport";
import RiskProtectionIndicators from "../../Molecules/RiskProtectionIndicators/RiskProtectionIndicators";
import {useState} from "react";
import ProductCard from "../../Molecules/ProductCard/ProductCard";
import DownloadButton from "../../Atoms/Button/DownloadButton";
import {RouteComponentProps} from "react-router-dom";
import t from '../../../App/Libs/Translate/Translate'

const RiskProtectionDetailsPage = (props: RouteComponentProps) => {

  const [isRiskProtectionCompleted, setIsRiskProtectionCompleted] = useState(true);

  const onHandleProtectionEdit = () => {

  }

  const onHandleProtectionDelete = () => {
    props.history.push("/Dashboard/CancelRiskProtection");
  }

  const renderDescription = () => {
    if (isRiskProtectionCompleted) return (
        <>
          <div className={styles.description}>{t('dashboard.risk_protection_details.desc_if_risk_protection_completed')}</div>

          <DownloadButton className={styles.downloadButton}>{t('dashboard.risk_protection_details.download_contract')}</DownloadButton>
        </>
      )
    else return (
      <div className={styles.infoPanelDescription}>{t('dashboard.risk_protection_details.desc_if_risk_protection_not_completed')}</div>
    )
  }

  return (
    <Page>
      <DashboardLayout>
        <DashboardHeader title={"Portfolio 1"}
                         subtitle={t('common.type_vested_benefits')}
                         onButtonEdit={onHandleProtectionEdit}
                         onButtonDelete={onHandleProtectionDelete}
        >
          <h1 className={styles.title}>{t('dashboard.risk_protection_details.title')}</h1>
        </DashboardHeader>
        <div className={styles.main}>
          {isRiskProtectionCompleted &&
            <div className={styles.indicators}>
              <RiskProtectionIndicators title={t('dashboard.risk_protection_details.annual_disability.title')}
                                        value={150000.00}
                                        description={t('dashboard.risk_protection_details.annual_disability.description')}
                                        progress={0.5}
              />
              <RiskProtectionIndicators title={t('dashboard.risk_protection_details.death_benefit.title')}
                                        value={250000.00}
                                        description={t('dashboard.risk_protection_details.death_benefit.description')}
                                        progress={0.5}
              />
            </div>
          }
          <InfoPanel icon={shieldIcon}>
            <div className={styles.infoPanelTitle}>{t('dashboard.risk_protection_details.risk_insurance')}</div>
            {renderDescription()}
          </InfoPanel>
          <div className={styles.links}>
            {isRiskProtectionCompleted &&
              <>
                <ProductCard title={t('dashboard.risk_protection_details.adjust_insurance_coverage')} to={"/Dashboard/DetermineInsuranceCoverage"}/>
                <ProductCard title={t('dashboard.risk_protection_details.dissolve_risk_protection')} to={"/Dashboard/DetermineInsuranceCoverage"}/>
              </>
            }
            <ProfileSupport title={t('dashboard.risk_protection_details.questions_about_risk_protection')}/>
          </div>
        </div>
      </DashboardLayout>
    </Page>
  )
}

export default RiskProtectionDetailsPage;