import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from "react-router-dom";
import PerformanceUtils from '../../../App/Libs/Helper/PerformanceUtils';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { PortfolioAccountsDto } from '../../../App/Models/Contracts/Portfolio/PortfolioAccountsDto';
import { ProductEnum } from '../../../App/Models/Enums/ProductEnum';
import DepositCard from '../../Molecules/DepositCard/DepositCard';
import Page from '../../Molecules/Page/Page';
import DashboardMessages from '../../Organisms/DashboardMessages/DashboardMessages';
import ProductCards from "../../Organisms/DashboardsProductCards/ProductCards";
import DashboardSubMenu from '../../Organisms/DashboardSubMenu/DashboardSubMenu';
import DashboardLayout from '../../Organisms/Layout/DashboardLayout';
import styles from './MainPage.module.scss';
import t from '../../../App/Libs/Translate/Translate';


type ChartSchema = {
  title: string;
  userID: string;
  amountPositionsX: number;
  depositeType: string;
  depositeName: string;
  userData: Array<ChartData>;
  aspectRatio: number;
}

type ChartData = {
  id: number;
  timestamp: Date;
  yearBefore: number;
  yearCurrent: number;
  month: number;
  monthName: string;
  currency: string;
  amount: number;
}

const messages = [
  {
    id: "1",
    title: "Der Quartals Report steht zum Download bereit",
    date: "16.06.23",
    downloadLink: "#",
    isNew: true,
  },
  {
    id: "2",
    title: "Ihre Risikoschutz-Prüfung ist abgeschlossen. Unterschreiben Sie angehängtes Dokument und sende sie es an uns zurück.",
    date: "02.06.23",
    downloadLink: "#",
    isNew: true,
  },
  {
    id: "3",
    title: "Die Eröffnung Ihrer 3a-Lösung ist abgeschlossen …",
    date: "12.09.22",
    downloadLink: "#",
    isNew: false,
  },
  {
    id: "4",
    title: "Die Strategie Ihrer Freizügigkeitslösung ist angepasst.",
    date: "08.08.22",
    downloadLink: "#",
    isNew: false,
  },
  {
    id: "5",
    title: "Willkommen zu Ihrer Eröffnung des Freizügigkeitskonto. Anbei finden Sie Ihre Vertragsbestätigung.",
    date: "02.08.22",
    downloadLink: "#",
    isNew: false,
  },
]

const DashboardMainPage = observer((props: RouteComponentProps) => {

  const UserChartDataMock: Array<ChartData> = getMockChartData();
  const UserChartSchemaMock: ChartSchema = getMockSchemaData(UserChartDataMock)


  const { Dashboard } = useStore()

  const data = Dashboard.investmentOverview

  const onClickFZDeposits = (portfolio: PortfolioAccountsDto) => {
    props.history.push(`/dashboard/depot/${portfolio.id}/overview`)
  }

  const onClick3ADeposits = (portfolio: PortfolioAccountsDto) => {
    props.history.push(`/Dashboard/depot/${portfolio.id}/3a/overview`)
  }

  const load = () => {
    return PortfolioActions
      .GetInvestmentOverview()
  }

  return (
    <Page>
      <DashboardLayout sync={() => load()}>
          <div className={styles.main}>
            <div className={styles.section}>
              <h3 className={styles.title}>{t("dashbord.mainpage.titleFZ")}</h3>
              <div className={styles.deposits}>
                {data?.portfolios.filter(x => (x.product.id === ProductEnum.Independent || x.product.id === ProductEnum.PensFree)).map(deposit => (
                  <DepositCard key={deposit.id} info={{
                    id:deposit.id,
                    title: deposit.name,
                    currency: "CHF",
                    amount: deposit.investedAmount,
                    diff: PerformanceUtils.CalculatePerformanceValue(deposit.currentValue, deposit.currentPerformance),
                    diffPercentage: deposit.currentPerformance, 
                    type: t("common.type_vested_benefits"),
                    chart: UserChartSchemaMock
                  }} onClick={() => onClickFZDeposits(deposit)} />
                ))}
              </div>
            </div>

            <div className={styles.section}>
              <h3 className={styles.title}>{t("dashbord.mainpage.title3a")}</h3>
              <div className={styles.deposits}>
                {data?.portfolios.filter(x => x.product.id === ProductEnum.PK).map(deposit => (
                  <DepositCard key={deposit.id} info={{
                    id:deposit.id,
                    title: deposit.name,
                    currency: "CHF",
                    amount: deposit.investedAmount,
                    diff: PerformanceUtils.CalculatePerformanceValue(deposit.currentValue, deposit.currentPerformance),
                    diffPercentage: deposit.currentPerformance, 
                    type: t("common.type_3a_depot"),
                    chart: UserChartSchemaMock
                  }} onClick={() => onClick3ADeposits(deposit)} />
                ))}
              </div>
            </div>

            <div className={styles.sectionMessagesAndSubMenu}>
              <div className={styles.sectionMessages}>
                <h3 className={styles.subtitle}>{t("dashbord.mainpage.messages")}</h3>
                <DashboardMessages  />
              </div>
              <div className={styles.sectionSubMenu}>
                <DashboardSubMenu />
              </div>
            </div>
            <div className={styles.section}>
              <ProductCards riskProtectionLink="/Dashboard/AddRiskProtection"
                favorLink="/dashboard/Beneficiary/Order/Add"
                portfolioLink="/onboarding/Open/Additional"
              />

            </div>
          </div>
      </DashboardLayout>
    </Page>
  );
});


function getMockChartData(): ChartData[] {

  const min: number = 355000.00;
  const max: number = 360000.00;
  const yearAgo: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

  var chartData: Array<ChartData> = [];


  for (var i = 0; i < 365; i++) {
    let tempDate: Date = new Date(yearAgo.setDate(yearAgo.getDate() + 1));
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let chartDataDay: ChartData = {
      id: i,
      timestamp: tempDate,
      yearBefore: tempDate.getFullYear() - 1,
      yearCurrent: tempDate.getFullYear(),
      month: tempDate.getMonth(),
      monthName: monthNames[tempDate.getMonth()],
      amount: Math.round((min + Math.random() * (max - min))),
      currency: "CHF"
    };

    chartData.push(chartDataDay);
  }

  return chartData;
}

function getMockSchemaData(UserChartDataMock: ChartData[]): ChartSchema {
  return {
    title: "yearBefore / yearCurrent",
    userID: "m.fleig123@qcentris.com",
    amountPositionsX: 12,
    depositeType: "Freizügigkeitsdepots",
    depositeName: "Protfolio 1",
    userData: UserChartDataMock,
    aspectRatio: 5.5
  };
}
export default DashboardMainPage;
