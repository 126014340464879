import Page from "../../Molecules/Page/Page";
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import SuccessPageLayout from '../../Organisms/Layout/SuccessPageLayout';

const DashboardAdjustRiskProtectionSuccessPage = () => {

  const renderFooter = () => {
    return <FooterNavBar
      linkToNext=''
      buttonNextText='Beenden'/>
  }

  return (
    <Page>
      <OnboardingLayout
        contentMaxWidth='1440px'
        footer={renderFooter()}
      >
        <SuccessPageLayout
          title={"Ihre Anpassungen sind vorgenommen. Bitte beachten Sie, dass es XX Tage dauert, bis die Anpassung in Kraft tritt."}
        />
      </OnboardingLayout>
    </Page>
  );
};

export default DashboardAdjustRiskProtectionSuccessPage;