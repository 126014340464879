import classNames from 'classnames';
import React from 'react';
import { Callback } from '../../../App/Models/Callback';
import styles from './InfoPanel.module.scss';
import detailsIcon from "../../../Assets/Images/PortfolioDetailsBtn.svg"

export type PanelProps = {
  className?: string
  onClick?: Callback<HTMLElement>
  children?: React.ReactNode
  icon?: string
  small?: boolean
}


const InfoPanel: React.FC<PanelProps> = (props) => {
  return (
    <div
      className={classNames(styles.main, props.className, props.small && styles.smallPanel)}
      onClick={e => props.onClick && props.onClick(e.currentTarget)}>
      <div className={styles.mainIcon}><img src={props.icon} alt="icon"/></div>
      {props.children}
      <div className={styles.detailsIcon}><img src={detailsIcon} alt="three dots icon"/></div>
    </div>
  );
};

export default InfoPanel;