import PreferentialTreatmentPageLayout from "../../Organisms/Layout/PreferentialTreatmentPageLayout";
import styles from "./DissolvePreferentialTreatmentPage.module.scss"
import Datepicker, {parseDate, formatDate} from "../../Atoms/Datepicker/Datepicker";
import {useState} from "react";
import {Button} from "../../Atoms/Button/Button";
import t from '../../../App/Libs/Translate/Translate'

const DashboardDissolvePreferentialTreatmentPage = () => {

  const [date, setDate] = useState<string>(formatDate(new Date()));
  const [requestText, setRequestText] = useState<string>("");

  const onChange = (date: string) => {
    setDate(date);
  }

  const onTextChange = (e) => {
    setRequestText(e.target.value);
  }

  const onHandleSubmit = (e) => {
    e.preventDefault();
    //send data to server
  }

  return (
    <PreferentialTreatmentPageLayout header={t("dashbord.products.cancel.favoritism")}
                                     supportCardDescription={t("dashboard.preferential.treatment.regulations.support")}
                                     title={t("dashboard.dissolve.preferential.treatment.title")}
    >
      <ol className={styles.list}>
        <li className={styles.listItem}>{t("dashboard.dissolve.preferential.treatment.list.step1")}</li>
        <li className={styles.listItem}>{t("dashboard.dissolve.preferential.treatment.list.step2")}</li>
        <li className={styles.listItem}>{t("dashboard.dissolve.preferential.treatment.list.step3")}</li>
      </ol>
      <div className={styles.datepickerContainer}>
        <p className={styles.description}>{t("dashboard.dissolve.preferential.treatment.cancel.benefit.from")}</p>
        <Datepicker onChange={(date: Date | null) => onChange(formatDate(date))}
                    selected={parseDate(date)}
                    className={styles.datepicker}
                    iconClassName={styles.datepickerIcon}/>
      </div>

      <form className={styles.textareaContainer} onChange={(e) =>onTextChange(e)} onSubmit={onHandleSubmit}>
        <p className={styles.textareaPlaceholder}>{t("account.assets.value.message")}</p>
        <textarea className={styles.textarea} value={requestText}/>
        <Button className={styles.textareaButton}>{t("dashboard.dissolve.preferential.treatment.submit.changes")}</Button>
      </form>
    </PreferentialTreatmentPageLayout>

  )
}

export default DashboardDissolvePreferentialTreatmentPage;