export const dataCurrently = [
    {
      id: '1',
      name: 'Steuerbescheinigung 2022',
      date: '31.12.2022',
      active: true,
    },
    {
      id: '2',
      name: 'Quartalsreport 4/4',
      date: '06.10.2022',
      active: true,
    },
  ];

  export const dataReporting = [
    {
      id: '3',
      name: 'Quartalsreport 3/4',
      date: '05.10.2022',
      active: false,
    },
    {
      id: '4',
      name: 'Quartalsreport 2/4',
      date: '28.09.2022',
      active: false,
    },
    {
      id: '5',
      name: 'Quartalsreport 1/4',
      date: '20.09.2022',
      active: false,
    },
  ];

export const dataContracts = [
    {
      id: '6',
      name: 'Bestätigung Strategie Anpassung',
      date: '08.08.2022',
      active: false,
    },
    {
      id: '7  ',
      name: 'Risikoschutz',
      date: '02.08.2022',
      active: false,
    },
  ];
