import { action, makeAutoObservable, observable } from "mobx";

export class Appstore {
     @observable isAppStarted = false

    constructor() {
        makeAutoObservable(this);
    }
    @action
    setIsAppStarted(value: boolean){
        this.isAppStarted = value
    }
}