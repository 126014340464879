import React from 'react';
import quotesImg from '../../../Assets/Images/quotes.svg';
import styles from './SuccessPageLayout.module.scss';


type SuccessPageProps = {
  title?: string | JSX.Element
  content?: string | JSX.Element
  linkTo?: string
  buttonText?: string,
  withoutBgColor?: boolean,
  ghostButton?: boolean
}

const SuccessPageLayout = (props: SuccessPageProps) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.quotes}><img src={quotesImg} alt=""/></div>
        <div className={styles.main}>
          <h3 className={styles.title}>{props.title}</h3>
          <div className={styles.content}>{props.content}</div>
        </div>
      </div>
    </>

  );
};

export default SuccessPageLayout;
