import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './Popup.module.scss';
import classnames from 'classnames';
import { Callback } from '../../../App/Models/Callback';

export type PopUpProps = {
    className?: string
    frameClassName?: string
    show: boolean
    onExit?: Callback<HTMLElement>
    children?: React.ReactNode
}

const Popup: React.FC<PopUpProps> = (props) => {
    return (
        <Modal show={props.show} dialogClassName={classnames(styles.contentStyle, props.className)} onExit={props.onExit}>
            {props.children}
        </Modal>
    );
};

export default Popup;
