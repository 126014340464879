import React from 'react';
import Page from "../../Molecules/Page/Page";
import DashboardLayout from '../../Organisms/Layout/DashboardLayout';
import ProfileSupport from '../../Organisms/ProfileSupport/ProfileSupport';
import DownloadButton from "../../Atoms/Button/DownloadButton";
import t from '../../../App/Libs/Translate/Translate'

import styles from './DashboardClosePortfolio.module.scss'
import Select from "../../Atoms/Select/Select";

const DashboardClose3aPortfolioPage = () => {

  const [isSelected, setIsSelected] = React.useState<boolean>(false);

  const itemList = [
    {
      id: "1",
      value: t("dashboard.close.portfolio.3a.list.selection1")
    },
    {
      id: "2",
      value: t("dashboard.close.portfolio.3a.list.selection2")
    },
    {
      id: "3",
      value: t("dashboard.close.portfolio.3a.list.selection3")
    },
    {
      id: "4",
      value: t("dashboard.close.portfolio.3a.list.selection4")
    },
    {
      id: "5",
      value: t("dashboard.close.portfolio.3a.list.selection5")
    }
  ]

  const onHandleSelect = (id) => {
    setIsSelected(true);
  }

  return (
    <Page>
      <DashboardLayout>
        <div className={styles.intro}>
          <div className={styles.title}>{t('dashboard.close.portfolio.3a.title')}
            <div className={styles.mt80}>
              <ProfileSupport
                className={styles.squredCorner}
                title={t('dashboard.beneficiary_regulations_depot.card_support.title')}
              />
            </div>
          </div>
          <div className={styles.descriptionWrapper}>
            <h3 className={styles.subtitle}>{t('dashboard.close.portfolio.3a.subtitle')}</h3>
            <p className={styles.description}>{t('dashboard.close.portfolio.3a.description')}</p>
            <Select itemsList={itemList} placeholder={"Saldierungsgrund"} onChange={onHandleSelect}/>
            {isSelected &&
              <DownloadButton className={styles.downloadButton}>{t('common.button.download.cancellation.document')}</DownloadButton>
            }

          </div>
        </div>
      </DashboardLayout>
    </Page>
  );
};

export default DashboardClose3aPortfolioPage;
