import classNames from 'classnames';
import React, {useState} from 'react';
import {Image} from 'react-bootstrap';
import closeImg from '../../../../Assets/Images/Close_menu.svg';
import Popup from '../../../Atoms/Popup/Popup';
import styles from './SideMenu.module.scss';
import {Callback} from "../../../../App/Models/Callback";
import Link from "../../../Atoms/Link/Link";
import LangSelect from "../../../Atoms/Select/LangSelect/LangSelect";
import t from '../../../../App/Libs/Translate/Translate'

type SideMenuProps = {
  className?: string
  frameClassName?: string
  show?: boolean
  onExit?: Callback<HTMLElement>
}

const SideMenu: React.FC<SideMenuProps> = (props) => {

  const [isOpened, setIsOpened] = useState(false);

  const handleToggleMenu = () => {
    setIsOpened(!isOpened)
  }

  return (
    <>
      <div className={styles.burgerButton} onClick={handleToggleMenu}/>
      <Popup className={styles.popup} {...props} show={isOpened}>
        <div className={classNames(styles.main, props.frameClassName)}>
          <div className={styles.actionBar}>
            <LangSelect/>
            <Image src={closeImg} onClick={handleToggleMenu}/>
          </div>

          <ul className={styles.navList}>
            <li className={styles.navItem}><Link>{t('menu.solution_freedom')}</Link></li>
            <li className={styles.navItem}><Link>{t('menu.solution_3a')}</Link></li>
            <li className={styles.navItem}><Link>FAQ</Link></li>
            <li className={styles.navItem}><Link>{t('menu.support_and_contact')}</Link></li>
            <li className={styles.navItem}><Link>{t('menu.about')}</Link></li>
          </ul>
        </div>
      </Popup>
    </>
  );
};

export default SideMenu;
