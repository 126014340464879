import { Switch } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { Environment } from '../App/Configs/Environment';
import OnboardingRoute from '../App/Libs/Route/OnboardingRoute';
import Account3aOptionsPage from '../Components/Pages/Account/3A/Account3aOptionsPage';
import AccountAssetsValuePage from '../Components/Pages/Account/AccountAssetsValuePage';
import AccountChooseStrategyPage from '../Components/Pages/Account/AccountChooseStrategyPage';
import AccountFinancialKnowledgeSurveyPage from '../Components/Pages/Account/AccountFinancialKnowledgeSurveyPage';
import AccountPortfolioSplitPage from '../Components/Pages/Account/AccountPortfolioSplitPage';
import AccountTransferFailFor3A from '../Components/Pages/Account/AccountTransferFailFor3A';
import AccountTransferScanFor3A from '../Components/Pages/Account/AccountTransferScanFor3A';
import AccountTransferSourcePage from '../Components/Pages/Account/AccountTransferSourcePage';
import AdditionalAccountPage from '../Components/Pages/Account/AdditionalAccountPage';
import ChooseProductAfterDeletionPage from '../Components/Pages/ChooseProduct/ChooseProductAfterDeletionPage';
import ChooseProductPage from '../Components/Pages/ChooseProduct/ChooseProductPage';
import AlreadyRegisteredPage from '../Components/Pages/Register/AlreadyRegisteredPage';
import Account3aOrNewPage from '../Components/Pages/Register/Contract/Account3aOrNewPage';
import CongratulationsPensionSolution from '../Components/Pages/Register/Contract/CongratulationsPensionSolution';
import ProductPage3a from '../Components/Pages/Register/Contract/ProductPage3a';
import SigningContract from '../Components/Pages/Register/Contract/SigningContract';
import SuccessStartedTransfer from '../Components/Pages/Register/Contract/SuccessStartedTransfer';
import SuccessStartedTransferFZ from '../Components/Pages/Register/Contract/SuccessStartedTransferFZ';
import TransferBenefits from '../Components/Pages/Register/Contract/TransferBenefits';
import TransferBenefits3a from '../Components/Pages/Register/Contract/TransferBenefits3a';
import TransferContract from '../Components/Pages/Register/Contract/TransferContract';
import RegisterPortfolioCreated from '../Components/Pages/Register/Portfolio/RegisterPortfolioCreated';
import RegisterPortfolioSecondCreated from '../Components/Pages/Register/Portfolio/RegisterPortfolioSecondCreated';
import RegisterPortfolioSuccess from '../Components/Pages/Register/Portfolio/RegisterPortfolioSuccess';
import RegisterThankForRequest from '../Components/Pages/Register/Portfolio/RegisterThankForRequest';
import RegisterConfirmCodePage from '../Components/Pages/Register/RegisterConfirmCodePage';
import RegisterSuccessPage from '../Components/Pages/Register/RegisterSuccessPage';
import RegisterInvestmentHorizonPage from '../Components/Pages/Register/RiskProfile/RegisterInvestmentHorizonPage';
import RegisterInvestmentTargetPage from '../Components/Pages/Register/RiskProfile/RegisterInvestmentTargetPage';
import RegisterRiskCapabilityPage from '../Components/Pages/Register/RiskProfile/RegisterRiskCapabilityPage';
import RegisterRiskProfilePage from '../Components/Pages/Register/RiskProfile/RegisterRiskProfilePage';
import RegisterRiskTypePage from '../Components/Pages/Register/RiskProfile/RegisterRiskTypePage';
import SsoSynchronizationPage from '../Components/Pages/SSO/SsoSynchronizationPage';


const OnboardingRoutes = () => {


    return (
        <Switch>
            <OnboardingRoute exact path="/onboarding/Confirm" render={p => <RegisterConfirmCodePage/>}/>
            <OnboardingRoute exact path="/onboarding/Success" render={p => <RegisterSuccessPage />}  />
            <OnboardingRoute exact path="/onboarding/AlreadyRegistered" render={p => <AlreadyRegisteredPage {...p}/>}/>

            <OnboardingRoute exact path="/onboarding/:proposalId/survey/horizon" render={p => <RegisterInvestmentHorizonPage {...p}/>}  />
            <OnboardingRoute exact path="/onboarding/:proposalId/Survey/Target" render={p => <RegisterInvestmentTargetPage {...p}/>}  />
            <OnboardingRoute exact path="/onboarding/:proposalId/Survey/Risk" render={p => <RegisterRiskTypePage {...p}/>}  />
            <OnboardingRoute exact path="/onboarding/:proposalId/Survey/Capability" render={p => <RegisterRiskCapabilityPage {...p}/>}  />

            <OnboardingRoute exact path="/onboarding/:proposalId/Risk/Profile"  render={p => <RegisterRiskProfilePage />} />
            <OnboardingRoute exact path="/onboarding/:proposalId/Strategy/Choose" render={p => <AccountChooseStrategyPage {...p}/>}/>

            <OnboardingRoute exact path="/onboarding/Contract/SigningContract" render={p => <SigningContract {...p}/>}/>

            <OnboardingRoute exact path="/onboarding/Product/Select" render={p => <ChooseProductPage {...p}/>} />
            <OnboardingRoute exact path="/onboarding/:proposalId/delete/success" render={p => <ChooseProductAfterDeletionPage {...p}/>}/>

            <OnboardingRoute exact path="/onboarding/Survey/Financial/Knowledge" render={p => <AccountFinancialKnowledgeSurveyPage {...p}/>} />
            <OnboardingRoute exact path="/onboarding/:proposalId/Assets/Value" render={p => <AccountAssetsValuePage {...p}/>} />
            <OnboardingRoute exact path="/onboarding/:proposalId/Portfolio/Split" render={p => <AccountPortfolioSplitPage {...p}/>} />



            <OnboardingRoute exact path="/onboarding/:proposalId/Portfolio/RegisterSuccess" render={p => <RegisterPortfolioSuccess />}/>
            <OnboardingRoute exact path="/onboarding/:proposalId/Portfolio/Created" render={p => <RegisterPortfolioCreated {...p}/>} />
            <OnboardingRoute exact path="/onboarding/Portfolio/SecondCreated" render={p => <RegisterPortfolioSecondCreated/>}  />
            <OnboardingRoute exact path="/onboarding/Portfolio/RegisterThankForRequest" render={p => <RegisterThankForRequest />} />

            <Route exact path="/onboarding/Product/Select" render={p => <ChooseProductPage {...p}/>} />

            <Route exact path="/onboarding/Contract/CongratulationsPensionSolution" component={CongratulationsPensionSolution} />
            <Route exact path="/onboarding/Contract/TransferContract" component={TransferContract} />
            <Route exact path="/onboarding/Contract/TransferBenefits" component={TransferBenefits} />
            <Route exact path="/onboarding/Contract/TransferBenefits3a" component={TransferBenefits3a} />
            <Route exact path="/onboarding/Contract/SuccessStartedTransfer" component={SuccessStartedTransfer} />
            <Route exact path="/onboarding/Contract/SuccessStartedTransferFZ" component={SuccessStartedTransferFZ} />
            <Route exact path="/onboarding/Contract/ProductPage3a" component={ProductPage3a} />


            <Route exact path="/onboarding/:Product/Start" render={() => <Redirect to="/onboarding/Welcome" />} />

            <Route exact path="/onboarding/Transfer/Source" component={AccountTransferSourcePage} />

            


            <Route exact path="/onboarding/3A/Options" component={Account3aOptionsPage} />
            <Route exact path="/onboarding/3A/Source" component={Account3aOrNewPage} />
            <Route exact path="/onboarding/3A/Transfer/Scan" component={AccountTransferScanFor3A} />
            <Route exact path="/onboarding/3A/Transfer/fail" component={AccountTransferFailFor3A} />
            <Route exact path="/onboarding/Open/Additional" component={AdditionalAccountPage} />

            <OnboardingRoute path="*" render={() => <Redirect to="/onboarding/Product/Select"/>}/>

        </Switch>
    );
};

export default OnboardingRoutes;
