import React from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ComponentDidMount } from '../../../../App/Libs/Helper/Helper';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../../Organisms/Layout/SplitLayout';
import SurveyAnswer from '../../../Organisms/Survey/SurveyAnswer';
import t from '../../../../App/Libs/Translate/Translate';
import styles from './RegisterInvestmentTargetPage.module.scss';
import { ProposalParams } from '../../../../App/Models/Params/ProposalParams';


const groupOrder = 1
const questionOrder = 2

const RegisterInvestmentTargetPage: React.FC<RouteComponentProps> = (props) => {

    const { Account } = useStore()

    const { proposalId } = useParams<ProposalParams>()

    ComponentDidMount(() => {
        var isOk = validateRequirements()

        if (!isOk)
            props.history.push(`/onboarding/${proposalId}/Investment/Horizon`)
    })

    function validateRequirements() {
        if (Account.questionnaire
            .groups[0]
            .steps[0]
            .questions
            .find(x => x.order === questionOrder - 1)
            .value?.ids?.length > 0)
            return true

        return false
    }

    function onClick(index: number) {


        var answer = Account.questionnaire
            .groups[groupOrder - 1]
            .steps[0]
            .questions[questionOrder - 1]
            .answers[index]

        Account.questionnaire.groups[groupOrder - 1].steps[0].questions[questionOrder - 1].value = {
            ids: [answer.id]
        }

        props.history.push(`/onboarding/${proposalId}/Survey/Risk`)
    }



    return (
        <Page>
            <OnboardingLayout
                contentMaxWidth='1340px'
                selectedStepIndex={2} progress={0.5}>
                <div className={styles.main}>
                    <SplitLayout className={styles.splitView}>
                        <div className={styles.titleSection}>
                            <h1>{t('register.survey.target.title')}</h1>
                        </div>
                        <div className={styles.answerSection}>
                            <SurveyAnswer
                                name='answer'
                                label={t('register.survey.target.capital_preservation')}
                                onClick={() => onClick(0)}
                            />
                            <SurveyAnswer
                                name='answer'
                                label={t('register.survey.target.capital_preservation_and_wealth_growth')}
                                onClick={() => onClick(1)}
                            />
                            <SurveyAnswer
                                name='answer'
                                label={t('register.survey.target.wealth_gain')}
                                onClick={() => onClick(2)}
                            />
                        </div>
                    </SplitLayout>
                </div>
            </OnboardingLayout>
        </Page>
    );
};

export default RegisterInvestmentTargetPage;
