import { useEffect, useState } from "react";
import { Action } from "../../Models/Callback";

export const ComponentDidMount = (action: Action) => {
    useEffect(() => {
        action()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export const ComponentWillUnmount = (action: Action) => {
    useEffect(() => {
        return () => {
            action()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
}


export const ForceUpdate = () =>{
    function useForceUpdate(){
        const [ , setValue] = useState(0); // integer state
        return () => setValue(value => value + 1); // update the state to force render
    }
    useForceUpdate()
}