import React from 'react';
import classnames from 'classnames';
import Link from '../../Atoms/Link/Link';
import addIcon from '../../../Assets/Images/add.svg';

import styles from './ProductCard.module.scss';

type ProductType = {
    title: string;
    to: string;
		className?: string;
}

const ProductCard = (props: ProductType) => {
    return (
        <Link to={props.to} className={classnames(styles.main, props.className)}>
            <span className={styles.title}>{props.title}</span>
            <div  className={styles.followLink}>
                <img src={addIcon} alt="follow" />
            </div>
        </Link>
    );
};

export default ProductCard;
