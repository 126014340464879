import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../../Organisms/Layout/SplitLayout';
import SurveyAnswerSimple from '../../../Organisms/Survey/SurveyAnswerSimple';
import styles from './Account3aOrNewPage.module.scss';

import t from '../../../../App/Libs/Translate/Translate';


type OptionType = {
  id: string,
  description: string,
  path: string,
}


const Account3aOrNewPage = (props: RouteComponentProps) => {

  const options = [
    {
      id: '1',
      description: t('account.3a.source.transfer'),
      path: '/onboarding/Contract/TransferBenefits3a',
    },
    {
      id: '2',
      description: t('account.3a.source.create_new'),
      path: '/onboarding/3A/Options',
    },
  ]

  const [selectedAnswerId, setSelectedAnswerId] = useState<string>('')

  function onAnswerClick(option: OptionType) {
    setSelectedAnswerId(option.id)
    props.history.push(option.path)
  }

  return (
    <Page>
      <OnboardingLayout contentMaxWidth='1600px'
          selectedStepIndex={1} progress={0.5}>
        <SplitLayout className={styles.splitView}>
          <div className={styles.titleContainer}>
            <h1>{t('account.3a.source.title')}</h1>
            <h3>{t('account.3a.source.subtitle')}</h3>
          </div>
          <div className={styles.selectors}>
            {options.map(option => (
              <SurveyAnswerSimple
                key={option.id}
                title={option.description}
                isChecked={option.id === selectedAnswerId}
                onClick={() => onAnswerClick(option)  }
              ></SurveyAnswerSimple>
            ))}
          </div>
        </SplitLayout>
      </OnboardingLayout>
    </Page>
  );
};

export default Account3aOrNewPage;
