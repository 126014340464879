import {  makeAutoObservable } from "mobx"
import { makePersistable } from 'mobx-persist-store'
import WebClient from "../../Libs/WebClient/WebClient";
import { LanguageDto } from "../../Models/Contracts/Common/LanguageDto";

export class CommonStore {
    Languages?: Array<LanguageDto>
    lang: string = 'de'

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: 'CommonStore', properties: ['Languages', 'lang'], storage: window.sessionStorage });
    }

    setLang(value: string) {
        this.lang = value
        WebClient.SetGlobalLanguage(value)
    }
}
