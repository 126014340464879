import React from 'react';
import { Callback } from '../../../App/Models/Callback';
import { Button } from '../../../Components/Atoms/Button/Button';
import Link from '../../../Components/Atoms/Link/Link';
import styles from './FooterNavbar.module.scss';

export enum NavbarButtonType{
    Arrow,
    Close
}


type FooterNavBarProps = {
    linkToBack?: string,
    linkToNext?: string,
    buttonNextText?: string,
    buttonBackText?: string,
    onNextClick?: Callback<HTMLElement>,
    buttonNextDisabled?: boolean,
    disabled?: boolean,
    buttonNextType?: NavbarButtonType,
    extraButton?: React.ReactNode,
}

const FooterNavbar: React.FC<FooterNavBarProps> = (props) => {

    function onNextClick(e: HTMLElement) {
        if (props.onNextClick)
            props.onNextClick(e)
    }

    function goBack() {
        // props.history.goBack()
    }

    function getNextButtonClassName(){
        switch(props.buttonNextType){
            case NavbarButtonType.Close:
                return styles.buttonClose
            default:
                return styles.buttonNext
        }
    }

    return (
        <div className={styles.footerNavBarDesktop}>
            {props.buttonBackText &&
                <Link to={props.linkToBack} className={styles.buttonBack}>
                    {props.buttonBackText}
                </Link>}
            {props.buttonNextText &&
                <div className={styles.buttonNextWrapper}>
                    { props.extraButton ??
                      props.extraButton
                    }
                    <Link
                        to={props.linkToNext}
                        disabled={props.buttonNextDisabled}
                        className={styles.linkNext}
                    >
                       {props.buttonNextText}
                    </Link>

                    <Button to={props.linkToNext}
                        disabled={props.buttonNextDisabled}
                        className={getNextButtonClassName()}
                        onClick={e => onNextClick(e.currentTarget)}
                    ></Button>
                </div>
            }
        </div>
    );
};

export default FooterNavbar;
