import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { CalculateAge } from '../../../../App/Libs/Helper/Calculations';
import { ComponentDidMount } from '../../../../App/Libs/Helper/Helper';
import { ComplianceActions } from '../../../../App/Mobx/Actions/ComplianceActions';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import { GenderEnum } from '../../../../App/Models/Enums/GenderEnum';
import ValidationErrorMessage from '../../../Atoms/ValidationErrorMessage/ValidationErrorMessage';
import FooterNavBar from '../../../Molecules/Navbar/FooterNavbar';
import Page from '../../../Molecules/Page/Page';
import ValuePanel from '../../../Molecules/Panel/ValuePanel';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../../Organisms/Layout/SplitLayout';
import MoreInfoLink from '../../../Organisms/Link/MoreInfoLink';
import InvestmentHorizonInfoPopup from '../../../Organisms/Popup/InvestmentHorizonInfoPopup';
import t from '../../../../App/Libs/Translate/Translate';
import styles from './RegisterInvestmentHorizonPage.module.scss';
import { ProposalParams } from '../../../../App/Models/Params/ProposalParams';

const groupOrder = 1
const questionOrder = 1

const RegisterInvestmentHorizonPage: React.FC<RouteComponentProps> = observer((props) => {

    const min = 1;
    const max = 64;

    const [investmentHorizonShow, setInvestmentHorizonShow] = useState<boolean>(false)
    const [investmentYears, setInvestmentYears] = useState<number>(0)
    const { Account, Questionnaire, Contact } = useStore()
    
  const {proposalId} = useParams<ProposalParams>()
  const currentPortoflio = Account.getCurrentVirtualPortfolio(proposalId)

    const age = CalculateAge(Contact.birthDate)

    ComponentDidMount(() => {
        ComplianceActions
            .GetQuestionnaire({
                productId: currentPortoflio.product.id
            })
    })

    function onInvestmentHorizonClick() {
        setInvestmentHorizonShow(true)
    }
    function onInvestmentHorizonExit() {
        setInvestmentHorizonShow(false)
    }

    function getAnswerIndex() {
        if (investmentYears < 4)
            return 0
        else if (investmentYears < 9)
            return 1
        else if (investmentYears < 16)
            return 2
        else
            return 3
    }

    function onNextClick() {

        if (!Account.questionnaire)
            Account.questionnaire = Questionnaire.questionnaire

        var answerIndex = getAnswerIndex()
        var answer = Account.questionnaire
            .groups[groupOrder - 1]
            .steps[0]
            .questions[questionOrder - 1]
            .answers[answerIndex]

        Account.questionnaire.groups[0].steps[0].questions[0].value = {
            ids: [answer.id]
        }
        currentPortoflio.investmentYears = investmentYears

        props.history.push(`/onboarding/${proposalId}/Survey/Target`)

    }


    function onValueChange(value: number) {
        setInvestmentYears(value)
    }

    function getDefaultInvestmentYears() {
        return getDefaultAge() - age
    }

    function getDefaultAge() {
        if (Contact.gender === GenderEnum.Male)
        return 65
    else
        return 64
    }

    function getMaxInvestmentYears() {
        return getMaxAge() - age
    }

    function getMaxAge() {
        if (Contact.gender === GenderEnum.Male)
            return 70
        else
            return 69
    }


    function renderFooter() {
        return <FooterNavBar
            onNextClick={() => onNextClick()}
            buttonBackText={t('common.back')}
            buttonNextText={t('register.survey.horizon.continue_to_risk_profile')}
            buttonNextDisabled={investmentYears === 0  || investmentYears > getMaxInvestmentYears()}></FooterNavBar>
    }


    return (
        <Page>
            <OnboardingLayout
                contentMaxWidth='1350px'
                footer={renderFooter()}
                selectedStepIndex={2} progress={0.5}>
                <div className={styles.main}>
                    <SplitLayout>
                        <div className={styles.titleSection}>
                            <h1>{t('register.survey.horizon.title')}</h1>
                            <h3>{t('register.survey.horizon.subtitle')}</h3>
                            <MoreInfoLink onClick={() => onInvestmentHorizonClick()}>{t('register.survey.horizon.more_info')}</MoreInfoLink>
                        </div>
                        <div className={styles.valueSection}>
                            <ValuePanel
                                className={styles.panel}
                                label={t('common.years')}
                                placeholder={getDefaultInvestmentYears()}
                                min={min}
                                max={max}
                                onChange={e => onValueChange(e)}
                            />
                            {investmentYears > getMaxInvestmentYears() && <ValidationErrorMessage className={styles.errorMessage} message={`Eine Eröffnung ist ab dem ${getMaxAge()} Geburtsjahr leider nicht erlaubt`} />}
                        </div>
                    </SplitLayout>

                    <div className={styles.content}>
                        <h3 className={styles.infoTitle}>
                            {t('register.survey.horizon.question_withdraw_before_or_after_retirement')}
                        </h3>
                        <p className={styles.p1}>{t('register.survey.horizon.advance_withdrawals_scenarios')}</p>
                        <ul>
                            <li>{t('register.survey.horizon.scenario_self_employment')}</li>
                            <li>{t('register.survey.horizon.scenario_acquisition_property')}</li>
                            <li>{t('register.survey.horizon.scenario_emigration')}</li>
                            <li>{t('register.survey.horizon.scenario_early_retirement')}</li>
                            <li>{t('register.survey.horizon.scenario_transfer_to_another_fund')}</li>
                        </ul>
                        <p className={styles.p2}>{t('register.survey.horizon.postponing_withdrawal')}</p>
                    </div>


                </div>
                <InvestmentHorizonInfoPopup show={investmentHorizonShow} onExit={() => onInvestmentHorizonExit()} />
            </OnboardingLayout>
        </Page>
    );
});

export default RegisterInvestmentHorizonPage;
