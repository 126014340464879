import classNames from 'classnames';
import React from 'react';
import { Image } from 'react-bootstrap';
import closeImg from '../../../Assets/Images/Close.svg';
import Popup, { PopUpProps } from '../../Atoms/Popup/Popup';
import styles from './InfoPopup.module.scss';

const InfoPopup: React.FC<PopUpProps> = (props) => {
    function onExit(obj: HTMLElement) {
        if (props.onExit)
            props.onExit(obj)
    }

    return (
        <Popup className={styles.popup} {...props} onExit={obj => onExit(obj)}>
            <div className={classNames(styles.main, props.frameClassName)}>
                <div className={styles.actionBar}><Image src={closeImg} onClick={e => props.onExit && props.onExit(e.currentTarget)} /></div>

                {props.children}
            </div>
        </Popup>
    );
};

export default InfoPopup;
