import React, { useRef, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import StrategyChangePopup from '../../Organisms/Popup/StrategyChangePopup';
import StrategyCarousel from '../../Organisms/Risk/DashboardStrategyCarousel';
import styles from './DashboardChooseStrategyPage.module.scss';
import { ComponentDidMount, ComponentWillUnmount } from '../../../App/Libs/Helper/Helper';
import { observer } from 'mobx-react';
import { ModelPortfolioDto } from '../../../App/Models/Contracts/Strategy/ModelPortfolioDto';
import t from '../../../App/Libs/Translate/Translate'
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { StrategyDto } from '../../../App/Models/Contracts/Strategy/StrategyDto';
import { ContactActions } from '../../../App/Mobx/Actions/ContactActions';
import WebSync from '../../Molecules/DataSync/WebSync';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';

type Params = {
  id?: string
}



const DashboardChooseStrategyPage: React.FC<RouteComponentProps> = observer((props) => {
  const [showWarning, setShowWarning] = useState<boolean>(false)
  const [selectedModelPortfolio, setSelectedModelPortfolio] = useState<ModelPortfolioDto>();
  const [strategy, setStrategy] = useState<StrategyDto>()

  const webRef = useRef<WebSync>()

  const { Dashboard } = useStore()
  const { id } = useParams<Params>()
  const Account = Dashboard.getPortfolioById(parseInt(id))
  if (!Account)
    props.history.replace('/')


  ComponentDidMount(() => {
    document.body.style.overscrollBehaviorX = 'none';
    // var model = strategy?.modelPortfolios?.find(x => x.riskCategory.id === strategy.calculatedRisk.id)
    // if (model)
    //   setSelectedModelPortfolio(model)
  })

  ComponentWillUnmount(() => {
    document.body.style.overscrollBehaviorX = 'unset';
  })

  function onStrategySelectClick() {
    var evaluatedExpectedReturn = strategy.calculatedRisk.expectedReturn
    var selectedExpectedReturn = selectedModelPortfolio.riskCategory.expectedReturn
    if (evaluatedExpectedReturn >= selectedExpectedReturn)
      onAcceptRiskClick()
    else
      setShowWarning(true)
  }

  function onWarningExit() {
    setShowWarning(false)
  }

  function onStrategySelect(value: ModelPortfolioDto) {
    setSelectedModelPortfolio(value)
  }

  function renderFooter() {
    return <FooterNavBar
      buttonBackText={t('common.back')}
      buttonNextText={t('account.strategy.choose.confirm_selection_and_continue')}
      onNextClick={() => onStrategySelectClick()} />
  }

  function onAcceptRiskClick() {
    webRef.current.Activity.show()
    PortfolioActions
      .PatchPortfolioProposal({
        proposalId: Account.portfolioProposalId,
        modelPortfolioId: selectedModelPortfolio.id
      })
      .onSuccess(() => {
        props.history.push("/Dashboard/OverviewDepots/ChangeStrategy/Success")
      })
      .onFail(() => webRef.current.Activity.hide())
    
  }


  const isStrategyRecommended = () => {
    return strategy?.calculatedRisk?.id === selectedModelPortfolio?.riskCategory?.id
  }

  const getRecommendedModelPortfolio = () => {
    return strategy?.modelPortfolios?.find(x => x.riskCategory.id === strategy.calculatedRisk.id)
  }

  const renderStrategyDescription = () => {
    if (isStrategyRecommended())
      return <h3>{t('account.strategy.choose.descriptions.recommended')} {getRecommendedModelPortfolio()?.name}</h3>;

    return <h3>{t('account.strategy.choose.descriptions.not_recommended')} {getRecommendedModelPortfolio().name}</h3>
  }

  function load() {
    return ContactActions
      .GetStrategy({
        productId: Account.product.id
      })
      .onSuccess(res => {
        setStrategy({
          ...res.data,
          calculatedRisk: Account.riskCategory
        })
      })
  }

  return (
    <Page>
      <OnboardingLayout
        classNameContent={styles.content}
        footer={renderFooter()}
        selectedStepIndex={3}
        progress={0.5}
        contentMaxWidth='100%'
        sync={() => load()}
        syncRef={webRef}
      >
          <div className={styles.main}>
            <div className={styles.textBlock}>
              <h1>{t('account.strategy.choose.title')}</h1>
              {renderStrategyDescription()}
            </div>
            {strategy &&
              <StrategyCarousel
                data={strategy}
                onChange={m => onStrategySelect(m)} />
            }

          </div>
        <StrategyChangePopup onAcceptClick={() => onAcceptRiskClick()} show={showWarning} onExit={() => onWarningExit()} />
      </OnboardingLayout>
    </Page>
  );
});

export default DashboardChooseStrategyPage;
