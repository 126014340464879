import React from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import t from '../../../App/Libs/Translate/Translate';
import AccountOptionsLayout from "../../Organisms/Layout/AccountOptionsLayout";

type Params = {
  id?: string
}

const MakeDepositPage = (props: RouteComponentProps) => {

  const data = [
    {
      title: t('account.3a.options.salary_ahv_title'),
      description: t('account.3a.options.salary_ahv_desc')
    },
    {
      title: t('account.3a.options.pension_fund_title'),
      description: t('account.3a.options.pension_fund_desc')
    }
  ]

  const { Account } = useStore()
  const { id } = useParams<Params>()

  function onSubmit(ahvValue, bvgValue) {

    if(ahvValue === '0' && bvgValue === '0')
      props.history.push('/Dashboard/OverviewDepots3a/MakeDeposit3a/Failed')
    else{
      Account.avhValue = ahvValue === "1"
      Account.bvgValue = bvgValue === "1"
      props.history.push(`/dashboard/depot/${id}/3a/pay`)
    }
  }

  return (
    <AccountOptionsLayout {...props}
                          onConfirm={onSubmit}
                          title={t('dashboard.deposit3a.make.title')}
                          subtitle={t('dashboard.deposit3a.make.subtitle')}
                          data={data}
                          headerBackButtonLink={"/Dashboard"}
    />
  );
};

export default MakeDepositPage;
