import { useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import commentImg from '../../../../Assets/Images/comment.svg';
import { Button } from '../../../Atoms/Button/Button';
import { GhostButton } from '../../../Atoms/Button/GhostButton';
import FooterNavBar from '../../../Molecules/Navbar/FooterNavbar';
import Page from '../../../Molecules/Page/Page';
import WarningPopup from '../../../Molecules/Popup/WarningPopup';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../../Organisms/Layout/SplitLayout';
import styles from './TransferContract.module.scss';
import { RouteComponentProps } from "react-router-dom";
import Formatter from '../../../../App/Libs/Formatter/Formatter';
import t from '../../../../App/Libs/Translate/Translate';
import { PortfolioActions } from '../../../../App/Mobx/Actions/PortfolioActions';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import { PortfolioPaymentInfoDto } from '../../../../App/Models/Contracts/Portfolio/PortfolioPaymentInfoDto';
import WebSync from '../../../Molecules/DataSync/WebSync';
import DocumentActions from '../../../../App/Mobx/Actions/DocumentActions';

const data = {
    emailVerified: false
}

const TransferContract = (props: RouteComponentProps) => {


    const [show, setShow] = useState<boolean>(false)
    const { Account } = useStore()

    const [paymentInfo, setPaymentInfo] = useState<PortfolioPaymentInfoDto>()

    function onDownloadPayment() {
        DocumentActions
            .GeneratePaymentInfo(Account.portfolios[0].id)
    }

    function onAlertHide() {
        setShow(false)
    }

    function renderFooter() {
        return <FooterNavBar
            linkToNext='/onboarding/Contract/ProductPage3a'
            buttonBackText={t('common.back')}
            buttonNextText={t('common.continue')} />
    }

    function loadPaymentInfo() {
        return PortfolioActions
            .GetPaymentInfo(Account.portfolios[0].id)
            .onSuccess(res => setPaymentInfo(res.data))
    }

    return (
        <Page>
            <OnboardingLayout
                footer={renderFooter()}
                contentMaxWidth='1240px'
                hideStepper={true}
                showBackgroundImage={true}>

                <SplitLayout className={styles.splitView}>
                    <div className={styles.titleSection}>
                        <h1 className={styles.title}>{t('register.contract.transfer_contract.title')}</h1>
                        <p className={styles.subtitle}>{t('register.contract.transfer_contract.subtitle')}</p>
                        <p>{t('register.contract.transfer_contract.extra_info')}</p>
                    </div>
                    <div className={styles.contentSection}>
                        <WebSync client={() => loadPaymentInfo()}>
                            {paymentInfo &&
                                <div className={styles.main}>
                                    <h5>{t('register.contract.transfer_contract.payment_instructions')}</h5>
                                    <div>{paymentInfo.beneficiary} </div>
                                    <div>{paymentInfo.beneficiaryAddress}</div>
                                    <div>{paymentInfo.beneficiaryPostalCode} {paymentInfo.beneficiaryCity}</div>
                                    <br />
                                    <div>IBAN: {Formatter.formatIban(paymentInfo.iban)}</div>
                                    <div>Bank: {paymentInfo.bankName}</div>
                                    <div>{t('register.contract.transfer_contract.purpose_of_use')}: {paymentInfo.referenceNumber}</div>

                                    <img className={styles.qrcode} src={`data:image/png;base64, ${paymentInfo.qrCode}`} alt="" />

                                    <GhostButton
                                        onClick={() => onDownloadPayment()}
                                        className={styles.ghostButton}>
                                        {t('register.contract.transfer_contract.send_instructions_via_email')}
                                    </GhostButton>
                                </div>
                            }
                        </WebSync>
                    </div>
                </SplitLayout>


                <WarningPopup show={show} onExit={() => onAlertHide()}>
                    <div className={styles.warningMain}>
                        <div>
                            <Image className={styles.warningImage} src={commentImg} alt="" />
                        </div>
                        <h4 className={styles.warningText}>{t('register.contract.transfer_contract.send_instructions_via_email_popup.title')}</h4>
                        <p>{t('register.contract.transfer_contract.send_instructions_via_email_popup.subtitle')}</p>
                        <Button
                            className={styles.warningButton}
                            to="/onboarding/Contract/ProductPage3a"
                            onClick={() => onAlertHide()}>ok</Button>
                    </div>
                </WarningPopup>

            </OnboardingLayout>
        </Page>
    );
};

export default TransferContract;
