import React from 'react';
import { NavLink, Redirect, Route, RouteProps } from 'react-router-dom';
import { useStore } from '../../Mobx/Infrastructure/mobx-hook';


const OnboardingRoute : React.FC<RouteProps> = (props) => {

    const { Account } = useStore()

    var routeProps = {
        ...props,
        condition: undefined
    }

    function getComponent(){
        if(!Account.isPortfoliosCreated())
            return props.component
        return <NavLink to="" replace />
    }

    return (
        <Route {...routeProps} render={p =>
            !Account.isPortfoliosCreated()?
            props.render(p) : <Redirect to="/onboarding/Contract/CongratulationsPensionSolution" />
        }/>
    );
};

export default OnboardingRoute;