import WebClient from "../../Libs/WebClient/WebClient";
import { GetQuestionnaireRequestDto } from "../../Models/Contracts/Compliance/GetQuestionnaireRequestDto";
import { GetPersonalInfoRequestDto } from "../../Models/Contracts/Contact/GetPersonalInformationDto";
import { GetStrategyRequestDto } from "../../Models/Contracts/Contact/GetStrategyRequestDto";
import { PostPersonalInfoRequestDto } from "../../Models/Contracts/Contact/PostPersonalInfoRequestDto";
import { ExperienceDto } from "../../Models/Contracts/OnBoarding/ExperienceDto";
import { FinancialInfoDTO } from "../../Models/Contracts/OnBoarding/FinancialInfoDTO";
import { QuestionnaireDto } from "../../Models/Contracts/Questionnaire/QuestionnaireDto";
import { ContactRiskDto } from "../../Models/Contracts/Strategy/ContactRiskDto";
import { StrategyDto } from "../../Models/Contracts/Strategy/StrategyDto";
import { GlobalStore } from "../Stores/MainStore";
import {SendWelcomeEmailDto} from "../../Models/Contracts/Contact/SendWelcomeEmailDto";
import { ContactProfilePictureDto } from "../../Models/Contracts/Contact/ContactProfilePictureDto";

export class ContactActions{

    static GetStrategy(model: GetStrategyRequestDto) {
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<StrategyDto>(`contact/strategy`, model)
            .onSuccess((response) => {
                GlobalStore.Contact.Strategy = response?.data
            })
    }


    static GetRisk(model: GetStrategyRequestDto) {
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<ContactRiskDto>(`contact/risk`, model)
    }

    static GetQuestionnaire(model: GetQuestionnaireRequestDto){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<any>('contact/questionnaire', model)
            .onSuccess(response =>{
                var questionnaire: QuestionnaireDto = response?.data?.questionnaire;
                GlobalStore.Questionnaire.questionnaire = questionnaire
            })
    }

    static GetPersonalInformation(){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<GetPersonalInfoRequestDto>('contact/info/personal')
    }

    static GetProfilePicture()
    {
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<ContactProfilePictureDto>('contact/profile/picture')
    }

    static PostProfilePicture(model: ContactProfilePictureDto)
    {
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Post<ContactProfilePictureDto>('contact/profile/picture', model)
    }

    static PostPersonalInformation(model: PostPersonalInfoRequestDto){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Post('contact/info/personal', model)
    }

    static GetFinancialInformation(){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<FinancialInfoDTO>("contact/info/financial")
    }


    static PostFinancialInformation(model: FinancialInfoDTO){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Post("contact/info/financial", model)
    }

    static PostExperience(model: ExperienceDto){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Post("contact/info/experience", model)
    }


    static RegisterSso(){
        return WebClient
            .Create()
            .Post('sso/register', {})
    }

    static GetClaims(){
        return WebClient
            .Create()
            .Get('sso/claims')
    }

    static GetCookies(){
        return WebClient
            .Create()
            .Get('sso/cookies')
    }

    static GetHeaders(){
        return WebClient
            .Create()
            .Get('sso/headers')
    }

    static SendEmailWelcome(model: SendWelcomeEmailDto){
        return WebClient
          .Create()
          .Authorize(GlobalStore.Auth.ToAuthorization())
          .Post("Onboarding/complete")
    }

    static SendEmailTransferDetail(model: SendWelcomeEmailDto){
        return WebClient
          .Create()
          .Authorize(GlobalStore.Auth.ToAuthorization())
          .Post("Onboarding/complete/transferdetails")
    }

    static SendEmailInstructions(model: SendWelcomeEmailDto){
        return WebClient
          .Create()
          .Authorize(GlobalStore.Auth.ToAuthorization())
          .Post("Onboarding/complete/riskprofle")
    }

}