import React from "react"
import { IconProps } from '../Menu'

const DiagramIcon = (props: IconProps) => {
  return (
    <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path fill={props.color ?? "white"} fillRule="evenodd" clipRule="evenodd" d="M13.9961 1.5V16.5469H14.8133V2.31606H17.2805V16.5469H18.0977V1.5H13.9961ZM11.2355 16.5469H12.0527V6.86283H7.95067V16.5469H8.76787V7.67889H11.2355V16.5469ZM5.1935 16.5469H6.01069V10.6941H1.90217V16.5469H2.71936V11.5101H5.1935V16.5469ZM20 18.5H0V17.6839H20V18.5ZM2.30537 6.03515C2.2011 6.03515 2.09649 5.99532 2.01674 5.916C1.85689 5.75671 1.85657 5.49818 2.01608 5.33888L5.0321 2.32141H3.25165C3.0261 2.32141 2.84305 2.13862 2.84305 1.91338C2.84305 1.68815 3.0261 1.50535 3.25165 1.50535H6.00461C6.23015 1.50535 6.41321 1.68815 6.41321 1.91338V4.64719C6.41321 4.87242 6.23015 5.05522 6.00461 5.05522C5.77906 5.05522 5.59601 4.87242 5.59601 4.64719V2.91255L2.59466 5.91535C2.5149 5.995 2.40997 6.03515 2.30537 6.03515Z" />
    </svg>
  );
};

export default DiagramIcon;
