import styles from './WelcomePage.module.scss';
import logoImg from "../../../Assets/Images/Logo.svg"
import {Button} from "../../Atoms/Button/Button";
import {GhostButton} from "../../Atoms/Button/GhostButton";
import Page from "../../Molecules/Page/Page";
import {ContactActions} from "../../../App/Mobx/Actions/ContactActions";
import {default as React, useEffect, useState} from "react";
import {GetPersonalInfoRequestDto} from "../../../App/Models/Contracts/Contact/GetPersonalInformationDto";
import WebSync from "../../Molecules/DataSync/WebSync";
import t from '../../../App/Libs/Translate/Translate';

const WelcomePage = () => {

  const [data, setData] = useState<GetPersonalInfoRequestDto>()

  const load = () => {
    return ContactActions
      .GetPersonalInformation()
      .onSuccess(res => setData(res.data))
  }

  useEffect(() => {
    load();
  }, [])

  return (
    <Page className={styles.page}>
      <WebSync client={load}>
        <div className={styles.main}>
          <div className={styles.header}>
            <div className={styles.container}>
              <img className={styles.logoImg} src={logoImg}/>
            </div>
          </div>
          <div>
            <h1 className={styles.title}>{t("dashboard.welcome.hi.title")} {data?.firstName} {data?.lastName}</h1>
            <p className={styles.description}>{t("dashboard.welcome.hi.subtitle")}</p>
          </div>
          <div>
            <h5 className={styles.subtitle}>{t("dashboard.welcome.in_view.title")}</h5>
            <p className={styles.description}>{t("dashboard.welcome.in_view.subtitle")}</p>
          </div>
          <Button to={"/Dashboard"} withArrow={true} className={styles.dashboardButton}>{t("dashboard.welcome.button.dashboard")}</Button>
          <div className={styles.section}>
            <h5 className={styles.subtitle}>{t("dashboard.welcome.all_clear.title")}</h5>
            <p className={styles.description}>{t("dashboard.welcome.all_clear.subtitle")}</p>
          </div>
          <GhostButton className={styles.helpcenterButton}>{t("dashboard.faq.to_helpcenter")}</GhostButton>
          <p className={styles.bestRegards}>
            {t("dashboard.welcome.best_regards")}
            <br/>
            {t("dashboard.welcome.relevate_team")}
          </p>

          <footer className={styles.footer}>
            <div className={styles.container}>
              © 2022 relevate by PensExpert   | {t("dashboard.menu.imprint")}   | {t("dashboard.menu.privacy")}
            </div>
          </footer>
        </div>
      </WebSync>
    </Page>
  );
}

export default WelcomePage;