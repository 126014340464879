import classNames from 'classnames';
import React from 'react';
import styles from './Radio.module.scss'

export type RadioProps ={
    className?: string
    name?: string
    value?: string
    defaultChecked?: boolean
    checked?: boolean
    onInput?: React.FormEventHandler<HTMLInputElement>
    onChange?: React.FormEventHandler<HTMLInputElement>
    children?: React.ReactNode
}

const Radio: React.FC<RadioProps> = React.forwardRef((props, ref: any) => {
    function onInput(e: React.FormEvent<HTMLInputElement>){
        if(props.onInput)
            props.onInput(e)
    }

    function onChange(e: React.FormEvent<HTMLInputElement>){
        if(props.onChange)
            props.onChange(e)
    }

    return (
        <label className={classNames(styles.label, props.className)}>
            <input
                type="radio"
                name={props.name}
                value={props.value}
                checked={props.checked}
                defaultChecked={props.defaultChecked}
                ref={ref}
                onInput={e => onInput(e)}
                onChange={e => onChange(e)}
            />
            <span>{props.children}</span>
        </label>
    );
});

export default Radio;
