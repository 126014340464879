import classNames from 'classnames';
import React, { useState } from 'react';
import { Callback } from '../../../App/Models/Callback';
import styles from './InputSearch.module.scss';

type InputSearchProps = {
    dataLists: Array<any>,
    className?: string,
    sendData?: any,
    onChange?: Callback<HTMLInputElement>
    onSelect?: Callback<string>
}

const InputSearch: React.FC<InputSearchProps> = (props) => {
    const [lists, setLists] = useState<Array<any>>([])
    const [inputSearchValue, setInputSearchValue] = useState<string>('')
    const [showList, setShowList] = useState<boolean>(false)
    const [ , setSelected] = useState<boolean>(false)

    const onCheckValue = (e: string) => {
        if(props.onSelect)
            props.onSelect(e)
        setInputSearchValue(e)
        setShowList(false)
        setSelected(true)

        if(props.sendData)
            props.sendData(true);
    }

    const getLists = async (e: any) => {


        if (e.target.value === '' || e.target.value === undefined || e.target.value === null) {
            setInputSearchValue('')
        }

        const keyword = e.target.value.toLowerCase();

        const filterData = props.dataLists.filter((e) => {
            if (e.toLowerCase().includes(keyword) ) {
                return e;
            }
            return undefined
        })

        

        setLists(filterData)
        setShowList(true)
        setInputSearchValue(e.target.value)
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>){
        getLists(e)

        if(props.onChange)
            props.onChange(e.currentTarget)
    }

    function onFocus(e: React.FocusEvent<HTMLInputElement>){
        if(e.target.value === '')
            getLists(e)
    }

    return (
        <div className={styles.inputSearchContainer}>
            <div className={styles.flex}>
                <input
                    id="transfer-source-search"
                    type="text"
                    value={inputSearchValue}
                    className={classNames(styles.inputSearch, props.className)}
                    onChange={e => onChange(e)}
                    autoComplete="off"
                    onFocus={e => onFocus(e)} />
                <input
                    className={styles.inputButton}
                    type="button" />
            </div>
            {(lists.length > 0 && showList) &&
                <ul className={styles.searchList}>
                    {lists.map((e) => {
                        return (
                            <li
                                className={styles.searchListLink}
                                key={e}
                                onClick={() => onCheckValue(e)}
                            >
                                {e}
                            </li>
                        );
                    })}
                </ul>
            }
        </div>
    )
};

export default InputSearch;
