import styles from './AddRiskProtectionPage.module.scss';
import Page from "../../Molecules/Page/Page";
import OnboardingLayout from "../../Organisms/Layout/OnboardingLayout";
import t from "../../../App/Libs/Translate/Translate";
import SurveyAnswerSimple from "../../Organisms/Survey/SurveyAnswerSimple";
import React from "react";
import {RouteComponentProps} from "react-router-dom";
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { ProductEnum } from '../../../App/Models/Enums/ProductEnum';

const AddRiskProtectionPage = (props: RouteComponentProps) => {

  const onHandleClick = (id: number) => {
    props.history.push("/Dashboard/DetermineInsuranceCoverage")
  }

  const { Dashboard } = useStore()

  function load() {
    return PortfolioActions
        .GetInvestmentOverview()
  }

  ComponentDidMount(() =>{
    load()
  })

  function getPortfolios(){
    return Dashboard.investmentOverview?.portfolios
        .filter(x => x.product.id === ProductEnum.Independent || x.product.id === ProductEnum.PensFree)
  }

  return (
    <Page>
      <OnboardingLayout
        contentMaxWidth='1450px'
        selectedStepIndex={1} progress={0.5}>
        <div className={styles.main}>
          <div className={styles.titleSection}>
            <h1 className={styles.title}>{t('dashboard.add_risk_protection.title')}</h1>
            <h3 className={styles.subtitle}>{t('dashboard.add_risk_protection.subtitle')}</h3>
          </div>
          <div className={styles.selectors}>
            {getPortfolios().map(portfolio => (
              <SurveyAnswerSimple
                key={portfolio.id}
                title={portfolio.name}
                description={portfolio.product.id === ProductEnum.PK? t('common.type_3a_depot') : t('common.type_vested_benefits')}
                onClick={() => onHandleClick(portfolio.id)}
                className={styles.surveyAnswerContainer}
                titleClassName={styles.surveyAnswerTitle}
              ></SurveyAnswerSimple>
            ))}
          </div>
        </div>
      </OnboardingLayout>
    </Page>
  );
}

export default AddRiskProtectionPage;