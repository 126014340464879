import Page from '../../../Molecules/Page/Page';
import DashboardContainer from "../../../Atoms/DashboardContainer/DashboardContainer";
import DashboardLayout from "../../../Organisms/Layout/DashboardLayout";
import styles from './FAQPage.module.scss';
import ProfileSupport from '../../../Organisms/ProfileSupport/ProfileSupport';
import t from '../../../../App/Libs/Translate/Translate';

const DashboardFAQPage = () => {

  return (
    <Page>
      <DashboardLayout>
        <DashboardContainer>
          <div className={styles.container}>
            <div className={styles.leftSideContainer}>
              <h1 className={styles.title}>{t("dashboard.faq.title")}</h1>
              <p className={styles.description}>{t("dashboard.faq.description")}</p>
            </div>
            <div className={styles.faqContainer}>
              <ProfileSupport title={t("dashbord.support_card")}/>
            </div>
          </div>
        </DashboardContainer>
      </DashboardLayout>
    </Page>
  );
};

export default DashboardFAQPage;