import styles from './RiskProtectionIndicators.module.scss'
import Formatter from "../../../App/Libs/Formatter/Formatter";
import PortfolioValueProgress from "../../Organisms/Portfolio/PortfolioValueProgress";

type RiskProtectionIndicatorsProps = {
  title: string,
  value: number,
  description: string,
  progress: number,
}

const RiskProtectionIndicators = (props: RiskProtectionIndicatorsProps) => {
  return (
    <div className={styles.panel}>
      <div className={styles.info}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.value}>{
          Formatter.formatNumber(props.value)} <span className={styles.currency}>CHF</span>
        </div>
        <div className={styles.description}>{props.description}</div>
      </div>
      <div className={styles.chart}>
        <PortfolioValueProgress index={1} count={2} progress={props.progress} cutoutRadiusChart={115}/>
      </div>
    </div>
  )
}

export default RiskProtectionIndicators;