import React, { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import commentImg from '../../../Assets/Images/comment.svg'
import { Image } from 'react-bootstrap';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { TransferSource } from '../../../App/Models/Enums/TransferSource';
import WarningPopup from '../../Molecules/Popup/WarningPopup';
import { Button } from '../../Atoms/Button/Button';
import { CalculateAge } from '../../../App/Libs/Helper/Calculations';
import WebSync from '../../Molecules/DataSync/WebSync';
import t from '../../../App/Libs/Translate/Translate'
import ChooseAnswerPageLayout from "../../Organisms/Layout/ChooseAnswerPageLayout/ChooseAnswerPageLayout";


const AccountTransferSourcePage = (props: RouteComponentProps) => {

    const data = [
        {
            title: t('account.transfer.source.funds_come_from'),
            subtitle: t('account.transfer.source.vested_benefits_foundation'),
            value: TransferSource.FZ
        },
        {
            title: t('account.transfer.source.funds_come_from'),
            subtitle: t('account.transfer.source.pension_fund'),
            value: TransferSource.PK
        }
    ]

    const [showPopup, setShowPopup] = useState<boolean>(false)
    const { Account, Contact } = useStore()

    const webRef = useRef<WebSync>()

    function onPopupExit() {
        setShowPopup(false)
    }

    function onPopupConfirm() {
        onSubmit(TransferSource.FZ)
        setShowPopup(false)
    }

    function onSelectorClick(source: TransferSource) {

        if (source === TransferSource.FZ) {
            var age = CalculateAge(Contact.birthDate)
            if (age > 57)
                setShowPopup(true)
            else
                onSubmit(source)
        }
        else
            onSubmit(source)
    }


    function onSubmit(source: TransferSource) {
        webRef.current.Activity.show()
        const vp = Account.virtualPortfolios[0]
        PortfolioActions
            .PatchPortfolioProposal({
                proposalId: vp.proposalId,
                transferSource: source
            })
            .onSuccess(response => {
                Account.transferSource = source
                props.history.push('/onboarding/Survey/Financial/Knowledge')
                webRef.current.Activity.hide()
            })
            .onFail(() => webRef.current.Activity.hide())
    }

    const renderPopup = () => {
        return(
          null
          // !!! We don't have styles for this popup yet. Is it popup needed? If not needed, remove this commented code and functions associated with it.

          // <WarningPopup show={showPopup} onExit={() => onPopupExit()}>
          //     <div className={styles.warningMain}>
          //         <div>
          //             <Image className={styles.warningImage} src={commentImg} alt="" />
          //         </div>
          //         <h4 className={styles.warningText}>{t('account.transfer.source.vested_benefits_foundation_popup.title')}</h4>
          //         <Button className={styles.warningButton} onClick={() => onPopupConfirm()}>{t('account.transfer.source.vested_benefits_foundation_popup.understood')}</Button>
          //     </div>
          // </WarningPopup>
        )
    }

    return (
        <Page>
            <WebSync ref={webRef}>
                <OnboardingLayout
                    contentMaxWidth='1600px'
                    selectedStepIndex={1} progress={0.5}>
                    <ChooseAnswerPageLayout title={t('account.transfer.source.title')}
                                            subtitle={t('account.transfer.source.description')}
                                            onSelectorClick={onSelectorClick}
                                            data={data}
                                            popup={renderPopup()}/>
                </OnboardingLayout>
            </WebSync>
        </Page>
    );
};

export default AccountTransferSourcePage;
