import React from 'react';
import FooterNavbar from '../../../Molecules/Navbar/FooterNavbar';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SuccessPageLayout from '../../../Organisms/Layout/SuccessPageLayout';
import styles from './RegisterPortfolioCreated.module.scss';
import t from '../../../../App/Libs/Translate/Translate'


const RegisterPortfolioSecondCreated = () => {

    function renderSuccessContent() {
        return (
            <>
                <div className={styles.paragraph}>
                    {t('register.portfolio.second_created.subtitle')}
                </div>
            </>
        )
    }

    function renderFooter() {
        return <FooterNavbar
            linkToNext='/onboarding/Contract/SigningContract'
            buttonBackText={t('common.back')}
            buttonNextText={t('register.portfolio.second_created.proceed_contract')}/>
    }

    return (
        <Page>
            <OnboardingLayout selectedStepIndex={3}
                footer={renderFooter()}>
                <div className={styles.main}>
                    <div className={styles.content}>
                        <SuccessPageLayout
                            title={t('register.portfolio.second_created.title')}
                            content={renderSuccessContent()}
                            buttonText={t('common.close_deal')}
                            linkTo='/onboarding/Contract/SigningContract'
                        />
                    </div>
                </div>
            </OnboardingLayout>
        </Page>
    );
};

export default RegisterPortfolioSecondCreated;
