import React from "react";
import ProductCard from "../../Molecules/ProductCard/ProductCard";
import styles from "./ProductCards.module.scss"
import t from '../../../App/Libs/Translate/Translate';

type ProductCardsType = {
  riskProtectionLink?: string,
  favorLink?: string,
  portfolioLink?: string
  className?: string
}

const ProductCards = (props: ProductCardsType) => {
  return (
    <div className={props.className}>
      <h4 className={styles.title}>{t("account.portfolio.depot.strategy.deposits.subtitle")}</h4>
      <div className={styles.productList}>
        {props.riskProtectionLink &&
          <ProductCard
            title={t("account.portfolio.depot.strategy.products.list.title2")}
            to={props.riskProtectionLink}
          />
        }
        {props.favorLink &&
          <ProductCard
            title={t("account.portfolio.depot.strategy.products.list.title3")}
            to={props.favorLink}
          />
        }
        {props.portfolioLink &&
          <ProductCard
            title={t("account.portfolio.depot.strategy.products.list.title")}
            to={props.portfolioLink}
          />
        }
      </div>
    </div>
  )
}

export default ProductCards;