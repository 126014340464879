import styles from "./StrategyDetailsPage.module.scss"
import Page from "../../Molecules/Page/Page";
import DashboardLayout from "../../Organisms/Layout/DashboardLayout";
import dartsLargeIcon from "../../../Assets/Images/Dashboard/darts-large.svg"
import supportHeartIcon from "../../../Assets/Images/Dashboard/support-heart.svg"
import shieldIcon from "../../../Assets/Images/Dashboard/shield.svg"
import React, { useState } from "react";
import { Button } from "../../Atoms/Button/Button";
import InfoPanel from "../../Atoms/Panel/InfoPanel";
import DepositCard from "../../Molecules/DepositCard/DepositCard";
import { RouteComponentProps, useParams } from "react-router-dom";
import ProductCards from "../../Organisms/DashboardsProductCards/ProductCards";
import checkmarkIcon from "../../../Assets/Images/Checkmark-little.svg";
import WarningPopup from '../../Molecules/Popup/WarningPopup';
import Input from '../../Atoms/Input/Input';
import { Image } from 'react-bootstrap';
import ok from "../../../Assets/Images/Dashboard/ok.svg"
import DashboardHeader from "../../Organisms/DashboardHeader/DashboardHeader";
import { useStore } from "../../../App/Mobx/Infrastructure/mobx-hook";
import { PortfolioActions } from "../../../App/Mobx/Actions/PortfolioActions";
import { ComponentDidMount } from "../../../App/Libs/Helper/Helper";
import StrategUtils from "../../../App/Libs/Helper/Strategy";
import { ModelPortfolioDto } from "../../../App/Models/Contracts/Strategy/ModelPortfolioDto";
import { ProductEnum } from "../../../App/Models/Enums/ProductEnum";
import PerformanceUtils from "../../../App/Libs/Helper/PerformanceUtils";
import t from '../../../App/Libs/Translate/Translate';
import DashboardProfitPanel from "../../Atoms/DashboardProfitPanel/DashboardProfitPanel";

type Params = {
  id?: string
}

const StrategyDetailsPage = (props: RouteComponentProps) => {
  const UserChartDataMock: Array<ChartData> = getMockChartData();
  const UserChartSchemaMock: ChartSchema = getMockSchemaData(UserChartDataMock);

  const [isStrategyChanged, setIsStrategyChanged] = useState<boolean | 0>(props.location.search === "?success=true" ? true : 0);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);


  const [modelPortfolio, setModelPortfolio] = useState<ModelPortfolioDto>()

  const { Dashboard } = useStore()
  const { id } = useParams<Params>()
  const Account = Dashboard.getPortfolioById(parseInt(id))
  if (!Account)
    props.history.replace('/')


  const showStrategyChangeFeedback = () => {
    if (isStrategyChanged === 0) return null;

    if (isStrategyChanged) {
      return (
        <div className={styles.strategyChangeSuccess}>
          <span>{t("dashboard.strategy.details.change.success")}</span>
          <img src={checkmarkIcon} alt="checkmark icon" />
        </div>
      )
    } else return (
      <div className={styles.strategyChangeFailed}>
        <span>{t("dashboard.strategy.details.change.fail")}</span>
        <img src={checkmarkIcon} alt="checkmark icon" />
      </div>
    )
  }

  const onHandleClick = () => {
    props.history.push(`/dashboard/depot/${id}/strategy`)
  }

  const onAccountClick = (id: number) => {
    props.history.replace(`/dashboard/depot/${id}/overview`)
    window.location.reload()

  }

  const editPortfolioName = () => {
    setShow(true)
  }

  const deletePortfolio = () => {
    props.history.push("/Dashboard/CloseVestedBenefitsDeposit")
  }

  const onAlertClose = () => {
    setShow(false);
    setShowSuccess(false);
  }

  const onAlertHide = () => {
    setShowSuccess(true)
  }

  function load() {
    PortfolioActions
      .GetProposalModelPortfolio(Account.portfolioProposalId)
      .onSuccess(x => setModelPortfolio(x.data))
  }

  ComponentDidMount(() => {
    load()
  })

  function onBeneficiaryOrderClick(){
    props.history.push(`/dashboard/depot/${id}/beneficiary/order`)
  }

  return (
    <Page>
      <DashboardLayout>
        <div>
          {showStrategyChangeFeedback()}
          <DashboardHeader
            title={t("account.portfolio.depot.title")}
            subtitle={Account.name}
            onStrategyDetailsClick={onHandleClick}
            onEdit={editPortfolioName}
            onDelete={deletePortfolio}
          >
            <DashboardProfitPanel stockAmount={Account.investedAmount} profitNumber={PerformanceUtils.CalculatePerformanceValue(Account.currentValue, Account.currentPerformance)} percentage={(Account.currentPerformance)}/>
          </DashboardHeader>
          <div className={styles.chartContainer}>
              <DepositCard key={Account.id} info={{
                id: Account.id,
                title: Account.name,
                currency: "CHF",
                amount: Account.investedAmount,
                diff: PerformanceUtils.CalculatePerformanceValue(Account.currentValue, Account.currentPerformance),
                diffPercentage: Account.currentPerformance,
                type: t("account.portfolio.depot.title"),
                chart: UserChartSchemaMock
              }} />
          </div>

          <div className={styles.infoContainer}>
            <div className={styles.infoBoard}>
              <InfoPanel icon={dartsLargeIcon} className={styles.strategyInfo} onClick={onHandleClick}>
                <p className={styles.panelHeader}>{t("dashboard.strategy.details.info.title")} {modelPortfolio?.name}</p>
                <p className={styles.panelTitle}>{t("dashboard.strategy.details.info.percentage").replace("[PERCENT]", StrategUtils.getRisk(modelPortfolio?.positions))}</p>
                <p className={styles.panelDescription}>{t("dashboard.strategy.details.info.description")}</p>
              </InfoPanel>
              <div className={styles.infoBoardRightSide}>
                <InfoPanel icon={shieldIcon} small={true} className={styles.riskProtection}>{t("account.assets.value.advantage_risk_protection_title")}</InfoPanel>
                <InfoPanel icon={supportHeartIcon} small={true}
                  className={styles.riskBenefits} onClick={() => onBeneficiaryOrderClick()}>{t("dashboard.strategy.details.info.favors")}</InfoPanel>
              </div>
            </div>

            <div className={styles.depositsContainer}>
              <h4 className={styles.depositsTitle}>{t("dashboard.strategy.details.other.benefits.accounts")}</h4>
              <div className={styles.deposits}>

                {Dashboard.investmentOverview?.portfolios
                  .filter(x => x.id !== Account.id && (x.product.id === ProductEnum.Independent || x.product.id === ProductEnum.PensFree))
                  .map(deposit => (
                    <DepositCard key={deposit.id} info={{
                      id: deposit.id,
                      title: deposit.name,
                      currency: "CHF",
                      amount: deposit.investedAmount,
                      diff: PerformanceUtils.CalculatePerformanceValue(deposit.currentValue, deposit.currentPerformance), //TODO needs to be validated
                      diffPercentage: deposit.currentPerformance,
                      type: t("account.portfolio.depot.title"),
                      chart: UserChartSchemaMock
                    }} onClick={() => onAccountClick(deposit.id)} />
                  ))}
              </div>
            </div>
            <ProductCards portfolioLink="/onboarding/Open/Additional" />
          </div>
        </div>

        <WarningPopup show={show} onExit={() => onAlertClose()}>
          <div className={styles.warningMain}>

            {showSuccess ?
              <div className={styles.showSuccessContainer}>
                <Image src={ok} alt="" />
                <h3>{t("dashboard.strategy.details.change.name.success")}</h3>
              </div> :
              <>
                <Input
                  placeholder={t("register.contract.signing_contract.vested_benefits_account_risk")}
                  type="text"
                />
                <p>{t("common.max_password_length")}</p>
                <Button
                  className={styles.warningButton}
                  to=""
                  onClick={() => onAlertHide()}>{t("common.button.save")}</Button>
              </>
            }

          </div>
        </WarningPopup>

      </DashboardLayout>
    </Page>
  )
}

type ChartSchema = {
  title: string;
  userID: string;
  amountPositionsX: number;
  depositeType: string;
  depositeName: string;
  userData: Array<ChartData>;
  aspectRatio: number;
}

type ChartData = {
  id: number;
  timestamp: Date;
  yearBefore: number;
  yearCurrent: number;
  month: number;
  monthName: string;
  currency: string;
  amount: number;
}


function getMockChartData(): ChartData[] {

  const min: number = 355000.00;
  const max: number = 360000.00;
  const yearAgo: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

  var chartData: Array<ChartData> = [];


  for (var i = 0; i < 365; i++) {
    let tempDate: Date = new Date(yearAgo.setDate(yearAgo.getDate() + 1));
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let chartDataDay: ChartData = {
      id: i,
      timestamp: tempDate,
      yearBefore: tempDate.getFullYear() - 1,
      yearCurrent: tempDate.getFullYear(),
      month: tempDate.getMonth(),
      monthName: monthNames[tempDate.getMonth()],
      amount: Math.round((min + Math.random() * (max - min))),
      currency: "CHF"
    };

    chartData.push(chartDataDay);
  }

  return chartData;
}

function getMockSchemaData(UserChartDataMock: ChartData[]): ChartSchema {
  return {
    title: "yearBefore / yearCurrent",
    userID: "m.fleig123@qcentris.com",
    amountPositionsX: 12,
    depositeType: "Freizügigkeitsdepots",
    depositeName: "Protfolio 1",
    userData: UserChartDataMock,
    aspectRatio: 12
  };
}


export default StrategyDetailsPage;