import React from 'react';

import styles from './Textarea.module.scss'

type Props = {
  children?: React.ReactNode
}

const Textarea: React.FC<Props> = (props) => {
  return (
    <textarea className={styles.main} {...props}>{props.children}</textarea>
  );
};

export default Textarea;
