import classnames from 'classnames';
import React from 'react';
import { Callback } from '../../../App/Models/Callback';
import Link from '../../Atoms/Link/Link';
import Selector from '../../Molecules/Selector/Selector';
import styles from './SurveyAnswerSimple.module.scss';


type Props = {
    title?: string
    description?: string,
    isChecked?: boolean,
    onClick?: Callback<HTMLElement>
    className?: string
    titleClassName?: string
}


const SurveyAnswerSimple = (props: Props) => {
    return (
        <Link className={classnames(styles.link, props.className)} onClick={a => props.onClick && props.onClick(a)}>
            <Selector>
                <div className={classnames(styles.surveyAnswer, props.isChecked ? styles.isChecked : null)}>
                    {props.title && (
                        <h3 className={classnames(styles.title, props.titleClassName)}>{props.title}</h3>
                    )}
                    <span className={styles.description}>{props.description}</span>
                </div>
            </Selector>
        </Link>
    );
};

export default SurveyAnswerSimple;
