import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import styles from './GhostButton.module.scss';


type Props = {
    className?: string,
    to?: string,
    disabled?: boolean,
    onClick?: MouseEventHandler<HTMLElement>,
    children?: React.ReactNode
}

export const GhostButton: React.FC<Props> = (props) => {
    if (props.to)
        return <Link {...props} to={props.to!} className={classNames(styles.main, props.className, props.disabled && styles.disabled)} onClick={props.onClick}>{props.children}</Link>
    else
        return <button disabled={props.disabled} {...props} className={classNames(styles.main, props.className)} onClick={props.onClick}>{props.children}</button>
};