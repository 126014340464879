import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import t from '../../../../App/Libs/Translate/Translate';
import CommonActions from '../../../../App/Mobx/Actions/CommonActions';
import { ContactActions } from '../../../../App/Mobx/Actions/ContactActions';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import { ContactRiskDto } from '../../../../App/Models/Contracts/Strategy/ContactRiskDto';
import { ProposalParams } from '../../../../App/Models/Params/ProposalParams';
import WebSync from '../../../Molecules/DataSync/WebSync';
import FooterNavBar from '../../../Molecules/Navbar/FooterNavbar';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import styles from './RegisterRiskProfilePage.module.scss';

const RegisterRiskProfilePage: React.FC = observer(() => {

    const riskTypes = [
        {
            name: t('register.risk.profile.riskType.safety.name'),
            description: t('register.risk.profile.riskType.safety.description'),
        },
        {
            name: t('register.risk.profile.riskType.calm.name'),
            description: t('register.risk.profile.riskType.calm.description'),
        },
        {
            name: t('register.risk.profile.riskType.balance.name'),
            description: t('register.risk.profile.riskType.balance.description'),
        },
        {
            name: t('register.risk.profile.riskType.economic.name'),
            description: t('register.risk.profile.riskType.economic.description'),
        },
        {
            name: t('register.risk.profile.riskType.adrenalin.name'),
            description: t('register.risk.profile.riskType.adrenalin.description'),
        },
    ]

    const { Account } = useStore()
    const { proposalId } = useParams<ProposalParams>()
    const virtualPortfolio = Account.virtualPortfolios.find(x => x.proposalId === parseInt(proposalId))

    const [risk, setRisk] = useState<ContactRiskDto>()

    function renderFooter() {
        return <FooterNavBar
            linkToNext={`/onboarding/${proposalId}/Strategy/Choose`}
            buttonBackText={t('common.back')}
            buttonNextText={t('register.risk.profile.choose_strategy')} />
    }

    function loadProfile(){

        return CommonActions
            .GetRiskCategory(virtualPortfolio.evaluatedRiskId)
            .onSuccess(response => setRisk(response.data))
    }

    var riskDescription = riskTypes.find(x => x.name === risk?.name.toLowerCase())?.description

    return (
        <Page>
            <WebSync client={() => loadProfile()}>
                <OnboardingLayout
                    footer={renderFooter()}
                    selectedStepIndex={2} progress={0.5} contentMaxWidth='1600px'>
                    <div className={styles.main}>
                        <h1 className={styles.title}>{t('register.risk.profile.title')} «<span className={styles.riskType}>{risk?.name}</span>»</h1>
                        <section className={styles.content}>
                            <h3>{risk?.name}</h3>
                            <p className={styles.description}>{riskDescription}</p>
                        </section>
                    </div>
                </OnboardingLayout>
            </WebSync>
        </Page>
    );
});


export default RegisterRiskProfilePage;
