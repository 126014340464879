import React from 'react';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SuccessPageLayout from '../../../Organisms/Layout/SuccessPageLayout';
import styles from './RegisterPortfolioSuccess.module.scss'
import FooterNavBar from "../../../Molecules/Navbar/FooterNavbar";
import t from '../../../../App/Libs/Translate/Translate'

const RegisterPortfolioSuccess = () => {

    function renderFooter() {
        return <FooterNavBar linkToNext="/onboarding/Contract/SigningContract"
                             buttonBackText={t('common.back')}
                             buttonNextText={t('common.close_deal')}/>
    }

    return (
        <Page>
            <OnboardingLayout footer={renderFooter()}
                              selectedStepIndex={3}
                              progress={0.5}
                              contentMaxWidth='1600px'
            >
                <div className={styles.main}>
                    <div className={styles.content}>
                        <SuccessPageLayout
                            title={t('register.portfolio.register_success.title')}
                        />
                    </div>
                </div>
            </OnboardingLayout>
        </Page>
    );
};

export default RegisterPortfolioSuccess;
