import React from 'react';
import { Callback } from '../../../App/Models/Callback';
import Selector from '../../Molecules/Selector/Selector';
import styles from './SurveyAnswer.module.scss';


type Props = {
    label: string
    name: string
    onClick?: Callback<HTMLElement>
}


const SurveyAnswer = (props: Props) => {
    return (
        <Selector onClick={e => props.onClick && props.onClick(e)}>
            <div className={styles.main}>
                <span>{props.label}</span>
            </div>
        </Selector>
    );
};

export default SurveyAnswer;