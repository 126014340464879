import React, { useEffect } from "react";
import styles from './LineChart.module.scss';

import { Line } from 'react-chartjs-2';
import { CategoryScale, ChartData, ChartOptions, ChartTypeRegistry } from "chart.js";
import Chart  from 'chart.js/auto';
import classNames from "classnames";

type Props =  {
    id?: string
    data: ChartData<"line", number[], string>
    options: any
}

const LineChart = (props: Props) => {

    useEffect(() => Chart.register(CategoryScale), []);

    return ( 
        <div className={classNames(styles.lineChartWrapper)}>
            <Line key={props.id} id={props.id} data={props.data} options={props.options}/>
        </div>
    )
}

export default LineChart;