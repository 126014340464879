import WebClient from "../../Libs/WebClient/WebClient";
import { CreatePortfolioDto } from "../../Models/Contracts/Portfolio/CreatePortfolioDto";
import { CreatePortfolioFromProposalDto } from "../../Models/Contracts/Portfolio/CreatePortfolioFromProposalDto";
import { CreatePortfolioProposalDto } from "../../Models/Contracts/Portfolio/CreatePortfolioProposalDto";
import { CreatePortfolioProposalResultDto } from "../../Models/Contracts/Portfolio/CreatePortfolioProposalResultDto";
import { CreatePortfolioResultDto } from "../../Models/Contracts/Portfolio/CreatePortfolioResultDto";
import { GetPortfolioProposalDto } from "../../Models/Contracts/Portfolio/GetPortfolioProposalDto";
import { GetPortfolioProposalResultDto } from "../../Models/Contracts/Portfolio/GetPortfolioProposalResultDto";
import { ModifyPortfolioProposalDto } from "../../Models/Contracts/Portfolio/ModifyPortfolioProposalDto";
import { PortfolioDepositResultDto } from "../../Models/Contracts/Portfolio/PortfolioDepositResultDto";
import { PortfolioDto } from "../../Models/Contracts/Portfolio/PortfolioDto";
import { PortfolioInvestmentOverviewResultDto } from "../../Models/Contracts/Portfolio/PortfolioInvestmentOverviewResultDto";
import { PortfolioPaymentInfoDto } from "../../Models/Contracts/Portfolio/PortfolioPaymentInfoDto";
import { PortfolioPerformanceDto } from "../../Models/Contracts/Portfolio/PortfolioPerformanceDto";
import { FilterModelPortfolioDto } from "../../Models/Contracts/Strategy/FilterModelPortfolioDto";
import { ModelPortfolioDto } from "../../Models/Contracts/Strategy/ModelPortfolioDto";
import { GlobalStore } from "../Stores/MainStore";

export class PortfolioActions{

    static GetPortfolioProposal(model: GetPortfolioProposalDto){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<GetPortfolioProposalResultDto>(`portfolio/proposal/${model.proposalId}`)
    }

    static GetProposalModelPortfolio(proposalId: number)
    {
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<ModelPortfolioDto>(`Portfolio/proposal/${proposalId}/modelportfolio`)
    }

    static PostPortfolioProposal(model: CreatePortfolioProposalDto){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Post<CreatePortfolioProposalResultDto>(`portfolio/proposal`, model)
    }

    static PatchPortfolioProposal(model: ModifyPortfolioProposalDto){
        var id = model.proposalId
        model.proposalId = undefined
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Patch(`portfolio/proposal/${id}`, model)
    }

    static PostPortfolio(model: CreatePortfolioDto){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Post<PortfolioDto>('portfolio', model)
    } 
    
    static GetModelPortfolio(modelPortfolioId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<ModelPortfolioDto>(`Portfolio/model-portfolios/${modelPortfolioId}`)
    }   

    static GetFilteredModelPortfolios(model: FilterModelPortfolioDto){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Post<Array<ModelPortfolioDto>>(`portfolio/model-portfolios`, model)
    }   


    static GetPortfolioDeposits(portfolioId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<PortfolioDepositResultDto>(`Portfolio/${portfolioId}/deposits`)
    }


    static GetPortfolio3aYearlyDeposits(portfolioId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<PortfolioDepositResultDto>(`Portfolio/${portfolioId}/3a/yearly/deposits`)
    }


    static GetInvestmentOverview(){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<PortfolioInvestmentOverviewResultDto>('portfolio/investment-overview')
            .onSuccess(response => GlobalStore.Dashboard.investmentOverview = response.data)
    }

    static GetPerformance(portfolioId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<PortfolioPerformanceDto>(`portfolio/${portfolioId}/performance`)
    }

    static GetPaymentInfo(portfolioId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get<PortfolioPaymentInfoDto>(`portfolio/${portfolioId}/payment`)
    }
}
