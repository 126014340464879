import classNames from 'classnames';
import React from 'react';
import { Callback } from '../../../App/Models/Callback';
import styles from './Panel.module.scss';


export type PanelProps = {
    className?: string
    highlight?: boolean
    onClick?: Callback<HTMLElement>
    children?: React.ReactNode
}


const Panel: React.FC<PanelProps> = (props) => {
    return (
        <div
            className={classNames(styles.main, props.className, props.highlight ? styles.selected : null)}
            onClick={e => props.onClick && props.onClick(e.currentTarget)}>
            {props.children}
        </div>
    );
};

export default Panel;