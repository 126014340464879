import React from 'react';
import styles from './Question.module.scss';

interface Props {
    isLoading?: boolean,
    className?: string
    children?: React.ReactNode
    questionNumber?: string
    question?: string
}

const Question: React.FC<Props> = (props) => {
    return (
        <div className={styles.flex}>
            <div className={styles.number}>{props.questionNumber}</div>
            <div className={styles.question}>{props.question}</div>
            <div className={styles.salutationWrapper}>
                {props.children}
            </div>
        </div>  
    );
};

export default Question;

