import { RouteComponentProps } from "react-router-dom";
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import TransferScanLayout from "../../Organisms/Layout/TransferScanLayout";
import styles from "../../Organisms/Layout/TransferScanLayout.module.scss";
import t from "../../../App/Libs/Translate/Translate";

const data = {
    emailVerified: true
}

const AccountTransferScanFor3A = (props: RouteComponentProps) => {

    const { Account } = useStore()



    function getAmount() {
        if (Account.bvgValue === false)
            return 'CHF 34’416.–'
        return 'CHF 6’883.–'
    }


    return (
        <TransferScanLayout
            {...props}
            amount={getAmount()}
            portfolioId={Account.portfolios[0].id}
            emailVerified={data.emailVerified}
            linkToNext="/onboarding/Open/Additional"
        >
            <p className={styles.subtitle}>{t('account.3a.transfer.amount_subtitle')}</p>
            <p className={styles.amountText}>{getAmount()}</p>
            <p
              className={styles.amountInfo}>{t('account.3a.transfer.max_amount_info').replace('[AMOUNT]', getAmount())}</p>
            <hr/>
            <p className={styles.subtitle}>{t('account.3a.transfer.if_transfer_success')}</p>
        </TransferScanLayout>
    );
};

export default AccountTransferScanFor3A;