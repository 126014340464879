import React, { useEffect, useState } from 'react';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import WebClient from '../../../App/Libs/WebClient/WebClient';
import { IWebClientPromise } from '../../../App/Libs/WebClient/WebClientPromise';
import { Callback, FuncEmpty } from '../../../App/Models/Callback';
import Loader from '../../Atoms/Loader/Loader';

type Props = {
    client?:  FuncEmpty<IWebClientPromise<any>>
    children?: any
}

type State = {
    isLoading: number
}

class WebSync extends  React.Component<Props, State> {

    state: State ={
        isLoading: 1
    }

    componentDidMount(): void {
        if (this.props.client)
            this.props.client()
                .onSuccess(() => this.setState({
                    isLoading: 0
                }))
                .onFail(() => this.setState({
                    isLoading: 0
                }))
        else
            this.setState(() => this.setState({
                isLoading: 0
            }))

    }


    Activity = {
        show: () =>{
            this.setState({
                isLoading: this.state.isLoading + 1
            })
        },
        hide: () =>{
            this.setState({
                isLoading: this.state.isLoading - 1 > 0? this.state.isLoading - 1 : 0
            })
        },
        foreHide: () =>{
            this.setState({
                isLoading: 0
            })
        }
    }

    render(){
        return (
            <Loader visible={this.state.isLoading > 0}>
                {this.props.children}
            </Loader>
        );
    }

    
};

export default WebSync;