import React from 'react';
import Page from "../../Molecules/Page/Page";
import ProductCard from '../../Molecules/ProductCard/ProductCard';
import DashboardLayout from '../../Organisms/Layout/DashboardLayout';
import ProfileSupport from '../../Organisms/ProfileSupport/ProfileSupport';

import styles from './RiskProtectionWithContractPage.module.scss'
import DownloadButton from "../../Atoms/Button/DownloadButton";

const RiskProtectionWithContractPage = () => {
  return (
    <Page>
      <DashboardLayout>
          <div className={styles.intro}>
              <h1 className={styles.title}>Risikoschutz</h1>
              <div className={styles.descriptionWrapper}>
                  <h3 className={styles.subtitle}>Sie sind im Besitz einer Risikoversicherung</h3>
                  <p className={styles.description}>Die Risikoversicherung schützt sie und Ihre Angehörige vor finanziellen Folgen bei Erwerbsunfähigkeit infolge Krankheit oder Unfalls, bei Pflegebedürftigkeit oder bei Tod  – abgestimmt auf Ihre individuelle Lebenslage.</p>
                  <DownloadButton>Vertrag herunterladen</DownloadButton>
              </div>
          </div>
          <div className={styles.actionsList}>
              <ProductCard
                  title="Versicherungs-schutz anpassen"
                  to="/"
                  className={styles.squredCorner}
              />
              <ProductCard
                  title="Risikoschutz auflösen"
                  to="/"
                  className={styles.squredCorner}
              />
              <ProfileSupport
                  className={styles.squredCorner}
                  title="Fragen zum Risikoschutz?"
              />
          </div>
      </DashboardLayout>
    </Page>
  );
};

export default RiskProtectionWithContractPage;
