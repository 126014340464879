import React from 'react';
import { Image } from 'react-bootstrap';
import LogoImg from '../../../Assets/Images/Logo.svg';
import styles from './Logo.module.scss';


type Props = {
    className?: string
}

const Logo: React.FC<Props> = (props) => {
    return (
        <div className={props.className}>
            <Image className={styles.image} src={LogoImg} alt="Logo" />
        </div>
    );
};

export default Logo;