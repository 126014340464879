import React from 'react';
import Link from '../../Atoms/Link/Link';
import {PopUpProps} from '../../Atoms/Popup/Popup';
import InfoPopup from '../../Molecules/Popup/InfoPopup';
import styles from './InvestmentHorizonInfoPopup.module.scss';
import t from '../../../App/Libs/Translate/Translate'

const InvestmentHorizonInfoPopup = (props: PopUpProps) => {

  function onExit(obj: HTMLElement) {
    if (props.onExit)
      props.onExit(obj)
  }

  return (
    <InfoPopup {...props}>
      <div className={styles.content}>
        <h4>{t('common.investment_horizon')}</h4>
        <h5>{t('register.survey.horizon.investment_horizon_definition')}</h5>
        <ul className={styles.list}>
          <li>
            <h6>{t('register.survey.horizon.rules_for_males')}</h6>
            <p>{t('register.survey.horizon.rules_for_males_extra')}</p>
          </li>
          <li>
            <h6>{t('register.survey.horizon.rules_for_woman')}</h6>
            <p>{t('register.survey.horizon.rules_for_woman_extra')}</p>
          </li>
        </ul>
        <Link className={styles.link} onClick={a => onExit(a)}>{t('common.conclude')}</Link>
      </div>
    </InfoPopup>
  );
};

export default InvestmentHorizonInfoPopup;
