import React, { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ChooseProductPageLayout from "../../Organisms/Layout/ChooseProductPageLayout/ChooseProductPageLayout";
import Page from "../../Molecules/Page/Page";
import OnboardingLayout from "../../Organisms/Layout/OnboardingLayout";
import { ComplianceActions } from '../../../App/Mobx/Actions/ComplianceActions';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { observer } from 'mobx-react';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import FooterNavbar, { NavbarButtonType } from '../../Molecules/Navbar/FooterNavbar';
import { ProductDto } from '../../../App/Models/Contracts/Compliance/ProductDto';
import WebSync from '../../Molecules/DataSync/WebSync';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';

const AdditionalAccountPage: React.FC<RouteComponentProps> = observer((props) => {

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { Account, Compliance } = useStore()

  const webSyncRef = useRef<WebSync>()

  var store = useStore()

  function load(){
    setIsLoading(true)
    ComplianceActions
      .GetProducts()
      .onSuccess(() => setIsLoading(false))
      .onFail(() => setIsLoading(false))
  }

  ComponentDidMount(() =>{
    load()
  })

  function renderFooter() {
    return <FooterNavbar
      buttonBackText='Zurück'
      linkToNext='/'
      buttonNextText='Nein danke'
      buttonNextType={NavbarButtonType.Close} />
  }

  
  function toNextPage(proposalId: number){
    props.history.push(`/onboarding/${proposalId}/survey/horizon`)
  }

  function createVirtualPortfolio(proposalId: number, product: ProductDto) {

    Account.virtualPortfolios = [{
      product: product,
      proposalId: proposalId,
      name: `Portfolio 1`
    }]
  }


  function onProductClick(product: ProductDto) {
    var vp = Account.virtualPortfolios.find(x => x.product.id === product.id && x.riskCategoryId === undefined)

    if (vp) {
      toNextPage(vp.proposalId)
      return
    }

    webSyncRef.current.Activity.show()
    PortfolioActions
      .PostPortfolioProposal({
        productId: product.id
      })
      .onSuccess(x => {
        createVirtualPortfolio(x.data.proposalId, product)
        toNextPage(x.data.proposalId)
        webSyncRef.current.Activity.hide()
      })
      .onFail(() => {
        webSyncRef.current.Activity.hide()
      })

  }

  return (
    <Page>
      <OnboardingLayout
        progress={0.5}
        footer={renderFooter()}
        selectedStepIndex={1}
        syncRef={webSyncRef}>
        <ChooseProductPageLayout
          {...props}
          title={"Anderes Portfolio eröffnen?"}
          secondaryText={"Bitte wählen Sie Ihre gewünschte Vorsorgelösung. relevate solutions sind smart und lassen sich jederzeit flexibel auf Ihre Bedürfnisse ausrichten – heute und in Zukunft."}
          products={store.Compliance?.Products}
          onProductClick={p => onProductClick(p)}/>
      </OnboardingLayout>
    </Page>
  );
});

export default AdditionalAccountPage;
