import classnames from 'classnames';
import React, { Component } from 'react';
import { Callback } from '../../../../App/Models/Callback';
import { StepTheme } from '../../Tabs/Dependencies/StepTheme';
import stylesFile from './Step.module.scss';

const styles = stylesFile

type Props ={
    theme?: StepTheme
    selected?: boolean
    clsName?: string
    onClick?: Callback<any>
    HideIndicator?: boolean
    index?: number
    header?: any
    progress?: number
}


export default class Step extends Component<Props, []> {

    render() {
        return (
            <div className={classnames(styles.Step, this.props.selected ? styles.Selected : null, this.props.clsName)} onTouchStart={e => this.props.onClick && this.props.onClick(e)} onPointerDown={e => this.props.onClick && this.props.onClick(e)}>
                <div className={styles.StepHeader}>
                    {/* {!this.props.HideIndicator &&
                        <div className={classnames(styles.StepLine)} style={{backgroundColor: this.props.theme?.default.BackgroundColor}}></div>
                    } */}
                    {/* <div className={classnames(styles.StepNumber, this.props.selected ? styles.Selected : null)} style={{
                        backgroundColor: this.props.selected? this.props.theme?.selected.BackgroundColor : this.props.theme?.default.BackgroundColor,
                        color: this.props.selected? this.props.theme?.selected.Color : this.props.theme?.default.Color
                        }}>
                        <span>{this.props.index! + 1}</span>
                    </div> */}
                    <div className={styles.stepProgress}>
                        <div style={{ width: (this.props.progress? this.props.progress * 100 : 0) + '%' }}></div>
                    </div>
                </div>
                <div className={styles.StepTitle} style={{color: this.props.theme?.default.BackgroundColor}}>
                    {this.props.header}
                </div>
            </div>
        );
    }
}