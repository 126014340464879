

const lightAttrValue = 'light'
const darkAttrValue = 'dark'
const modeAttr = 'mode'


export enum ThemeOptions{
    Dark,
    Light,
    Auto
}

export default class Theme{
    static Configure(themeOption: ThemeOptions){
        if(themeOption === ThemeOptions.Auto)
            this.detectOsTheme()
        else
            this.assignTheme(themeOption)
    }

    private static detectOsTheme(){
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.assignTheme(ThemeOptions.Dark)
        }
        this.onThemeChange()
    }

    private static onThemeChange(){
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            event.matches? this.assignTheme(ThemeOptions.Dark) : this.assignTheme(ThemeOptions.Light)
        });
    }

    private static assignTheme(theme: ThemeOptions){
        if(theme === ThemeOptions.Dark)
            document.body.setAttribute(modeAttr, darkAttrValue)
        else
            document.body.setAttribute(modeAttr, lightAttrValue)
    }
}
