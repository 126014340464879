import { toJS } from 'mobx';
import React, { useRef } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ComplianceActions } from '../../../../App/Mobx/Actions/ComplianceActions';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import WebSync from '../../../Molecules/DataSync/WebSync';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SplitLayout from "../../../Organisms/Layout/SplitLayout";
import SurveyAnswer from '../../../Organisms/Survey/SurveyAnswer';
import t from '../../../../App/Libs/Translate/Translate';
import styles from './RegisterRiskCapabilityPage.module.scss';
import { ContactActions } from '../../../../App/Mobx/Actions/ContactActions';
import { PortfolioActions } from '../../../../App/Mobx/Actions/PortfolioActions';
import { ProposalParams } from '../../../../App/Models/Params/ProposalParams';

const groupOrder = 2
const questionOrder = 2

const RegisterRiskCapabilityPage: React.FC<RouteComponentProps> = (props) => {

    const { Account } = useStore()
    const { proposalId } = useParams<ProposalParams>()
    var virtualPortfolio = Account.getCurrentVirtualPortfolio(proposalId)


    const webRef = useRef<WebSync>()

    function onClick(index: number) {

        var answer = Account.questionnaire
            .groups[groupOrder - 1]
            .steps[0]
            .questions[questionOrder - 1]
            .answers[index]

        Account.questionnaire.groups[groupOrder - 1].steps[0].questions[questionOrder - 1].value = {
            ids: [answer.id]
        }

        var questionnaire = toJS(Account.questionnaire!)
        virtualPortfolio.questionnaire = questionnaire
        webRef.current.Activity.show()
        ComplianceActions
            .PostQuestionnaire(questionnaire)
            .onSuccess(() => {
                ContactActions
                    .GetRisk({
                        productId: virtualPortfolio.product.id
                    })
                    .onSuccess(response => {
                        virtualPortfolio.evaluatedRiskId = response.data.id 
                        PortfolioActions
                            .PatchPortfolioProposal({
                                proposalId: virtualPortfolio.proposalId,
                                evaluatedRiskId: virtualPortfolio.evaluatedRiskId,
                                questionnaire: virtualPortfolio.questionnaire
                            })
                            .onSuccess(() => {
                                props.history.push(`/onboarding/${proposalId}/Risk/Profile`)
                                webRef.current.Activity.hide()
                            })
                            .onFail(() => webRef.current.Activity.hide())
                            
                    })
                    .onFail(() => webRef.current.Activity.hide())
            })
            .onFail(() => webRef.current.Activity.hide())
    }


    return (
        <Page>
            <WebSync ref={webRef}>
                <OnboardingLayout selectedStepIndex={2} progress={0.5} contentMaxWidth={"1600px"}>
                    <SplitLayout className={styles.main}>
                        <div className={styles.titleContainer}>
                            <h1>{t('register.survey.capability.title')}</h1>
                            <h3>{t('register.survey.capability.subtitle')}</h3>
                        </div>
                        <div className={styles.answerContainer}>
                            <SurveyAnswer
                                name='answer'
                                label={t('register.survey.capability.sell_all_securities')}
                                onClick={() => onClick(0)}
                            />
                            <SurveyAnswer
                                name='answer'
                                label={t('register.survey.capability.switch_to_conservative_strategy')}
                                onClick={() => onClick(1)}
                            />
                            <SurveyAnswer
                                name='answer'
                                label={t('register.survey.capability.maintain_chosen_strategy')}
                                onClick={() => onClick(2)}
                            />
                            <SurveyAnswer
                                name='answer'
                                label={t('register.survey.capability.switch_to_riskier_strategy')}
                                onClick={() => onClick(3)}
                            />
                        </div>
                    </SplitLayout>
                </OnboardingLayout>
            </WebSync>
        </Page>
    );
};

export default RegisterRiskCapabilityPage;
