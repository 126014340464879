import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import editIcon from '../../../../Assets/Images/Edit/Pencil.svg';
import trashIcon from '../../../../Assets/Images/Edit/Trash.svg';
import Checkbox from '../../../Atoms/Checkbox/Checkbox';
import Accordion from '../../../Molecules/Accordion/Accordion';
import FooterNavBar from '../../../Molecules/Navbar/FooterNavbar';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import styles from './SigningContract.module.scss';
import Dropdown from "../../../Atoms/Dropdown/Dropdown";
import { GetPersonalInfoRequestDto } from '../../../../App/Models/Contracts/Contact/GetPersonalInformationDto';
import { ComponentDidMount } from '../../../../App/Libs/Helper/Helper';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import { GenderEnum } from '../../../../App/Models/Enums/GenderEnum';
import t from '../../../../App/Libs/Translate/Translate'
import WebSync from '../../../Molecules/DataSync/WebSync';
import { PortfolioActions } from '../../../../App/Mobx/Actions/PortfolioActions';
import DownloadButton from "../../../Atoms/Button/DownloadButton";
import { PortfolioDto } from '../../../../App/Models/Contracts/Portfolio/PortfolioDto';
import { VirtualPortfolioDto } from '../../../../App/Models/Contracts/Portfolio/VirtualPortfolioDto';
import DocumentActions from '../../../../App/Mobx/Actions/DocumentActions';
import { ComplianceActions } from '../../../../App/Mobx/Actions/ComplianceActions';
import { ProductEnum } from '../../../../App/Models/Enums/ProductEnum';


type SignContractCheckBoxes = {
  checkbox_doc_1: boolean,
    checkbox_doc_2: boolean,
    checkbox_doc_3: boolean
}

const SigningContract = (props: RouteComponentProps) => {

  const [disableButton, setDisableButton] = useState<boolean>(true)
  const [checkBoxObject, setCheckBoxObject] = useState<SignContractCheckBoxes>({
    checkbox_doc_1: false,
    checkbox_doc_2: false,
    checkbox_doc_3: false
  })

  const [data, setData] = useState<GetPersonalInfoRequestDto>()
  const { Account, Contact, Compliance } = useStore()

  const webSyncRef = React.createRef<WebSync>()

  ComponentDidMount(() => {
    fetchData()
  })

  const calculateData = () => {

    var uncheckedCount = Object.values<boolean>({
      ...checkBoxObject,
      checkbox_doc_3: undefined
    }).filter(x => !x).length

    if (uncheckedCount <= 1)
      setDisableButton(false)
    else
      setDisableButton(true)
  }

  const getData = (val: boolean, name: string) => {
    checkBoxObject[name] = val
    setCheckBoxObject({
      ...checkBoxObject,
      [name]: val
    })
    calculateData();

    if (name === 'checkbox_doc_3') {
      Contact.sendWelcomeEmail = val
    }

  }

  const fetchData = () => {
    // ContactActions
    //   .GetPersonalInformation()
    //   .onSuccess(res =>[
    //       setData(res.data)
    //   ])
  }

  function onInvestProfileClick(portfolio: VirtualPortfolioDto){
    DocumentActions
      .GenerateInvestorProfileContract(portfolio.proposalId)
      
  }

  function onBeneficiaryOrder(portfolio: VirtualPortfolioDto){
    DocumentActions
      .GenerateBeneficiaryOrder(portfolio.product.id)
      
  }

  function getProductId(portfolio: VirtualPortfolioDto){
    if(portfolio.product.id === ProductEnum.PensFree)
      return ProductEnum.Independent
    return portfolio.product.id
  }

  const renderPortfolioOverview = () => {
    return (
      <>
        {Account.virtualPortfolios.map((portfolio, index) => (
          <div className={styles.portfolioContainer} key={index}>

            <div className={styles.portfolioNumber}>{portfolio.name}</div>
            <div className={styles.portfolioName}>{Compliance.Products.find(x => x.id === getProductId(portfolio)).name}</div>

            <table className={styles.table}>
              <tr className={styles.tableRow}>
                <td>{t('common.investment_horizon')}</td>
                <td>{portfolio.investmentYears} {portfolio.investmentYears > 1 ? t('common.years') : t('common.year')}</td>
              </tr>
              <tr className={styles.tableRow}>
                <td>{t('register.contract.signing_contract.strategy')}</td>
                <td>{portfolio.modelPortfolio?.name}</td>
                <td className={styles.dropdownContainer}>
                  <Dropdown>
                    <div className={styles.contentPortfolioDetailsMenu}>
                      <div
                        className={styles.contentPortfolioDetailsMenuLink}
                        onClick={() => portfolioEdit(portfolio)}>
                        {t('common.edit_portfolio')} <img className={styles.whiteIcon} src={editIcon} alt="" />
                      </div>
                      <div
                        className={styles.contentPortfolioDetailsMenuLink}
                        onClick={() => portfolioDelete(portfolio)}>
                        {t('common.delete_portfolio')} <img className={styles.whiteIcon} src={trashIcon} alt="" />
                      </div>
                    </div>
                  </Dropdown>
                </td>
              </tr>
            </table>

            <div className={styles.downloadButtonsWrapper}>
              <DownloadButton onClick={e => onBeneficiaryOrder(portfolio)} className={styles.downloadButton} >{t("register.contract.signing_contract.pension_agreement")}</DownloadButton>
              <DownloadButton onClick={e => onInvestProfileClick(portfolio)} className={styles.downloadButton}>{t("register.contract.signing_contract.invertor_profile")}</DownloadButton>
            </div>

            <div className={styles.grayLinePortfolio}></div>
          </div>
        ))}
      </>
    )
  }

  const renderAccountHolder = () => {
    return (
        <div>
          <h5 className={styles.blockTitle}>{t('register.contract.signing_contract.account_holder')}</h5>

          <table className={styles.table}>
            <tr className={styles.tableRow}>
              <td>{t('register.contract.signing_contract.salutation')}</td>
              <td>{Contact.gender === GenderEnum.Male ? t('common.genders.male') : t('common.genders.woman')}</td>
            </tr>

            <tr className={styles.tableRow}>
              <td>{t('common.first_name')}</td>
              <td>{Contact.firstName}</td>
            </tr>

            <tr className={styles.tableRow}>
              <td>{t('common.surname')}</td>
              <td>{Contact.lastName}</td>
            </tr>

            <tr className={styles.tableRow}>
              <td>{t('common.address')}</td>
              <td>{Contact.address}</td>
            </tr>

            <tr className={styles.tableRow}>
              <td>{t('common.postcode')}/{t('common.location')}</td>
              <td>{Contact.postalCode} {Contact.city}</td>
            </tr>

            <tr className={styles.tableRow}>
              <td>{t('common.date_of_birth')}</td>
              <td>{new Date(Contact.birthDate).toLocaleDateString("de-CH")}</td>
            </tr>
          </table>
      </div>
    )
  }

  const renderProvisions = () => {
    return (
      <div>
        <h5 className={styles.accordtionTitle}>{t('register.contract.signing_contract.provisions')}</h5>

        <p>{t('register.contract.signing_contract.provisions_description')}</p>

        <p>{t('register.contract.signing_contract.provisions_can_be_withdrawn')}</p>

        <ul className={styles.list}>
          <li>{t('register.contract.signing_contract.reaching_ahv_age')}</li>
          <li>{t('register.contract.signing_contract.home_ownership_promotion')}</li>
          <li>{t('register.contract.signing_contract.purchase_into_pension_fund')}</li>
          <li>{t('register.contract.signing_contract.self_employment')}</li>
          <li>{t('register.contract.signing_contract.leaving_switzerland')}</li>
          <li>{t('register.contract.signing_contract.disability_death')}</li>
        </ul>
      </div>
    )
  }

  const renderTermsOfContract = () => {
    return (
      <>
        <div>
            <div className={styles.downloadButtonsWrapper}>
                <DownloadButton className={styles.downloadButton}>{t("register.contract.signing_contract.pension_plan")}</DownloadButton>
                <DownloadButton className={styles.downloadButton}>{t("register.contract.signing_contract.schedule_of_fees")}</DownloadButton>
            </div>

        </div>
        <div>
            <div className={styles.downloadButtonsWrapper}>
                <DownloadButton className={styles.downloadButton}>{t("register.contract.signing_contract.privacy_policy")}</DownloadButton>
                <DownloadButton className={styles.downloadButton}>{t("register.contract.signing_contract.terms_of_use")}</DownloadButton>
            </div>
        </div>
      </>
    )
  }

  const portfolioDelete = (portfolio: VirtualPortfolioDto) => {
    Account.virtualPortfolios = Account.virtualPortfolios.filter(x => x !== portfolio)
    props.history.push(`/onboarding/${portfolio.proposalId}/delete/success`);
  }

  const portfolioEdit = (portfolio: VirtualPortfolioDto) => {
    props.history.push(`/onboarding/${portfolio.proposalId}/survey/horizon`);
  }

  const createPortfolio = () => {
    webSyncRef.current.Activity.show()
    var toBeProcessed = Account.virtualPortfolios.length
    Account.virtualPortfolios.forEach((p, index) => {
      webSyncRef.current.Activity.show()

      PortfolioActions
        .PostPortfolio({
          name: `Portfolio ${index + 1}`,
          productId:  p.product.id,
          proposalId: p.proposalId,
          transferSource: Account.transferSource,
          createAccounts: true,
          attachDocuments: checkBoxObject.checkbox_doc_3
        })
        .onSuccess(res => {
          if(!Account.portfolios)
            Account.portfolios = [res.data]
          else
            Account.portfolios.push(res.data)
          
          p.isCreated = true
          webSyncRef.current.Activity.hide()
          toBeProcessed--
          if(toBeProcessed == 0)
            props.history.push('/onboarding/Contract/CongratulationsPensionSolution')
        })
        .onFail(res =>{
          webSyncRef.current.Activity.hide()
        })
    })
  }


  function renderFooter() {
    return <FooterNavBar
      buttonNextDisabled={disableButton}
      linkToBack='/onboarding/Portfolio/RegisterSuccess'
      onNextClick={() => createPortfolio()}
      buttonBackText={t('common.back')}
      buttonNextText={t('common.close_deal')} />
  }

  return (
    <Page>
      <WebSync ref={webSyncRef}>
        <OnboardingLayout
          footer={renderFooter()}
          contentMaxWidth='1240px'
          hideStepper={true}>
          <h1 className={styles.title}>{t('register.contract.signing_contract.title')}</h1>
          <h4 className={styles.subTitle}>{t('register.contract.signing_contract.subtitle')}</h4>
          <div className={styles.main}>
            <div className={styles.content}>

              <div className={styles.accordionContainer}>
                {renderAccountHolder()}

                <Accordion
                  content={renderPortfolioOverview()}
                  withArrow={true}
                  name={t('register.contract.signing_contract.portfolio_overview')}
                  opened={true}
                />

                <Accordion
                  content={renderTermsOfContract()}
                  withArrow={true}
                  name={t('register.contract.signing_contract.terms_of_contract')}
                  opened={true}
                />

                {renderProvisions()}
              </div>

              <div className={styles.checkboxContainer}>
                <Checkbox
                  className={styles.checkbox}
                  onChange={e => getData(e.currentTarget.checked, e.currentTarget.id)}
                  id='checkbox_doc_1'
                >
                  {t('register.contract.signing_contract.agree_to_pension_agreement')}
                </Checkbox>
                <Checkbox
                  className={styles.checkbox}
                  onChange={e => getData(e.currentTarget.checked, e.currentTarget.id)}
                  id='checkbox_doc_2'
                >
                  {t('register.contract.signing_contract.agree_to_terms_and_conditions_and_data_protection')}
                </Checkbox>
                <Checkbox
                  className={styles.checkbox}
                  onChange={e => getData(e.currentTarget.checked, e.currentTarget.id)}
                  id='checkbox_doc_3'
                >
                  {t('register.contract.signing_contract.email_me_terms_and_conditions')}
                </Checkbox>
              </div>
              <div className={styles.bg}></div>
            </div>
          </div>
        </OnboardingLayout>
      </WebSync>
    </Page>
  );
};

export default SigningContract;
