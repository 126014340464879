import React from "react"
import { IconProps } from '../Menu'

const DocumentIcon = (props: IconProps) => {
  return (
    <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path fill={props.color ?? "white"} fillRule="evenodd" clipRule="evenodd" d="M10.7995 0H2.89522C2.67708 0 2.5 0.179219 2.5 0.4V19.6C2.5 19.8208 2.67708 20 2.89522 20H17.123C17.3412 20 17.5182 19.8208 17.5182 19.6V6.8C17.5182 6.55041 17.4284 6.24374 17.2686 5.90001C17.255 5.84649 17.2307 5.7973 17.1981 5.7549C16.2849 3.94904 13.6177 1.24939 11.8331 0.324439C11.7909 0.291146 11.7419 0.266338 11.6885 0.252507C11.349 0.0908433 11.0461 0 10.7995 0ZM11.1948 0.886602C11.045 0.830254 10.9119 0.8 10.7995 0.8H3.29043V19.2H16.7278V6.8C16.7278 6.68631 16.6979 6.55159 16.6422 6.4H11.59C11.3718 6.4 11.1948 6.22082 11.1948 6V0.886602ZM16.22 5.6C15.3118 4.19389 13.3745 2.23318 11.9852 1.31398V5.6H16.22Z"/>
    </svg>
  );
};

export default DocumentIcon;
