import React from 'react';
import Link from '../../Atoms/Link/Link';
import {PopUpProps} from '../../Atoms/Popup/Popup';
import InfoPopup from '../../Molecules/Popup/InfoPopup';
import styles from './DetailInfoPopup.module.scss';

type DetailInfoPopupProps = {
    children?: Array<JSX.Element> | JSX.Element,
}

const DetailInfoPopup = (props: PopUpProps & DetailInfoPopupProps) => {
  function onExit(obj: HTMLElement) {
    if (props.onExit)
      props.onExit(obj)
  }

  return (
    <InfoPopup {...props} frameClassName={styles.main}>
        <div className={styles.content}>
            {props.children}
        </div>
    </InfoPopup>
  );
};

export default DetailInfoPopup;
