import { AxiosResponse } from "axios";
import WebClient from "../../Libs/WebClient/WebClient";
import { GlobalStore } from "../Stores/MainStore";


const DEFAULT_FILE_TYPE = 'octet/stream';
export default class DocumentActions{

    
    private static GetFileNameFromContentDisposition(value?: string) {
        if (!value) {
            return value;
        }

        var index = value.lastIndexOf("_");

        if (index !== -1) {
            return value.substr(index + 1, value.length);
        }

        return value;
    }

    private static  GetFileDataFromResponse(response: AxiosResponse<any>) {
        if (!response) {
            return;
        }
        
        var contentDisposition:string = response.headers["content-disposition"]

        var filenameAttribute = contentDisposition.split(";").find(x => x.trim().startsWith("filename="))



        var fileName = filenameAttribute?.split("filename=")[1];
        var fileType = response.headers['content-type'];
    
        return {
            fileName: this.GetFileNameFromContentDisposition(fileName),
            fileType
        }
    }


    private static CreateUrlForDownloadFile(data: any, fileType?:string) {
        let blob = new Blob([data], { type: fileType || DEFAULT_FILE_TYPE })
        return window.URL.createObjectURL(blob);
    }

    private static CreateElementForDownloadFile(fileName: string, url: string) {
        var a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = decodeURIComponent(fileName);
        document.body.appendChild(a);
        a.click();
    
        return a;
    }

    private static DownloadFile(response: AxiosResponse){
        var file = this.GetFileDataFromResponse(response);
        var url = this.CreateUrlForDownloadFile(response.data, file?.fileType);
        var element = this.CreateElementForDownloadFile(file?.fileName || "unbenannt.pdf", url);
        setTimeout(() => {
            window.URL.revokeObjectURL(url)
            document.body.removeChild(element)
        }, 10000);
    }

    static GetDocuments(){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Get('documents')
    }

    static DownloadDocument(documentId: string){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Configure({ responseType: 'arraybuffer' })
            .Get(`document/${documentId}`)
            .onSuccess(response =>{
                this.DownloadFile(response)
            })
    }


    static GenerateBasicContract(){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Configure({ responseType: 'arraybuffer' })
            .Get("Document/generate/contract/basic")
            .onSuccess(response =>{
                this.DownloadFile(response)
            })
    }


    static GenerateInvestorProfileContract(proposalId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Configure({ responseType: 'arraybuffer' })
            .Get("Document/generate/contract/investor/profile",{
                proposalId: proposalId
            })
            .onSuccess(response =>{
                this.DownloadFile(response)
            })
    }

    static GenerateAssetManagementContract(){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Configure({ responseType: 'arraybuffer' })
            .Get("Document/generate/contract/asset")
            .onSuccess(response =>{
                this.DownloadFile(response)
            })
    }

    static GeneratePowerOfAttorneyContract(productId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Configure({ responseType: 'arraybuffer' })
            .Get("Document/generate/contract/powerofattorney",{
                productId
            })
            .onSuccess(response =>{
                this.DownloadFile(response)
            })
    }

    static GeneratePensionContract(productId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Configure({ responseType: 'arraybuffer' })
            .Get("Document/generate/contract/pension", { productId })
            .onSuccess(response =>{
                this.DownloadFile(response)
            })
    }
    
    static GenerateKycContract(){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Configure({ responseType: 'arraybuffer' })
            .Get("Document/generate/contract/kyc")
            .onSuccess(response =>{
                this.DownloadFile(response)
            })
    }


    static GenerateBeneficiaryOrder(productId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Configure({ responseType: 'arraybuffer' })
            .Get('document/generate/contract/beneficiaryorder', {
                productId
            })
            .onSuccess(res => this.DownloadFile(res))
    }

    static GeneratePaymentInfo(portfolioId: number){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Configure({ responseType: 'arraybuffer' })
            .Get('document/generate/payment/info', {
                portfolioId
            })
            .onSuccess(res => this.DownloadFile(res))
    }
}