import React, { useState } from 'react';
import Page from '../../Molecules/Page/Page';
import DashboardLayout from '../../Organisms/Layout/DashboardLayout';
import styles from './DepositOverviewPage.module.scss';
import t from '../../../App/Libs/Translate/Translate'
import { LargeButton } from '../../Atoms/Button/LargeButton';
import { Button } from '../../Atoms/Button/Button';
import { GhostButton } from '../../Atoms/Button/GhostButton';
import DashboardHeader from "../../Organisms/DashboardHeader/DashboardHeader";
import { RouteComponentProps, useParams } from "react-router-dom";
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import { PortfolioDepositResultDto } from '../../../App/Models/Contracts/Portfolio/PortfolioDepositResultDto';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import WebSync from '../../Molecules/DataSync/WebSync';
import Formatter from '../../../App/Libs/Formatter/Formatter';

type Params = {
  id?: string
}

const DepositOverviewPage = (props: RouteComponentProps) => {

  const [portfolioDeposits, setPortfolioDeposits] = useState<PortfolioDepositResultDto>()

  const { Dashboard } = useStore()
  const { id } = useParams<Params>()
  const Account = Dashboard.getPortfolioById(parseInt(id))
  if (!Account)
    props.history.replace('/')


  function load() {
    return PortfolioActions
      .GetPortfolio3aYearlyDeposits(Account.id)
      .onSuccess(res => setPortfolioDeposits(res.data))
  }

  const onHandleDepositClick = () => {
    props.history.push(`/dashboard/depot/${id}/3a/pay/info`)
  }

  const onHandleTransferClick = () => {
    props.history.push("/Dashboard/OverviewDepots3a/DepositTransfer");
  }

  
  function getCurrentYear() {
    return new Date(Date.now()).getFullYear()
  }

  function getDepositAmountPerYear(year: number){
      var value =  portfolioDeposits?.transactions.filter(x => new Date(x.date).getFullYear() === year)
        .reduce((a,b) => a + b.value, 0) ?? 0
    return value
  }

  function getProgressPercent(){
    if(!portfolioDeposits)
      return 0
    return (getDepositAmountPerYear(getCurrentYear())/portfolioDeposits.maxDepositPerYear) * 100
  }

  return (
    <Page>
        <DashboardLayout sync={() => load()}>
          <DashboardHeader
            title={t("dashbord.deposit.overview.3a_depot")}
            subtitle={Account.name}
            onDepositClick={onHandleDepositClick}
            onTransferClick={onHandleTransferClick}
          >
            <div className={styles.titleContainer}>
              <div className={styles.title}>{t("dashbord.deposit.overview.title")}</div>
            </div>
          </DashboardHeader>
          <div className={styles.section}>
            <div className={styles.deposits}>
              <div className={styles.graph}>
                <div className={styles.title}>{t("dashbord.deposit.overview.title")} {getCurrentYear()}</div>
                <div className={styles.container}>
                  <div className={styles.subtitle}>CHF {getDepositAmountPerYear(getCurrentYear())}.00 </div>
                  <div className={styles.subtitle}>CHF {portfolioDeposits?.maxDepositPerYear - getDepositAmountPerYear(getCurrentYear())}.–</div>
                </div>
                <div className={styles.progressLine}>
                  <div className={styles.progressLineGreen} style={{ width: `${getProgressPercent()}%` }}></div>
                </div>
                <div className={styles.caption}>{t("dashbord.deposit.overview.max_sum")} CHF {portfolioDeposits?.maxDepositPerYear}.00</div>
              </div>
            </div>
          </div>
          <LargeButton to={`/dashboard/depot/${id}/3a/pay/info`}>
            <div className={styles.btnTitleContainer}>
              <div className={styles.subtitle}>{t("dashbord.deposit.overview.3a_depot")}</div>
              <div className={styles.subtitle}>{t("dashbord.deposit.overview.deposit_now")}</div>
            </div>
          </LargeButton>
          <div className={styles.main}>
            <div className={styles.flex}>
              <div className={styles.paymentsContainer}>
                <div className={styles.paymentsTitle}>{t("dashbord.deposit.overview.deposit_detail").replace('2022', getCurrentYear())}</div>
                <div>
                  <hr className={styles.line} />
                  {portfolioDeposits?.transactions?.map((transaction) => {
                    return (
                      <div>
                        <div className={styles.container}>
                          <div className={styles.paymentsSubtitle}>{Formatter.formatDate(transaction.date)}</div>
                          <div className={styles.paymentsSubtitle}>CHF {Formatter.formatNumber(transaction.value)}</div>
                        </div>
                        <hr className={styles.line} />
                      </div>
                    )
                  })}
                  <hr className={styles.line} />
                </div>
              </div>
              <div className={styles.totalContainer}>
                <hr className={styles.whiteLine} />
                <div className={styles.totalTitle}>{t("dashbord.deposit.overview.deposit_total")}</div>
                <div className={styles.totalAmount}>CHF {Formatter.formatNumber(getDepositAmountPerYear(getCurrentYear()))}.–</div>
                <hr className={styles.whiteLine} />
                <div className={styles.totalSubtitle}>{t("dashbord.deposit.overview.sum_possible")}</div>
                <div className={styles.remainingAmount}>CHF {Formatter.formatNumber(portfolioDeposits?.maxDepositPerYear -  getDepositAmountPerYear(getCurrentYear()))}.–</div>
                <hr className={styles.whiteLine} />
                <div className={styles.flex}>
                  <GhostButton className={styles.btn}>{t("dashbord.deposit.overview.make_the_transfer")}</GhostButton>
                  <Button className={styles.btn}>{t("dashbord.deposit.overview.deposit_now")}</Button>
                </div>
              </div>
            </div>
          </div>
        </DashboardLayout>
    </Page>
  );
};

export default DepositOverviewPage;