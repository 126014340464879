import React from 'react';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import WebClientPromise from '../../../App/Libs/WebClient/WebClientPromise';
import { AuthActions } from '../../../App/Mobx/Actions/AuthActions';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';

const SsoSynchronizationPage: React.FC = () => {
    const { Auth } = useStore()

    function synchronize() {
        AuthActions
            .SynchronizeSso()
            .onSuccess(() => Auth.IsSsoSynchronized = true)
            .onFail(e =>{
                if(e.response?.status !== 401)
                    alert(e.response.data.title)
            })

    }

    ComponentDidMount(() => {
        synchronize()
    })

    return (
        <Page>
            <OnboardingLayout contentMaxWidth='1600px'
                hideStepper={true} sync={() => new WebClientPromise<any>()}
                hideMenu={true}>
            </OnboardingLayout>
        </Page>
    );
};

export default SsoSynchronizationPage;