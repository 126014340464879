import classnames from 'classnames';
import React, { useRef } from 'react';
import { IWebClientPromise } from '../../../App/Libs/WebClient/WebClientPromise';
import { FuncEmpty } from '../../../App/Models/Callback';
import WebSync from '../DataSync/WebSync';

import styles from './Layout.module.scss'

export type LayoutProps = {
    sync?: FuncEmpty<IWebClientPromise<any>>
    syncRef?: React.MutableRefObject<WebSync>
    className?: string
    header?: any
    footer?: any
    isFullWidth?: boolean
    children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = (props) => {
    return (
        <WebSync ref={props.syncRef} client={props.sync}>
            <div className={classnames(styles.main, props.className)}>
                <div className={classnames(styles.navBarContainer, props.isFullWidth ? styles.navBarContainerPaddings : '')}>
                    {props.header}
                </div>
                <div className={styles.contentContainer}>
                    {props.children}
                </div>
                <div className={styles.footerContainer}>
                    {props.footer}
                </div>
            </div>
        </WebSync>
    );
};

export default Layout;
