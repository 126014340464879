import Page from "../../Molecules/Page/Page";
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import FooterNavBar, {NavbarButtonType} from '../../Molecules/Navbar/FooterNavbar';
import SuccessPageLayout from '../../Organisms/Layout/SuccessPageLayout';
import DownloadButton from "../../Atoms/Button/DownloadButton";
import t from '../../../App/Libs/Translate/Translate';
import styles from './DepositTransfer3aSuccessPage.module.scss';

const DepositTransfer3aSuccessPage = () => {

  const renderFooter = () => {
    return <FooterNavBar
      linkToBack=''
      linkToNext='/Dashboard/OverviewDepots3a/StrategyDetails'
      buttonBackText={t('common.back')}
      buttonNextText={t('common.end')}
      buttonNextType={NavbarButtonType.Close}
    />
  }

  const renderContent = () => {
    return (
      <>
        {t('dashboard.overview_depots_3a.deposit_transfer_success.subtitle')}
        <DownloadButton className={styles.downloadButton}>
          {t('dashboard.overview_depots_3a.deposit_transfer_success.download_cancellation_doc')}
        </DownloadButton>
      </>
    )
  }

  return (
    <Page>
      <OnboardingLayout
        hideStepper={true}
        contentMaxWidth='1440px'
        backButtonLink="/Dashboard/OverviewDepots3a/StrategyDetails"
        footer={renderFooter()}
      >
        <SuccessPageLayout
          title={t('dashboard.overview_depots_3a.deposit_transfer_success.title')}
          content={renderContent()}
        />

      </OnboardingLayout>
    </Page>
  );
};

export default DepositTransfer3aSuccessPage;
