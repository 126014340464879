import { RouteComponentProps } from 'react-router-dom';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../Organisms/Layout/SplitLayout';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';

import styles from './RiskPremiumPaymentPage.module.scss';

const DashboardRiskPremiumPaymentPage = (props: RouteComponentProps) => {

    const renderFooter = () => {
        return <FooterNavBar
            linkToNext='/AdjustRiskProtectionSuccess'
            buttonBackText='Zurück'
            buttonNextText='Risikoprämienzahlung ansehen'/>
      }

    return (
        <Page>
            <OnboardingLayout
                contentMaxWidth='1440px'
                footer={renderFooter()}>

                <SplitLayout className={styles.splitView}>
                    <div className={styles.titleSection}>
                        <h1 className={styles.title}>Risikoprämienzahlung</h1>
                    </div>
                    <div className={styles.contentSection}>
                        <p>Die anfallende Risikoprämie für die versicherten Leistungen wird direkt dem Vorsorgevermögen belasten</p>
                        <p>Die Risikoprämie ist jeweils am 1. Januar fällig. Bei unter-jährigem Beginn ist die Risikoprämie jeweils am Tag des Versicherungsbeginns fällig. Ist die Risikoprämie 14 Tagen nach Fälligkeit nicht beglichen, wird sie direkt dem Vorsorge-vermögen des Vorsorgenehmers belastet.</p>
                        <hr className={styles.line} />
                        <div className={styles.amountText}>Ihre berechneten Prämien pro Jahr</div>

                        <div className={styles.amount}>CHF 0’000.–</div>
                        <hr className={styles.line} />
                    </div>
                </SplitLayout>
            </OnboardingLayout>
        </Page>
    );
};

export default DashboardRiskPremiumPaymentPage;