import classNames from 'classnames';
import React, {useState} from 'react';
import { Button } from '../../Atoms/Button/Button';
import Datepicker, {formatDate, parseDate} from '../../Atoms/Datepicker/Datepicker';
import SwitchBox from '../../Atoms/Switch/SwitchBox';
import Textarea from '../../Atoms/Textarea/Textarea';
import t from '../../../App/Libs/Translate/Translate';
import styles from './PersonalConsultationForm.module.scss';



type PersonalConsultationFormPropTypes = {
  to?: string,
  className?: string,
}

const PersonalConsultationForm = (props: PersonalConsultationFormPropTypes) => {

  const [pickedDate, setPickedDate] = useState(null);

  const handleSetDate = (date: Date | null) => {
    formatDate(date);
    setPickedDate(parseDate(date.toString()))
  }

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>{t('account.assets.value.arrange_consultation_secondary')}</h1>
      <p className={styles.description}>{t('account.assets.value.arrange_consultation_secondary_description')}</p>

      <div className={classNames(styles.block, styles.line)}>
        <p className={styles.label}>{t('account.assets.value.desired_dates')}</p>
        <Datepicker
          onChange={handleSetDate} placeholderText={"Datum"}
          selected={pickedDate}
        />
      </div>

      <div className={classNames(styles.block, styles.line)}>
        <p className={styles.label}>{t('account.assets.value.reachability')}</p>
        <div className={styles.switchBoxGroup}>
          <div className={styles.switch}>
            <SwitchBox>
              {t('account.assets.value.morning')}
              <span className={styles.timeRange}>08 - 12 Uhr</span>
            </SwitchBox>
          </div>
          <div className={styles.switch}>
            <SwitchBox>
              {t('account.assets.value.afternoon')}
              <span className={styles.timeRange}>13 - 18 Uhr</span>
            </SwitchBox>
          </div>
        </div>
      </div>

      <div className={classNames(styles.block, styles.line)}>
        <p className={styles.label}>{t('account.assets.value.way_to_contact')}</p>
        <div className={styles.switchBoxGroup}>
          <div className={styles.switch}>
            <SwitchBox>{t('account.assets.value.by_phone')}</SwitchBox>
          </div>
          <div className={styles.switch}>
            <SwitchBox>{t('account.assets.value.by_email')}</SwitchBox>
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <p className={styles.labelOptional}>{t('account.assets.value.message')}</p>
        <Textarea></Textarea>
      </div>

      <Button className={styles.button} to={props.to}>{t('account.assets.value.arrange_consultation')}</Button>
    </div>
  );
};

export default PersonalConsultationForm;
