import { RouteComponentProps } from "react-router-dom";
import FooterNavbar, { NavbarButtonType } from '../../Molecules/Navbar/FooterNavbar';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../Organisms/Layout/SplitLayout';
import styles from './AccountTransferFailFor3A.module.scss';
import t from '../../../App/Libs/Translate/Translate';


const data = {
    emailVerified: false
}

const AccountTransferFailFor3A = (props: RouteComponentProps) => {
    function renderFooter() {
        return <FooterNavbar
          buttonBackText={t('common.back')}
          linkToNext='/'
          buttonNextText={t('common.end')}
          buttonNextType={NavbarButtonType.Close} />
      }

    return (
        <Page>
            <OnboardingLayout
                footer={renderFooter()}
                contentMaxWidth='1500px'
                hideStepper={true}
                showBackgroundImage={true}>

                <SplitLayout className={styles.splitView}>
                    <div className={styles.titleSection}>
                        <h1 className={styles.title}>{t('account.3a.transfer.fail.title')}</h1>
                        <p className={styles.subtitle}>{t('account.3a.transfer.fail.subtitle')}</p>
                    </div>
                </SplitLayout>
            </OnboardingLayout>
        </Page>
    );
};

export default AccountTransferFailFor3A;