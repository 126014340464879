import React, { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ChooseProductPageLayout from "../../Organisms/Layout/ChooseProductPageLayout/ChooseProductPageLayout";
import Page from "../../Molecules/Page/Page";
import OnboardingLayout from "../../Organisms/Layout/OnboardingLayout";
import { ComplianceActions } from '../../../App/Mobx/Actions/ComplianceActions';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { observer } from 'mobx-react';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import WebSync from '../../Molecules/DataSync/WebSync';
import t from '../../../App/Libs/Translate/Translate'
import { ContactActions } from '../../../App/Mobx/Actions/ContactActions';
import { GenderEnum } from '../../../App/Models/Enums/GenderEnum';
import { ProductDto } from '../../../App/Models/Contracts/Compliance/ProductDto';
import { ProductOfferEnum } from '../../../App/Models/Enums/ProductOfferEnum';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { TransferSource } from '../../../App/Models/Enums/TransferSource';
import { Environment } from '../../../App/Configs/Environment';

const ChooseProductPage: React.FC<RouteComponentProps> = observer((props) => {


  var { Account, Contact, Compliance} = useStore()

  var webSyncRef = useRef<WebSync>()

  function load() {
    return ComplianceActions
      .GetProducts()
  }

  ComponentDidMount(() => {
    load()
  })

  function getSubtitle(){
    var subtitle = t('account.product.select.subtitle') as string
    subtitle = subtitle.replace('{{salutation}}', Contact.gender === GenderEnum.Male? t('common.genders.male') : t('common.genders.woman'))
    subtitle = subtitle.replace('{{name}}', `${Contact.firstName} ${Contact.lastName}`)
    return subtitle
  }

  function toNextScreen(product: ProductDto){
    switch (product.productOfferId) {
      case ProductOfferEnum.Pension:
        props.history.push("/onboarding/Survey/Financial/Knowledge")
        break
      default:
        props.history.push("/onboarding/Transfer/Source")
    }
  }

  function onProductSelect(product: ProductDto){
    webSyncRef.current.Activity.show()
    Account.portfolios = undefined
    
    PortfolioActions
      .PostPortfolioProposal({
        productId: product.id,
        transferSource: TransferSource.PK
      })
      .onSuccess(res =>{
        Account.virtualPortfolios = [{
          product: product,
          proposalId: res.data.proposalId,
          name: "Portfolio 1"
        }]
        toNextScreen(product)
        webSyncRef.current.Activity.hide()
      })
      .onFail(() => webSyncRef.current.Activity.hide())

  }

  return (
    <Page>
      <OnboardingLayout
        progress={0.5}
        selectedStepIndex={1}>
        <WebSync ref={webSyncRef} client={() => load()}>
          <ChooseProductPageLayout
            {...props}
            title={t('account.product.select.title')}
            secondaryText={getSubtitle()}
            footerText={t('account.product.select.secondary_text')}
            products={Compliance?.Products}
            onProductClick={p => onProductSelect(p)}
             />
        </WebSync>
      </OnboardingLayout>
    </Page>
  );
});

export default ChooseProductPage;
