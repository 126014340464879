import {useState} from 'react';
import {Image} from 'react-bootstrap';
import {RouteComponentProps} from "react-router-dom";
import Formatter from '../../../App/Libs/Formatter/Formatter';
import t from '../../../App/Libs/Translate/Translate';
import {ContactActions} from "../../../App/Mobx/Actions/ContactActions";
import DocumentActions from '../../../App/Mobx/Actions/DocumentActions';
import {PortfolioActions} from '../../../App/Mobx/Actions/PortfolioActions';
import {useStore} from '../../../App/Mobx/Infrastructure/mobx-hook';
import {PortfolioPaymentInfoDto} from '../../../App/Models/Contracts/Portfolio/PortfolioPaymentInfoDto';
import commentImg from '../../../Assets/Images/comment.svg';
import {Button} from '../../Atoms/Button/Button';
import {GhostButton} from '../../Atoms/Button/GhostButton';
import FooterNavbar from '../../Molecules/Navbar/FooterNavbar';
import Page from '../../Molecules/Page/Page';
import WarningPopup from '../../Molecules/Popup/WarningPopup';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../Organisms/Layout/SplitLayout';
import styles from './TransferScanLayout.module.scss';


type TransferScanType = {
  amount: string
  headerBackButtonLink?: string
  linkToNext?: string
  emailVerified: boolean
  portfolioId: number
  qrcode?: string
  children?: any
}

const TransferScanLayout = (props: RouteComponentProps & TransferScanType) => {

  const [show, setShow] = useState<boolean>(false)
  const {Auth, Account} = useStore()

  const [paymentInfo, setPaymentInfo] = useState<PortfolioPaymentInfoDto>()

  function load() {
    return PortfolioActions
      .GetPaymentInfo(props.portfolioId)
      .onSuccess(res => setPaymentInfo(res.data))
  }


  function onDocumentDownload() {
    DocumentActions
      .GeneratePaymentInfo(props.portfolioId)
  }

  function onAlertHide() {
    setShow(false)
  }

  function renderFooter() {

    if (Account.bvgValue === false)
      return null

    return <FooterNavbar
      linkToNext={props.linkToNext}
      buttonBackText={t('common.back')}
      buttonNextText={t('common.continue')}/>
  }

  return (
    <Page>
      <OnboardingLayout
        sync={() => load()}
        footer={renderFooter()}
        contentMaxWidth='1500px'
        hideStepper={true}
        showBackgroundImage={true}
        backButtonLink={props.headerBackButtonLink}
      >

        <SplitLayout className={styles.splitView}>
          <div className={styles.titleSection}>
            <h1 className={styles.title}>{t('account.3a.transfer.title')}</h1>
            <p className={styles.subtitle}>{t('account.3a.transfer.subtitle')}</p>
            <hr/>
            {props.children}
          </div>
          <div className={styles.contentSection}>
            <div className={styles.main}>
              <h5 className={styles.paymentTitle}>{t('account.3a.transfer.payment_instructions')}</h5>
              {paymentInfo &&
                <>
                  <div>
                    <div className={styles.paymentSubtitle}>{t('account.3a.transfer.payment_instructions')}</div>
                    <div>{paymentInfo.beneficiary}</div>
                    <div>{paymentInfo.beneficiaryAddress}</div>
                    <div>{paymentInfo.beneficiaryPostalCode} {paymentInfo.beneficiaryCity}</div>
                  </div>
                  <div>
                    <div className={styles.paymentSubtitle}>{t('account.3a.transfer.bank_address')}</div>
                    <div>{paymentInfo.bankName}</div>
                    <div>{paymentInfo.bankAddress}</div>
                    <div>{paymentInfo.bankPostalCode} {paymentInfo.bankCity}</div>
                  </div>
                  <div>
                    <div>
                      <span className={styles.paymentSubtitle}>IBAN: </span>
                      {Formatter.formatIban(paymentInfo.iban)}
                    </div>
                    <div>
                      <span className={styles.paymentSubtitle}>{t("account.3a.transfer.reference_number")}: </span>
                      {paymentInfo.referenceNumber}
                    </div>
                  </div>

                  <img className={styles.qrcode} src={`data:image/png;base64, ${paymentInfo.qrCode}`} alt=""/>

                  <GhostButton
                    onClick={() => onDocumentDownload()}
                    className={styles.ghostButton}>
                    {t('register.contract.transfer_contract.send_instructions_via_email')}
                  </GhostButton>
                </>
              }
            </div>
          </div>
        </SplitLayout>


        <WarningPopup show={show} onExit={() => onAlertHide()}>
          <div className={styles.warningMain}>
            <div>
              <Image className={styles.warningImage} src={commentImg} alt=""/>
            </div>
            <h4
              className={styles.warningText}>{t('register.contract.transfer_contract.send_instructions_via_email_popup.title')}</h4>
            <p>{t('register.contract.transfer_contract.send_instructions_via_email_popup.subtitle')}</p>
            <Button
              className={styles.warningButton}
              to="/onboarding/Contract/ProductPage3a"
              onClick={() => onAlertHide()}>ok</Button>
          </div>
        </WarningPopup>

      </OnboardingLayout>
    </Page>
  );
};

export default TransferScanLayout;