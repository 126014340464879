import React from 'react';
import styles from './MedicamentForm.module.scss';
import Input from '../../Atoms/Input/Input';
import Textarea from "../../Atoms/Textarea/Textarea";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import classnames from 'classnames';
import t from '../../../App/Libs/Translate/Translate'



const ERRORS = {
    required: `${t('errors.required')}`,
}

type Inputs = {
    medikament: string,
    menge: string,
    wieOft: string,
};

interface Props {
    drug?: string
    crowd?: string
    how_often?: string
    per_day?: string
    reason?: string
}


const MedicamentForm: React.FC<Props> = (props) => {
    const {
        register,
        formState: { errors },
    } = useForm<Inputs>();

    return (
        <div>
            <div className={styles.relative}>
                <Input
                    placeholder={props.drug}
                    {...register("medikament", {
                    required: true,
                    })}
                    error={errors.medikament && ERRORS.required}
                />
            </div>                        
            <div className={styles.flex}>
                <div className={classnames(styles.relative, styles.inputWidth43, styles.mr40, styles.m0)}>
                    <Input
                        placeholder={props.crowd}
                        {...register("menge", {
                        required: true,
                        })}
                        error={errors.menge && ERRORS.required}
                    />
                    <div className={styles.sufix}>mg</div>
                </div>
                <div className={classnames(styles.relative, styles.inputWidth43, styles.m0)}>
                    <Input
                        placeholder={props.how_often}
                        {...register("wieOft", {
                        required: true,
                        })}
                        error={errors.wieOft && ERRORS.required}
                    />
                    <div className={styles.sufix}>{props.per_day}</div>
                </div>
            </div>
            <div className={styles.block}>
                <p className={styles.labelOptional}>{props.reason}</p>
                <Textarea></Textarea>
            </div>
        </div>
    );
};

export default MedicamentForm;

