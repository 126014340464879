
import React from 'react';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import styles from './AlreadyRegisteredPage.module.scss';
import Product from "../../Organisms/Product/Product";
import {RouteComponentProps} from "react-router-dom";
import Selector from '../../Molecules/Selector/Selector';
import t from '../../../App/Libs/Translate/Translate';

const products = [
    {
        id: '1',
        title: t('register.already_registered.question_have_account'),
        description: t('register.already_registered.have_account_desc'),
        path: ''
    },
    {
        id: '2',
        title: t('register.already_registered.create_new_account'),
        description: t('register.already_registered.create_new_account_desc'),
        path: '/onboarding/Success'
    },
]

const AlreadyRegisteredPage = (props: RouteComponentProps) => {

    function onProductClick(path: string) {
        props.history.push(path)
    }

    return (
        <Page>
            <OnboardingLayout
                hideStepper={true}
                contentMaxWidth='1600px'
            >
                <div className={styles.content}>
                    <h1 className={styles.title}>{t('register.already_registered.title')}</h1>
                    <div className={styles.productContainer}>
                        {products.map((product) => {
                            return (
                                <Selector
                                className={styles.product}
                                onClick={() => onProductClick(product.path)}>
                                    <h6 className={styles.selectorTitle}>{product.title}</h6>
                                    <p className={styles.selectorDescription}>{product.description}</p>
                                </Selector>
                            )
                        })}
                    </div>
                </div>
            </OnboardingLayout>
        </Page>
    );
};

export default AlreadyRegisteredPage;
