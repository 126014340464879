import React from 'react';
import { observer } from 'mobx-react';
import styles from './LangSelect.module.scss';
import Select from "../Select";
import arrowIcon from "../../../../Assets/Images/Dropwdown_arrow.svg";
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';

const languages = [
  {id: "de", value: "DE"},
  {id: "en", value: "EN"},
  {id: "fr", value: "FR"}
];

const LangSelect = observer(() => {
  var { Common } = useStore()

  const handleOnChange = (value) => {
    Common.setLang(value)
    window.location.reload()
  }

  return (
      <Select className={styles.langSelect}
              itemsList={languages}
              placeholder={languages.find(item => item.id === Common.lang).value}
              customToggle={CustomToggle}
              onChange={handleOnChange}
      />
  )
});


const CustomToggle = React.forwardRef<any, customToggleProps>(({children, onClick}, ref) => (
  <span
    ref={ref}
    className={styles.selector}
    onClick={e => onClick(e)}
  >
        {children}
    <div className={styles.arrowContainer}>
            <img src={arrowIcon} alt=""/>
        </div>
    </span>

));

type customToggleProps = {
  onClick?: any
  children?: React.ReactNode
}


export default LangSelect;
