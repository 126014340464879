import React, {useEffect, useRef} from 'react';
import Page from '../Molecules/Page/Page';
import ValuePanel from '../Molecules/Panel/ValuePanel';
import PersonalConsultationForm from '../Organisms/Consultancy/PersonalConsultationForm';
import PersonalConsultationInfo from '../Organisms/Consultancy/PersonalConsultationInfo';
import OnboardingLayout from '../Organisms/Layout/OnboardingLayout';
import FooterNavBar from '../Molecules/Navbar/FooterNavbar';
import styles from './AccountAssetsValueTemplate.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import WebSync from '../Molecules/DataSync/WebSync';
import t from '../../App/Libs/Translate/Translate'
import {GhostButton} from "../Atoms/Button/GhostButton";


type AccountAssetsValueTemplateProps = {
  onSubmit: () => void
  onDigitalFlowClicked: () => void
  onValueChange: (value: number) => void
  isConsultationProposed: () => boolean
  linkToBack: string
  linkToPersonalConsultation: string
  showSpinner: boolean
}


const AccountAssetsValuePage = (props : RouteComponentProps & AccountAssetsValueTemplateProps) => {

  const webRef = useRef<WebSync>()

  useEffect(() => {
    if (props.showSpinner) {
      webRef.current.Activity.show()
    } else {
      webRef.current.Activity.hide()
    }
  }, [props.showSpinner]);



  function renderFooter() {
    return (
      <FooterNavBar
        linkToBack={props.linkToBack}
        buttonBackText={t('common.back')}
        onNextClick={props.onSubmit}
        buttonNextText={props.isConsultationProposed() ? t('account.assets.value.arrange_consultation') : t('account.assets.value.go_to_investment_horizon')}
        extraButton={props.isConsultationProposed() ? <GhostButton className={styles.whiteButton} onClick={() => props.onDigitalFlowClicked()}>{t('account.assets.value.continue_digitally')}</GhostButton> : null}
      />
    )
  }

  return (
    <Page>
      <WebSync ref={webRef}>
        <OnboardingLayout
          selectedStepIndex={1}
          progress={0.5}
          contentMaxWidth={'1600px'}
          footer={renderFooter()}
        >
          <div className={styles.mainText}>
            <div>
              <h4 className={styles.title}>{t('account.assets.value.title')}</h4>
              <h5 className={styles.secondaryText}>{t('account.assets.value.subtitle')}</h5>
            </div>

            <div className={styles.valuePanelWrapper}>
              <ValuePanel
                label={'CHF'}
                placeholder={100000}
                step={10000}
                onChange={value => props.onValueChange(value)}
              />
            </div>
          </div>

          {props.isConsultationProposed() && (
            <>
              <PersonalConsultationInfo></PersonalConsultationInfo>

              <div className={styles.formWrapper}>
                <PersonalConsultationForm to={props.linkToPersonalConsultation} />
              </div>

              <div className={styles.continueBlock}>
                <div className={styles.continueBlockInner}>
                  <h6>{t('account.assets.value.do_not_want_personal_consultation_continue_on_digital_path')}</h6>
                  <GhostButton className={styles.whiteButton} onClick={() => props.onDigitalFlowClicked()}>{t('account.assets.value.continue_digitally')}</GhostButton>
                </div>
              </div>
            </>
          )}
        </OnboardingLayout>
      </WebSync>
    </Page>
  );
};

export default AccountAssetsValuePage;
