import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import Selector from '../../../Molecules/Selector/Selector';
import { RouteComponentProps, useParams } from 'react-router-dom';

import styles from './RegisterPortfolioCreated.module.scss'
import SuccessPageLayout from '../../../Organisms/Layout/SuccessPageLayout';
import t from '../../../../App/Libs/Translate/Translate'

import React, { useRef } from 'react';
import WebSync from '../../../Molecules/DataSync/WebSync';
import { PortfolioActions } from '../../../../App/Mobx/Actions/PortfolioActions';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import { toJS } from 'mobx';
import { ProposalParams } from '../../../../App/Models/Params/ProposalParams';
import { VirtualPortfolioDto } from '../../../../App/Models/Contracts/Portfolio/VirtualPortfolioDto';
import { ModelPortfolioDto } from '../../../../App/Models/Contracts/Strategy/ModelPortfolioDto';

const RegisterPortfolioCreated = (props: RouteComponentProps) => {

    const webSyncRef = useRef<WebSync>()

    const { Account } = useStore()
    const { proposalId } = useParams<ProposalParams>()

    const currentVirtualPortfolio = Account.virtualPortfolios.find(x => x.proposalId === parseInt(proposalId))
    const nextVirtualPortfolio = Account.virtualPortfolios.find(x => x.riskCategoryId == undefined)

    function createPorfolioSameStrategyStep3(proposalId: number, modelPortfolio: ModelPortfolioDto) {
        var fromVirtualPortfolio = Account.virtualPortfolios[0]
        PortfolioActions
            .PatchPortfolioProposal({
                proposalId: proposalId,
                modelPortfolioId: modelPortfolio.id,
                targetAmount: Account.virtualPortfolios[1].amount,
                evaluatedRiskId: fromVirtualPortfolio.evaluatedRiskId,
                questionnaire: fromVirtualPortfolio.questionnaire
            })
            .onSuccess(() => {
                nextVirtualPortfolio.investmentYears = currentVirtualPortfolio.investmentYears
                nextVirtualPortfolio.modelPortfolio = modelPortfolio
                props.history.push('/onboarding/Portfolio/SecondCreated');
                webSyncRef.current.Activity.hide()
            })
            .onFail(() => {
                webSyncRef.current.Activity.hide()
            })
    }

    function createPorfolioSameStrategyStep2(modelPortfolio: ModelPortfolioDto) {
        PortfolioActions
            .PostPortfolioProposal({
                productId: Account.virtualPortfolios[1].product.id,
                transferSource: Account.transferSource
            })
            .onSuccess(response => {
                Account.virtualPortfolios[1].proposalId = response.data.proposalId
                createPorfolioSameStrategyStep3(response.data.proposalId, modelPortfolio)
            })
            .onFail(() => webSyncRef.current.Activity.hide())
    }

    function createPorfolioSameStrategyStep1(portfolio: VirtualPortfolioDto) {
        webSyncRef.current.Activity.show()
        PortfolioActions
            .GetFilteredModelPortfolios({
                productId: portfolio.product.id,
                riskCategoryId: portfolio.riskCategoryId
            })
            .onSuccess(res =>{
                 createPorfolioSameStrategyStep2(res.data[0])
            })
            .onFail(() => webSyncRef.current.Activity.show())
    }

    function createProposalDifferentStrategy() {
        webSyncRef.current.Activity.show()
        if (nextVirtualPortfolio.proposalId)
            props.history.push(`/onboarding/${nextVirtualPortfolio.proposalId}/Survey/horizon`)
        else
            PortfolioActions
                .PostPortfolioProposal({
                    productId: nextVirtualPortfolio.product.id,
                    transferSource: Account.transferSource
                })
                .onSuccess(response => {
                    PortfolioActions
                        .PatchPortfolioProposal({
                            proposalId: response.data.proposalId,
                            targetAmount: nextVirtualPortfolio.amount
                        })
                        .onSuccess(() => {
                            nextVirtualPortfolio.proposalId = response.data.proposalId
                            props.history.push(`/onboarding/${nextVirtualPortfolio.proposalId}/Survey/horizon`);
                        })

                })
    }

    function onSelectorClick(boolean: boolean) {


    }

    function getVirtualPortfolioNumber(virtualPortfolio: VirtualPortfolioDto) {
        return Account.virtualPortfolios.findIndex(x => x === virtualPortfolio) + 1
    }

    function renderSuccessContent() {
        return (
            <>
                <div className={styles.selector}>
                    {Account.virtualPortfolios.filter(x => x.riskCategoryId != undefined).map(vp => {
                        return (
                            <Selector className={styles.selectorBlock} onClick={() => createPorfolioSameStrategyStep1(vp)}>
                                <span className={styles.choiceText}>
                                    {
                                        t('register.portfolio.created.adopt_risk_profile_and_strategy')
                                            .replace('1', getVirtualPortfolioNumber(vp))
                                    }
                                </span>
                            </Selector>
                        )
                    })}
                    <Selector className={styles.selectorBlock} onClick={() => createProposalDifferentStrategy()}>
                        <span className={styles.choiceText}>{t('register.portfolio.created.define_new_risk_profile_and_strategy')}</span>
                    </Selector>
                </div>
            </>
        )
    }

    return (
        <Page>
            <WebSync ref={webSyncRef}>
                <OnboardingLayout selectedStepIndex={3}
                    progress={0.5}
                    contentMaxWidth="1600px"
                >
                    <div className={styles.main}>
                        <div className={styles.content}>
                            <SuccessPageLayout
                                withoutBgColor={false}
                                title={
                                    t('register.portfolio.created.title')
                                        .replace('1', getVirtualPortfolioNumber(currentVirtualPortfolio))
                                        .replace('2', getVirtualPortfolioNumber(nextVirtualPortfolio))
                                }
                                content={renderSuccessContent()}
                            />
                        </div>
                    </div>
                </OnboardingLayout>
            </WebSync>
        </Page>
    );
};

export default RegisterPortfolioCreated;
