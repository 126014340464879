import axios from "axios"
import WebClient from "../../Libs/WebClient/WebClient";
import { WebClientResponse } from "../../Libs/WebClient/WebClientResponse";
import { GetQuestionnaireRequestDto } from "../../Models/Contracts/Compliance/GetQuestionnaireRequestDto";
import { GetThemeRequestDto } from "../../Models/Contracts/Compliance/GetThemeRequestDto";
import { ProductDto } from "../../Models/Contracts/Compliance/ProductDto";
import { QuestionnaireDto } from "../../Models/Contracts/Questionnaire/QuestionnaireDto";
import { ThemeDto } from "../../Models/Contracts/Strategy/ThemeDto";
import { GlobalStore } from "../Stores/MainStore";


export class ComplianceActions {

  static GetProducts(){
    return WebClient
      .Create()
      .Get<Array<ProductDto>>('compliance/products')
      .onSuccess((response) => {
        GlobalStore.Compliance.Products = response?.data.map(p =>{
          p.name = "relevate‑solution "+ p.name
          return p
        }).reverse()
      })
  }

  static GetQuestionnaire(model: GetQuestionnaireRequestDto) {
    return WebClient
      .Create()
      .Get('compliance/questionnaire', model)
      .onSuccess((response?: WebClientResponse<any>) => {
        var questionnaire: QuestionnaireDto = response?.data?.questions[0];
        GlobalStore.Questionnaire.questionnaire = questionnaire
      })
  }

  static PostQuestionnaire(questionnaire: QuestionnaireDto) {
    // questionnaire.name = null
    // questionnaire.description = null
    // questionnaire.groups.forEach(group =>{
    //   group.description = null
    //   group.name= null
    //   group.steps.forEach(step =>{
    //     step.description = null
    //     step.name = null
    //     step.questions.forEach(question =>{
    //       question.text = null
    //       question.type = null
    //       question.answers.forEach(a =>{
    //         a.text = null
    //         a.header = null
    //         a.description = null
    //       })
    //     })
    //   })
    // })

    return WebClient
      .Create()
      .Authorize(GlobalStore.Auth.ToAuthorization())
      .Post('contact/questionnaire', questionnaire)
  }

  static GetThemes(model: GetThemeRequestDto) {
    return WebClient
      .Create()
      .Get<Array<ThemeDto>>('compliance/themes', model)
      .onSuccess((response) => {
        GlobalStore.Compliance.Themes = response?.data
      })
  }
}