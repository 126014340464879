import WebClient from "../../Libs/WebClient/WebClient";
import { LanguageDto } from "../../Models/Contracts/Common/LanguageDto";
import { CountryDto } from "../../Models/Contracts/Compliance/CountryDto";
import { PostalCodeDto } from "../../Models/Contracts/Compliance/PostalCodeDto";
import { RiskCategoryDto } from "../../Models/Contracts/Strategy/RiskCategoryDto";
import { GlobalStore } from "../Stores/MainStore";

export default class CommonActions{
    static GetLanguages(){
        return WebClient
            .Create()
            .Get<Array<LanguageDto>>("common/languages")
            .onSuccess(response =>{
                GlobalStore.Common.Languages = response.data
            })
    }

    static GetCountries(){
        return WebClient
          .Create()
          .Get<Array<CountryDto>>('common/countries')
          .onSuccess(response => GlobalStore.Compliance.Countries = [
                response?.data.filter(x => x.countryCode.trim() === "CH")[0],
                ...response?.data.filter(x => x.countryCode.trim() !== "CH")
          ])
      }

      static GetPostalCode(postalCode: number){
        return WebClient
          .Create()
          .Get<Array<PostalCodeDto>>('common/postal',{ postalCode })
      }


      static GetRiskCategory(riskCategoryId: number){
        return WebClient
            .Create()
            .Get<RiskCategoryDto>('Common/riskCategory', {
                riskCategoryId
            })
      }

}
