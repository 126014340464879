import React from 'react';
import Page from '../../Molecules/Page/Page';
import DashboardLayout from '../../Organisms/Layout/DashboardLayout';
import DashboardAllMessages from '../../Organisms/DashboardMessages/DashboardAllMessages';
import styles from './Messages.module.scss';
import t from '../../../App/Libs/Translate/Translate';

const messages = [
  {
    id: "1",
    title: "Der Quartals Report steht zum Download bereit",
    date: "16.06.2023",
    downloadLink: "#",
    isNew: true,
  },
  {
    id: "2",
    title: "Ihre Risikoschutz-Prüfung ist abgeschlossen. Unterschreiben Sie angehängtes Dokument und sende sie es an uns zurück.",
    date: "02.06.2023",
    downloadLink: "#",
    isNew: true,
  },
  {
    id: "3",
    title: "Die Eröffnung Ihrer 3a-Lösung ist abgeschlossen …",
    date: "12.09.2022",
    downloadLink: "#",
    isNew: false,
  },
  {
    id: "4",
    title: "Die Strategie Ihrer Freizügigkeitslösung ist angepasst.",
    date: "08.08.2022",
    downloadLink: "#",
    isNew: false,
  },
  {
    id: "5",
    title: "Willkommen zu Ihrer Eröffnung des Freizügigkeitskonto. Anbei finden Sie Ihre Vertragsbestätigung.",
    date: "02.08.2022",
    downloadLink: "#",
    isNew: false,
  },
]

const   DashboardMessagesPage = () => {
    return (
        <Page>
            <DashboardLayout>
                <div className={styles.main}>
                    <h3 className={styles.title}>{t("dashbord.mainpage.messages")}</h3>

                    <DashboardAllMessages />
                </div>
            </DashboardLayout>
        </Page>
    );
};

export default DashboardMessagesPage;
