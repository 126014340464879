import React from 'react';
import Page from "../../Molecules/Page/Page";
import DashboardLayout from "../../Organisms/Layout/DashboardLayout";
import DashboardDocuments from "../../Organisms/DashboardDocuments/DashboardDocuments";
import DashboardContainer from "../../Atoms/DashboardContainer/DashboardContainer";

import { dataCurrently, dataReporting, dataContracts } from "./data";
import DocumentActions from '../../../App/Mobx/Actions/DocumentActions';
import t from '../../../App/Libs/Translate/Translate';

const DashboardDocumentsPage = () => {

  function load() {
    return DocumentActions
      .GetDocuments()
  }

  return (
    <Page>
      <DashboardLayout sync={() => load()}>
          <DashboardContainer title={t("dashbord.documents.title")}>
            <DashboardDocuments title={t("dashbord.documents.current")} data={dataCurrently} />
            <DashboardDocuments title={t("dashbord.documents.reporting")} data={dataReporting} />
            <DashboardDocuments title={t("dashbord.documents.contracts")} data={dataContracts} />
          </DashboardContainer>
      </DashboardLayout>
    </Page>
  );
};

export default DashboardDocumentsPage;
