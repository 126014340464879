import { RouteComponentProps } from "react-router-dom";
import DepositCard from "../../Molecules/DepositCard/DepositCard";
import Page from "../../Molecules/Page/Page";
import ProductCards from "../../Organisms/DashboardsProductCards/ProductCards";
import DashboardLayout from "../../Organisms/Layout/DashboardLayout";
import styles from "./OverviewDepots3aPage.module.scss";

import { useStore } from "../../../App/Mobx/Infrastructure/mobx-hook";
import { ProductEnum } from "../../../App/Models/Enums/ProductEnum";
import type { ChartDataType, ChartSchemaType, DepositType } from "../../Molecules/DepositCard/DepositCard";
import { AccountDto } from "../../../App/Models/Contracts/Account/AccountDto";
import { PortfolioAccountsDto } from "../../../App/Models/Contracts/Portfolio/PortfolioAccountsDto";
import PerformanceUtils from "../../../App/Libs/Helper/PerformanceUtils";
import t from '../../../App/Libs/Translate/Translate'

const OverviewDepotsPage = (props: RouteComponentProps) => {

  const UserChartDataMock: Array<ChartDataType> = getMockChartData();
  const UserChartSchemaMock: ChartSchemaType = getMockSchemaData(UserChartDataMock)
  const deposits: Array<DepositType> = createDepositsMock(UserChartSchemaMock)

  
  const { Dashboard } = useStore()
  const data = Dashboard.investmentOverview

  const onHandleChartClick = (portfolio: PortfolioAccountsDto) => {
    props.history.push(`/dashboard/depot/${portfolio.id}/3a/overview`);
  }

  return (
    <Page>
      <DashboardLayout>
        <div className={styles.container}>
          <div>
            <h4 className={styles.title}>3a-Depots</h4>
            <div className={styles.deposits}>
            {data?.portfolios.filter(x => x.product.id === ProductEnum.PK).map(deposit => (
                  <DepositCard key={deposit.id} info={{
                    id:deposit.id,
                    title: deposit.name,
                    currency: "CHF",
                    amount: deposit.investedAmount,
                    diff: PerformanceUtils.CalculatePerformanceValue(deposit.currentValue, deposit.currentPerformance), 
                    diffPercentage: deposit.currentPerformance,
                    type: t("dashbord.deposit.overview.3a_depot"),
                    chart: UserChartSchemaMock
                  }} onClick={() => onHandleChartClick(deposit)} />
                ))}
            </div>
          </div>
          <ProductCards className={styles.productsContainer}
                        portfolioLink="/onboarding/Open/Additional"
                        favorLink="/Dashboard/Beneficiary/Order/Add"/>
        </div>
      </DashboardLayout>
    </Page>
  )
}

function createDepositsMock(UserChartSchemaMock: ChartSchemaType): DepositType[] {
  return [
    {
      id: 2,
      title: "Portfolio 1",
      type: "Freizügigkeitsdepot",
      amount: 356560.80,
      currency: "CHF",
      diff: 24.38,
      diffPercentage: 2.48,
      chart: UserChartSchemaMock
    },
    {
      id: 3,
      title: "Portfolio 2",
      type: "Freizügigkeitsdepot",
      amount: 125560.35,
      currency: "CHF",
      diff: 24.38,
      diffPercentage: 2.48,
      chart: UserChartSchemaMock
    }
  ];
}

function getMockChartData(): ChartDataType[] {

  const min: number = 355000.00;
  const max: number = 360000.00;
  const yearAgo: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

  var chartData: Array<ChartDataType> = [];


  for (var i = 0; i < 365; i++) {
    let tempDate: Date = new Date(yearAgo.setDate(yearAgo.getDate() + 1));
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    let chartDataDay: ChartDataType = {
      id: i,
      timestamp: tempDate,
      yearBefore: tempDate.getFullYear() - 1,
      yearCurrent: tempDate.getFullYear(),
      month: tempDate.getMonth(),
      monthName: monthNames[tempDate.getMonth()],
      amount: Math.round((min + Math.random() * (max - min))),
      currency: "CHF"
    };

    chartData.push(chartDataDay);
  }

  return chartData;
}

function getMockSchemaData(UserChartDataMock: ChartDataType[]): ChartSchemaType {
  return {
    title: "yearBefore / yearCurrent",
    userID: "m.fleig123@qcentris.com",
    amountPositionsX: 12,
    depositeType: "Freizügigkeitsdepots",
    depositeName: "Protfolio 1",
    userData: UserChartDataMock,
    aspectRatio: 12
  };
}

export default OverviewDepotsPage;