import React from 'react';
import {PopUpProps} from '../../../Atoms/Popup/Popup';
import InfoPopup from '../../..//Molecules/Popup/InfoPopup';
import styles from './SplitPortfolioInfoPopup.module.scss';
import Link from "../../../Atoms/Link/Link";
import t from '../../../../App/Libs/Translate/Translate';

const SplitPortfolioInfoPopup = (props: PopUpProps) => {

  function onExit(obj: HTMLElement) {
    if (props.onExit)
      props.onExit(obj)
  }

  return (
    <InfoPopup {...props} frameClassName={styles.main} className={styles.popup}>
      <div className={styles.content}>
        <h2>{t('account.portfolio.split.detail_title')}</h2>
        <p className={styles.paragraph}>
          {t('account.portfolio.split.detail_desc_1')}
        </p>
        <p className={styles.paragraph}>
          {t('account.portfolio.split.detail_desc_2')}
        </p>
        <p className={styles.paragraph}>
          {t('account.portfolio.split.info')}
        </p>
        <Link className={styles.closeText} onClick={a => onExit(a)}>{t('common.conclude')}</Link>
      </div>
    </InfoPopup>
  );
};

export default SplitPortfolioInfoPopup;
