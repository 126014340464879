import React, { useState } from 'react';
import FooterNavBar from '../../../Molecules/Navbar/FooterNavbar';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import styles from './TransferBenefits.module.scss';
import Lists from '../../../../Assets/Data/Transfer3A.json'
import t from '../../../../App/Libs/Translate/Translate'
import {ContactActions} from "../../../../App/Mobx/Actions/ContactActions";
import {RouteComponentProps} from "react-router-dom";
import {useStore} from "../../../../App/Mobx/Infrastructure/mobx-hook";
import SplitLayout from "../../../Organisms/Layout/SplitLayout";
import InputSearchPanel, {additionalInfoType} from "../../../Molecules/InputSearchPanel/InputSearchPanel";

const TransferBenefits3a = (props: RouteComponentProps) => {

    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [showInput, setShowInput] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>()
    const [additionalInfo, setAdditionalInfo] = useState<additionalInfoType | null>(null)


    const {Auth} = useStore();

    function onSearchChange(e: HTMLInputElement) {
        setShowInput(false)
        setDisableButton(true)
    }

    function onSearchSelect(value: string) {
        if (value.toLowerCase() === "andere")
            setShowInput(true)
        else {
            setDisableButton(false)
            setShowInput(false)
        }

        setSearchText(value)
    }

    const sendTransferInstructions = () => {
        props.history.push("/onboarding/Contract/SuccessStartedTransfer");
    }

    const additionalInfoInput = (data : additionalInfoType | null) => {
        setDisableButton(false);
        if (data) {
            setAdditionalInfo(data);
        } else {
            setDisableButton(true)
        }
    }

    function renderFooter() {
        return <FooterNavBar
            buttonNextDisabled={disableButton}
            onNextClick={sendTransferInstructions}
            buttonBackText={t('common.back')}
            buttonNextText={t('common.trigger_transfer')} />
    }

    return (
      <Page>
          <OnboardingLayout
            footer={renderFooter()}
            contentMaxWidth='1600px'
            hideStepper={true}>

              <SplitLayout className={styles.splitView}>
                  <div className={styles.titleSection}>
                      <h1 className={styles.title}>{t('register.contract.transfer_benefits_3a.title')}</h1>
                      <p className={styles.subtitle}>{t('register.contract.transfer_benefits_3a.subtitle')}</p>
                  </div>
                  <InputSearchPanel onSearchSelect={onSearchSelect}
                                    onSearchChange={onSearchChange}
                                    lists={Lists}
                                    showInput={showInput}
                                    getAdditionalInfoInput={additionalInfoInput}
                  />

              </SplitLayout>




          </OnboardingLayout>
      </Page>
    );
};

export default TransferBenefits3a;
