import React, { useEffect, useState } from 'react';
import { Callback } from '../../../App/Models/Callback';
import { VirtualPortfolioDto } from '../../../App/Models/Contracts/Portfolio/VirtualPortfolioDto';
import styles from './PortfolioSplit.module.scss';
import PortfolioValuePanel from './PortfolioValuePanel';


type Props = {
    amount: number,
    isSplitEnabled: boolean
    portfolios: Array<PortfolioSplitDto>
    onChange: Callback<Array<VirtualPortfolioDto>>
}

type PortfolioSplitDto = {
    name: string,
    disabled: boolean
}

const PortfolioSplit: React.FC<Props> = (props) => {

    const [amounts, setAmounts] = useState<Array<number>>([])
    const [updateValue, setUpdateValue] = useState(0)

    if(amounts.length === 0)
        props.portfolios.forEach(( _, index) => {
            if (props.isSplitEnabled)
                amounts.push(props.amount / props.portfolios.length)
            else
                if (index === 0)
                    amounts.push(props.amount)
                else
                    amounts.push(0)
        })

    

   

    function calculateNewAmountPerPortfolio(index: number, value: number) {

        amounts[index] = value

        if (!props.isSplitEnabled && value !== props.amount)
            amounts[0] = props.amount


        if (props.isSplitEnabled) {
            var total = amounts.reduce((a, b) => a + b, 0)
            if (total > props.amount) {
                let difference = total - props.amount
                for (let i = amounts.length - 1; i >= 0; i--) {
                    if (i !== index) {
                        if (difference <= amounts[i]) {
                            amounts[i] -= difference
                            break;
                        }
                        else {
                            difference -= amounts[i]
                            amounts[i] = 0
                        }
                    }
                }
            }
            else if (total < props.amount) {
                let difference = props.amount - total
                for (let i = 0; i < amounts.length; i++) {
                    if (i !== index) {
                        amounts[i] += difference
                    }
                }
            }
        }
        setAmounts(amounts)

    }

    function onValueChange(index: number, value: number) {
        calculateNewAmountPerPortfolio(index, value)
        props.onChange && props.onChange(props.portfolios.map((p, index) => {
            return {
                name: p.name,
                amount: amounts[index],
            }
        }))
        setUpdateValue(updateValue + 1)
    }

    useEffect(() =>{
        calculateNewAmountPerPortfolio(0, amounts[0])
        if (props.isSplitEnabled) {
            onValueChange(0, amounts[0]/2)
        }
        else {
            onValueChange(0, amounts[0])
        }
        setTimeout(() =>{
        }, 0)
    }, [props.isSplitEnabled])

    return (
        <div className={styles.portfolios}>
            {
                props.portfolios.map((p, index) =>
                    <PortfolioValuePanel
                      key={index}
                      isSplitEnabled={props.isSplitEnabled}
                      index={index}
                      max={props.amount}
                      value={amounts[index]}
                      count={props.portfolios.length}
                      name={p.name} disabled={p.disabled && !props.isSplitEnabled}
                      onChange={val => onValueChange(index, val)} />)
            }
        </div>
    );
};

export default PortfolioSplit;