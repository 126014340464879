import React, { useEffect, useRef, useState } from 'react';
import Panel from "../../Atoms/Panel/Panel";
import styles from "./TaxBenefitsCalculator.module.scss";
import Select from "../../Atoms/Select/Select";
import PortfolioValueProgress from "../Portfolio/PortfolioValueProgress";
import classNames from "classnames";
import t from '../../../App/Libs/Translate/Translate';
import Formatter from '../../../App/Libs/Formatter/Formatter';
import { MaritalStatusEnum } from '../../../App/Models/Enums/MartialStatusEnum';
import { TaxActions } from '../../../App/Mobx/Actions/TaxActions';
import { PortfolioAmountDto } from '../../../App/Models/Contracts/Portfolio/PortfolioAmountDto';
import { TaxInfoResultDto } from '../../../App/Models/Contracts/Taxes/TaxInfoResultDto';
import { CancelTokenSource } from 'axios';
import WebSync from '../../Molecules/DataSync/WebSync';

const marriageStatus = [
  { id: MaritalStatusEnum.Ledig.toString(), value: t('account.portfolio.split.civil_statuses.single') },
  { id: MaritalStatusEnum.Verheiratet.toString(), value: t('account.portfolio.split.civil_statuses.married') },
  { id: MaritalStatusEnum.EingetragenePartnerschaft.toString(), value: t('account.portfolio.split.civil_statuses.registered') }
]

const religion = [
  { id: "3", value: "römisch-katholisch" },
  { id: "2", value: "evangelisch-reformiert" },
  { id: "4", value: "christkatholisch" },
  // { id: "4", value: "muslimisch" },
  { id: "5", value: "jüdisch" },
  { id: "6", value: "ohne" },
  { id: "1", value: "andere" },
]

type Props = PropTypes & {
  amounts: Array<number>
}

var abortContoller: AbortController = undefined


const TaxBenefitsCalculator = (props: Props) => {

  const [maritalStatus, setMaritalStatus] = useState<number>()
  const [denomination, setDenomination] = useState<number>()
  const [taxInfo, setTaxInfo] = useState<Array<TaxInfoResultDto>>()


  const webSyncRef = useRef<WebSync>()

  useEffect(() =>{
    getTaxInformation()
  }, [props.amounts, denomination, maritalStatus])

  const getTaxInformation = () => {
    if (!denomination ||
      !maritalStatus ||
      !props.amounts || props.amounts.length === 0)
      return
    abortContoller?.abort()
    webSyncRef.current.Activity.show()
    TaxActions
      .GetInformation({
        denomination: denomination,
        maritalStatus: maritalStatus,
        portfolioAmount: [{
          capital: getTotal()
        },
        ...props.amounts.map<PortfolioAmountDto>(x => {
          return {
            capital: x
          }
        })
        ]
      })
      .abortController(x => {abortContoller = x})
      .onSuccess(response => {
        webSyncRef.current.Activity.hide()
        setTaxInfo(response.data)
      })
      .onFail(x => webSyncRef.current.Activity.hide())
  }

  const getTotal = () => {
    var value = props.amounts?.reduce((a, b) => a + b, 0) ?? 0
    return value
  }


  function onDenominationChange(value: string) {
    setDenomination(parseInt(value))
  }

  function onMaritalStatusChange(value: string) {
    setMaritalStatus(parseInt(value))
  }

  function getTaxSaving() {
    if (!taxInfo || taxInfo.length < 2)
      return 0
    var total = taxInfo[0].taxTotal
    var totalWithSplit = taxInfo.reduce((a, b) => a + b.taxTotal, -total)

    return total - totalWithSplit
  }

  function getTaxSavingRate() {
    if (!taxInfo || taxInfo.length < 1)
      return 0
    var total = taxInfo[0].taxTotal
    var totalAfterSaving = getTaxSaving()

    return totalAfterSaving / total
  }


  return (
    <Panel className={classNames(styles.tax, props.className)}>
      <section className={styles.optionsSection}>
        <h2 className={styles.title}>{t('account.portfolio.split.calculate_tax_benefits')}</h2>
        <div className={styles.selectContainer}>
          <Select className={styles.select} itemsList={marriageStatus} onChange={x => onMaritalStatusChange(x)} placeholder={t('account.portfolio.split.civil_status')} />
          <Select className={styles.select} itemsList={religion} onChange={x => onDenominationChange(x)} placeholder={t('account.portfolio.split.confession')} />
        </div>

      </section>
      <hr />
      <section className={styles.calculationSection}>
        <div className={styles.withdrawTaxContainer}>
          <h4 className={styles.withdrawTitle}>Steuerbetrag für den Bezug des gesamten Kapitals</h4>
          <div className={styles.withdrawTax}>CHF {Formatter.formatNumber(getTotal())}.00</div>
        </div>
        {props.amounts?.map((value, index) => {
          return (
            <div className={styles.withdrawTaxContainer}>
              <h4 className={styles.withdrawTitle}>Steuerbetrag für den Bezug des {index + 1}. Depots (CHF 150.00)</h4>
              <div className={styles.withdrawTax}>CHF {Formatter.formatNumber(value)}.00</div>
            </div>
          )
        })}
      </section>
      <WebSync ref={webSyncRef}>
        <section className={styles.diagramSection}>
          <div className={styles.taxSavings}>
            <h4 className={styles.taxSavingsTitle}>{t('account.portfolio.split.tax_savings')}</h4>
            <div className={styles.taxSavingsAmount}>CHF {Formatter.formatNumber(parseFloat(getTaxSaving().toFixed(2)))}</div>
          </div>
          <div className={styles.diagram}>
            <div className={styles.diagramContainer}>
              <PortfolioValueProgress index={1} count={2} progress={getTaxSavingRate()} cutoutRadiusChart={173} />
              <div className={styles.savingsPercent}>{(getTaxSavingRate() * 100).toFixed(1)}%</div>
            </div>
          </div>
        </section>
      </WebSync>
    </Panel>
  )
}



type PropTypes = {
  className?: string
}


export default TaxBenefitsCalculator;
