import React  from "react";
import ChatIcon from "../Menu/icons/ChatIcon";
import styles from "./ProfileSupport.module.scss";
import { Button } from '../../Atoms/Button/Button';
import classNames from "classnames";
import t from '../../../App/Libs/Translate/Translate';

type ProfileSupportType = {
  title: string
  className? : string
}

const ProfileSupport = (props: ProfileSupportType) => {

  return (
    <div className={classNames(styles.profileContainer, props.className)}>
      <ChatIcon className={styles.profileChatIcon}></ChatIcon>
      <div className={styles.profileTitle}>{props.title}</div>
      <div className={styles.profileEdit}>
        <Button
          className={styles.button}
          to="/Dashboard/Faq"
        >
          {t("dashboard.faq.to_helpcenter")}
        </Button>
      </div>
    </div>
  );
};

export default ProfileSupport;
