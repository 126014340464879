import styles from './DashboardHeader.module.scss';
import {Button} from "../../Atoms/Button/Button";
import dartsIcon from "../../../Assets/Images/Dashboard/darts.svg";
import Dropdown from "../../Atoms/Dropdown/Dropdown";
import editIcon from "../../../Assets/Images/Edit/Pencil.svg";
import trashIcon from "../../../Assets/Images/Edit/Trash.svg";
import React from "react";
import detailsIcon from "../../../Assets/Images/PortfolioDetailsBtn.svg";
import payIcon from "../../../Assets/Images/Dashboard/pay.svg";
import moreIcon from "../../../Assets/Images/Dashboard/transfer.svg";
import classNames from "classnames";
import t from '../../../App/Libs/Translate/Translate'

type DashboardHeaderProps = {
  title: string;
  subtitle: string;
  onDepositClick?: () => void;
  onTransferClick?: () => void;
  onChangeStrategyClick?: () => void;
  onStrategyDetailsClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onButtonEdit?: () => void;
  onButtonDelete?: () => void;
  children?: React.ReactNode;

}

type customToggleProps = {
  onClick?: any
  children?: React.ReactNode
}

const DashboardHeader = (props: DashboardHeaderProps) => {

  const CustomToggle = React.forwardRef<any, customToggleProps>(({children, onClick}, ref) => (
    <span
      ref={ref}
      className={classNames(styles.button, styles.mg_bottom_0)}
      onClick={e => onClick(e)}
    >
        {children}
      <span className={styles.buttonIcon}><img src={detailsIcon} alt={"dots icon"}/></span>
      <div>{t('dashboard.common_actions.edit')}</div>
    </span>

  ));

  return (
    <div className={styles.header}>
      <div className={styles.infoContainer}>
        <h3 className={styles.title}>{props.title}</h3>
        <div className={styles.subtitle}>{props.subtitle}</div>
        {props.children}
      </div>
      <div className={styles.buttonsContainer}>

        {props.onDepositClick &&
          <Button className={styles.button} onClick={props.onDepositClick}>
            <span className={styles.buttonIcon}><img src={payIcon} alt={"piggy bank icon"}/></span>
            <div>{t('dashboard.common_actions.deposit')}</div>
          </Button>}

        {props.onTransferClick &&
          <Button className={styles.button} onClick={props.onTransferClick}>
            <span className={styles.buttonIcon}><img src={moreIcon} alt={"arrows icon"}/></span>
            <div>{t('dashboard.common_actions.transfer')}</div>
          </Button>}

        {props.onChangeStrategyClick &&
          <Button className={styles.button} onClick={props.onChangeStrategyClick}>
            <span className={styles.buttonIcon}><img src={dartsIcon} alt={"darts icon"}/></span>
            <div>{t('dashboard.common_actions.change_strategy')}</div>
          </Button>}
        {props.onStrategyDetailsClick &&
          <Button className={styles.button} onClick={props.onStrategyDetailsClick}>
            <span className={styles.buttonIcon}><img src={dartsIcon} alt={"darts icon"}/></span>
            <div>{t('register.contract.signing_contract.strategy')}</div>
          </Button>}

        {props.onButtonEdit &&
          <Button className={styles.button} onClick={props.onButtonEdit}>
            <span className={styles.buttonIcon}><img src={editIcon} alt={"pen icon"}/></span>
            <div>{t('dashboard.common_actions.change_risk_protection')}</div>
          </Button>}

        {props.onButtonDelete &&
          <Button className={styles.button} onClick={props.onButtonDelete}>
            <span className={styles.buttonIcon}><img src={trashIcon} alt={"trash icon"}/></span>
            <div>{t('dashboard.common_actions.dissolve_risk_protection')}</div>
          </Button>}

        {props.onEdit && props.onDelete &&
          <Dropdown customToggle={CustomToggle}>
            <div className={styles.detailsMenu}>
              <div
                className={styles.detailsMenuLink}
                onClick={() => props.onEdit()}>
                {t('dashboard.common_actions.change_name')} <img src={editIcon} alt="pen icon"/>
              </div>
              <div
                className={styles.detailsMenuLink}
                onClick={() => props.onDelete()}>
                {t('dashboard.common_actions.balance_deposit')} <img src={trashIcon} alt="trash icon"/>
              </div>
            </div>
          </Dropdown>}
      </div>
    </div>
  );
}

export default DashboardHeader;