import React from 'react';
import Page from '../../Molecules/Page/Page';
import DashboardLayout from '../../Organisms/Layout/DashboardLayout';
import DepositCard from '../../Molecules/DepositCard/DepositCard';
import DevelopmentOfAssets from '../../Molecules/DevelopmentOfAssets/DevelopmentOfAssets';
import ArrowUpIcon from '../../../Assets/Images/arrow-up.svg'
import styles from './OverviewPortfolioPage.module.scss';
import Formatter from '../../../App/Libs/Formatter/Formatter';
import classnames from 'classnames';
import { useForm } from "react-hook-form";
import t from '../../../App/Libs/Translate/Translate'
import InfoPanel from '../../Atoms/Panel/InfoPanel';
import dartsLargeIcon from "../../../Assets/Images/Dashboard/darts-large.svg"
import ProductCards from '../../Organisms/DashboardsProductCards/ProductCards';


type DepositType = {
  id: number;
  title: string;
  type: string;
  amount: number;
  currency: string;
  diff: number;
  diffPercentage: number;
  chart: ChartSchema;
} 

type ChartSchema = {
  title: string;
  userID: string;
  amountPositionsX: number;
  depositeType: string;
  depositeName:string;
  userData: Array<ChartData>;
  aspectRatio: number;
}

type ChartData = {
  id: number;
  timestamp: Date;
  yearBefore:number;
  yearCurrent: number;
  month: number;
  monthName: string;
  currency: string;
  amount: number;
}

type Inputs = {
  height: string,
  weight: string,
  ort: string,
  surname: string,
  firstname: string,
  practice: string,
  adress: string,
  plz: string,
  question_2: string,
  question_3: string,
  question_4: string,
  question_5: string,
  question_6: string,
};



const OverviewPortfolioPage = () => {

  const UserChartDataMock: Array<ChartData> = getMockChartData();
  const UserChartSchemaMock: ChartSchema = getMockSchemaData(UserChartDataMock)

  const deposits3a: Array<DepositType> = createDeposits3aMock(UserChartSchemaMock)

  const onHandleClick = () =>  {
  }

  const {
      register,
      handleSubmit,
      formState: { errors },
  } = useForm<Inputs>();

    return (
        <Page>
            <DashboardLayout>
              <div className={styles.main}>
                  <div className={styles.submain}>
                      <span className={styles.score}>{t('account.portfolio.depot.title')}</span>
                      <span className={styles.type}>Portfolio 1</span>

                    <div className={styles.amountWrapper}>
                        <span className={styles.amount}>{Formatter.formatNumber(356560.80)}</span>
                        <span className={styles.currency}>CHF</span>
                    </div>

                    <div className={styles.difference}>
                      <img src={ArrowUpIcon} alt="arrow up icon" />
                      <span>CHF</span>
                      <span className={styles.differenceValue}>24.38</span>
                      <span>2.48 %</span>
                    </div>

                    <div className={styles.iconSection}>
                      <div className={styles.iconContainer}>
                        <div className={classnames(styles.icon, styles.iconDarts)}></div>
                        <div>{t('account.portfolio.depot.strategy.title')}</div>
                      </div>
                      <div className={styles.iconContainer}>
                        <div className={classnames(styles.icon, styles.iconPoint)}></div>
                        <div>{t('account.portfolio.depot.strategy.subtitle')}</div>
                      </div>
                    </div>
                  </div>
              </div>
                <div className={styles.section}>
                    <div className={styles.deposits}>
                        {deposits3a.map(deposit3a => (
                            <DevelopmentOfAssets key={deposit3a.id} {...deposit3a} />
                        ))}
                    </div>
                </div>
              <div className={styles.main}>
                    <InfoPanel icon={dartsLargeIcon} className={styles.strategyInfo} onClick={onHandleClick}>
                      <div className={styles.sectionStrategy}>
                        <h3 className={styles.titleStrategy}>{t("account.portfolio.depot.strategy.container.title")}</h3>
                        <h4 className={styles.subtitle}>{t("account.portfolio.depot.strategy.container.subtitle")}</h4>
                        <p className={styles.text}>{t('account.portfolio.depot.strategy.container.text')}</p>
                        <p className={styles.text}>{t('account.portfolio.depot.strategy.container.text2')}</p>
                      </div>
                    </InfoPanel>
                    <div className={styles.section}>
                        <h3 className={styles.title}>{t('account.portfolio.depot.strategy.deposits.title')}</h3>
                        <div className={styles.deposits}>
                            {/* {deposits3a.map(deposit3a => (
                                // <DepositCard key={deposit3a.id} {...deposit3a} />
                            ))} */}
                        </div>
                    </div>  

                    <div className={styles.section}>
                        <h3 className={styles.subtitle}>{t('account.portfolio.depot.strategy.deposits.subtitle')}</h3>

                        <div className={styles.productsList}>
                          <ProductCards portfolioLink="/" riskProtectionLink="/" favorLink="/" className={styles.productCards}></ProductCards>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        </Page>
    );
};

function createDepositsMock(UserChartSchemaMock: ChartSchema): DepositType[] {
  return [
    {
      id: 1,
      title: "Portfolio 1",
      type: "Freizügigkeitsdepot",
      amount: 356560.80,
      currency: "CHF",
      diff: 24.38,
      diffPercentage: 2.48,
      chart: UserChartSchemaMock
    },
    {
      id: 2,
      title: "Portfolio 1",
      type: "Freizügigkeitsdepot",
      amount: 356560.80,
      currency: "CHF",
      diff: 24.38,
      diffPercentage: 2.48,
      chart: UserChartSchemaMock
    },
    {
      id: 3,
      title: "Portfolio 1",
      type: "Freizügigkeitsdepot",
      amount: 356560.80,
      currency: "CHF",
      diff: 24.38,
      diffPercentage: 2.48,
      chart: UserChartSchemaMock
    }
  ];
}

function createDeposits3aMock(UserChartSchemaMock: ChartSchema): DepositType[] {
  return [{
    id: 2,
    title: `${t('account.portfolio.depot.strategy.products.list.portfolio')}`,
    type: `${t('account.portfolio.depot.title')}`,
    amount: 356560.80,
    currency: "CHF",
    diff: 24.38,
    diffPercentage: 2.48,
    chart: UserChartSchemaMock
  }];
}

function getMockChartData(): ChartData[] {

  const min: number = 355000.00;
  const max: number = 360000.00;
  const yearAgo: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

  var chartData: Array<ChartData> = [];


  for(var i = 0; i < 365; i++ ){
    let tempDate: Date =  new Date(yearAgo.setDate(yearAgo.getDate() + 1));
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    let chartDataDay: ChartData = {
      id: i,
      timestamp: tempDate,
      yearBefore: tempDate.getFullYear() - 1,
      yearCurrent: tempDate.getFullYear(),
      month: tempDate.getMonth(),
      monthName: monthNames[tempDate.getMonth()],
      amount: Math.round((min + Math.random() * (max - min))),
      currency: "CHF"
    };

    chartData.push(chartDataDay);
  }

  return chartData;
}

function getMockSchemaData(UserChartDataMock: ChartData[]): ChartSchema {
  return {
      title: "yearBefore / yearCurrent",
      userID: "m.fleig123@qcentris.com",
      amountPositionsX: 12,
      depositeType: "Freizügigkeitsdepots",
      depositeName: "Protfolio 1",
      userData: UserChartDataMock,
      aspectRatio: 12
  };
}

function setChartOptions(aspectRatio: number) {
  return {
      bezierCurve: true,
      layout: {
          padding: {
              left: -15,
              right: -100,
              bottom: -100
          }
      },
      responsive: true,
      maintainSpectRatio: false,
      aspectRatio: aspectRatio,
      elements: {
          point: {
              radius: 0
          }
      },
      scales: {
          y: {
              beginAtZero: false,
              ticks: {
                  display: false,
                  padding: -20
              },
              grid: {
                  display: false
              }
          },
          x: {
              beginAtZero: false,
              ticks: {
                  display: false,
                  min: 0
              },
              grid: {
                  display: false
              }
          }
      },
      legend: {
          display: false
      },
      plugins: {
          legend: {
              display: false,
          },
          tooltips: {
              callbacks: {
                  label: function (tooltipItem) {
                      return tooltipItem.yLabel;
                  }
              }
          }
      }
  };
}



export default OverviewPortfolioPage;
