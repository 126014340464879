import styles from "./InputSearchPanel.module.scss"
import Panel from "../../Atoms/Panel/Panel";
import t from "../../../App/Libs/Translate/Translate";
import InputSearch from "../../Atoms/Input/InputSearch";
import Input from "../../Atoms/Input/Input";
import React from "react";
import {useForm} from "react-hook-form";


export type additionalInfoType = {
  name: string,
  address: string,
  additional_address: string,
  postIndex: string,
  location: string,
}

type InputSearchPanelProps = {
  onSearchSelect: (value: string) => void;
  onSearchChange: (e: HTMLInputElement) => void;
  showInput?: boolean
  getAdditionalInfoInput?: (data: additionalInfoType) => void;
  lists: Array<string>
}


const InputSearchPanel = (props: InputSearchPanelProps) => {

  const { register, handleSubmit } = useForm<additionalInfoType>();

  const onValid = (data: additionalInfoType) => {
    props.getAdditionalInfoInput({
      name: data.name,
      address: data.address,
      additional_address: data.additional_address,
      postIndex: data.postIndex,
      location: data.location
    });
  }

  const onInvalid = () => {
    props.getAdditionalInfoInput(null);
  }

  return (
    <Panel className={styles.main}>
      <div className={styles.content}>

        <div>
          <label className={styles.searchLabel}>
            <span>{t('common.current_foundation')}</span>
            <InputSearch
              className={styles.w100}
              dataLists={props.lists}
              onChange={e => props.onSearchChange(e)}
              onSelect={v => props.onSearchSelect(v)}
            />
          </label>
        </div>

        {props.showInput &&
          <form className={styles.additionalInfo}
                onBlur={handleSubmit(onValid, onInvalid)}>
          <label className={styles.label}>
            <Input
              placeholder={t('register.contract.transfer_benefits.name_current_foundation')}

              className={styles.w100}
              {...register("name", {
                required: props.showInput,
              })}
            />
          </label>
          <label className={styles.label}>
            <Input
              placeholder={t('common.address')}
              className={styles.w100}
              {...register("address", {
                required: props.showInput,
              })}
            />
          </label>
          <label className={styles.label}>
            <Input
              placeholder={t('common.address_supplement')}
              className={styles.w100}
              {...register("additional_address", {
                required: props.showInput,
              })}
            />
          </label>
          <div className={styles.labelsRow}>
            <label className={styles.label}>
              <Input
                placeholder={t('common.postcode')}
                className={styles.w100}
                {...register("postIndex", {
                  required: props.showInput,
                })}
              />
            </label>
            <label className={styles.label}>
              <Input
                placeholder={t('common.location')}
                className={styles.w100}
                {...register("location", {
                  required: props.showInput,
                })}
              />
            </label>
          </div>
        </form>
        }

      </div>
    </Panel>
  )
}

export default InputSearchPanel