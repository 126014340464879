import classNames from 'classnames';
import { default as React, InputHTMLAttributes, useState } from 'react';
import styles from './Checkbox.module.scss';



type CheckboxStringsProps = {
    name?: string,
    id?: string,
    className?: string
    children?: React.ReactNode
}

type CheckboxProps = CheckboxStringsProps & React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>


const Checkbox: React.FC<CheckboxProps> = (props) => {

    const [isChecked, setIsChecked] = useState<boolean>(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { checked } = e.target

        setIsChecked(checked)

        if (props.onChange)
            props.onChange(e)

    }

    return (
        <label className={classNames(styles.label, props.className)}>
            <input {...props}
                type="checkbox"
                name={props.name}
                id={props.id}
                defaultChecked={isChecked}
                children={null}
                onChange={e => handleChange(e)} />
            <span>{props.children}</span>
        </label>
    );
};

export default Checkbox;