import classNames from 'classnames';
import React from 'react';
import { Callback } from '../../../App/Models/Callback';
import arrowImg from '../../../Assets/Images/arrow-right.svg';
import selectorShapeImg from '../../../Assets/Images/selector-shape.svg'
import Panel from '../../Atoms/Panel/Panel';
import styles from './Selector.module.scss';


export type SelectorProps = {
    className?: string
    onClick?: Callback<HTMLElement>
    children?: React.ReactNode
}

const Selector: React.FC<SelectorProps> = (props) => {
    return (
        <Panel className={classNames(styles.main, props.className)}
            onClick={e => props.onClick && props.onClick(e)}>
            <div className={styles.selectorIconContainer}>
                <img src={selectorShapeImg} alt="" />
            </div>
            <div className={styles.content}>
                {props.children}
            </div>
            <div className={styles.arrowContainer}>
                <img src={arrowImg} alt="" />
            </div>
        </Panel>
    );
};

export default Selector;
