import React, { useRef, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import StrategyChangePopup from '../../Organisms/Popup/StrategyChangePopup';
import StrategyCarousel from '../../Organisms/Risk/StrategyCarousel';
import styles from './AccountChooseStrategyPage.module.scss';
import { ComponentDidMount, ComponentWillUnmount } from '../../../App/Libs/Helper/Helper';
import { ContactActions } from '../../../App/Mobx/Actions/ContactActions';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { observer } from 'mobx-react';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { ModelPortfolioDto } from '../../../App/Models/Contracts/Strategy/ModelPortfolioDto';
import WebSync from '../../Molecules/DataSync/WebSync';
import t from '../../../App/Libs/Translate/Translate'
import { ProposalParams } from '../../../App/Models/Params/ProposalParams';

const AccountChooseStrategyPage: React.FC<RouteComponentProps> = observer((props) => {
    const [showWarning, setShowWarning] = useState<boolean>(false)
    const [selectedModelPortfolio, setSelectedModelPortfolio] = useState<ModelPortfolioDto>();


    const { Account, Contact } = useStore()
    
    const { proposalId } = useParams<ProposalParams>()

    const webRef = useRef<WebSync>()

    ComponentDidMount(() => {
        document.body.style.overscrollBehaviorX = 'none';
        var model = Contact.Strategy?.modelPortfolios?.find(x => x.riskCategory.id === Contact.Strategy.calculatedRisk.id)
        if (model)
            setSelectedModelPortfolio(model)
    })

    ComponentWillUnmount(() => {
        document.body.style.overscrollBehaviorX = 'unset';
    })

    function onStrategySelectClick() {
        var evaluatedExpectedReturn = Contact.Strategy.calculatedRisk.expectedReturn
        var selectedExpectedReturn = selectedModelPortfolio.riskCategory.expectedReturn
        if (evaluatedExpectedReturn >= selectedExpectedReturn)
            onAcceptRiskClick()
        else
            setShowWarning(true)
    }

    function onWarningExit() {
        setShowWarning(false)
    }

    function onStrategySelect(value: ModelPortfolioDto) {
        setSelectedModelPortfolio(value)
    }

    function load() {
        var proposal = Account.virtualPortfolios.find(x => x.proposalId == parseInt(proposalId))
        return ContactActions
            .GetStrategy({
                productId: proposal.product.id
            })
            .onSuccess(response => {
                setSelectedModelPortfolio(response.data
                    .modelPortfolios.find(x => x.riskCategory.id === proposal.evaluatedRiskId))

            })
    }

    function renderFooter() {
        return <FooterNavBar
            buttonBackText={t('common.back')}
            buttonNextText={t('account.strategy.choose.confirm_selection_and_continue')}
            onNextClick={() => onStrategySelectClick()} />
    }

    function onAcceptRiskClick() {
        webRef.current.Activity.show()
        var virtualPortfolio = Account.virtualPortfolios.find(x => x.proposalId === parseInt(proposalId))
        PortfolioActions
            .PatchPortfolioProposal({
                proposalId: virtualPortfolio.proposalId,
                modelPortfolioId: selectedModelPortfolio.id
            })
            .onSuccess(() => {
                virtualPortfolio.riskCategoryId = selectedModelPortfolio.riskCategory.id
                virtualPortfolio.modelPortfolio = selectedModelPortfolio
                Account.virtualPortfolios.filter(x => x.riskCategoryId == undefined).length > 0 ?
                    props.history.push(`/onboarding/${proposalId}/Portfolio/Created`) :
                    props.history.push(`/onboarding/${proposalId}/Portfolio/RegisterSuccess`)
                webRef.current.Activity.hide()
            })
            .onFail(() => webRef.current.Activity.hide())

    }


    const isStrategyRecommended = () => {
        return Contact.Strategy?.calculatedRisk?.id === selectedModelPortfolio?.riskCategory?.id
    }

    const getRecommendedModelPortfolio = () => {
        return Contact.Strategy?.modelPortfolios?.find(x => x.riskCategory.id === Contact.Strategy.calculatedRisk.id)
    }

    const renderStrategyDescription = () => {
        if (isStrategyRecommended())
            return <h3>{t('account.strategy.choose.descriptions.recommended')} {getRecommendedModelPortfolio()?.name}</h3>;

        return <h3>{t('account.strategy.choose.descriptions.not_recommended')} {getRecommendedModelPortfolio().name}</h3>
    }

    return (
        <Page>
            <WebSync
                ref={webRef}
                client={() => load()}>
                <OnboardingLayout
                    classNameContent={styles.content}
                    footer={renderFooter()}
                    selectedStepIndex={3}
                    progress={0.5}
                    contentMaxWidth='100%'
                >
                    <div className={styles.main}>
                        <div className={styles.textBlock}>
                            <h1>{t('account.strategy.choose.title')}</h1>
                            {renderStrategyDescription()}
                        </div>
                        {Contact.Strategy &&
                            <StrategyCarousel
                                data={Contact.Strategy}
                                onChange={m => onStrategySelect(m)} />
                        }

                    </div>
                    <StrategyChangePopup onAcceptClick={() => onAcceptRiskClick()} show={showWarning} onExit={() => onWarningExit()} />
                </OnboardingLayout>
            </WebSync>
        </Page>
    );
});

export default AccountChooseStrategyPage;
