import { configurePersistable } from 'mobx-persist-store';
import ReactDOM from 'react-dom';
import App from './App';
import { GlobalStore, GlobalStoreContext } from './App/Mobx/Stores/MainStore';
import reportWebVitals from './reportWebVitals';



configurePersistable(
  {
    storage: window.localStorage,
    expireIn: 86400000,
    removeOnExpiration: true,
    stringify: false,
    debugMode: true,
  },
  { delay: 0, fireImmediately: true }
);

ReactDOM.render(
    <GlobalStoreContext.Provider value={GlobalStore}>
      <App />
    </GlobalStoreContext.Provider >,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
