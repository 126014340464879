import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import OverviewPortfolioPage from '../Components/Pages/Dashboard/OverviewPortfolioPage';
import DashboardMainPage from "../Components/Pages/Dashboard/MainPage";
import DashboardDocumentsPage from "../Components/Pages/Dashboard/DocumentsPage";
import DashboardProfilePage from "../Components/Pages/Dashboard/ProfilePage";
import DashboardMessagesPage from "../Components/Pages/Dashboard/MessagesPage";
import DashboardFAQPage from "../Components/Pages/Dashboard/FAQ/FAQPage";
import DashboardRiskProtectionPage from "../Components/Pages/Dashboard/RiskProtectionPage";
import DashboardRiskProtectionWithContractPage from "../Components/Pages/Dashboard/RiskProtectionWithContractPage";
import DashboardCancelRiskProtectionPage from "../Components/Pages/Dashboard/CancelRiskProtectionPage";
import DashboardAdjustTheBenefitPage from "../Components/Pages/Dashboard/AdjustTheBenefitPage";
import DashboardPreferentialTreatmentPage from "../Components/Pages/Dashboard/DissolvePreferentialTreatmentPage";
import DashboardPreferentialTreatmentRegulationsPage from "../Components/Pages/Dashboard/PreferentialTreatmentRegulationsPage";
import DashboardBeneficiaryRegulationsWithContractPage from "../Components/Pages/Dashboard/BeneficiaryRegulationsWithContractPage";
import DashboardAdjustRiskProtectionPage from "../Components/Pages/Dashboard/AdjustRiskProtectionPage";
import DashboardRiskPremiumPaymentPage from "../Components/Pages/Dashboard/RiskPremiumPaymentPage";
import DashboardAdjustRiskProtectionSuccessPage from "../Components/Pages/Dashboard/AdjustRiskProtectionSuccessPage";
import DepositTransfer3aPage from "../Components/Pages/Dashboard/DepositTransfer3aPage";
import DepositTransfer3aSuccessPage from "../Components/Pages/Dashboard/DepositTransfer3aSuccessPage";
import DashboardStrategyDetailsPage from "../Components/Pages/Dashboard/StrategyDetailsPage";
import DashboardChangeStrategyPage from "../Components/Pages/Dashboard/ChangeStrategyPage";
import DashboardOverviewDepotsPage from "../Components/Pages/Dashboard/OverviewDepotsPage";
import DashboardOverviewDepots3aPage from "../Components/Pages/Dashboard/OverviewDepots3aPage";
import DashboardChangeStrategySuccess from "../Components/Pages/Dashboard/ChangeStrategySuccess";
import DashboardDepositOverview3APage from "../Components/Pages/Dashboard/DashboardDepositOverview3APage";
import DashboardStrategyRelevateExcitedPage from "../Components/Pages/Dashboard/DashboardStrategyRelevateExcitedPage";
import DashboardMakeDepositPage from "../Components/Pages/Dashboard/MakeDepositPage";
import DashboardDepositTransferScanPage from "../Components/Pages/Dashboard/DepositTransferScanPage";
import DashboardAddRiskProtectionPage from "../Components/Pages/Dashboard/AddRiskProtectionPage";
import DashboardAddFavorPage from "../Components/Pages/Dashboard/AddFavorPage";
import DetermineInsuranceCoveragePage from "../Components/Pages/Dashboard/DetermineInsuranceCoveragePage";
import DashboardPaymentOfRiskPremium from "../Components/Pages/Dashboard/DashboardPaymentOfRiskPremium";
import DashboardHealthCheckPage from "../Components/Pages/Dashboard/DashboardHealthCheckPage";
import DashboardHealthSuccessPage from "../Components/Pages/Dashboard/DashboardHealthSuccessPage";
import DashboardChooseStrategyPage from "../Components/Pages/Dashboard/DashboardChooseStrategyPage";
import DashboardMakeDepositFailedPage from "../Components/Pages/Dashboard/MakeDepositFailedPage";
import DashboardCloseVestedBenefitsDepositPage from "../Components/Pages/Dashboard/DashboardCloseVestedBenefitsDepositPage";
import DepositOverviewPage from "../Components/Pages/Dashboard/DepositOverviewPage";
import RiskProtectionDetailsPage from "../Components/Pages/Dashboard/RiskProtectionDetailsPage";
import DashboardClose3aPortfolioPage from "../Components/Pages/Dashboard/DashboardClose3aPortfolioPage";
import WelcomePage from "../Components/Pages/Dashboard/WelcomePage";
import DashboardAccountAssetsValuePage
        from "../Components/Pages/Dashboard/FlowAddNewPortdolio/DashboardAccountAssetsValuePage";
import DashboardAdditionalAccountPage
        from "../Components/Pages/Dashboard/FlowAddNewPortdolio/DashboardAdditionalAccountPage";
import DashboardAccountTransferSourcePage
        from "../Components/Pages/Dashboard/FlowAddNewPortdolio/DashboardAccountTransferSourcePage";
import DashboardRequestForConsultationPage
        from "../Components/Pages/Dashboard/FlowAddNewPortdolio/DashboardRequestForConsultationPage";
import DashboardSplitPortfolioPage from "../Components/Pages/Dashboard/FlowAddNewPortdolio/DashboardSplitPortfolioPage";
import DashboardSelectingPreferStrategyPage
        from "../Components/Pages/Dashboard/FlowAddNewPortdolio/DashboardSelectingPreferStrategyPage";
import DashboardInvestmentExperiencePage
        from "../Components/Pages/Dashboard/FlowAddNewPortdolio/DashboardInvestmentExperiencePage";


const DashboardRoutes: React.FC = () => {
    return (
        <Switch>
            <Route exact path="/welcome" component={WelcomePage} />
            <Route exact path="/Dashboard/OverviewPortfolio" component={OverviewPortfolioPage} />
            <Route exact path="/Dashboard/DepositOverview" component={DepositOverviewPage} />
            <Route exact path="/Dashboard/DetermineInsuranceCoverage" component={DetermineInsuranceCoveragePage} />
            <Route exact path="/Dashboard/PaymentOfRiskPremium" component={DashboardPaymentOfRiskPremium} />
            <Route exact path="/Dashboard/HealthCheck" component={DashboardHealthCheckPage} />
            <Route exact path="/Dashboard/HealthSuccess" component={DashboardHealthSuccessPage} />
            <Route exact path="/Dashboard/MakeDeposit3a" component={DashboardMakeDepositPage} />
            <Route exact path="/Dashboard/MakeDeposit3aTransferScan" component={DashboardDepositTransferScanPage} />



            <Route exact path="/Dashboard" component={DashboardMainPage} />

            <Route exact path="/Dashboard/Documents" component={DashboardDocumentsPage} />
            <Route exact path="/Dashboard/Profile" component={DashboardProfilePage} />
            <Route exact path="/Dashboard/Messages" component={DashboardMessagesPage} />
            <Route exact path="/Dashboard/Faq" component={DashboardFAQPage} />
            <Route exact path="/Dashboard/RiskProtection" component={DashboardRiskProtectionPage} />
            //TODO: not actual page?
            <Route exact path="/Dashboard/RiskProtectionWithContract" component={DashboardRiskProtectionWithContractPage} />
            //
            <Route exact path="/Dashboard/AdjustBenefit" component={DashboardAdjustTheBenefitPage} />
            //TODO: same as Dashboard/AddRiskProtection ?
            <Route exact path="/Dashboard/AdjustRiskProtection" component={DashboardAdjustRiskProtectionPage} />
            //
            //TODO: same as Dashboard/PaymentOfRiskPremium ?
            <Route exact path="/Dashboard/RiskPremiumPayment" component={DashboardRiskPremiumPaymentPage} />
            //
            //TODO: not actual page?
            <Route exact path="/Dashboard/AdjustRiskProtectionSuccess" component={DashboardAdjustRiskProtectionSuccessPage} />
            //

            <Route exact path="/Dashboard/Beneficiary/Order/Add" component={DashboardAddFavorPage} />


            <Route exact path="/dashboard/fz/depots" component={DashboardOverviewDepotsPage} />
            <Route exact path="/dashboard/depot/:id/overview" component={DashboardStrategyDetailsPage} />
            <Route exact path="/Dashboard/depot/:id/strategy" component={DashboardChangeStrategyPage} />
            <Route exact path="/Dashboard/OverviewDepots/ChangeStrategy/Success" component={DashboardChangeStrategySuccess} />
            <Route exact path="/Dashboard/depot/:id/strategy/change" component={DashboardChooseStrategyPage} />

            <Route exact path="/Dashboard/depot/:portfolioId/beneficiary/order" component={DashboardPreferentialTreatmentRegulationsPage} />
            <Route exact path="/Dashboard/BeneficiaryRegulations" component={DashboardBeneficiaryRegulationsWithContractPage} />
            <Route exact path="/Dashboard/PreferentialTreatment" component={DashboardPreferentialTreatmentPage} />


            <Route exact path="/Dashboard/3a/depots" component={DashboardOverviewDepots3aPage} />
            <Route exact path="/dashboard/depot/:id/3a/overview" component={DashboardDepositOverview3APage} />
            <Route exact path="/dashboard/depot/:id/3a/deposits" component={DepositOverviewPage} />
                //TODO: not actual page
            <Route exact path="/Dashboard/depot/3a/strategy" component={DashboardStrategyRelevateExcitedPage} />
                //
            <Route exact path="/Dashboard/OverviewDepots3a/DepositTransfer" component={DepositTransfer3aPage} />
            <Route exact path="/Dashboard/OverviewDepots3a/DepositTransferSuccessPage" component={DepositTransfer3aSuccessPage} />

            <Route exact path="/dashboard/depot/:id/3a/pay/info" component={DashboardMakeDepositPage} />
            <Route exact path="/dashboard/depot/:id/3a/pay" component={DashboardDepositTransferScanPage} />
            <Route exact path="/Dashboard/OverviewDepots3a/MakeDeposit3a/Failed" component={DashboardMakeDepositFailedPage} />

            <Route exact path="/Dashboard/AddRiskProtection" component={DashboardAddRiskProtectionPage} />
            <Route exact path="/Dashboard/DetermineInsuranceCoverage" component={DetermineInsuranceCoveragePage} />
            <Route exact path="/Dashboard/PaymentOfRiskPremium" component={DashboardPaymentOfRiskPremium} />
            <Route exact path="/Dashboard/HealthCheck" component={DashboardHealthCheckPage} />
            <Route exact path="/Dashboard/HealthSuccess" component={DashboardHealthSuccessPage} />
            <Route exact path="/Dashboard/RiskProtectionDetails" component={RiskProtectionDetailsPage} />
            <Route exact path="/Dashboard/CancelRiskProtection" component={DashboardCancelRiskProtectionPage} />

            <Route exact path="/Dashboard/CloseVestedBenefitsDeposit" component={DashboardCloseVestedBenefitsDepositPage} />
            <Route exact path="/Dashboard/Close3aPortfolioPage" component={DashboardClose3aPortfolioPage} />

                <Route exact path="/dashboard/portfolio/add" component={DashboardAdditionalAccountPage} />
                <Route exact path="/dashboard/portfolio/add/source" component={DashboardAccountTransferSourcePage} />
                <Route exact path="/dashboard/portfolio/add/assets" component={DashboardAccountAssetsValuePage} />
                <Route exact path="/dashboard/portfolio/add/consultation-request" component={DashboardRequestForConsultationPage} />
                <Route exact path="/dashboard/portfolio/add/split" component={DashboardSplitPortfolioPage} />
                <Route exact path="/dashboard/portfolio/add/prefer-strategy" component={DashboardSelectingPreferStrategyPage} />
                <Route exact path="/dashboard/portfolio/add/experience" component={DashboardInvestmentExperiencePage} />


            <Route path="*" render={() => <Redirect to="/dashboard"/>}/>
        </Switch>
    );
};

export default DashboardRoutes;