import React, { useRef, useState } from 'react';
import { Button } from '../../Atoms/Button/Button';
import Page from '../../Molecules/Page/Page';
import ValuePanel from '../../Molecules/Panel/ValuePanel';
import PersonalConsultationForm from '../../Organisms/Consultancy/PersonalConsultationForm';
import PersonalConsultationInfo from '../../Organisms/Consultancy/PersonalConsultationInfo';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import styles from './AccountAssetsValuePage.module.scss';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Callback } from '../../../App/Models/Callback';
import { TransferSource } from '../../../App/Models/Enums/TransferSource';
import WebSync from '../../Molecules/DataSync/WebSync';
import t from '../../../App/Libs/Translate/Translate'
import { ProposalParams } from '../../../App/Models/Params/ProposalParams';

const ASSET_VALUE_TO_OFFER_CONSULTATION = 300000

const MAX_ASSET_VALUE = 12000000

type WhiteButtonProps = {
    onClick: React.MouseEventHandler<HTMLElement>
}

const WhiteButton: React.FC<WhiteButtonProps> = (props) => <Button className={styles.whiteButton} onClick={e => props.onClick(e)}>Digital weiterfahren</Button>

const AccountAssetsValuePage: React.FC<RouteComponentProps> = (props) => {
    const [assetValue, setAssetValue] = useState<number>(0)
    const { Account } = useStore()

    const { proposalId } = useParams<ProposalParams>() 
    var currentVirtualPortfolio = Account.virtualPortfolios.find(x => x.proposalId === parseInt(proposalId))

    const webRef = useRef<WebSync>()

    function isConsultationProposed() {
        return assetValue >= ASSET_VALUE_TO_OFFER_CONSULTATION
    }

    function onValueChange(value: number) {
        setAssetValue(value)
    }

    function onDigitalFlowClicked() {
        webRef.current.Activity.show()
        PortfolioActions
            .PatchPortfolioProposal({
                proposalId: currentVirtualPortfolio.proposalId,
                targetAmount: assetValue
            })
            .onSuccess(() => {
                var path = undefined
                currentVirtualPortfolio.amount = assetValue

                if (Account.transferSource === TransferSource.PK) {
                    path = `/onboarding/${proposalId}/Portfolio/Split`
                }
                else {
                    path = `/onboarding/${proposalId}/Survey/Horizon`
                }
                Account.assetAmount = assetValue
                props.history.push(path)
                webRef.current.Activity.hide()
            })
            .onFail(() => webRef.current.Activity.hide())
    }

    function onSubmit() {
        webRef.current.Activity.show()
        PortfolioActions
            .PatchPortfolioProposal({
                proposalId: currentVirtualPortfolio.proposalId,
                targetAmount: assetValue
            })
            .onSuccess(() => {
                var path = isConsultationProposed() ?
                    '/onboarding/Portfolio/RegisterThankForRequest' :
                    `/onboarding/${proposalId}/Survey/Horizon`
                currentVirtualPortfolio.amount = assetValue
                Account.assetAmount = assetValue
                props.history.push(path)
                webRef.current.Activity.hide()
            })
            .onFail(() => webRef.current.Activity.hide())
    }

    function renderFooter() {
        return (
            <FooterNavBar
                linkToBack='/onboarding/Survey/Financial/Knowledge'
                buttonBackText={t('common.back')}
                onNextClick={() => onSubmit()}
                buttonNextText={isConsultationProposed() ? t('account.assets.value.arrange_consultation') : t('account.assets.value.go_to_investment_horizon')}
                extraButton={isConsultationProposed() ? <WhiteButton  onClick={e => onDigitalFlowClicked()} />: null}
            />
        )
    }

    return (
        <Page>
            <WebSync ref={webRef}>
                <OnboardingLayout
                    selectedStepIndex={1}
                    progress={0.5}
                    contentMaxWidth={'1600px'}
                    footer={renderFooter()}
                >
                    <div className={styles.mainText}>
                        <div>
                            <h4 className={styles.title}>{t('account.assets.value.title')}</h4>
                            <h5 className={styles.secondaryText}>{t('account.assets.value.subtitle')}</h5>
                        </div>

                        <div className={styles.valuePanelWrapper}>
                            <ValuePanel
                                label={'CHF'}
                                placeholder={100000}
                                step={10000}
                                onChange={value => onValueChange(value)}
                            />
                        </div>
                    </div>

                    {isConsultationProposed() && (
                        <>
                            <PersonalConsultationInfo></PersonalConsultationInfo>

                            <div className={styles.formWrapper}>
                                <PersonalConsultationForm to="/onboarding/Portfolio/RegisterThankForRequest" />
                            </div>

                            <div className={styles.continueBlock}>
                                <div className={styles.continueBlockInner}>
                                    <h6>{t('account.assets.value.do_not_want_personal_consultation_continue_on_digital_path')}</h6>
                                    <WhiteButton onClick={() => onDigitalFlowClicked()} />
                                </div>
                            </div>
                        </>
                    )}
                </OnboardingLayout>
            </WebSync>
        </Page>
    );
};

export default AccountAssetsValuePage;
