import { action, makeObservable, observable } from "mobx"
import { makePersistable } from "mobx-persist-store"
import { CountryDto } from "../../Models/Contracts/Compliance/CountryDto"
import { ProductDto } from "../../Models/Contracts/Compliance/ProductDto"
import { ThemeDto } from "../../Models/Contracts/Strategy/ThemeDto"


export class ComplianceStore{
    @observable Themes?: Array<ThemeDto>
    @observable Products?: Array<ProductDto>
    @observable Countries?: Array<CountryDto>

    @action PensFreeProduct(){
        return this.Products.find(x => x.externalId === "FZPF")
    }

    @action IndependentProduct(){
        return this.Products.find(x => x.externalId === "FZI")
    }

    @action Pens3AProduct(){
        return this.Products.find(x => x.externalId === "3A")
    }

    constructor(){
        makeObservable(this)
        makePersistable(this, { name: 'ComplianceStore', properties: ['Themes', 'Products'], storage: window.sessionStorage });
    }
}