import React, { ChangeEvent, ChangeEventHandler, FormEventHandler, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './Select.module.scss';
import arrowIcon from '../../../Assets/Images/Dropwdown_arrow.svg'
import ValidationErrorMessage from "../ValidationErrorMessage/ValidationErrorMessage";
import { Callback } from '../../../App/Models/Callback';



type customToggleProps = {
  onClick?: any
  children?: React.ReactNode
}

type propsTypes = {
  itemsList: Array<{
    id: string
    value: string
  }>
  placeholder?: string
  errorMessage?: string
  className?: string
  customToggle?: any
  onChange?: Callback<string>
  children?: React.ReactNode
}


const Select: React.FC<propsTypes> = (props) => {
  const { itemsList, errorMessage, placeholder, className, customToggle } = props
  const [selectedItemID, setSelectedItemID] = useState<string>('')
  const [selectedItemValue, setSelectedItemValue] = useState<string>("");


  const selectItem = (itemID: string, itemValue: string) => {
    setSelectedItemID(itemID);
    setSelectedItemValue(itemValue);
    props.onChange && props.onChange(itemID)
  }

  return (
    <>
      <Dropdown className={className}>
        <Dropdown.Toggle as={customToggle ? customToggle : DefaultCustomToggle}>
          {selectedItemID ? itemsList.find(item => item.id === selectedItemID)?.value : placeholder}
        </Dropdown.Toggle>

        <Dropdown.Menu className={styles.dropdownMenu}>
          {itemsList.map((item: { id: string, value: string }) => {
            return (
              <Dropdown.Item key={item.value}
                className={styles.dropdownItem}
                onClick={e => selectItem(item.id, item.value)}
              >
                {item.value}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
      {errorMessage ? <ValidationErrorMessage message={errorMessage} /> : null}
    </>
  )
};

const DefaultCustomToggle = React.forwardRef<any, customToggleProps>(({ children, onClick }, ref) => (
  <span
    ref={ref}
    className={styles.main}
    onClick={e => onClick(e)}
  >
    {children}
    <div className={styles.arrowIcon}>
      <img src={arrowIcon} alt="" />
    </div>
  </span>

));


export default Select;
