import { default as React, useCallback } from 'react';
import backgroundVideo from '../../../Assets/Videos/background.mp4';
import Logo from '../../Atoms/Logo/Logo';
import Layout, { LayoutProps } from '../../Molecules/Layout/Layout';
import Step from '../../Molecules/Stepper/Dependencies/Step';
import Stepper from '../../Molecules/Stepper/Stepper';
import classnames from 'classnames';
import styles from './OnboardingLayout.module.scss';
import SideMenu from "../Popup/SideMenu/SideMenu";
import Link from "../../Atoms/Link/Link";
import closeButton from "../../../Assets/Images/Close.svg"
import { IWebClientPromise } from '../../../App/Libs/WebClient/WebClientPromise';
import { FuncEmpty } from '../../../App/Models/Callback';
import WebSync from '../../Molecules/DataSync/WebSync';



type OnboardingLayoutProps = LayoutProps & {
    classNameContent?: string,
    selectedStepIndex?: number,
    children?: React.ReactNode,
    progress?: number
    showBackgroundImage?: boolean
    contentMaxWidth?: string
    hideStepper?: boolean
    isLoading?: boolean
    backButtonLink?: string
    stepCount?: number
    hideMenu?: boolean
}

const OnboardingLayout = (props: OnboardingLayoutProps) => {

    const renderSteps = () => {
        return Array.from( {length: props.stepCount || 4}, (item, index) => <Step key={`step-${index}`}/>);
    }

    const renderBackButton = useCallback(() => {
        return (
            <Link to={props.backButtonLink} className={styles.backButton}><img src={closeButton} alt="close button" /></Link>
        )
    }, [props.backButtonLink]);

    function renderHeader() {
        return (
            <>
                <div className={styles.header}>
                    <Logo className={styles.logo} />
                    {props.backButtonLink ? renderBackButton() : !props.hideMenu? <SideMenu /> : undefined}
                </div>
                {props.hideStepper !== true ?
                    <Stepper disabled={true}
                        className={styles.stepper}
                        selectedIndex={props?.selectedStepIndex}
                        progress={props.progress}
                        theme={{
                            selected: {
                                BackgroundColor: '#BCCED9',
                                Color: 'white',
                            },
                            default: {
                                BackgroundColor: '#E0E7F6',
                                Color: '#90A3B0'
                            }
                        }}>
                        {renderSteps()}
                    </Stepper>
                    :
                    <div className={styles.stepper}></div>
                }
            </>
        )
    }

    const isFullWidth = props.contentMaxWidth === '100%'

    return (
        <Layout
            className={classnames(styles.main, isFullWidth ? styles.mainFullWidth : styles.mainScreenPaddings)}
            isFullWidth={isFullWidth}
            header={renderHeader()}
            footer={props.footer}
            sync={props.sync}
            syncRef={props.syncRef}
        >
                <video autoPlay muted loop playsInline>
                    <source src={backgroundVideo} type="video/mp4" />
                </video>

                {props.isLoading !== true &&
                    <div
                        className={classnames(styles.content, props.classNameContent ? props.classNameContent : '')}
                        style={{ maxWidth: props.contentMaxWidth ? props.contentMaxWidth : 'inherited' }}
                    >
                        {props.children}
                    </div>
                }
        </Layout>
    );
};

export default OnboardingLayout;
