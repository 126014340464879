import { makeAutoObservable, makeObservable, observable } from "mobx"
import { makePersistable } from "mobx-persist-store"
import { StrategyDto } from "../../Models/Contracts/Strategy/StrategyDto"
import { GenderEnum } from "../../Models/Enums/GenderEnum"


export class ContactStore{
    @observable Strategy?: StrategyDto
    @observable birthDate?: Date
    @observable gender?: GenderEnum
    @observable firstName?: string
    @observable lastName?: string
    @observable address?: string
    @observable city?: string
    @observable postalCode: string
    @observable sendWelcomeEmail?: boolean


    constructor(){
        makeAutoObservable(this)
        makePersistable(this, { name: 'Contact', properties: ['birthDate', 'gender', 'firstName', 'lastName', 'address', 'city', 'postalCode'], storage: window.sessionStorage });
    }
}