import Page from "../../Molecules/Page/Page";
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import { useForm } from "react-hook-form";
import Input from '../../Atoms/Input/Input';
import Radio from '../../Atoms/Radio/Radio';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import ValidationErrorMessage from "../../Atoms/ValidationErrorMessage/ValidationErrorMessage";

import styles from './DashboardHealthCheckPage.module.scss';
import { useState } from "react";
import classnames from 'classnames';
import { Button } from '../../Atoms/Button/Button';
import  Link  from '../../Atoms/Link/Link';
import Question from "../../Molecules/Question/Question";
import t from '../../../App/Libs/Translate/Translate'
import InputForm from "../../Molecules/InputForm/InputForm";
import TextareaForm from "../../Molecules/TextareaForm/TextareaForm";
import MedicamentForm from "../../Molecules/MedicamentForm/MedicamentForm";

const ERRORS = {
    required: `${t('errors.required')}`,
}

type Inputs = {
    height: string,
    weight: string,
    ort: string,
    surname: string,
    firstname: string,
    practice: string,
    adress: string,
    plz: string,
    question_2: string,
    question_3: string,
    question_4: string,
    question_5: string,
    question_6: string,
    age: number,
    durationOfSuspension: string,
    wieOft: string,
};




const DashboardHealthCheckPage = () => {

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>();

    const renderFooter = () => {
        return <FooterNavBar
            linkToNext='/Dashboard/HealthSuccess'
            buttonBackText={t('common.back')}
            buttonNextText={t('dashbord.survey.health.check.next')}/>
    }

    const [watchShowQuestion_2, watchShowQuestion_3, watchShowQuestion_4, watchShowQuestion_5, watchShowQuestion_6,] = [watch("question_2"), watch("question_3"), watch("question_4"), watch("question_5"), watch("question_6"),]
    const initialState = {
        question_2: 0,
        question_5: 0,
        question_6: 0,
    };

    const [posts, setPosts] = useState(initialState);

    const addPost = (elem, counts) =>{
        let returnForm =[];
        for(let i = 0; i<counts; i++){
            console.log(i)
            returnForm.push(elem) 
        };
        return returnForm
    }

  return (
    <Page>
      <OnboardingLayout
        contentMaxWidth='1440px'
        footer={renderFooter()}
        progress={0.5}
        selectedStepIndex={1}
      >

        <div className={styles.flex}>
            <h3 className={styles.title}>
              {t("dashbord.survey.health.check.title")}
            </h3>
            <div className={styles.paragraph}>
              {t("dashbord.survey.health.check.detail_desc_1")}
            </div>
        </div>

        <hr className={styles.line} />
        <Question
            questionNumber="01"
            question={t('dashbord.survey.health.check.question_1')}
        >
            <div className={styles.flex}>
                    <div className={classnames(styles.relative, styles.inputWidth43, styles.mr40)}>
                        <Input
                            placeholder={t('common.height')}
                            {...register("height", {
                            required: true,
                            })}
                            error={errors.height && ERRORS.required}
                            type={"number"}
                        />
                        <div className={styles.sufix}>cm</div>
                    </div>
                    <div className={classnames(styles.relative, styles.inputWidth43)}>
                        <Input
                            placeholder={t('common.weight')}
                            {...register("weight", {
                            required: true,
                            })}
                            error={errors.weight && ERRORS.required}
                            type={"number"}
                        />
                        <div className={styles.sufix}>kg</div>
                    </div>
                </div>
        </Question>

        <hr className={styles.line} />
        
        <Question
            questionNumber="02"
            question={t('dashbord.survey.health.check.question_2')}
        >
            <div className={styles.radioWrapper}>
                <Radio
                    className={styles.radio}
                    value="female"
                    {...register("question_2", {
                    required: true,
                    })}
                >
                    {t('common.yes')}
                </Radio>
                <Radio
                    className={styles.radio}
                    value="male"
                    {...register("question_2", {
                        required: false,
                    })}  
                    >
                    {t('common.no')}
                </Radio>
            </div>
            {errors.question_2?.type === 'required' && <ValidationErrorMessage message={ERRORS.required}/>}

            {watchShowQuestion_2 ==="female" && 
            <div>
                <InputForm
                    duration={t('dashbord.survey.health.check.duration')}
                    year={t('common.year')}
                    duration_of_suspension={t('dashbord.survey.health.check.duration_of_suspension')}
                    months={t('common.months')}
                    what={t('common.what')}
                    type_illness_accident={t('dashbord.survey.health.check.type_illness_accident')}
                    place_of_treatment_2={t('dashbord.survey.health.check.place_of_treatment_2')}
                    doctor_hospital={t('dashbord.survey.health.check.doctor_hospital')}
                    address={t('common.address')}
                    postcode={t('common.postcode')}
                    location={t('common.location')}
                >
                </InputForm> 
                {addPost(
                    <InputForm
                        duration={t('dashbord.survey.health.check.duration')}
                        year={t('common.year')}
                        duration_of_suspension={t('dashbord.survey.health.check.duration_of_suspension')}
                        months={t('common.months')}
                        what={t('common.what')}
                        type_illness_accident={t('dashbord.survey.health.check.type_illness_accident')}
                        place_of_treatment_2={t('dashbord.survey.health.check.place_of_treatment_2')}
                        doctor_hospital={t('dashbord.survey.health.check.doctor_hospital')}
                        address={t('common.address')}
                        postcode={t('common.postcode')}
                        location={t('common.location')}
                        add_another_record={t('dashbord.survey.health.check.add_another_record')}
                    ></InputForm>, posts.question_2)}
                 <div className={styles.newPortfolioBlock}>
                    <Button 
                        className={styles.buttonAddPortfolio}
                        onClick={() => setPosts({...posts, question_2: posts.question_2+1})} 
                    >
                    </Button>
                    <Link
                        className={styles.linkAddPortfolio}
                        onClick={() => setPosts({...posts, question_2: posts.question_2+1})} 
                    >
                        {t('dashbord.survey.health.check.add_another_record')}
                    </Link>
                </div>
            </div>}
            
        </Question>

        <hr className={styles.line} />

        <Question
            questionNumber="03"
            question={t('dashbord.survey.health.check.question_3')}
        >
            <div className={styles.radioWrapper}>
                            <Radio
                                className={styles.radio}
                                value="healthy"
                                {...register("question_3", {
                                required: true,
                                })}
                            >
                                {t('common.yes')}
                            </Radio>
                            <Radio
                                className={styles.radio}
                                value="unwell"
                                {...register("question_3", {
                                required: true,
                                })}
                            >
                                {t('common.no')}
                            </Radio>
                    </div>
                    {watchShowQuestion_3 === "unwell" && 
                    <TextareaForm
                        title={t('dashbord.survey.health.check.fell_healthy')}
                        note_reasons={t('dashbord.survey.health.check.note_reasons')}
                    ></TextareaForm>
                    }   
        </Question>

        <hr className={styles.line} />

        <Question
            questionNumber="04"
            question={t('dashbord.survey.health.check.question_4')}
        >
            <div className={styles.radioWrapper}>
                            <Radio
                                className={styles.radio}
                                value="yes"
                                {...register("question_4", {
                                required: true,
                                })}
                            >
                                {t('common.yes')}
                            </Radio>
                            <Radio
                                className={styles.radio}
                                value="no"
                                {...register("question_4", {
                                required: true,
                                })}
                            >
                                {t('common.no')}
                            </Radio>
                    </div>
                    {watchShowQuestion_4 === "yes" && 
                    <TextareaForm
                        title={t('dashbord.survey.health.check.fell_healthy_2')}
                        note_reasons={t('dashbord.survey.health.check.note_reasons_2')}
                    ></TextareaForm>
                    }  
        </Question>

        <hr className={styles.line} />

        <Question
            questionNumber="05"
            question={t('dashbord.survey.health.check.question_5')}
        >
            <div className={styles.radioWrapper}>
                            <Radio
                                className={styles.radio}
                                value="yes"
                                {...register("question_5", {
                                required: true,
                                })}
                            >
                                {t('common.yes')}
                            </Radio>
                            <Radio
                                className={styles.radio}
                                value="no"
                                {...register("question_5", {
                                required: true,
                                })}
                            >
                                {t('common.no')}
                            </Radio>
                    </div>

                    {watchShowQuestion_5 === "yes" && 
                    <div>
                        <MedicamentForm
                            drug={t('dashbord.survey.health.drug')}
                            crowd={t('dashbord.survey.health.crowd')}
                            how_often={t('common.how_often')}
                            per_day={t('dashbord.survey.health.per_day')}
                            reason={t('dashbord.survey.health.reason')}
                        ></MedicamentForm>
                        {addPost(
                            <MedicamentForm
                                drug={t('dashbord.survey.health.drug')}
                                crowd={t('dashbord.survey.health.crowd')}
                                how_often={t('common.how_often')}
                                per_day={t('dashbord.survey.health.per_day')}
                                reason={t('dashbord.survey.health.reason')}
                            ></MedicamentForm>, posts.question_5)}
                        <div className={styles.newPortfolioBlock}>
                            <Button 
                                className={styles.buttonAddPortfolio}
                                onClick={() => setPosts({...posts, question_5: posts.question_5+1})} 
                            >
                            </Button>
                            <Link
                                className={styles.linkAddPortfolio} 
                                onClick={() => setPosts({...posts, question_5: posts.question_5+1})}  
                                >
                                {t('dashbord.survey.health.add_another_drug')}
                            </Link>
                        </div>
                    </div>
                    }  
        </Question>

        <hr className={styles.line} />

        <Question
            questionNumber="06"
            question={t('dashbord.survey.health.check.question_6')}
        >
            <div className={styles.radioWrapper}>
                    <Radio
                        className={styles.radio}
                        value="yes"
                        {...register("question_6", {
                        required: true,
                        })}
                    >
                        {t('common.yes')}
                    </Radio>
                    <Radio
                        className={styles.radio}
                        value="no"
                        {...register("question_6", {
                            required: true,
                        })}
                    >
                        {t('common.no')}
                    </Radio>
                </div>
                {errors.question_6?.type === 'required' && <ValidationErrorMessage message={ERRORS.required}/>}

                {watchShowQuestion_6 ==="yes" && 
            <div>
                <InputForm
                    duration={t('dashbord.survey.health.check.duration')}
                    year={t('common.year')}
                    duration_of_suspension={t('dashbord.survey.health.check.duration_of_suspension')}
                    months={t('common.months')}
                    what={t('common.what')}
                    type_illness_accident={t('dashbord.survey.health.check.type_illness_accident')}
                    place_of_treatment_2={t('dashbord.survey.health.check.place_of_treatment_2')}
                    doctor_hospital={t('dashbord.survey.health.check.doctor_hospital')}
                    address={t('common.address')}
                    postcode={t('common.postcode')}
                    location={t('common.location')}
                >
                </InputForm> 
                {addPost(
                    <InputForm
                        duration={t('dashbord.survey.health.check.duration')}
                        year={t('common.year')}
                        duration_of_suspension={t('dashbord.survey.health.check.duration_of_suspension')}
                        months={t('common.months')}
                        what={t('common.what')}
                        type_illness_accident={t('dashbord.survey.health.check.type_illness_accident')}
                        place_of_treatment_2={t('dashbord.survey.health.check.place_of_treatment_2')}
                        doctor_hospital={t('dashbord.survey.health.check.doctor_hospital')}
                        address={t('common.address')}
                        postcode={t('common.postcode')}
                        location={t('common.location')}
                        add_another_record={t('dashbord.survey.health.check.add_another_record')}
                    ></InputForm>, posts.question_6)}
                 <div className={styles.newPortfolioBlock}>
                    <Button 
                        className={styles.buttonAddPortfolio}
                        onClick={() => setPosts({...posts, question_6: posts.question_6+1})} 
                    >
                    </Button>
                    <Link
                        className={styles.linkAddPortfolio}
                        onClick={() => setPosts({...posts, question_6: posts.question_6+1})} 
                    >
                        {t('dashbord.survey.health.check.add_another_record')}
                    </Link>
                </div>
            </div>}
        </Question>

        <hr className={styles.line} />

        <Question
            questionNumber="07"
            question={t('dashbord.survey.health.check.question_7')}
        >
            <div className="">
                    <div>
                        <Input
                            placeholder={t('dashbord.survey.health.check.surname_dr')}
                            {...register("surname", {
                            required: true,
                            })}
                            error={errors.surname && ERRORS.required}
                        />
                    </div>
                    <div>
                        <Input
                            placeholder={t('common.first_name')}
                            {...register("firstname", {
                            required: true,
                            })}
                            error={errors.firstname && ERRORS.required}
                        />
                    </div>
                    <div>
                        <Input
                            placeholder={t('dashbord.survey.health.check.practice')}
                            {...register("practice", {
                            required: true,
                            })}
                            error={errors.practice && ERRORS.required}
                        />
                    </div>
                    <div>
                        <Input
                            placeholder={t('common.address')}
                            {...register("adress", {
                            required: true,
                            })}
                            error={errors.adress && ERRORS.required}
                        />
                    </div>
                    <div className={styles.flex}>
                        <span className={styles.inputWidth}>
                            <Input
                                placeholder={t('common.postcode')}
                                {...register("plz", {
                                required: true,
                                })}
                                error={errors.plz && ERRORS.required}
                            />
                        </span>
                        <span className={styles.inputWidth100}>
                            <Input
                                placeholder={t('common.location')}
                                {...register("ort", {
                                required: true,
                                })}
                                error={errors.ort && ERRORS.required}
                            />
                        </span>
                    </div>
                </div>
        </Question>

        <hr className={styles.line}/>

      </OnboardingLayout>
    </Page>
  );
};

export default DashboardHealthCheckPage;