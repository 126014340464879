import React from 'react';
import styles from './TextareaForm.module.scss';
import Textarea from "../../Atoms/Textarea/Textarea";



interface Props {
    title?: string
    note_reasons?: string
}


const TextareaForm: React.FC<Props> = (props) => {
    return (
        <div className={styles.answerContainer}>
            <div className={styles.titleAnswer}>{props.title}</div>
            <div className={styles.block}>
                <p className={styles.labelOptional}>{props.note_reasons}</p>
                <Textarea></Textarea>
            </div>
        </div>
    );
};

export default TextareaForm;

