import { RouteComponentProps, useParams } from "react-router-dom";
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import t from '../../../App/Libs/Translate/Translate'
import TransferScanLayout from "../../Organisms/Layout/TransferScanLayout";
import styles from "./DepositTransferScanPage.module.scss";

const data = {
  emailVerified: false
}

type Params = {
  id?: string
}

const DepositTransferScanPage = (props: RouteComponentProps) => {

  const { Account } = useStore()

  const { id } = useParams<Params>()

  function getAmount(){
    if(Account.bvgValue === false)
      return 'CHF 34’416.–'
    return 'CHF 6’883.–'
  }

  return (
    <TransferScanLayout
      {...props}
      amount={getAmount()}

      portfolioId={parseInt(id)}
      emailVerified={data.emailVerified}


      linkToNext="/Dashboard/OverviewDepots3a"
      headerBackButtonLink={"/Dashboard/OverviewDepots3a"}
    >
      <p className={styles.total}>{t("dashbord.deposit.overview.deposit_total")} 2023</p>
      <p className={styles.totalAmount}>CHF 6’000.–</p>
      <hr/>
      <p className={styles.subtitle}>{t('account.3a.transfer.still_possible')}</p>
      <p className={styles.amountText}>{getAmount()}</p>
      <p className={styles.amountInfo}>
        {t('account.3a.transfer.max_amount_info').replace('[AMOUNT]', getAmount())}
      </p>
      <hr/>
    </TransferScanLayout>
  );
};

export default DepositTransferScanPage;