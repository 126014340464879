import React, { useState } from 'react';
import Panel from '../../Atoms/Panel/Panel';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../Organisms/Layout/SplitLayout';
import CheckMarkRadio from '../../Organisms/Radio/CheckMarkRadio';
import styles from './AccountOptionsLayout.module.scss';
import ValidationErrorMessage from "../../Atoms/ValidationErrorMessage/ValidationErrorMessage";
import t from '../../../App/Libs/Translate/Translate';


type AccountOptionsLayoutType = {
  onConfirm: Function
  title: string
  subtitle: string
  data: Array<{title: string, description: string}>
  headerSelectedStepIndex?: number
  headerProgress? :number
  headerBackButtonLink?: string
}

const AccountOptionsLayout = (props: AccountOptionsLayoutType) => {

  const [ahvValue, setAhvValue] = useState<string>()
  const [bvgValue, setBvgValue] = useState<string>()

  function renderFooter() {
    return <FooterNavBar
      buttonBackText={t('common.back')}
      buttonNextText={t('common.apply_and_continue')}
      buttonNextDisabled={isAnyErrors()}
      onNextClick={() => props.onConfirm(ahvValue, bvgValue)}
    />
  }

  function onAhvChange(value: string){
    setAhvValue(value)
  }

  function onBvgChange(value: string){
    setBvgValue(value)
  }

  const isValidationError = () => {
    if (ahvValue === "0" && bvgValue === "1") {
      return true;
    }
  }

  const isAnyErrors = () => {
    if (isValidationError()) return true;
    if (!ahvValue || !bvgValue) {
      return true;
    }
  }

  return (
    <Page>
      <OnboardingLayout
        footer={renderFooter()}
        contentMaxWidth='100%'
        hideStepper={!props.headerSelectedStepIndex}
        selectedStepIndex={props.headerSelectedStepIndex}
        progress={props.headerProgress}
        backButtonLink={props.headerBackButtonLink}
      >
        <SplitLayout className={styles.splitView}>
          <div className={styles.titleSection}>
            <h1>{props.title}</h1>
            <h3>{props.subtitle}</h3>
          </div>
          <div className={styles.contentSection}>
            <Panel className={styles.panel}>
              <h3>{props.data[0].title}</h3>
              <p>{props.data[0].description}</p>
              <div className={styles.radioContainer}>
                <CheckMarkRadio name='ahv' value='1' onChange={e => onAhvChange(e.currentTarget.value)}>{t('common.yes')}</CheckMarkRadio>
                <CheckMarkRadio name='ahv' value='0' onChange={e => onAhvChange(e.currentTarget.value)}>{t('common.no')}</CheckMarkRadio>
              </div>
            </Panel>
            <Panel className={styles.panel}>
              <h3>{props.data[1].title}</h3>
              <p>{props.data[1].description}</p>
              <div className={styles.radioContainer}>
                <CheckMarkRadio name='bvg' value='1' onChange={e => onBvgChange(e.currentTarget.value)}>{t('common.yes')}</CheckMarkRadio>
                <CheckMarkRadio name='bvg' value='0' onChange={e => onBvgChange(e.currentTarget.value)}>{t('common.no')}</CheckMarkRadio>
              </div>
            </Panel>
            {isValidationError() && <ValidationErrorMessage className={styles.errorMessage} message={t('errors.general')}/>}
          </div>
        </SplitLayout>
      </OnboardingLayout>
    </Page >
  );
};

export default AccountOptionsLayout;
