import styles from "./StrategyRisksInfo.module.scss";
import {Doughnut} from "react-chartjs-2";
import StrategUtils from "../../../App/Libs/Helper/Strategy";
import React from "react";
import {FilteredAssetClassDto} from "../../../App/Models/Contracts/FilteredAssetClassDto";
import {ModelPortfolioDto} from "../../../App/Models/Contracts/Strategy/ModelPortfolioDto";
import {StrategyDto} from "../../../App/Models/Contracts/Strategy/StrategyDto";
import t from '../../../App/Libs/Translate/Translate'

type StrategyRisksInfoType = {
  details: ModelPortfolioDto,
  data: StrategyDto,
  detailsIndex: number
  showDescription? : boolean
}

const StrategyRisksInfo = (props: StrategyRisksInfoType) => {


  function getRiskPercentage() {
    let total = 0
    props.details.positions
      .filter(x => x.security.assetClass.externalId === "Equity").forEach(x => {
      total += x.allocation
    })
    return Math.round(total * 100.0)
  }

  function getChartData(assetClasses: Array<FilteredAssetClassDto>) {

    let labels = assetClasses.map(x => x.name)
    let allocations = assetClasses.map(x => Math.round(x.positions.reduce((a, b) => a + b.allocation, 0) * 100))
    let colors = assetClasses.map(x => x.color);
    return {
      maintainAspectRatio: false,
      responsive: false,
      labels: labels,
      datasets: [
        {
          data: allocations,
          backgroundColor: colors,
          hoverBackgroundColor: colors
        }
      ]
    }
  }

  const bondsOrShares = props.data?.modelPortfolios[props.detailsIndex]?.name === "relevate safe" ? "Obligationen" : "Aktienanteil"

  return (
    <>
      <h6 className={styles.detailsRiskInfo}>
          { props.data?.modelPortfolios[props.detailsIndex]?.name === "relevate safe"
            ? t('account.strategy.choose.popup.title_1').replace('[PERCENT]', `${getRiskPercentage()} %`)
            : t('account.strategy.choose.popup.title_2').replace('[PERCENT]', `${getRiskPercentage()} %`)
          }
      </h6>
      <p className={styles.investmentClasses}>{t('account.strategy.choose.popup.chart.title')}</p>
      {props.showDescription && <p className={styles.description}>Ihre Obligationen werden in Schweizer Franken gehandelt, dadurch entstehen keine zusätzlichen Risiken durch Fremdwährungen. Das durchschnittliche Bonitätsrating – die Bewertung von Fähigkeit und Wille von Unternehmen, Schulden zurückzuzahlen – entspricht der Stufe AA (höchstes Rating: AAA). </p>}
      {/* !!! Needs getting the description of strategy from server 👆*/}
      <hr className={styles.lineSeparator} />
      {
        <div className={styles.chartSection}>
          <div className={styles.chartContainer}>
            <Doughnut
              data={getChartData(StrategUtils.getFilteredAssetClass(props.details.positions))}
              options={{
                plugins: {
                  legend: {
                    display: false
                  },
                },
                responsive: true,
                cutout: 170,
                elements: {
                  arc: {
                    borderWidth: 0
                  }
                }
              }}
            />
          </div>
          <div>
            {
              StrategUtils.getFilteredAssetClass(props.details.positions).map(ac =>{
                return  <p className={styles.chartLegend}> <span className={styles.assetClassColor} style={{backgroundColor: ac.color}}></span>{ac.name} {Math.round(StrategUtils.getPositionsAllocation(ac.positions) * 100.0)}.0%</p>
              })
            }
          </div>
        </div>
      }
      {
        StrategUtils.getFilteredAssetClass(props.details.positions).map(ac => {
          return (
            <div className={styles.assetClassDiv}>
              <div className={styles.detailsPercentRecordDiv}>
                <p className={styles.detailsAssetClassName}>{ac.name}</p>
                <p className={styles.detailsAssetClassPercentage}>{Math.round(StrategUtils.getPositionsAllocation(ac.positions) * 100.0)}.0%</p>
              </div>
              {
                ac.positions.map(pos => {
                  return (
                    <>
                      <div className={styles.detailsPercentRecordDiv}>
                        <p className={styles.detailsPercentRecordDivName}>{pos.security.name}</p>
                        <p className={styles.detailsAssetClassPercentage}>{Math.round(pos.allocation * 100)}.0%</p>
                      </div>
                    </>
                  )
                })
              }
            </div>
          )
        })
      }
    </>
  )
}


const Disclaimer = () => {
  return (
    <>
      <p className={styles.disclaimerTitle}>{t('account.strategy.choose.popup.disclaimer.title')}</p>
      <p className={styles.detailsInfoText}>{t('account.strategy.choose.popup.disclaimer.p1')}</p>
      <p className={styles.detailsInfoText}>
        {t('account.strategy.choose.popup.disclaimer.p2')}
        <br/>
        {t('account.strategy.choose.popup.disclaimer.p3')}
      </p>
      <p className={styles.detailsInfoText}>{t('account.strategy.choose.popup.disclaimer.p4')}</p>
      <p className={styles.detailsInfoText}>{t('account.strategy.choose.popup.disclaimer.p5')}</p>
      <p className={styles.detailsInfoText}>{t('account.strategy.choose.popup.disclaimer.p6')}</p>
      <p className={styles.detailsInfoText}>{t('account.strategy.choose.popup.disclaimer.p7')}</p>
      <p className={styles.detailsInfoText}>{t('account.strategy.choose.popup.disclaimer.p8')}</p>
    </>
  )
}

const StrategyDetails = () => {

  const strategyData = [
    {
      name: t('account.strategy.choose.popup.strategy_details.headers.focus'),
      description: t('account.strategy.choose.popup.strategy_safe.focus_description'),
    },
    {
      name: t('account.strategy.choose.popup.strategy_details.headers.ideal_for'),
      description: t('account.strategy.choose.popup.strategy_safe.ideal_for_description'),
    },
    {
      name: t('account.strategy.choose.popup.strategy_details.headers.investment_period'),
      description: t('account.strategy.choose.popup.strategy_safe.investment_period_description'),
    },
    {
      name: t('account.strategy.choose.popup.strategy_details.headers.the_bond'),
      description: t('account.strategy.choose.popup.strategy_safe.the_bond_description'),
    },
    {
      name: t('account.strategy.choose.popup.strategy_details.headers.average_credit_rating'),
      description: t('account.strategy.choose.popup.strategy_safe.average_credit_rating_description'),
    }
  ]


  return (
    <div className={styles.strategyDetailsContainer}>
      <p className={styles.strategyDetailsTitle}>{t('account.strategy.choose.popup.strategy_details.title')}</p>
      {strategyData.map((item) => {
        return (
          <div className={styles.strategyDetailsColumn} key={item.name}>
            <div className={styles.strategyDetailsParam}>{item.name}:</div>
            <div className={styles.strategyDetailsDescription}>{item.description}</div>
          </div>
        )
      })}
    </div>
  )
}

export {Disclaimer, StrategyDetails}

export default StrategyRisksInfo;
