import { useState } from 'react';
import PortfolioValuePanel from '../../Organisms/Portfolio/PortfolioValuePanel';
import SwitchBox from '../../Atoms/Switch/SwitchBox';
import styles from './DashboardPensionCard.module.scss';
import t from '../../../App/Libs/Translate/Translate'
import MoreInfoLink from "../Link/MoreInfoLink";
import Link from "../../Atoms/Link/Link";

type DashboardPensionCardProps = {
  title?: string,
  description?: string,
  maxValue?: number,
  rulesList?: Array<String>,
  defaultValue?: number,
  onPopupClick?: () => void,
}

const DashboardPensionCard = (props: DashboardPensionCardProps) => {

  const [disabled, setDisabled] = useState(false);

  return (
    <div className={styles.dashboardPensionCard}>
        <div className={styles.title}>{props.title}</div>

        <div className={styles.description}>
            {props.description}
        </div>

        <ul>
            {props.rulesList.map((value: string, i: number) => (
                <li key={i}>
                    { value }
                </li>
            ))}  
        </ul>

      {props.onPopupClick &&
        <div className={styles.formGroup}>
          <Link onClick={props.onPopupClick} className={styles.tdNone}>
            <MoreInfoLink onClick={props.onPopupClick}>{t('common.learn_more')}</MoreInfoLink>
          </Link>
        </div>
      }

        <div className={styles.description}>
            {t('dashbord.pension.card.detail_desc_1')}
        </div>
     
        <PortfolioValuePanel
            key={props.title}
            name={props.title} 
            max={props.maxValue || 0}
            value={props.defaultValue}
            disabled={disabled}
        />

        <div className={styles.formGroup}>
            <SwitchBox
              onChange={() => {setDisabled(!disabled)}}
            >
                {t('dashbord.pension.card.detail_desc_2')}
            </SwitchBox>
        </div>

    </div>
  )
};

export default DashboardPensionCard;
