import React, {useState} from 'react';
import { Image } from 'react-bootstrap';
import { Input as ReactInput, InputProps } from 'reactstrap';
import hiddenImg from '../../../Assets/Images/Pass-Hidden.svg';
import visibleImg from '../../../Assets/Images/Pass-Visible.svg';
import styles from './Input.module.scss';

type PasswordProps = {
    isVisible?: boolean
}

const Password =  React.forwardRef((props: InputProps & PasswordProps, ref: any ) => {
    const [isVisible, setIsVisible] = useState<boolean | undefined>(props.isVisible)
    
    const onClick =() => {
        setIsVisible(!isVisible);
    }

        return (
            <div className={props.error && styles.error_bg}>
                <div className={styles.pass}>
                    <ReactInput
                      innerRef={ref}
                      {...props}
                      type={isVisible ? "text" : "password"}
                      autoComplete={props.autoComplete ?? "off"}
                    />
                    {props.disableLabel !== true &&
                        <span>{props.placeholder}</span>
                    } 
                    <div className={styles.passButton} onClick={() => onClick()}>
                        {
                            isVisible ?
                                <Image src={visibleImg} alt="" />
                                :
                                <Image src={hiddenImg} alt="" />
                        }
                    </div>
                </div>
                {props.error &&
                    <div className={styles.error_tip}>
                        {props.error}
                    </div>
                }
            </div>
        );
})

export default Password;