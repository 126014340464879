import classNames from 'classnames';
import React, { createRef, useEffect } from 'react';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import styles from './Carousel.module.scss';

export enum CarouselEffects{
    Scale
}

type Props = {
    className?: string,
    indicatorClassName?: string;
    slidesClassName?: string;
    selectedIndex?: number
    effect?: CarouselEffects
    autoScroll?: boolean
    children?: React.ReactNode
}

const Carousel: React.FC<Props> = (props) => {

    const indicatorRef = createRef<HTMLDivElement>()
    var sliderContainer: HTMLDivElement | null = null

    ComponentDidMount(() =>{
        scrollToIndex(props.selectedIndex || 0)
    })

    useEffect(() =>{
        if(props.autoScroll !== false)
            scrollToIndex(props.selectedIndex!)
    },[props.selectedIndex])

    function selectIndicator(selectIndex: number) {
        if (!indicatorRef.current)
            return

        var elements = indicatorRef.current!.children

        if (selectIndex >= elements.length)
            selectIndex = elements.length - 1

        for (var _i = 0; _i < elements.length; _i++) {
            var element = elements[_i];
            element.classList.remove(styles.selected)
            if (_i === selectIndex)
                element.classList.add(styles.selected)
        }
    }



    function onScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {

        var sliderSelectedPosition = e.currentTarget.clientWidth / 2
        var elements = e.currentTarget.children;

        var elementMatched = false
        for (var _i = 0; _i < elements.length; _i++) {
            var rect1 = elements[_i].getBoundingClientRect()
            var element = elements[_i] as HTMLElement
            if (sliderSelectedPosition >= rect1.left &&
                sliderSelectedPosition <= (rect1.left + rect1.width)) {
               element.setAttribute('checked', 'true')
               selectIndicator(_i)
               elementMatched = true
            }
            else{
                element.removeAttribute('checked')
            }
        }

        if(!elementMatched)
            selectIndicator(-1)
    }

    function scrollToIndex(index: number) {

        var elements = sliderContainer!.childNodes;


        if (index >= elements.length || index < 0)
            return

        var element = elements[index] as HTMLElement

        if(!element)
            return 
            
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })

    }

    function getEffectClassName(){
        switch(props.effect){
            case CarouselEffects.Scale:
                return styles.scaleEffect
            default:
                return undefined
        }
    }

    var slideIndex = 0

    return (
        <div className={classNames(styles.slider, props.className)}>
            <div ref={r => sliderContainer = r} className={classNames(props.slidesClassName, styles.slides, getEffectClassName())} onScroll={e => onScroll(e)}>
                {props.children}
            </div>

            <div ref={indicatorRef} className={classNames(styles.indicatorWrapper, props.indicatorClassName)}>
                {
                    React.Children.map(props.children, (child: any) => {
                        var href = "#slide-" + (slideIndex + 1)

                        var element = (<a className={classNames((slideIndex === 0 ? styles.selected : undefined))} href={href}>&nbsp;</a>)
                        slideIndex++
                        return element

                    })
                }
            </div>
        </div>
    );
};

export default Carousel;