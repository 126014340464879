import classNames from 'classnames';
import React, { FormEvent, useEffect } from 'react';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import { Callback } from '../../../App/Models/Callback';
import styles from './Slider.module.scss';


type Props = {
    className?: string
    value?: number
    max?: number
    min?: number,
    defaultValue?: number
    onInput?: Callback<FormEvent<HTMLInputElement>>
}


const Slider: React.FC<Props> = (props) => {

    var leftDiv: HTMLDivElement | null = null
    var slider: HTMLInputElement | null = null
    var thumb: HTMLSpanElement | null = null

 
    ComponentDidMount(() =>{
        updateProgress()
    })

    useEffect(() =>{
        updateProgress();
    })

    function onInput(e: FormEvent<HTMLInputElement>) {
        updateProgress()
        if (props.onInput)
            props.onInput(e)
    }

    function updateProgress() {
        if (!slider)
            return

        var max = parseInt(slider!.max) || 100
        var min = parseInt(slider!.min || "0")
        var value = ((parseInt(slider!.value) - min) / (max - min)) * 100
        if (leftDiv)
            leftDiv!.style.width = value + '%'
        if (thumb)
            thumb!.style.left = value + '%'
    }

    

    return (
        <div className={classNames(styles.main, props.className)}>
            <div className={styles.container}>
                <div className={styles.progressDiv} ref={r => leftDiv = r}></div>
                <input ref={r => slider = r} className={styles.slider}
                    max={props.max} min={props.min}
                    defaultValue={props.defaultValue}
                    value={props.value}
                    type="range" onInput={e => onInput(e)} />
                <div className={styles.thumbDiv}>
                    <span ref={r => thumb = r}></span>
                </div>
            </div>
        </div>
    );
};

export default Slider;