import WebClient from "../Libs/WebClient/WebClient";
import { Environment } from "./Environment";

export default class Configuration {

    static Configure() {

        WebClient.SetGlobalBaseUrl(Environment.BaseUrl)
        WebClient.SetGlobalLanguage('de');

        WebClient.Intercept((response: any) => {
            if (response?.status === 401) {

                if(Environment.IsSsoAuthentication){
                    window.location.assign(Environment.SsoRegisterUrl)
                    return
                }
            }
        })
    }

}