import React, { useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ComponentDidMount } from '../../../App/Libs/Helper/Helper';
import { ContactActions } from '../../../App/Mobx/Actions/ContactActions';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';
import { ProductEnum } from '../../../App/Models/Enums/ProductEnum';
import { TransferSource } from '../../../App/Models/Enums/TransferSource';
import WebSync from '../../Molecules/DataSync/WebSync';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import SurveyAnswerSimple from '../../Organisms/Survey/SurveyAnswerSimple';
import t from '../../../App/Libs/Translate/Translate';
import styles from './AccountFinancialKnowledgeSurveyPage.module.scss';

const AccountFinancialKnowledgeSurveyPage = (props: RouteComponentProps) => {

  const financialKnowledgeOptions = [
    {
      id: '1',
      title: t('account.survey.financial.knowledge.little_knowledge_title'),
      description: t('account.survey.financial.knowledge.little_knowledge_desc'),
    },
    {
      id: '2',
      title: t('account.survey.financial.knowledge.advanced_knowledge_title'),
      description: t('account.survey.financial.knowledge.advanced_knowledge_desc'),
    },
    {
      id: '3',
      title: t('account.survey.financial.knowledge.expert_title'),
      description: t('account.survey.financial.knowledge.expert_desc'),
    }
  ]

  const [selectedAnswerId, setSelectedAnswerId] = useState<string>()

  const { Account } = useStore()

  const [isSynced, setIsSynced] = useState<boolean>(false)

  const webRef = useRef<WebSync>()

  ComponentDidMount(() => {
      setIsSynced(true)
  })

  function onAnswerClick(id: string) {
    setSelectedAnswerId(id)
    webRef.current.Activity.show()
    ContactActions
      .PostExperience({
        levelOfExperience: parseInt(id)
      })
      .onSuccess(() => {
        goToNextPage()
        webRef.current.Activity.hide()
      })
      .onFail(() =>{
        webRef.current.Activity.hide()
      })

  }

  function goToNextPage() {
    var vp = Account.virtualPortfolios[0]
    if (vp.product.id === ProductEnum.Independent)
      props.history.push(`/onboarding/${vp.proposalId}/Assets/Value`)
    else{
      props.history.push(`/onboarding/${vp.proposalId}/Survey/Horizon`)
    }
  }

  return (
    <Page>
      <WebSync ref={webRef}>
        <OnboardingLayout
          contentMaxWidth='1450px'
          selectedStepIndex={1} progress={0.5}>
          {isSynced &&
            <div className={styles.main}>
              <div className={styles.titleSection}>
                <h1 className={styles.title}>{t('account.survey.financial.knowledge.title')}</h1>
                <h3 className={styles.subtitle}>{t('account.survey.financial.knowledge.subtitle')}</h3>
              </div>
              <div className={styles.selectors}>
                {financialKnowledgeOptions.map(option => (
                  <SurveyAnswerSimple
                    key={option.id}
                    title={option.title}
                    description={option.description}
                    isChecked={option.id === selectedAnswerId}
                    onClick={() => onAnswerClick(option.id)}
                  ></SurveyAnswerSimple>
                ))}
              </div>
            </div>

          }
        </OnboardingLayout>
      </WebSync>
    </Page>
  );
};

export default AccountFinancialKnowledgeSurveyPage;
