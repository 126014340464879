import React, { useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import FooterNavBar, { NavbarButtonType } from '../../../Molecules/Navbar/FooterNavbar';
import ChooseProductPageLayout from "../../../Organisms/Layout/ChooseProductPageLayout/ChooseProductPageLayout";
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import { ProductEnum } from '../../../../App/Models/Enums/ProductEnum';
import t from '../../../../App/Libs/Translate/Translate'
import { ProductDto } from '../../../../App/Models/Contracts/Compliance/ProductDto';
import { PortfolioActions } from '../../../../App/Mobx/Actions/PortfolioActions';
import { TransferSource } from '../../../../App/Models/Enums/TransferSource';
import WebSync from '../../../Molecules/DataSync/WebSync';


type ProductPage3aProps = RouteComponentProps & {
  showBackgroundImage?: boolean,
  productOnly3a?: boolean,
}


const ProductPage3a: React.FC<ProductPage3aProps> = (props) => {

  const { Account, Compliance } = useStore()

  const webSyncRef = useRef<WebSync>()

  function renderFooter() {
    return <FooterNavBar
      buttonBackText={t('common.back')}
      linkToNext='/'
      buttonNextText={t('common.end')}
      buttonNextType={NavbarButtonType.Close} />
  }

  function toNextPage(proposalId: number){
    props.history.push(`/onboarding/${proposalId}/survey/horizon`)
  }

  function createVirtualPortfolio(proposalId: number, product: ProductDto) {

    Account.virtualPortfolios = [{
      product: product,
      proposalId: proposalId,
      name: `Portfolio 1`
    }]
  }


  function onProductClick(product: ProductDto) {
    var vp = Account.virtualPortfolios.find(x => x.product.id === product.id && x.riskCategoryId === undefined)

    if (vp) {
      toNextPage(vp.proposalId)
      return
    }

    webSyncRef.current.Activity.show()
    PortfolioActions
      .PostPortfolioProposal({
        productId: product.id
      })
      .onSuccess(x => {
        createVirtualPortfolio(x.data.proposalId, product)
        toNextPage(x.data.proposalId)
        webSyncRef.current.Activity.hide()
      })
      .onFail(() => {
        webSyncRef.current.Activity.hide()
      })

  }

  return (
    <Page>
      <WebSync ref={webSyncRef}>
        <OnboardingLayout
          contentMaxWidth='1400px'
          footer={renderFooter()}
          showBackgroundImage={true}
          hideStepper={true}>
          <ChooseProductPageLayout
            {...props}
            onProductClick={p => onProductClick(p)}
            title={t('register.contract.product_page_3a.title')}
            secondaryText={t('register.contract.product_page_3a.subtitle')}
            products={Compliance.Products?.filter(x => x.id === ProductEnum.PK)} />
        </OnboardingLayout>
      </WebSync>
    </Page>
  );
};

export default ProductPage3a;
