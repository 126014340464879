import { configure, makeObservable, observable } from "mobx";
import { hydrateStore } from "mobx-persist-store";
import React, { createContext, useContext } from "react";
import { AccountStore } from "./AccountStore";
import { Appstore } from "./AppStore";
import { AuthStore } from "./AuthStore";
import { CommonStore } from "./CommonStore";
import { ComplianceStore } from "./ComplianceStore";
import { ContactStore } from "./ContactStore";
import { DashboardStore } from "./DashboardStore";
import { QuestionnaireStore } from "./QuestionnaireStore";
import { RegisterStore } from "./RegisterStore";
import { RiskProtectionCalculationStore } from "./RiskProtectionCalculationStore";


export class MainStore {
    @observable Register = new RegisterStore()
    @observable Auth = new AuthStore()
    @observable Questionnaire = new QuestionnaireStore()
    @observable Compliance = new ComplianceStore()
    @observable Contact = new ContactStore()
    @observable App = new Appstore()
    @observable Common = new CommonStore()
    @observable Account = new AccountStore()
    @observable Dashboard = new DashboardStore()
    @observable RiskProtectionCalculation = new RiskProtectionCalculationStore()
  GlobalStore: any;

    constructor() {
        makeObservable(this)

        Promise.all([
            hydrateStore('AuthStore'),
            hydrateStore('AccountStore'),
            hydrateStore('RiskProtectionCalculationStore')
        ]).then(() => {
            this.App.isAppStarted = true

        })

    }
}

configure({ enforceActions: "observed" })

export const GlobalStore = new MainStore()
export const GlobalStoreContext = React.createContext<MainStore>(GlobalStore);