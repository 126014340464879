import SuccessTransferPageLayout from "../../../Organisms/Layout/SuccessTransferPageLayout";
import React from 'react';
import t from '../../../../App/Libs/Translate/Translate'

const SuccessStartedTransfer = () => {

    return (
          <SuccessTransferPageLayout linkTo="/onboarding/Contract/ProductPage3a" buttonText={t('common.trigger_transfer')}/>
    )
};

export default SuccessStartedTransfer;
