import styles from "./DownloadButton.module.scss";
import downloadIcon from "../../../Assets/Images/Contract.svg";
import {Button} from "./Button";
import React, {MouseEventHandler} from "react";
import classNames from "classnames";

type DownloadButtonType = {
  to? : string
  className?: string
  onClick?: MouseEventHandler<HTMLElement>
  children: any
}

const DownloadButton = (props: DownloadButtonType) => {
  return (
    <Button onClick={props.onClick}
            className={classNames(styles.downloadButton, props.className)}
            to={props.to}
    >
      <span className={styles.downloadIcon}>
        <img src={downloadIcon} alt="download icon"/>
      </span>
      {props.children}
    </Button>
  )
}

export default DownloadButton;