import { default as React } from 'react';
import Logo from '../../Atoms/Logo/Logo';
import Menu from '../Menu/Menu';
import { Button } from '../../Atoms/Button/Button';
import styles from './DashboardLayout.module.scss';
import  Link  from '../../Atoms/Link/Link';
import t from '../../../App/Libs/Translate/Translate';
import classNames from 'classnames';
import { FuncEmpty } from '../../../App/Models/Callback';
import { IWebClientPromise } from '../../../App/Libs/WebClient/WebClientPromise';
import WebSync from '../../Molecules/DataSync/WebSync';
import DashboardSideMenu from '../Popup/DashboardSideMenu/DashboardSideMenu';

type PropsType = {
    sync?: FuncEmpty<IWebClientPromise<any>>
    syncRef?: React.MutableRefObject<WebSync>
    children?: Array<JSX.Element> | JSX.Element,
}

const DashboardLayout = (props: PropsType) => {
    return (
        <div className={styles.main}>
            <div className={styles.mobileMenu}>
                <Logo className={styles.logo} />
                <DashboardSideMenu></DashboardSideMenu>
            </div>
            <div className={styles.sidebar}>
                <Logo className={styles.logo} />

                <div className={styles.menu}>
                    <Menu />
                </div>
                <div className={classNames(styles.menu, styles.menuFooter)}>
                    <div className={styles.newPortfolioBlock}>
                        <Button
                            to="/onboarding/Open/Additional"
                            className={styles.buttonAddPortfolio}
                        >
                        </Button>
                        <Link
                            to="/onboarding/Open/Additional"
                            className={styles.linkAddPortfolio}
                        >
                          {t("dashboard.menu.new.portfolio")}
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.contentContainer}>
                <WebSync ref={props.syncRef} client={props.sync}>
                    <div className={styles.contentWrapper}>

                        <div className={styles.content}>
                            {props.children}
                        </div>
                        <footer className={styles.footer}>
                            <span>© 2022 relevate by PensExpert   | {t("dashboard.menu.imprint")}   | {t("dashboard.menu.privacy")}</span>
                        </footer>
                    </div>
                </WebSync>
            </div>
        </div>
    );
};

export default DashboardLayout;
