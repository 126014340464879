import WebClient from "../../Libs/WebClient/WebClient";
import { TaxInfoDto } from "../../Models/Contracts/Taxes/TaxInfoDto";
import { TaxInfoResultDto } from "../../Models/Contracts/Taxes/TaxInfoResultDto";
import { GlobalStore } from "../Stores/MainStore";

export class TaxActions{
    static GetInformation(model: TaxInfoDto){
        return WebClient
            .Create()
            .Authorize(GlobalStore.Auth.ToAuthorization())
            .Post<Array<TaxInfoResultDto>>('tax/info', model)
    }
}