import React from 'react';
import styles from './DashboardTitle.module.scss';

type Props = {
    children?: React.ReactNode
}

const DashboardTitle: React.FC<Props> = (props) => {
    return (
        <h1 className={styles.dashboardTitle}>
            {props.children}
        </h1>
    );
};

export default DashboardTitle;
