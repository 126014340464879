import React, {useState} from 'react';
import {useStore} from '../../../App/Mobx/Infrastructure/mobx-hook';
import {RouteComponentProps, useParams} from 'react-router-dom';
import t from '../../../App/Libs/Translate/Translate';
import {VirtualPortfolioDto} from '../../../App/Models/Contracts/Portfolio/VirtualPortfolioDto';
import {ProductEnum} from '../../../App/Models/Enums/ProductEnum';
import AccountSplitPortfolioTemplate from "../../Templates/AccountSplitPortfolioTemplate";
import { ProposalParams } from '../../../App/Models/Params/ProposalParams';

const portfoliosDummy = [
  {
    name: "Portfolio 1",
    disabled: false
  },
  {
    name: "Portfolio 2",
    disabled: true
  }
]

const AccountPortfolioSplitPage = (props: RouteComponentProps) => {


  const [isSplitEnabled, setIsSplitEnabled] = useState<boolean>(false);
  const [portfolios, setPortfolios] = useState<Array<VirtualPortfolioDto>>([])

  const {Account, Compliance} = useStore()

  const {proposalId} = useParams<ProposalParams>()
  const currentPortoflio = Account.getCurrentVirtualPortfolio(proposalId)

  const singlePortfolio: Array<VirtualPortfolioDto> = [{
    name: portfoliosDummy[0].name,
    amount: Account.assetAmount,
  }]

  function onSplitChange(e: HTMLInputElement) {
    setIsSplitEnabled(e.checked)
  }

  function onNextClick() {
    if (isSplitEnabled)
      Account.virtualPortfolios = portfolios.map<VirtualPortfolioDto>((x, index) => {
        return {
          amount: x.amount,
          name: x.name,
          modelPortfolio: x.modelPortfolio,
          product: index > 0 && currentPortoflio.product.id === ProductEnum.Independent ? Compliance.PensFreeProduct() : currentPortoflio.product,
          proposalId: index == 0 ? currentPortoflio.proposalId : undefined
        }
      })
    else
      Account.virtualPortfolios = [ currentPortoflio ]

    props.history.push(`/onboarding/${proposalId}/Survey/Horizon`)
  }

  function onSplitValueChange(e: Array<VirtualPortfolioDto>) {
    setPortfolios(e)
  }

  return (
    <AccountSplitPortfolioTemplate {...props}
                                   title={t('account.portfolio.split.title')}
                                   subtitle={t('account.portfolio.split.subtitle')}
                                   selectedStepIndex={1}
                                   progress={0.5}
                                   portfolios={portfolios}
                                   portfoliosDummy={portfoliosDummy}
                                   onNextClick={onNextClick}
                                   onSplitValueChange={onSplitValueChange}
                                   onSplitChange={onSplitChange}
                                   isSplitEnabled={isSplitEnabled}
                                   assetAmount={Account.assetAmount}/>
  )

}

export default AccountPortfolioSplitPage;
