import React from 'react';
import {PopUpProps} from '../../../Atoms/Popup/Popup';
import InfoPopup from '../../../Molecules/Popup/InfoPopup';
import styles from './DashboardCountryListInfoPopup.module.scss';
import Link from "../../../Atoms/Link/Link";
import t from '../../../../App/Libs/Translate/Translate';

const DashboardCountryListInfoPopup = (props: PopUpProps) => {

  function onExit(obj: HTMLElement) {
    if (props.onExit)
      props.onExit(obj)
  }

const country1 = [
  t('dashbord.define.insurance.coverage.country_list.high_risk.Afghanistan'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Algerien'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Angola'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Antarktis'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Äthiopien'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Bangladesh'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Bolivien'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Burundi'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Elfenbeinküste'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Eritrea'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Guinea'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Haiti'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Honduras'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Irak')
]

const country2 = [
  t('dashbord.define.insurance.coverage.country_list.high_risk.Iran'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Jemen'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Kambodscha'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Kamerun'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Kirgisistan'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Kolumbien'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Kongo'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Libanon'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Liberia'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Libyen'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Mali'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Mauretanien'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Mosambik'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Niger')
]

const country3 = [
  t('dashbord.define.insurance.coverage.country_list.high_risk.Nigeria'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.NordKorea'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Pakistan'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Palästina'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Papua_Neu_Guinea'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Ruanda'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Somalia'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Sudan'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Syrien'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Tadschikistan'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Tschad'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Uganda'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Ukraine'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Zentralafr_Republik'),
  t('dashbord.define.insurance.coverage.country_list.high_risk.Zimbabwe')
]

const country4 = [
  t("dashbord.define.insurance.coverage.country_list.high_risk.Ägypten"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Armenien"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Aserbaidschan"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Benin"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.BurkinaFaso"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.CostaRica"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Dschibuti"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Ecuador"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Gabun"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Gambia"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Georgien"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Guatemala"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Indien")
]

const country5 =[
  t("dashbord.define.insurance.coverage.country_list.high_risk.Indonesien"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Jamaika"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Kasakhstan"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Kenia"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Lesotho"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Madagaskar"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Malawi"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Mexiko"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Moldawien"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Myanmar_Burma"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Namibia"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Nepal"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Nicaragua")
]

const country6 = [
  t("dashbord.define.insurance.coverage.country_list.high_risk.Philippinien"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Saudi_Arabien"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Senegal"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Sierra_Leone"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Sri_Lanka"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Swasiland"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Tansania"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Togo"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Tunesien"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Türkei"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Turkmenistan"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Urugay"),
  t("dashbord.define.insurance.coverage.country_list.high_risk.Usbekistan")
]

   return (
    <InfoPopup {...props} frameClassName={styles.main} className={styles.popup}>
      <div className={styles.content}>
        <h2>{t('dashbord.define.insurance.coverage.country_list.title')}</h2>
        <p className={styles.paragraph}>
          {t('dashbord.define.insurance.coverage.country_list.text')}
        </p>
        <h3 className={styles.subtitle}>{t('dashbord.define.insurance.coverage.country_list.high_risk')}</h3>
        <hr className={styles.line}></hr>
        <p className={styles.paragraph}>{t('dashbord.define.insurance.coverage.country_list.high_risk.subtitle')}</p>
        <div className={styles.countrysContainer}>
          <div className={styles.countryContainer}>
            {country1.map((elem) => (<div>{elem}</div>))}
          </div>
          <div className={styles.countryContainer}>
            {country2.map((elem) => (<div>{elem}</div>))}
          </div>
          <div className={styles.countryContainer}>
            {country3.map((elem) => (<div>{elem}</div>))}
          </div>
        </div>

        <h3 className={styles.subtitle}>{t('dashbord.define.insurance.coverage.medium_risc.title')}</h3>
        <hr className={styles.line}></hr>
        <p className={styles.paragraphMediumRisk}>{t('dashbord.define.insurance.coverage.medium_risc.subtitle1')}</p>
        <p className={styles.paragraphMediumRisk}>{t('dashbord.define.insurance.coverage.medium_risc.subtitle2')}</p>
        <div className={styles.countrysContainer}>
          <div className={styles.countryContainer}>
            {country4.map((elem) => (<div>{elem}</div>))}
          </div>
          <div className={styles.countryContainer}>
            {country5.map((elem) => (<div>{elem}</div>))}
          </div>
          <div className={styles.countryContainer}>
            {country6.map((elem) => (<div>{elem}</div>))}
          </div>
        </div>
        <Link className={styles.closeText} onClick={a => onExit(a)}>{t('common.conclude')}</Link>
      </div>
    </InfoPopup>
  );
};

export default DashboardCountryListInfoPopup;
