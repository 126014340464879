import React from 'react';

import DashboardSubMenuCard from './DashboardSubMenuCard'

import DocumentIcon from "../Menu/icons/DocumentIcon";
import ChatIcon from "../Menu/icons/ChatIcon";
import ProfileIcon from "../Menu/icons/ProfileIcon";
import GearIcon from "../Menu/icons/GearIcon";

import styles from './DashboardSubMenu.module.scss';
import t from '../../../App/Libs/Translate/Translate';

const DashboardSubMenu = () => {
    return (
      <div className={styles.container}>
        <div className={styles.main}>
            <DashboardSubMenuCard
                title={t("dashbord.documents.title")}
                description={t("dashboard.submenu.documents.contracts")}
                Icon={DocumentIcon}
                to="/Dashboard/Documents"
            />
            <DashboardSubMenuCard
                title={t("dashboard.menu.help.center")}
                description={t("dashboard.submenu.faq")}
                Icon={ChatIcon}
                to="/Dashboard/Faq"
            />
            <DashboardSubMenuCard
                title={t("dashboard.menu.profile")}
                description={t("dashboard.submenu.customize_profile")}
                Icon={ProfileIcon}
                to="/Dashboard/Profile"
            />
          <div className={styles.hidden}>
            <DashboardSubMenuCard
              title="Einstellungen"
              description="Benachrichtigungen einstellen"
              Icon={GearIcon}
              to="/Dashboard/Messages"
            />
          </div>
        </div>
      </div>
    );
};

export default DashboardSubMenu;
