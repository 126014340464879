import classnames from 'classnames';
import React from 'react';
import styles from './Page.module.scss';
import { observer } from 'mobx-react';

interface Props {
    isLoading?: boolean,
    className?: string
    children?: React.ReactNode
}

const Page: React.FC<Props> = observer((props) => {
    return (
        <main className={classnames(styles.container, props.className)}>
            <div className={styles.scrollArea}>
                {props.children}
            </div>
        </main>
    );
});

export default Page;
