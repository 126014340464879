import React from 'react';
import {Accordion as ReactAccordion} from "react-bootstrap";
import dropDownIcon from '../../../Assets/Images/Dropwdown_arrow.svg';
import styles from './Accordion.module.scss';


export type DropdownsProps = {
  name?: string
  content?: JSX.Element
  withArrow?: boolean
  opened?: boolean
}

const Accordion = (props: DropdownsProps) => {

  return (
    <ReactAccordion defaultActiveKey={props.opened ? "0" : undefined}>

      <ReactAccordion.Toggle className={styles.accordionToggle} eventKey="0">
        <div className={styles.flex}>
          <div className={styles.name}>{props.name}</div>
          <div>{props.withArrow && <img className={styles.dropDownIcon} src={dropDownIcon} alt=""/>}</div>
        </div>
      </ReactAccordion.Toggle>

      <ReactAccordion.Collapse eventKey="0">
        <>
          {props.content}
        </>
      </ReactAccordion.Collapse>

    </ReactAccordion>
  );
};

export default Accordion;
