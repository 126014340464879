import { ArcElement, Chart } from 'chart.js';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';


export const data = {
    datasets: [
        {
            labels: ['yellow'],
            data: [50, 50],
            backgroundColor: [
                '#585656',
                '#585656'
            ],
            borderWidth: 0
        }
    ]
};

type Props = {
    index: number,
    count: number,
    progress: number,
    cutoutRadiusChart? : number
}


const PortfolioValueProgress = (props: Props) => {

    const {cutoutRadiusChart  = 90} = props;

    Chart.register(ArcElement);

    function getData(){

        var dataArr: Array<number> = []

        var backgroundColors: Array<string> = []
        for(var i = 0; i < props.count; i++){
            backgroundColors[i] = '#585656'
            dataArr[i] = (((props.count - 1) * (1- props.progress)) / (props.count - 1)) * 100
        }

        backgroundColors[(props.count - 1) - props.index] = '#00A76D'
        dataArr[(props.count - 1) - props.index] = props.progress * 100

        return {
            datasets: [
                {
                    data: dataArr,
                    backgroundColor: backgroundColors,
                    borderWidth: 0
                }
            ]
        }
    }

    return (
        <Doughnut width='100%' height='100%' data={getData()} options={{
            cutout: cutoutRadiusChart
        }}/>
    );
};

export default PortfolioValueProgress;
