import React from 'react';
import Page from "../../Molecules/Page/Page";
import ProductCard from '../../Molecules/ProductCard/ProductCard';
import DashboardLayout from '../../Organisms/Layout/DashboardLayout';
import ProfileSupport from '../../Organisms/ProfileSupport/ProfileSupport';

import styles from './BeneficiaryRegulationsWithContractPage.module.scss'
import DownloadButton from "../../Atoms/Button/DownloadButton";
import t from '../../../App/Libs/Translate/Translate'

const BeneficiaryRegulationsWithContractPage = () => {
  return (
    <Page>
      <DashboardLayout>
          <div className={styles.intro}>
              <h1 className={styles.title}>{t("dashboard.preferential.treatment.regulations.header")} </h1>
              <div className={styles.descriptionWrapper}>
                  <h3 className={styles.subtitle}>{t("dashboard.beneficiary.regulations.intro.subtitle")}</h3>
                  <p className={styles.description}>{t("dashboard.beneficiary.regulations.intro.description")}</p>
                  <DownloadButton>{t("dashboard.beneficiary.regulations.intro.download.document")}</DownloadButton>
              </div>
          </div>
          <div className={styles.actionsList}>
              <ProductCard
                  title={t("dashbord.adjust.benefit.header")}
                  to="/Dashboard/AdjustBenefit"
                  className={styles.squredCorner}
              />
              <ProductCard
                  title="Begünstigung auflösen"
                  to="/Dashboard/PreferentialTreatment"
                  className={styles.squredCorner}
              />
              <ProfileSupport
                  className={styles.squredCorner}
                  title={"dashboard.preferential.treatment.regulations.support"}
              />
          </div>
      </DashboardLayout>
    </Page>
  );
};

export default BeneficiaryRegulationsWithContractPage;
