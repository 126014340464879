import classNames from 'classnames';
import React from 'react';
import { StepTheme } from '../Tabs/Dependencies/StepTheme';
import Tabs from '../Tabs/Tabs';
import Step from './Dependencies/Step';

type Props = {
    theme?: StepTheme
    stepContainer?: string
    HideIndicator?: boolean
    progress?: number
}

class Stepper extends Tabs<Props, []> {

    setTabSelectedEnforce(Index: number) {
        super.setTabSelected(Index);
        if (this.handler)
            this.handler.setNavBarTitle(Index + 1);
    }

    setStartingStep() {
        var selected = this.state.selectedIndex || this.startingstep;
        this.setTabSelectedEnforce(selected);
    }


    getTabType() {
        return Step;
    }

    getIndex() {
        return this.props.selectedIndex;
    }

    componentDidMount() {
        this.setStartingStep();
    }

    isSelected(index: number, selectedIndex: number) {

    }

    getProgress(index: number){
        if(index > this.state.selectedIndex)
            return 0
        else if(index < this.state.selectedIndex)
            return 1
        else 
            return this.props.progress || 1
    }

    renderTabs() {
        var steps: Array<any> = [];
        this.headers.forEach((element, index) => {
            if (this.headers[index])
                steps.push(
                    <Step
                        key={index}
                        header={React.cloneElement(this.headers[index], { selected: index === this.state.selectedIndex })}
                        selected={index === this.state.selectedIndex}
                        progress={this.getProgress(index)}
                        onClick={() => this.tabSelected(index)}
                        index={index}
                        clsName={classNames(this.props.stepContainer, this.headers[index]?.props?.clsName)}
                        HideIndicator={this.props.HideIndicator}
                        theme={this.props.theme}
                    />
                );
        });
        return steps;
    }

}
export default Stepper;
