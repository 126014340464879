import styles from "./DashboardProfitPanel.module.scss"
import classNames from "classnames";
import arrowIcon from "../../../Assets/Images/arrow-up.svg";
import React from "react";
import Formatter from "../../../App/Libs/Formatter/Formatter";

type DashboardProfitPanelProps = {
  stockAmount: number;
  profitNumber: number
  percentage: number
  chartMode?: boolean
}

const DashboardProfitPanel = (props: DashboardProfitPanelProps) => {
  return (
    <>
      <div className={props.chartMode ? styles.amountChart: styles.amount}>
        {Formatter.formatNumber(props.stockAmount?.toFixed(2))}
        <span className={props.chartMode ? styles.amountCurrencyChart : styles.amountCurrency}>CHF</span>
      </div>
      <div className={props.chartMode ? styles.profitFiguresChart : styles.profitFigures}>
        <span className={classNames(props.chartMode ? styles.arrowIconChart : styles.arrowIcon, props.profitNumber < 0 && styles.stockDown)}>
          <img src={arrowIcon} alt="arrow icon"/>CHF {Formatter.formatNumber(props.profitNumber.toFixed(2))} ({(props.percentage * 100).toFixed(2)}%)
        </span>
      </div>
    </>
  )
}

export default DashboardProfitPanel;