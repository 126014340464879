import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Formatter from '../../../App/Libs/Formatter/Formatter';
import { Callback } from '../../../App/Models/Callback';
import Panel from '../../Atoms/Panel/Panel';
import Slider from '../../Atoms/Slider/Slider';
import styles from './PortfolioValuePanel.module.scss';
import PortfolioValueProgress from './PortfolioValueProgress';


type PortfolioValuePanelProps = {
    name: string,
    disabled?: boolean
    min?: number
    max: number
    count?: number
    value?: number
    index?: number
    onChange?: Callback<number>
    isSplitEnabled?: boolean
}

const PortfolioValuePanel = (props: PortfolioValuePanelProps) => {

    const [value, setValue] = useState<number | any>(props.value || 0);
    const [isHandleEditing, setIsHandleEditing] = useState<boolean>(false);

    function onSliderChange(value: number) {
        if (!value) value = 0
        if (value > props.max) value = props.max

        setValue(value)
        if (props.onChange)
            props.onChange(value)
    }

    useEffect(() => {
        if (props.value !== undefined && props.value !== value)
            setValue(props.value)

        if (value > props.max)
            setValue(props.max)

    }, [props.value])

    useEffect(() => {
        sessionStorage.setItem(props.name, parseFloat(value).toFixed(2));
    }, [value])

    useEffect(() => {
        if (props.disabled === true)
            sessionStorage.setItem(props.name, '0');
    }, [props.disabled])

    function getProgress() {
        if (props.disabled === true)
            return 0

        return value / props.max
    }


    return (
        <Panel className={classNames(styles.panel, props.disabled ? styles.disabled : null)}>
            <div className={styles.main}>
                <div className={styles.details}>
                    <div className={styles.info}>
                        <p className={styles.name}>{props.name}</p>
                        {!isHandleEditing &&
                          <span className={styles.valueLabel}
                                onClick={() => setIsHandleEditing(true)}>
                              CHF {Formatter.formatNumber(props.disabled ? 0 : value)}.–
                          </span>
                        }
                        {isHandleEditing &&
                          <input className={styles.input}
                                 onChange={(e) => onSliderChange(parseInt(e.currentTarget.value))}
                                 value={value}
                                 onBlur={() => setIsHandleEditing(false)}
                                 autoFocus={true}/>
                        }
                    </div>
                    <div className={styles.circleContainer}>
                        <PortfolioValueProgress index={props.index || 0} progress={getProgress()} count={props.count || 2} />
                    </div>
                </div>
                <Slider min={props.min || 0} max={props.max}
                    value={props.disabled ? 0 : value}
                    onInput={e => onSliderChange(e.currentTarget.valueAsNumber)} />
            </div>
        </Panel>
    );
};

export default PortfolioValuePanel;