import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useForm, SubmitHandler, Controller, SubmitErrorHandler } from "react-hook-form";
import { RouteComponentProps } from 'react-router-dom';
import { Button } from '../../Atoms/Button/Button';
import Datepicker, { parseDate, formatDate } from '../../Atoms/Datepicker/Datepicker';
import Input from '../../Atoms/Input/Input';
import Password from '../../Atoms/Input/Password';
import Radio from '../../Atoms/Radio/Radio';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import styles from './RegisterPersonalInfoPage.module.scss';
import ValidationErrorMessage from '../../Atoms/ValidationErrorMessage/ValidationErrorMessage';
import PassRequirements from '../../Molecules/PassRequirements/PassRequirements';
import { RegisterActions } from '../../../App/Mobx/Actions/RegisterActions';
import { RegisterDto } from '../../../App/Models/Contracts/Register/RegisterDto';
import { AuthActions } from '../../../App/Mobx/Actions/AuthActions';
import { GlobalStore } from '../../../App/Mobx/Stores/MainStore';
import Select from '../../Atoms/Select/Select';
import { GenderEnum } from '../../../App/Models/Enums/GenderEnum';
import isAnyValidationErrors from "../../../App/Libs/Validation/Validation";
import WebSync from '../../Molecules/DataSync/WebSync';
import t from '../../../App/Libs/Translate/Translate'

const ERRORS = {
  required: t('errors.required'),
  emailIsIncorrect: t('errors.email_is_incorrect'),
  mustBeOverEighteen: t('errors.must_be_over_eighteen'),
  passRequirements: t('errors.pass_requirements'),
  passMatch: t('errors.pass_match'),
  onlySwiss: t('errors.only_swiss'),
}

const isOverEighteen = (date: Date) => {
  let TODAY = new Date(Date.now());
  let EIGHTEEN_YEARS_BACK = new Date((new Date(TODAY).getFullYear() - 18) + "/" + (new Date(TODAY).getMonth()+1) + "/" + (new Date(TODAY).getDate()));
  let USER_INPUT = new Date(date.setHours(0, 0, 0, 0));
  return EIGHTEEN_YEARS_BACK >= USER_INPUT;
}

const RegisterPersonalInfoPage: React.FC<RouteComponentProps> = (props) => {
  const { register, control, handleSubmit, formState: { errors }, watch } = useForm<RegisterDto>();

  const webRef = useRef<WebSync>()

  const residenceList = [
    { id: "73", value: t('common.residences.switzerland')},
    { id: "84", value: t('common.residences.germany')},
    { id: "99", value: t('common.residences.france')}
  ]
  const SWITZERLAND_ID = parseInt(residenceList[0].id);

  const onSubmit: SubmitHandler<RegisterDto> = data => {
    webRef.current.Activity.show()
    RegisterActions
      .Register(data)
      .onSuccess(() => {
        AuthActions
          .Login({
            UserName: data.email,
            Password: data.password
          })
          .onSuccess(response => {
            GlobalStore.Auth.SetAuthorization(response.data)
            GlobalStore.Contact.birthDate = new Date(data.birthdate)
            GlobalStore.Contact.gender = (data.salutation === 300 ? GenderEnum.Female : GenderEnum.Male)
            GlobalStore.Contact.firstName = data.firstName
            GlobalStore.Contact.lastName = data.lastName
            GlobalStore.Contact.address = data.address
            GlobalStore.Contact.postalCode = data.postalCode
            GlobalStore.Contact.city = data.city
            GlobalStore.Auth.IsOnboarded = false
            props.history.push("/onboarding/Confirm")
            webRef.current.Activity.hide()
          })
      })
      .onFail(e => {
        alert(e.response?.data.title)
        webRef.current.Activity.hide()
      })
  }

  const onInvalid: SubmitErrorHandler<RegisterDto> = data => {
  }

  const getEmailValidationError = () => {
    if (errors.email?.type === 'required') {
      return ERRORS.required
    }

    if (errors.email?.type === 'pattern') {
      return ERRORS.emailIsIncorrect
    }

    return null
  }

  return (
    <Page>
      <WebSync ref={webRef}>
        <OnboardingLayout
          contentMaxWidth='771px'
          showBackgroundImage={true} selectedStepIndex={0} progress={0.5}>
          <h1 className={styles.title}>{t('register.personal.title')}</h1>
          <h5 className={styles.description}>{t('register.personal.subtitle')}</h5>
          <Form className={styles.form}>
            <div className={styles.salutationWrapper}>
              <div className={styles.radioWrapper}>
                <Controller
                  control={control}
                  name='salutation'
                  render={({ field }) => (
                    <Radio
                      className={styles.radio}
                      value="300"
                      name='salutation'
                      onInput={e => field.onChange(+e.currentTarget.value)}
                    >
                      {t('common.genders.woman')}
                    </Radio>

                  )}
                  rules={{
                    required: true
                  }}
                />
                <Controller
                  control={control}
                  name='salutation'
                  render={({ field }) => (
                    <Radio
                      className={styles.radio}
                      value="299"
                      name='salutation'
                      onInput={e => field.onChange(+e.currentTarget.value)}
                    >
                      {t('common.genders.male')}
                    </Radio>

                  )}
                  rules={{
                    required: true
                  }}
                />
              </div>

              {errors.salutation?.type === 'required' && <ValidationErrorMessage message={ERRORS.required} />}
            </div>
            <label>
              <Input
                placeholder={t('common.first_name')}
                {...register("firstName", {
                  required: true,
                })}
                error={errors.firstName ? ERRORS.required : null}
              />
            </label>
            <label>
              <Input
                placeholder={t('common.surname')}
                {...register("lastName", {
                  required: true,
                })}
                error={errors.lastName ? ERRORS.required : null}
              />
            </label>
            <label>
              <Input
                type={'text'}
                placeholder="E-Mail"
                {...register("email", {
                  required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                error={getEmailValidationError()}
              />
            </label>
            <label>
              <Input
                placeholder={t('common.phone')}
                {...register("phone", {
                  required: true,
                })}
                error={errors.phone ? ERRORS.required : null}
              />
            </label>
            <label>
              <Input
                placeholder={t('common.address')}
                {...register("address", {
                  required: true,
                })}
                error={errors.address ? ERRORS.required : null}
              />
            </label>
            <label>
              <Input placeholder={t('common.address_supplement')} {...register("additionalAddress")} />
            </label>
            <section className={styles.postalSection}>
              <label>
                <Input
                  placeholder={t('common.postcode')}
                  {...register("postalCode", {
                    required: true
                  })}
                  error={errors.postalCode && ERRORS.required}
                />
              </label>
              <label>
                <Input
                  placeholder={t('common.location')}
                  {...register("city", {
                    required: true
                  })}
                  error={errors.city && ERRORS.required}
                />
              </label>
            </section>
            <hr />
            <label className={styles.residence}>
              <Controller
                control={control}
                name='country'
                render={({ field }) => (
                  <Select
                    itemsList={residenceList}
                    placeholder={t('common.residence')}
                    onChange={(v: string) => field.onChange(parseInt(v))}
                    errorMessage={errors.country && ERRORS.onlySwiss}
                  />
                )}
                rules={{
                  required: true,
                  validate: id => id === SWITZERLAND_ID,
                }}
              />

            </label>
            <label className={styles.birthdate}>
              <Controller
                control={control}
                name='birthdate'
                render={({ field }) => (
                  <Datepicker
                    onChange={(date: Date | null) => field.onChange(formatDate(date))}
                    selected={parseDate(field.value)}
                  />
                )}
                rules={{
                  required: true,
                  validate: v => isOverEighteen(new Date(v)),
                }}
              />
              {errors.birthdate?.type === 'required' && <ValidationErrorMessage message={ERRORS.required} />}
              {errors.birthdate?.type === 'validate' && <ValidationErrorMessage message={ERRORS.mustBeOverEighteen} />}
            </label>
            <hr />

            <label className={styles.passwordInput}>
              <Controller
                control={control}
                name='password'
                render={({ field }) => (
                  <Password
                    placeholder={t('common.password')}
                    autoComplete='new-password'
                    onChange={e => field.onChange(e.currentTarget.value)}
                    error={errors.password && ERRORS.passRequirements}
                  />
                )}
                rules={{
                  required: true,
                  pattern: /(?=.*\d)(?=.*[!@#$%^&.*])(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z!@#$%^&.*]{8,}/g,
                }}
              />

            </label>

            <label className={styles.passwordInput}>
              <Controller
                control={control}
                name='passwordConfirm'
                render={({ field }) => (
                  <Password
                    placeholder={t('common.repeat_password')}
                    autoComplete='new-password'
                    onChange={e => field.onChange(e.currentTarget.value)}
                    error={errors.passwordConfirm && ERRORS.passMatch}
                  />
                )}
                rules={{
                  required: true,
                  validate: () => watch().password === watch().passwordConfirm,
                }}
              />

            </label>

            {errors.password && <PassRequirements password={watch().password} />}
            {isAnyValidationErrors(errors) && <ValidationErrorMessage message={t('errors.incorrect_entries')} />}

            <Button
              className={styles.button}
              onClick={handleSubmit(onSubmit, onInvalid)}
            >
              {t('register.personal.continue_registration')}
            </Button>
          </Form>
        </OnboardingLayout>
      </WebSync>
    </Page>
  );
};

export default RegisterPersonalInfoPage;
