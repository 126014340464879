import React from "react"
import { IconProps } from '../Menu'

const ExitIcon = (props: IconProps) => {
  return (
    <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path fill={props.color ?? "white"} fillRule="evenodd" clipRule="evenodd" d="M2.40548 16.5957H8.77342C9.04378 16.5957 9.26325 16.8152 9.26321 17.0856C9.26321 17.3559 9.04378 17.5754 8.77338 17.5754H1.91561C1.64525 17.5754 1.42578 17.356 1.42578 17.0856V2.39022C1.42578 2.11982 1.64525 1.90039 1.91561 1.90039H8.77342C9.04378 1.90039 9.26325 2.11986 9.26325 2.39022C9.26325 2.66059 9.04382 2.88006 8.77342 2.88006H2.40548V16.5957ZM13.5788 5.96755L16.9587 9.39597C17.1492 9.58603 17.1473 9.90493 16.9582 10.0955L13.5788 13.5239C13.3877 13.7174 13.0776 13.7174 12.8861 13.5239C12.6946 13.3309 12.6946 13.0179 12.8861 12.8244L15.4333 10.2405H5.346C5.07564 10.2405 4.85617 10.0191 4.85617 9.74573C4.85617 9.47239 5.0756 9.25097 5.346 9.25097H15.4333L12.8861 6.66703C12.6946 6.47403 12.6946 6.16055 12.8861 5.96755C13.0772 5.77454 13.3872 5.77406 13.5788 5.96755Z" />
    </svg>
  );
};

export default ExitIcon;
