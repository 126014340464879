import { useForm, Controller } from "react-hook-form";
import Page from "../../Molecules/Page/Page";
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import Input from '../../Atoms/Input/Input';
import Datepicker from '../../Atoms/Datepicker/Datepicker';
import { RouteComponentProps } from 'react-router-dom';
import DashboardPensionCard from '../../Organisms/DashboardPensionCard/DashboardPensionCard';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import t from '../../../App/Libs/Translate/Translate'
import {RiskProtectionCalculationModelDto} from '../../../App/Models/Contracts/Dashboard/RiskProtectionCalculationModelDto';

import styles from './DetermineInsuranceCoveragePage.module.scss';
import MoreInfoLink from "../../Organisms/Link/MoreInfoLink";
import {useState, useRef} from "react";
import Formatter from "../../../App/Libs/Formatter/Formatter";
import DashboardCountryListInfoPopup from "../../Organisms/Popup/DashboardCountryListInfoPopup/DashboardCountryListInfoPopup";
import Link from "../../Atoms/Link/Link";
import DashboardLearnMoreInfoPopup from "../../Organisms/Popup/DashboardLearnMoreInfoPopup/DashboardLearnMoreInfoPopup";
import {RiskProtectionCalculationActions} from '../../../App/Mobx/Actions/RiskProtectionCalculationActions';
import WebSync from '../../Molecules/DataSync/WebSync';
import { useHistory } from "react-router-dom";
import { GlobalStore } from "../../../App/Mobx/Stores/MainStore";
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';

type Inputs = {   
  residence: string,
  profession: string,
  startInsuranceDate: Date,
};

const DetermineInsuranceCoveragePage = (props: RouteComponentProps) => {

  const { RiskProtectionCalculation } = useStore();

  const [amount, setAmount] = useState(300000);
  const [data, setData] = useState<RiskProtectionCalculationModelDto>();
  const webRef = useRef<WebSync>();
  // const [modelPortfolio, setModelPortfolio] = useState<ModelPortfolioDto>()

  const annualDisabilityPensionAssetsPercent = 30;
  const annualDisabilityMax = Formatter.formatNumber(amount)
  const deathBenefitAssetsPercent = 300;
  const deathBenefitMax = Formatter.formatNumber(5000000);

  const annualDisabilityPensionList = [
    t('dashbord.survey.risk.insurance.detail_max_percent').replace("[PERCENT]", `${annualDisabilityPensionAssetsPercent}%`),
    t('dashbord.survey.risk.insurance.detail_max_sum').replace("[AMOUNT]", `CHF ${annualDisabilityMax}`),
    "Sowohl für Personen mit Wohnsitz in der Schweiz wie auch im Ausland"
  ];

  const deathBenefitList = [
    t('dashbord.survey.risk.insurance.detail_max_percent').replace("[PERCENT]", `${deathBenefitAssetsPercent}%`),
    t('dashbord.survey.risk.insurance.detail_max_sum').replace("[AMOUNT]", `CHF ${deathBenefitMax}`),
    t('dashbord.survey.risk.insurance.detail_all_people')
  ];

  const renderFooter = () => {
    return <FooterNavBar
      onNextClick={() => onSubmit()}
      
      buttonBackText={t('common.back')}
      buttonNextText={t('dashbord.survey.risk.insurance.next')}/>
  }

  const {
    register,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const ERRORS = {
    required: t('errors.required'),
  }

  const onSubmit = () => {
    RiskProtectionCalculationActions
      .PostRiskProtectionCalculationActions({
        insuranceDate: data.insuranceDate,
        months24Sum: parseInt(sessionStorage.getItem('Jährliche Invalidenrente')),
        deathBenefit: parseInt(sessionStorage.getItem('Todesfallkapital')),
      }).onSuccess((response) =>{
        RiskProtectionCalculation.riskprotectionOutput = response.data
        props.history.push('/Dashboard/PaymentOfRiskPremium');
      });
  }

  const [popupIsOpened, setPopupOpenedStatus] = useState(false);
  const [popupIsOpenedLearnMore, setPopupIsOpenedLearnMore] = useState(false);
  const [popupIsOpenedDeathBenefit, setPopupIsOpenedDeathBenefit] = useState(false);

const openPopUp = () => {
  setPopupOpenedStatus(true)
}

const closePopUp = () => {
  setPopupOpenedStatus(false);
}

const openPopUpLearnMore = () => {
  setPopupIsOpenedLearnMore(true)
}

const closePopUpLearnMore = () => {
  setPopupIsOpenedLearnMore(false);
}
const openPopUpDeathBenefit = () => {
  setPopupIsOpenedDeathBenefit(true)
}

const closePopUpDeathBenefit = () => {
  setPopupIsOpenedDeathBenefit(false);
}

const annualDisabilityPensionListElem = [
	t("dashbord.define.insurance.coverage.annual_disability_pension.list_elem_1"),
	t("dashbord.define.insurance.coverage.annual_disability_pension.list_elem_2"),
	t("dashbord.define.insurance.coverage.annual_disability_pension.list_elem_3"),
	t("dashbord.define.insurance.coverage.annual_disability_pension.list_elem_4"),
	t("dashbord.define.insurance.coverage.annual_disability_pension.list_elem_5")
]

const deathBenefitListElem = [
	t("dashbord.define.insurance.coverage.death_benefit.list_elem_1"),
	t("dashbord.define.insurance.coverage.death_benefit.list_elem_2"),
	t("dashbord.define.insurance.coverage.death_benefit.list_elem_3"),
	t("dashbord.define.insurance.coverage.death_benefit.list_elem_4"),
	t("dashbord.define.insurance.coverage.death_benefit.list_elem_5")
]

  return (
    <Page>
      <OnboardingLayout
        contentMaxWidth='770px'
        stepCount={3}
        selectedStepIndex={0}
        progress={0.5}
        footer={renderFooter()}
        backButtonLink={"/Dashboard"}
      >
        <h1 className={styles.title}>{t('dashbord.survey.risk.insurance.title')}</h1>
      <div className={styles.flex}>
        <Controller
          control={control}
          name='startInsuranceDate'
          render={({ field }) => (
            <Datepicker
              placeholderText={t('dashbord.survey.risk.insurance.input.commencement_of_insurance')}
              onChange={
                (date: Date | null) => { field.onChange(date);
                setData({
                ...data, insuranceDate: date
                });
              }}
              selected={field.value}
            />
          )}
          rules={{
            required: true,
          }}
        />
      </div>

        <Input
          placeholder={t('common.profession')}
          {...register("profession", {
            required: true,
          })}
          error={errors.profession && ERRORS.required}
        />

        <p className={styles.subtitle}>{t('dashbord.survey.risk.insurance.description')}</p>
        <Link onClick={openPopUp} className={styles.tdNone}>
          <MoreInfoLink>{t('dashbord.survey.risk.insurance.country_list')}</MoreInfoLink>
        </Link>

        <hr />

        <DashboardPensionCard
          title={t('dashbord.survey.risk.insurance.subtitle')}
          description={t('dashbord.survey.risk.insurance.detail_desc_1')}
          maxValue={amount}
          rulesList={annualDisabilityPensionList}
          defaultValue={150000}
          onPopupClick={openPopUpLearnMore}
        />

        <hr />

        <DashboardPensionCard
          title={t('dashbord.survey.risk.insurance.second_subtitle')}
          description={t('dashbord.survey.risk.insurance.detail_desc_2')}
          maxValue={amount}
          rulesList={deathBenefitList}
          defaultValue={250000}
          onPopupClick={openPopUpDeathBenefit}
        />

      </OnboardingLayout>
      <DashboardCountryListInfoPopup show={popupIsOpened} onExit={closePopUp}></DashboardCountryListInfoPopup>
      <DashboardLearnMoreInfoPopup
        show={popupIsOpenedLearnMore}
        onExit={closePopUpLearnMore}
        title={t('dashbord.define.insurance.coverage.annual_disability_pension.title')}
        subtitle={t('dashbord.define.insurance.coverage.annual_disability_pension.subtitle')}
        list = {annualDisabilityPensionListElem}
        downSubtitle={t('dashbord.define.insurance.coverage.annual_disability_pension.downsubtitle')}
        >
      </DashboardLearnMoreInfoPopup>
      <DashboardLearnMoreInfoPopup
        show={popupIsOpenedDeathBenefit}
        onExit={closePopUpDeathBenefit}
        title={t('dashbord.define.insurance.coverage.death_benefit.title')}
        subtitle={t('dashbord.define.insurance.coverage.death_benefit.subtitle')}
        list = {deathBenefitListElem}
        downSubtitle={t('dashbord.define.insurance.coverage.death_benefit.downsubtitle')}
        >
      </DashboardLearnMoreInfoPopup>
    </Page>
  );
};

export default DetermineInsuranceCoveragePage;