import React from 'react';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import SuccessPageLayout from '../../Organisms/Layout/SuccessPageLayout';
import DownloadButton from "../../Atoms/Button/DownloadButton";
import t from '../../../App/Libs/Translate/Translate'

type SuccessTransferPageLayoutTypes = {
  linkTo: string,
  buttonText: string,
}

const SuccessTransferPageLayout = ({linkTo, buttonText}: SuccessTransferPageLayoutTypes) => {

  function renderSuccessContent() {
    return (
      <div>
        <p>{t('dashboard.overview_depots_3a.deposit_transfer_success.subtitle')}</p>
        <DownloadButton>{t('register.contract.success_started_transfer.download_document')}</DownloadButton>
      </div>
    )
  }

  function renderFooter(){
    return <FooterNavBar
      linkToNext={linkTo}
      buttonBackText={t('common.back')}
      buttonNextText={buttonText}/>
  }

  return (
    <Page>
      <OnboardingLayout
        footer={renderFooter()}
        hideStepper={true}
        showBackgroundImage={true}
        contentMaxWidth='1600px'>
            <SuccessPageLayout
              title={t('dashboard.overview_depots_3a.deposit_transfer_success.title')}
              content={renderSuccessContent()}
            />
      </OnboardingLayout>
    </Page>
  );
};

export default SuccessTransferPageLayout;
