import classNames from 'classnames';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Callback } from '../../../App/Models/Callback';
import styles from './Link.module.scss';

type Props ={
    to?: string
    className?: string
    onClick?: Callback<HTMLElement>
    disabled?: boolean
    children?: React.ReactNode
}


const Link: React.FC<Props> = (props) => {

    function onClick(e: HTMLElement){
        if(props.onClick)
            props.onClick(e)
    }

    if(props.to)
            return <ReactLink to={props.to} onClick={a => onClick(a.currentTarget)} className={classNames(styles.main, props.className, props.disabled ? styles.disabled : null)}>{props.children}</ReactLink>
        else
            return <span onClick={a => onClick(a.currentTarget)} className={classNames(styles.main, props.className)}>{props.children}</span>
};

export default Link;
