import classNames from "classnames";
import React from "react";
import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import styles from './Loader.module.scss';


type Props = {
  className?: string
  children?: JSX.Element
  visible: boolean
}


const Loader: React.FC<Props> = (props) => {
  
  return (
    <div className={classNames(props.className, styles.main, props.visible? styles.loading : undefined)}>
      {props.children}
      {props.visible === true &&
        <div className={styles.loaderContainer}>
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            color="rgba(0, 167, 109, 0.7)"
            secondaryColor="rgba(88,86,86, 0.3)"
          />
        </div>
      }
    </div>
  );
};

export default Loader;
