import classNames from 'classnames';
import React from 'react';
import Radio, { RadioProps } from '../../Atoms/Radio/Radio';

import styles from './CheckMarkRadio.module.scss'

const CheckMarkRadio : React.FC<RadioProps> = (props) => {
    return (
        <Radio {...props} className={classNames(styles.radio, props.className)}>
        </Radio>
    );
};

export default CheckMarkRadio;