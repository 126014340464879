import React from 'react';
import { Image } from 'react-bootstrap';
import closeImg from '../../../Assets/Images/Close.svg';
import Popup, { PopUpProps } from '../../Atoms/Popup/Popup';
import styles from './WarningPopup.module.scss';


const WarningPopup: React.FC<PopUpProps> = (props) => {

    function onExit(obj: HTMLElement) {
        if (props.onExit)
            props.onExit(obj)
    }

    return (
        <Popup {...props} className={styles.warningPopUp} onExit={obj => onExit(obj)}>
            <div className={styles.main}>
                <div className={styles.actionBar}>
                    <Image src={closeImg} alt="" onClick={obj => onExit(obj.currentTarget)} />
                </div>
                {props.children}
            </div>
        </Popup>
    );
};

export default WarningPopup;