import React, { useEffect } from 'react';
import langDE from '../../../Assets/Languages/de.json';
import langEN from '../../../Assets/Languages/en.json';
import langFR from '../../../Assets/Languages/fr.json';

import { GlobalStore } from '../../../App/Mobx/Stores/MainStore';
import { useStore } from '../../../App/Mobx/Infrastructure/mobx-hook';

type Props = {
    key: string,
}

function Translate(key) {
	const language = GlobalStore.Common.lang

  let vocabulary

  switch (language) {
    case "de":
      vocabulary = langDE
      break;

    case "en":
      vocabulary = langEN
      break;

    case "fr":
      vocabulary = langFR
      break;
  }

  return vocabulary[key] || key;
};


export default Translate
