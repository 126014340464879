import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { PortfolioInvestmentOverviewResultDto } from "../../Models/Contracts/Portfolio/PortfolioInvestmentOverviewResultDto";

export class DashboardStore{

    investmentOverview?: PortfolioInvestmentOverviewResultDto

    getPortfolioById(id?: number){
        return this.investmentOverview.portfolios.find(x => x.id === id)
    }

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: 'DashboardStore', properties: ['investmentOverview'], storage: window.sessionStorage });
    }
}