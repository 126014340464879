import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import WebSync from "../../../Components/Molecules/DataSync/WebSync";
import { Callback } from "../../Models/Callback";
import { AuthorizationDto } from "../../Models/Contracts/Auth/AuthorizationDto";
import { WebClientHeaders } from "./WebClientHeaders";
import WebClientPromise, { IWebClientPromise } from "./WebClientPromise";
import { WebClientResponse } from "./WebClientResponse";

class WebClient {
   private headers?: WebClientHeaders
   private configure?: AxiosRequestConfig
   private baseUrl?: string
   private static BaseUrl?: string
   private static Interceptors:Array<Callback<WebClientResponse<any>>> = []
   private abortcontroller = new AbortController();


   static Create() {
      return new WebClient()
   }


   static SetGlobalBaseUrl(baseUrl?: string): typeof WebClient {
      WebClient.BaseUrl = baseUrl
      return WebClient
   }

   static Intercept(callback: Callback<WebClientResponse<any>>){
      this.Interceptors.push(callback)
   }

   private static CallInterceptors(response: WebClientResponse<any>){
      WebClient.Interceptors.forEach(element => {
         element(response)
      });
   }

   SetBaseUrl(baseUrl?: string): WebClient {
      this.baseUrl = baseUrl
      return this
   }

   private GetFullUrl(queryString: string) {
      var baseUrl = this.baseUrl ?? WebClient.BaseUrl
      return `${baseUrl}${queryString}`
   }

   

   SetHeaders(headers: WebClientHeaders): WebClient {
      this.headers = headers
      return this;
   }

   static SetGlobalLanguage(language?: string) {
      axios.defaults.headers.common['accept-language'] = language;
   }

   Authorize(auth: AuthorizationDto): WebClient {
      axios.defaults.headers.common['Authorization'] = auth?.token
      axios.defaults.headers.common['x-session-id'] = auth?.sessionId
      return this
   }

   private GetConfiguration(): AxiosRequestConfig {
      return {
         ...this.configure,
         signal: this.abortcontroller.signal,
         headers: {
            "Content-Type": this.headers?.ContentType
         },
      }
   }

   Configure(config: AxiosRequestConfig){
      this.configure = {
         ...this.configure,
         ...config
      }
      return this
   }



   Get<T extends any>(queryString: string, model?: any): IWebClientPromise<T> {
      var promise = new WebClientPromise<T>()
      promise.setAbortController(this.abortcontroller)
      var config: AxiosRequestConfig = {
         params: model,
         ...this.GetConfiguration()
      }



      axios.get(this.GetFullUrl(queryString), config)
         .then((response) => {
            WebClient.CallInterceptors(response)
            promise.fireonSuccess(response)
         })
         .catch((error) => {
            WebClient.CallInterceptors(error.response)
            promise.fireOnFail(error)
         })

      return promise
   }

   Post<T extends any>(queryString: string, payload?: any): IWebClientPromise<T> {

      var promise = new WebClientPromise<T>()
      promise.setAbortController(this.abortcontroller)
      var config = this.GetConfiguration()
      axios.post(this.GetFullUrl(queryString), payload, config)
         .then((response) => {
            WebClient.CallInterceptors(response)
            promise.fireonSuccess(response)
         })
         .catch((error) => {
            WebClient.CallInterceptors(error.response)
            promise.fireOnFail(error)
         })

      return promise
   }

   Patch<T extends any>(queryString: string, payload?: any): IWebClientPromise<T> {

      var promise = new WebClientPromise<T>()
      promise.setAbortController(this.abortcontroller)
      axios.patch(this.GetFullUrl(queryString), payload, this.GetConfiguration())
         .then((response) => {
            WebClient.CallInterceptors(response)
            promise.fireonSuccess(response)
         })
         .catch((error) => {
            WebClient.CallInterceptors(error.response)
            promise.fireOnFail(error)
         })

      return promise

   }

   Delete<T extends any>(queryString: string, payload?: any): IWebClientPromise<T> {

      var promise = new WebClientPromise<T>()
      promise.setAbortController(this.abortcontroller)
      axios.delete(this.GetFullUrl(queryString), {
         params: payload,
         ...this.GetConfiguration()
      })
         .then((response) => {
            WebClient.CallInterceptors(response)
            promise.fireonSuccess(response)
         })
         .catch((error) => {
            WebClient.CallInterceptors(error.response)
            promise.fireOnFail(error)
         })

      return promise

   }

}

export default WebClient;