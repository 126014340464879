import React from 'react';
import InfoPopup from '../../../Molecules/Popup/InfoPopup';
import styles from './DashboardLearnMoreInfoPopup.module.scss';
import Link from "../../../Atoms/Link/Link";
import t from '../../../../App/Libs/Translate/Translate';
import { Callback } from '../../../../App/Models/Callback';

type PopUpProps ={
  className?: string
  frameClassName?: string
  show: boolean
  onExit?: Callback<HTMLElement>
  children?: React.ReactNode
  title?: string
  subtitle?: string
  downSubtitle?: string
  list?: Array<string>
  
}

const DashboardLearnMoreInfoPopup = (props: PopUpProps) => {

  function onExit(obj: HTMLElement) {
    if (props.onExit)
      props.onExit(obj)
  }

   return (
    <InfoPopup {...props} frameClassName={styles.main} className={styles.popup}>
      <div className={styles.content}>
        <h2>{props.title}</h2>
        <p className={styles.paragraph}>
          {props.subtitle}
        </p>
          <div className={styles.listContainer}>
            {props.list.map((elem) => (<div className={styles.listElem}>{elem}</div>))}
          </div>
        <h3 className={styles.downSubtitle}>{props.downSubtitle}</h3>
        <Link className={styles.closeText} onClick={a => onExit(a)}>{t('common.conclude')}</Link>
      </div>
    </InfoPopup>
  );
};

export default DashboardLearnMoreInfoPopup;


