import React, { useState } from 'react';
import FooterNavBar from '../../../Molecules/Navbar/FooterNavbar';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import SplitLayout from '../../../Organisms/Layout/SplitLayout';
import styles from './TransferBenefits.module.scss';
import Lists from '../../../../Assets/Data/Transfer3A.json'
import t from '../../../../App/Libs/Translate/Translate';
import InputSearchPanel, {additionalInfoType} from "../../../Molecules/InputSearchPanel/InputSearchPanel";



const TransferBenefits = () => {

    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [showInput, setShowInput] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>()
    const [additionalInfo, setAdditionalInfo] = useState<additionalInfoType | null>(null)


    function onSearchChange(e: HTMLInputElement) {
        setShowInput(false)
        setDisableButton(true)
    }

    function onSearchSelect(value: string) {
        if (value.toLowerCase() === "andere")
            setShowInput(true)
        else {
            setDisableButton(false)
            setShowInput(false)
        }

        setSearchText(value)
    }

    const additionalInfoInput = (data : additionalInfoType | null) => {
        setDisableButton(false);
        if (data) {
            setAdditionalInfo(data);
        } else {
            setDisableButton(true)
        }
    }

    function renderFooter() {
        return <FooterNavBar
            buttonNextDisabled={disableButton}
            linkToNext='/onboarding/Contract/SuccessStartedTransferFZ'
            buttonBackText={t('common.back')}
            buttonNextText={t('common.trigger_transfer')} />
    }

    return (
        <Page>
            <OnboardingLayout
                footer={renderFooter()}
                contentMaxWidth='1600px'
                hideStepper={true}>

                <SplitLayout className={styles.splitView}>
                    <div className={styles.titleSection}>
                        <h1 className={styles.title}>{t('register.contract.transfer_benefits.title')}</h1>
                        <p className={styles.subtitle}>{t('register.contract.transfer_benefits.subtitle')}</p>
                    </div>
                        <InputSearchPanel onSearchSelect={onSearchSelect}
                                          onSearchChange={onSearchChange}
                                          lists={Lists}
                                          showInput={showInput}
                                          getAdditionalInfoInput={additionalInfoInput}
                        />

                </SplitLayout>




            </OnboardingLayout>
        </Page>
    );
};

export default TransferBenefits;
