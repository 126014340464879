import React, {useEffect} from 'react';
import FooterNavBar from '../../../Molecules/Navbar/FooterNavbar';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import styles from './CongratulationsPensionSolution.module.scss';
import SuccessPageLayout from "../../../Organisms/Layout/SuccessPageLayout";
import { PortfolioActions } from '../../../../App/Mobx/Actions/PortfolioActions';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import { TransferSource } from '../../../../App/Models/Enums/TransferSource';
import { RouteComponentProps } from 'react-router-dom';
import { ProductEnum } from '../../../../App/Models/Enums/ProductEnum';
import t from '../../../../App/Libs/Translate/Translate'
import {ContactActions} from "../../../../App/Mobx/Actions/ContactActions";


const isEmailVerified = false;


const CongratulationsPensionSolution: React.FC<RouteComponentProps> = (props) => {

    const { Account, Auth, Contact } = useStore()

    const renderSuccessContent = () => {
        const notVerifiedEmailText = t('register.contract.congratulations_pension_solution.subtitle').replace('[EMAIL]', Auth.userName);
        const verifiedEmailText =  t('register.contract.congratulations_pension_solution.subtitle_verified');

        return (
            <div className={styles.text}>
                {isEmailVerified ? verifiedEmailText : notVerifiedEmailText}
            </div>
        )

    }

    function onSubmit() {
        var vp = Account.virtualPortfolios[0]
        PortfolioActions
            .GetPortfolioProposal({
                proposalId: vp.proposalId
            })
            .onSuccess(response => {
                if (vp.product.id === ProductEnum.Independent) {
                    if (response.data.transferSource === TransferSource.FZ)
                        props.history.push('/onboarding/Contract/TransferBenefits')
                    else
                        props.history.push('/onboarding/Contract/TransferContract')
                }
                else {
                    props.history.push('/onboarding/3A/Source')
                }
            })
    }


    function renderFooter() {
        return <FooterNavBar
            onNextClick={() => onSubmit()}
            buttonBackText={t('common.back')}
            buttonNextText={t('register.contract.congratulations_pension_solution.continue_transfer')} />
    }

    return (
        <Page>
            <OnboardingLayout
                footer={renderFooter()}
                selectedStepIndex={0}
                progress={1}
                contentMaxWidth='1600px'>
                <div className={styles.main}>
                    <div className={styles.content}>
                        <SuccessPageLayout
                            title={t('register.contract.congratulations_pension_solution.title')}
                            content={renderSuccessContent()}
                        />
                    </div>
                </div>
            </OnboardingLayout>
        </Page>
    );
};

export default CongratulationsPensionSolution;
