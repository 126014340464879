import AccountAssetsValueTemplate from "../../../Templates/AccountAssetsValueTemplate";
import {RouteComponentProps} from "react-router-dom";
import {useStore} from "../../../../App/Mobx/Infrastructure/mobx-hook";
import {useState} from "react";

const ASSET_VALUE_TO_OFFER_CONSULTATION = 300000


const DashboardAccountAssetsValuePage = (props: RouteComponentProps) => {

  const [assetValue, setAssetValue] = useState<number>(0)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  function isConsultationProposed() {
    return assetValue >= ASSET_VALUE_TO_OFFER_CONSULTATION
  }

  function onDigitalFlowClicked() {
    // setShowSpinner(true)
    // do something with data
    // setShowSpinner(false)
    props.history.push('/dashboard/portfolio/add/split')

  }

  function onSubmit() {
    // setShowSpinner(true)
    // do something with data
    // setShowSpinner(false)
    props.history.push('/dashboard/portfolio/add/split')
  }


  return (
    <AccountAssetsValueTemplate {...props}
                                onValueChange={setAssetValue}
                                onSubmit={onSubmit}
                                onDigitalFlowClicked={onDigitalFlowClicked}
                                isConsultationProposed={isConsultationProposed}
                                linkToBack={"/dashboard/portfolio/add/source"}
                                linkToPersonalConsultation={"/dashboard/portfolio/add/consultation-request"}
                                showSpinner={showSpinner}
    />
  );
}

export default DashboardAccountAssetsValuePage;