import React from "react"
import { IconProps } from '../Menu'

const HomeIcon = (props: IconProps) => {
  return (
    <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path fill={props.color ?? "white"} fillRule="evenodd" clipRule="evenodd" d="M19.8545 9.25059L10.5207 1.68427C10.2175 1.43858 9.78212 1.43858 9.47912 1.68427L7.08839 3.62265V2.61657C7.08839 2.39927 6.91107 2.22374 6.69198 2.22374H4.39137C4.17228 2.22374 3.99496 2.39945 3.99496 2.61657V6.13026L0.145482 9.25059C-0.0241519 9.38775 -0.048848 9.63557 0.0893809 9.80365C0.167865 9.89853 0.281976 9.94838 0.39707 9.94838C0.485213 9.94838 0.573645 9.91906 0.647386 9.85975L2.64563 8.23947V18.1069C2.64563 18.3241 2.82296 18.5 3.04158 18.5H16.9585C17.1772 18.5 17.3549 18.3241 17.3549 18.1069V8.23969L19.3532 9.85998C19.4268 9.91947 19.5155 9.94861 19.6034 9.94861C19.7181 9.94861 19.8322 9.8987 19.9107 9.80382C20.0488 9.63556 20.0241 9.38809 19.8545 9.25059ZM4.7876 3.00941H6.29545V4.26502L4.7876 5.48748V3.00941ZM3.43776 17.7138H16.5624V7.59715L10.3392 2.5525C10.1417 2.39239 9.85818 2.39239 9.66072 2.5525L3.43776 7.59715V17.7138Z"/>
    </svg>
  );
};

export default HomeIcon;
