import { makeObservable, observable } from "mobx"
import { RiskProtectionCalculationOutputModelDto } from "../../Models/Contracts/Dashboard/RiskProtectionCalculationOutputModelDto"
import { RiskProtectionCalculationModelDto } from "../../Models/Contracts/Dashboard/RiskProtectionCalculationModelDto"
import { makePersistable } from "mobx-persist-store"

export class RiskProtectionCalculationStore{

    @observable riskprotection?: RiskProtectionCalculationModelDto
    @observable riskprotectionOutput?: RiskProtectionCalculationOutputModelDto

    constructor(){
        makeObservable(this);
        makePersistable(this, { name: 'RiskProtectionCalculationStore', properties: ['riskprotection', 'riskprotectionOutput'], storage: window.sessionStorage });
    }
}