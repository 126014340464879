import WebClient from "../../Libs/WebClient/WebClient";
import { AuthorizationResponseDto } from "../../Models/Contracts/Auth/AuthorizationResponseDto";
import { RegisterDto } from "../../Models/Contracts/Register/RegisterDto";
import { RegisterPasswordDto } from "../../Models/Contracts/Register/RegisterPasswordDto";
import { GlobalStore } from "../Stores/MainStore";
import { AuthActions } from "./AuthActions";

export class RegisterActions{

    static Register(model: RegisterDto) {
        return WebClient
            .Create()
            .Post(`auth/register`, model)
    }

    static SetPassword(model: RegisterPasswordDto) {
        return WebClient
            .Create()
            .Post<AuthorizationResponseDto>(`auth/password`, model)
            .onSuccess((response) =>{
                GlobalStore.Auth.SetAuthorization(response!.data)
            })
            .onFail(() =>{

            })
    }
    static ForgetPassword(email: string){
        return WebClient
        .Create()
        .Post(`auth/password/reset`, { email })
        .onSuccess((response) =>{
            // GlobalStore.Auth.SetAuthorization(response!.data)
        })
        .onFail(() =>{

        })
    }
}