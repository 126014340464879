import React from 'react';
import Page from '../../Molecules/Page/Page';
import OnboardingLayout from '../../Organisms/Layout/OnboardingLayout';
import styles from './DashboardHealthSuccessPage.module.scss';
import SuccessPageLayout from "../../Organisms/Layout/SuccessPageLayout";
import FooterNavBar from '../../Molecules/Navbar/FooterNavbar';
import t from '../../../App/Libs/Translate/Translate'

const DashboardHealthSuccessPage = () => {

  function renderFooter() {
    return <FooterNavBar linkToNext="/Dashboard/RiskProtectionDetails"
                         linkToBack="/Dashboard/HealthCheck"
                         buttonBackText={t('common.back')}
                         buttonNextText={t('common.end')}/>
  }

  return (
    <Page>
      <OnboardingLayout footer={renderFooter()}
                        selectedStepIndex={0}
                        progress={1}
                        contentMaxWidth='1600px'
      >
        <div className={styles.content}>
          <SuccessPageLayout
            title={t('dashbord.survey.health.success.title')}
          />
        </div>
      </OnboardingLayout>
    </Page>
  );
};

export default DashboardHealthSuccessPage;
