import t from "../../../../App/Libs/Translate/Translate";
import {TransferSource} from "../../../../App/Models/Enums/TransferSource";
import React, {useRef, useState} from "react";
import {useStore} from "../../../../App/Mobx/Infrastructure/mobx-hook";
import WebSync from "../../../Molecules/DataSync/WebSync";
import {PortfolioActions} from "../../../../App/Mobx/Actions/PortfolioActions";
import WarningPopup from "../../../Molecules/Popup/WarningPopup";
import styles from "./DashboardAccountTransferSourcePage.module.scss";
import {Image} from "react-bootstrap";
import commentImg from "../../../../Assets/Images/comment.svg";
import {Button} from "../../../Atoms/Button/Button";
import Page from "../../../Molecules/Page/Page";
import OnboardingLayout from "../../../Organisms/Layout/OnboardingLayout";
import ChooseAnswerPageLayout from "../../../Organisms/Layout/ChooseAnswerPageLayout/ChooseAnswerPageLayout";
import {RouteComponentProps} from "react-router-dom";

const DashboardSelectingPreferStrategyPage = (props: RouteComponentProps) => {

  const data = [
    {
      title: t('dashboard.selecting.prefer.strategy.choice1.title'),
      description: t("dashboard.selecting.prefer.strategy.choice1.description"),
      value: "choice1",
    },
    {
      title: t('dashboard.selecting.prefer.strategy.choice2.title'),
      description: t("dashboard.selecting.prefer.strategy.choice2.description"),
      value: "choice2",
    }
  ]

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { Account } = useStore()

  const webRef = useRef<WebSync>()

  function onPopupExit() {
    setShowPopup(false)
  }

  function onPopupConfirm() {
    onSubmit(TransferSource.FZ)
    setShowPopup(false)
  }

  function onSelectorClick(source: any) {
    if (source === "choice1"){
      props.history.push("/")
    } else {
      props.history.push("/dashboard/portfolio/add/experience")
    }
  }


  function onSubmit(source: TransferSource) {
    webRef.current.Activity.show()

    //TODO FIX
    // PortfolioActions
    //   .PostPortfolioProposal({
    //     productId: Account.product.id,
    //     transferSource: source
    //   })
    //   .onSuccess(response => {
    //     Account.proposalId = response.data.proposalId
    //     Account.transferSource = source
    //     props.history.push('/onboarding/Survey/Financial/Knowledge')
    //     webRef.current.Activity.hide()
    //   })
    //   .onFail(() => webRef.current.Activity.hide())
  }

  const renderPopup = () => {
    return(
      <WarningPopup show={showPopup} onExit={() => onPopupExit()}>
        <div className={styles.warningMain}>
          <div>
            <Image className={styles.warningImage} src={commentImg} alt="" />
          </div>
          <h4 className={styles.warningText}>{t('account.transfer.source.vested_benefits_foundation_popup.title')}</h4>
          <Button className={styles.warningButton} onClick={() => onPopupConfirm()}>{t('account.transfer.source.vested_benefits_foundation_popup.understood')}</Button>
        </div>
      </WarningPopup>
    )
  }

  return  (
    <Page>
      <WebSync ref={webRef}>
        <OnboardingLayout
          contentMaxWidth='1600px'
          selectedStepIndex={1} progress={0.5}>

          <ChooseAnswerPageLayout title={t('dashboard.selecting.prefer.strategy.title')}
                                  subtitle={t('dashboard.selecting.prefer.strategy.subtitle')}
                                  onSelectorClick={onSelectorClick}
                                  data={data}
                                  popup={renderPopup()}/>
        </OnboardingLayout>
      </WebSync>
    </Page>
  )
};

export default DashboardSelectingPreferStrategyPage;