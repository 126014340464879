import React, { useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from '../../../../App/Mobx/Infrastructure/mobx-hook';
import FooterNavBar from '../../../Molecules/Navbar/FooterNavbar';
import Page from '../../../Molecules/Page/Page';
import OnboardingLayout from '../../../Organisms/Layout/OnboardingLayout';
import RiskCarousel from '../../../Organisms/Risk/RiskCarousel';
import t from '../../../../App/Libs/Translate/Translate'
import styles from './RegisterRiskTypePage.module.scss';
import { ProposalParams } from '../../../../App/Models/Params/ProposalParams';


const groupOrder = 2
const questionOrder = 1

const RegisterRiskTypePage: React.FC<RouteComponentProps> = (props) => {

    const [riskTypeIndex, setRiskTypeIndex] = useState<number>()
    const { Account } = useStore()
    const { proposalId } = useParams<ProposalParams>()

    function renderFooter() {
        return <FooterNavBar
                    onNextClick={() => onNextClick()}
                    buttonNextDisabled={riskTypeIndex === 0 ? false : !riskTypeIndex}
                    buttonBackText={t('common.back')}
                    buttonNextText={t('common.apply_and_continue')} />
    }

    function onNextClick(){

        var answer = Account.questionnaire
            .groups[groupOrder - 1]
            .steps[0]
            .questions[questionOrder - 1]
            .answers[riskTypeIndex]

        Account.questionnaire.groups[groupOrder - 1].steps[0].questions[questionOrder - 1].value = {
            ids:  [ answer.id ]
        }


        props.history.push(`/onboarding/${proposalId}/Survey/Capability`)
    }

    function onRiskTypeChange(value: string){
        var index = parseInt(value)
        setRiskTypeIndex(index)
    }

    return (
        <Page>
            <OnboardingLayout
                classNameContent={styles.content}
                footer={renderFooter()}
                selectedStepIndex={2} progress={0.5} contentMaxWidth="100%"
            >
                <div className={styles.main}>
                    <div className={styles.textBlock}>
                        <h1>{t('register.survey.risk.title')}</h1>
                        <h3>{t('register.survey.risk.subtitle')}</h3>
                    </div>

                    <RiskCarousel
                        onInput={e => onRiskTypeChange(e)}/>
                </div>
            </OnboardingLayout>
        </Page>
    );
};

export default RegisterRiskTypePage;
