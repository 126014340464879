import React from "react"
import { IconProps } from '../Menu'

const ProfileIcon = (props: IconProps) => {
  return (
    <svg
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path fill={props.color ?? "white"} fillRule="evenodd" clipRule="evenodd" d="M13.8212 4.7491C13.8212 2.62335 12.1068 0.89336 9.99992 0.89336C7.89298 0.89336 6.17867 2.62335 6.17867 4.7491C6.17867 6.87574 7.89298 8.60529 9.99992 8.60529C12.1068 8.60529 13.8212 6.87574 13.8212 4.7491ZM14.7064 4.7491C14.7064 7.36798 12.5951 9.49865 9.99991 9.49865C7.40476 9.49865 5.2934 7.36798 5.2934 4.7491C5.2934 2.13066 7.40476 0 9.99991 0C12.5951 0 14.7064 2.13066 14.7064 4.7491ZM16.6284 19.1068C16.9268 19.1068 17.2047 18.9728 17.392 18.7388C17.5841 18.498 17.6567 18.1853 17.5907 17.8807C17.5091 17.499 17.4383 17.1269 17.3699 16.7667L17.3681 16.7573C16.7741 13.6127 16.4151 11.7143 10 11.7143C3.58493 11.7143 3.22596 13.6127 2.6315 16.7577C2.56289 17.1209 2.49207 17.4956 2.4093 17.8807C2.34379 18.1858 2.41594 18.498 2.60804 18.7388C2.79483 18.9724 3.07281 19.1068 3.37114 19.1068H16.6284ZM18.2378 16.5898C18.3051 16.9458 18.3751 17.3138 18.4561 17.6908C18.5787 18.2613 18.4419 18.8477 18.0811 19.2993C17.7253 19.7447 17.1959 20.0002 16.6284 20.0002H3.37114C2.80369 20.0002 2.27474 19.7447 1.91842 19.2989C1.55812 18.8473 1.42134 18.2613 1.54395 17.6908C1.6254 17.3138 1.69489 16.9462 1.76173 16.5902L1.76181 16.5898C2.37128 13.3668 2.85269 10.8209 10 10.8209C17.1468 10.8209 17.6283 13.3667 18.2377 16.5893L18.2378 16.5898Z"/>
    </svg>
  );
};

export default ProfileIcon;
