import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Image } from 'react-bootstrap';
import { Callback } from '../../../App/Models/Callback';
import addImg from '../../../Assets/Images/add.svg';
import Carousel, { CarouselEffects } from '../../Molecules/Carousel/Carousel';
import SwitchRadio from '../../Atoms/Switch/SwitchRadio';
import DetailInfoPopup from '../Popup/DetailInfoPopup'
import arrowLeftIcon from "../../../Assets/Images/arrow-left.svg"
import arrowRightIcon from "../../../Assets/Images/arrow-right.svg"
import styles from './StrategyCarousel.module.scss';
import { StrategyDto } from '../../../App/Models/Contracts/Strategy/StrategyDto';
import { ModelPortfolioDto } from '../../../App/Models/Contracts/Strategy/ModelPortfolioDto';
import { PortfolioActions } from '../../../App/Mobx/Actions/PortfolioActions';
import { ArcElement, Chart } from 'chart.js';
import {Button} from "../../Atoms/Button/Button";
import Link from "../../Atoms/Link/Link";
import StrategyRisksInfo, {Disclaimer, StrategyDetails} from "../StrategyRisksInfo/StrategyRisksInfo";
import t from '../../../App/Libs/Translate/Translate'



export type StrategyCarouselProps = {
    className?: string,
    onChange?: Callback<ModelPortfolioDto>,
    data?: StrategyDto
}

Chart.register(ArcElement)


const StrategyCarousel: React.FC<StrategyCarouselProps> = (props) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [isDetailInfoPopupOpen, setIsDetailInfoPopupOpen] = useState<boolean>(false)
    const [details, setDetails] = useState<ModelPortfolioDto>()
    const [detailsIndex, setDetailsIndex] = useState<number>()

    function handleMoreInfoClick(slideIndex: number) {
        setDetails(undefined)
        showDetailInfoPopup(slideIndex)
    }

    function showDetailInfoPopup(index: number) {
        PortfolioActions
            .GetModelPortfolio(props.data.modelPortfolios[index].id)
            .onSuccess(response => {
                setDetails(response.data)
            })
        setDetailsIndex(index)
        setIsDetailInfoPopupOpen(true)
    }

    function hideDetailInfoPopup() {
        setIsDetailInfoPopupOpen(false)
    }

    function onInput(slide: ModelPortfolioDto) {
        props.onChange(slide)
    }

    useEffect(() => {
        setInitalIndex()
    }, [props.data])

    function renderSlides() {
        return props.data?.modelPortfolios?.map((slide, index) => {
            var isRecommanded = slide.riskCategory?.id === props.data.calculatedRisk.id
            return (
                <div key={index} className={styles.slide}>
                    <div className={styles.slideContent}>
                        <div className={styles.slidePagination}>
                            <span className={styles.slideNumber}>{`0${index + 1}`}</span>
                            <span className={styles.slidePaginationDelimeter}></span>
                            <span className={styles.slidesNumber}>{`0${props.data?.modelPortfolios.length}`}</span>
                        </div>

                        <span className={styles.slideTitle}>strategy <div>{slide.name}</div></span>

                        <div className={styles.slideAction} onClick={() => handleMoreInfoClick(index)}><Image src={addImg} /> &nbsp;  {t('common.more_info')}</div>
                    </div>

                    <div className={styles.slideSwitch}>
                        <SwitchRadio value={index} name="strategy" onInput={() => onInput(slide)} isDefaultChecked={isRecommanded} />
                    </div>

                    {isRecommanded && (
                        <span className={styles.recommended}>{t('account.strategy.choose.recommendation')}</span>
                    )}
                </div>
            )
        })
    }

    function onLeftArrowClick() {
        setSelectedIndex(selectedIndex - 1)
    }

    function onRightArrowClick() {
        setSelectedIndex(selectedIndex + 1)
    }

    function setInitalIndex() {
        let obj = props.data?.modelPortfolios.find(x => x.riskCategory.id === props.data.calculatedRisk.id)
        setSelectedIndex(props.data?.modelPortfolios.indexOf(obj))
    }

    const handleConfirmButtonClick = () => {
        onInput(details);
        hideDetailInfoPopup();
    }


    return (
        <div className={classNames(styles.main, props.className)}>
            <Carousel
                effect={CarouselEffects.Scale}
                className={styles.carousel}
                slidesClassName={styles.slidesContainer}
                indicatorClassName={styles.indicator}
                selectedIndex={selectedIndex}
            >
                {renderSlides()}
            </Carousel>

            <div className={styles.arrowButtonsWrapper}>
                {selectedIndex !== 0 && (
                    <button className={styles.leftArrowButton} onClick={() => onLeftArrowClick()}>
                        <img src={arrowLeftIcon} alt="previous slide button" />
                    </button>
                )}

                {selectedIndex + 1 !== props.data?.modelPortfolios?.length && (
                    <button className={styles.rightArrowButton} onClick={() => onRightArrowClick()}>
                        <img src={arrowRightIcon} alt="next slide button" />
                    </button>
                )}
            </div>

            <DetailInfoPopup show={isDetailInfoPopupOpen} onExit={hideDetailInfoPopup}>
                {/* <div className={styles.slidePagination}>
                  <span className={styles.slideNumber}>{`0${selectedIndex+1}`}</span>
                  <span className={styles.slidePaginationDelimeter}></span>
                  <span className={styles.slidesNumber}>{`0${props.data?.modelPortfolios?.length}`}</span>
              </div> */}
                <h3 className={styles.detailInfoTitle}>strategy<br />{props.data?.modelPortfolios[detailsIndex]?.name}</h3>

                {details &&
                  <>
                      <StrategyRisksInfo details={details} data={props.data} detailsIndex={detailsIndex}/>
                      <StrategyDetails/>
                      <Disclaimer/>
                      <Button className={styles.confirmStrategyButton} withArrow={true} onClick={handleConfirmButtonClick}>
                          {t('account.strategy.choose.popup.confirm')}
                      </Button>
                      <Link className={styles.closePopupLink} onClick={hideDetailInfoPopup}>{t('common.conclude')}</Link>
                  </>
                }
            </DetailInfoPopup>
        </div>
    );
};


export default StrategyCarousel;
